import React from "react";
import {
    QPDS_QP_SETTER,
  QPDS_SETTER_UPLOAD_QP,
  RE_EXAM_QPDS_SETTER,
  RE_EXAM_QPDS_SETTER_CREATE,
  RE_EXAM_QPDS_SETTER_UPLOAD_QP,
} from "../../utils/Examination.apiConst";
import { useState } from "react";
import { useEffect } from "react";
import { Http } from "../../Services/Services";
import Select from "react-select";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { LOCAL_EMPLOYEE } from "../../utils/LocalStorageConstants";
import { AiOutlineArrowRight } from "react-icons/ai";

const ModalNewExamSetter = ({ time_table_id }) => {
  const [data, setData] = useState([]);

  const employee = JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE));

  const [flag, setFlag] = useState(false);

  const [questionPaper, setQuestionPaper] = useState(null);

  const [questionPaperRemark, setQuestionPaperRemark] = useState("");

  const [addNewObj, setAddNewObj] = useState({
    staff_full_name: "",
    employee_id: "",
    staff_email_id: "",
    question_paper_set: "A",
    is_employee: true,
  });

  const clearAddNewObj = () => {
    setAddNewObj({
      staff_full_name: "",
      employee_id: "",
      staff_email_id: "",
      question_paper_set: "A",
      is_employee: true,
    });
  };

  const getData = async () => {
    await Http.get(`${QPDS_QP_SETTER}?time_table_id=${time_table_id}`)
      .then((res) => {
        setData(res.data.data);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const handleAddSetter = async () => {
    await Http.post(`${QPDS_QP_SETTER}`, {
      ...addNewObj,
      time_table_id,
    })
      .then((res) => {
        toast.success("Added Successfully");
        clearAddNewObj();
        getData();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const getQuestionPaper = async (id) => {
    await Http.get(`${QPDS_SETTER_UPLOAD_QP}?qp_setter_id=${id}`)
      .then((res) => {
        console.log(res);
        setQuestionPaper(res.data.data);
        setFlag(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ApproveDeclinePaper = async (status, id) => {
    if (status == "REJECTED" && questionPaperRemark == "")
      return toast.error("Please add remark to reject");

    Http.put(`${QPDS_SETTER_UPLOAD_QP}/${id}`, {
      status: status,
      remark: questionPaperRemark,
    })
      .then((res) => {
        console.log(res);
        toast.success("Success");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong");
      });

    getQuestionPaper(questionPaper.qp_setter_id);
  };

  useEffect(() => {
    if (time_table_id) getData();
  }, [time_table_id]);

  return (
    <div
      className="modal fade"
      id="modal_setter"
      tabIndex={-1}
      role="dialog"
      aria-labelledby=""
      aria-hidden="true"
    >
      <div className="modal-dialog mw-75 w-100 modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Paper Setter
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {flag ? (
            <div className="modal-body">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setFlag(false);
                  setQuestionPaper(null);
                }}
              >
                Back
              </button>
              <table className="table mt-3">
                <tr>
                  <th></th>
                  <th>Link</th>
                  <th>Remark</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
                {questionPaper ? (
                  <tr>
                    <th> Question Paper</th>
                    <td>
                      <a href={questionPaper?.file_url} target="_blank">
                        Click Here
                      </a>
                    </td>
                    <td>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Enter Remark Here"
                        value={questionPaperRemark}
                        onChange={(e) => {
                          setQuestionPaperRemark(e.target.value);
                        }}
                      />
                    </td>
                    <td>{questionPaper?.status}</td>
                    <td>
                      <button
                        className="btn btn-success mr-3"
                        onClick={() => {
                          ApproveDeclinePaper("APPROVED", questionPaper?.id);
                        }}
                      >
                        Approve
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          ApproveDeclinePaper("REJECTED", questionPaper?.id);
                        }}
                      >
                        Decline
                      </button>
                    </td>
                  </tr>
                ) : (
                  <tr>No Question Paper Found</tr>
                )}
              </table>
            </div>
          ) : (
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <tr>
                        <th>Sl.No</th>
                        <th>Is Employee</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Question Paper set</th>
                        <th>status</th>
                        <th>Action</th>
                      </tr>

                      {data && data?.length != 0 ? (
                        data?.map((i, key) => (
                          <tr>
                            <td>{key + 1}</td>
                            <td>{i?.is_employee ? "YES" : "NO"}</td>
                            <td>{i?.staff_full_name}</td>
                            <td>{i?.staff_email_id}</td>
                            <td>{i?.question_paper_set}</td>
                            <td>
                              <span
                                className={`badge badge-soft-${
                                  i?.status == "PENDING"
                                    ? "warning"
                                    : i?.status == "APPROVED"
                                    ? "success"
                                    : "danger"
                                }`}
                              >
                                {i?.status == "INACTIVE"
                                  ? "Deleted"
                                  : i?.status}
                              </span>
                            </td>
                            <td>
                              <a
                                role="button"
                                class="badge badge-light p-1 mr-2"
                                data-toggle="tooltip"
                                title="View Uploaded document"
                                onClick={() => {
                                  getQuestionPaper(i?.id);
                                }}
                              >
                                <i class="ri-file-pdf-line"></i>
                              </a>
                              {/* <a href="" role="button" class="badge badge-light text-success p-1 mr-2" data-toggle="tooltip" title="Edit" ><i class="ri-edit-2-line"></i></a> */}
                              <a
                                class="badge badge-light text-danger p-1 mr-2"
                                // onClick={() => { handleDeleteAddNewData(i?.id) }}
                              >
                                <i class="ri-delete-bin-2-line"></i>
                              </a>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>Please add A Quesion Paper setter</td>
                        </tr>
                      )}

                      <tr>
                        <td>{data?.length + 1}</td>
                        <td>
                          <select
                            type="number"
                            className="form-control"
                            value={addNewObj?.is_employee}
                            onChange={(e) => {
                              setAddNewObj((prev) => ({
                                ...prev,
                                is_employee: e.target.value,
                              }));
                            }}
                          >
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                          </select>
                        </td>
                        {addNewObj?.is_employee == "true" ||
                        addNewObj?.is_employee === true ? (
                          <>
                            <th>
                              <Select
                                className="font-normal"
                                onChange={(e) => {
                                  setAddNewObj((prev) => ({
                                    ...prev,
                                    employee_id: e?.value,
                                    staff_email_id: employee?.find(
                                      (s) => s?.id == e?.value
                                    )?.email,
                                  }));
                                }}
                                options={employee?.map((s) => {
                                  return {
                                    value: s.id,
                                    label: s?.first_name + " " + s?.last_name,
                                  };
                                })}
                              />
                            </th>
                            <th>
                              {
                                employee?.find(
                                  (s) => s?.id == addNewObj?.employee_id
                                )?.email
                              }
                            </th>
                          </>
                        ) : (
                          <>
                            <th>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="eg. Suresh"
                                value={addNewObj?.staff_full_name}
                                onChange={(e) => {
                                  setAddNewObj((prev) => ({
                                    ...prev,
                                    staff_full_name: e.target.value,
                                  }));
                                }}
                              />
                            </th>
                            <th>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="eg. test@swaminarayanuniversity.ac.in"
                                value={addNewObj?.staff_email_id}
                                onChange={(e) => {
                                  setAddNewObj((prev) => ({
                                    ...prev,
                                    staff_email_id: e.target.value,
                                  }));
                                }}
                              />
                            </th>
                          </>
                        )}
                        <td>
                          <select
                            className="form-control"
                            value={addNewObj?.question_paper_set}
                            onChange={(e) => {
                              setAddNewObj((prev) => ({
                                ...prev,
                                question_paper_set: e.target.value,
                              }));
                            }}
                          >
                            <option value="A">A</option>
                            <option value="B">B</option>
                            <option value="C">C</option>
                            <option value="D">D</option>
                            <option value="E">E</option>
                            <option value="F">F</option>
                          </select>
                        </td>
                        <td>
                          <span className="badge badge-soft-primary">
                            Click here to add <AiOutlineArrowRight />
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn btn-success"
                            onClick={handleAddSetter}
                          >
                            Add
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalNewExamSetter;
