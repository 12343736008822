import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TT_NOTY_GET, TT_NOTY_UPDATE } from '../../utils/InfoUploadingApiConst';

function ViewTTNoty({ setLoading }) {

    const [data, setData] = useState([]);   

    const navigate = useNavigate();

    const getData = async () => {
        const config = {
            method: "get",
            url: TT_NOTY_GET,
            headers: {
                "Content-Type": "application/json",
            },
        };

        await axios(config)
            .then((res) => {
                res.data.data.sort((a, b) => b.id - a.id);
                // res.data.data.forEach((element) => {
                //     element.attachment = JSON.parse(element.attachment);
                // });
                console.log(res.data.data)
                setData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const handleDelete = (id) => {
        const config = {
            method: 'put',
            url: `${TT_NOTY_UPDATE}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data: {
                status: 'INACTIVE'
            }
        }

        axios(config)
            .then(res => {
                setLoading(0)
                toast.success("Success")
                const updatedData = data.map(item => (item.id == id ? { ...item, status: 'INACTIVE' } : item));
                setData(updatedData);
            })
            .catch(err => {
                setLoading(0)
                toast.error("Something Went Wrong")
            })
    }

    const handleApprove = (id) => {
        const config = {
            method: 'put',
            url: `${TT_NOTY_UPDATE}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data: {
                status: 'ACTIVE'
            }
        }

        axios(config)
            .then(res => {
                setLoading(0)
                toast.success("Success")
                const updatedData = data.map(item => (item.id == id ? { ...item, status: 'ACTIVE' } : item));
                setData(updatedData);
            })
            .catch(err => {
                setLoading(0)
                toast.error("Something Went Wrong")
            })
    }

  return (
    <div>

<div class="card">

<div class="card-header">
    {/* <h3 class="text-dark pt-3"> Previous Question Papers </h3> */}
    <h3 class="text-dark pt-3"> TIME TABLE NOTIFICATIONS </h3>
</div>

<div className="card-body">
    <table className="table table-bordered table-hover">
        <thead>
        <tr>
            <th>Sl.No.</th>
            <th>Faculty</th>
            <th>Title</th>
            <th>Attachments</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
        </tr>
        </thead>
        <tbody>

            {
                data && data
                ?.map((item, key) => {
                    return (

                        <tr>
                            <td>{key + 1}</td>
                            <td>{item?.faculty}</td>
                            <td>{item?.title}</td>
                            <td><a href={item?.attachment} target="_blank">View Uploaded File</a></td>
                            <td>{item?.date ? new Date(item.date).toISOString().slice(0, 10) : ''}</td>
                            <td>{item?.status}</td>
                            <td>{item?.status == "INACTIVE" ? <button className="btn btn-danger mr-2" onClick={() => handleApprove(item?.id)}>INACTIVE</button> : <button className="btn btn-success mr-2" onClick={() => handleDelete(item?.id)}>Active</button>}</td>
                        </tr>

                    )
                })
            }
        </tbody>
    </table>
</div>
</div>
      
    </div>
  )
}

export default ViewTTNoty;
