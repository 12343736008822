import { ROUTES } from './../../../Router/routerConfig';


const drop = 'dropdown'
const stat = 'static'
const sheet = 'sheet'

export const NavExamSetter = [
    {
        title:'Upload',
        type:stat,
        route:ROUTES.Examination.ExamSetter.Home,
        icon:<i className="ri-folder-chart-line mr-2" />
    },
]