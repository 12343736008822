import React, { useState } from "react";
import { useEffect } from "react";
import useEmployee from "../../../Hooks/Employee/useEmployee";

import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  PRE_EXAM_TIMETABLE,
  REPORTS_BY_NUMBERS,
  REPORTS_DETAILED,
  REPORTS_DETAILED_ENG,
} from "../../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
//other libraries
import { toast } from "react-toastify";
import axios from "axios";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import * as XLSX from "xlsx/xlsx.mjs";

function PublishReportPharmacy({ setLoading }) {
  //get data from local storage

  //////program data//////

  //function to get data
  const getLocalProgramData = () =>
    localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  useEffect(() => {
    setProgramOpt(getLocalProgramData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  //////College Data//////

  //function to get data
  const getLocalCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  //////Department Data//////

  //function to get data
  const getLocalDeptData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
    department_id: "",
  });

  //main object for data manipulation
  const [mainUser, setMainUser] = useState({
    class_examination_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  const gparef = [
    {
      min: 90,
      max: 100,
      grade: "O",
      gp: 10,
    },
    {
      min: 80,
      max: 89.99,
      grade: "A",
      gp: 9,
    },
    {
      min: 70,
      max: 79.99,
      grade: "B",
      gp: 8,
    },
    {
      min: 60,
      max: 69.99,
      grade: "C",
      gp: 7,
    },
    {
      min: 50,
      max: 59.99,
      grade: "D",
      gp: 6,
    },
    // {
    //   min: 40,
    //   max: 44.99,
    //   grade: "CD",
    //   gp: 0,
    // },
    // {
    //   min: 35,
    //   max: 39.99,
    //   grade: "DD",
    //   gp: 0,
    // },
    {
      min: 0,
      max: 49.99,
      grade: "F",
      gp: 0,
    },
  ];

  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College Engineering and Technology,Kalol",
    },
    {
      id: 1111001,
      name: "FACULTY OF AYURVEDA",
      college_type_id: "AYU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Ayurvedic College,Kalol",
    },
    {
      id: 1111002,
      name: "FACULTY OF LAW",
      college_type_id: "LAW",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Law College, Kalol",
    },
    {
      id: 1111003,
      name: "FACULTY OF NURSING",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  College of Nursing, Kalol",
    },
    {
      id: 1111004,
      name: "FACULTY OF ARTS AND HUMANITIES ",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Arts  College, Kalol",
    },
    {
      id: 1111005,
      name: "FACULTY OF COMMERCE AND MANAGEMENT",
      college_type_id: "COM",
      status: "ACTIVE",
      colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT,Kalol",
    },
    {
      id: 1111010,
      name: "Faculty of Education",
      college_type_id: "EDU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan B.Ed College, Kalol",
      program_name: "Bachelor of Education ",
    },
    {
      id: 1111011,
      name: "FACULTY OF SCIENCE",
      college_type_id: "SCI",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  Science College, Kalol",

      program_name: "Bachelor of Science ",
    },
    {
      id: 1111007,
      name: "FACULTY OF HOMEOPATHY",
      college_type_id: "HOMEOPATHY",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Homoeopathy College, Kalol",
    },
  ];

  const onExport = () => {
    let json_data = [];
    for (const i of reportStat?.studentData) {
      const arr2 = reportStat.marks.filter((s) => s.student_id == i.student_id);
      const collegeId = arr2[0]?.college_id;
      const deptId = arr2[0]?.department_id;
      //   console.log(collegeId);
      // console.log(i?.e_session.substring(0));
      let session = i?.e_session?.toString();
      console.log(typeof i?.e_session);
      session = session.slice(2, 4);

      let sub = 1;
      const obj = {
        ORG_NAME: facultyData?.find((s) => s.id == collegeId)?.colloge_name,
        ORG_NAME_L: "",
        ACADEMIC_COURSE_ID: i?.student_id.substring(5, 9),

        COURSE_NAME: DeptOpt?.find((s) => s.id == deptId)?.name,
        COURSE_NAME_L: DeptOpt?.find((s) => s.id == deptId)?.name,
        STREAM: "",
        STREAM_L: "",
        SESSION: i?.e_session - 1 + "-" + session,
        REGN_NO: i?.student_id,
        RROLL: i?.student_id,
        CNAME: i?.name,
        GENDER: i?.gender.charAt(0).toUpperCase(),
        DOB: i?.dob?.split("T")[0],
        FNAME: i?.father_name,
        MNAME: i?.mother_name,
        PHOTO: "",

        MRKS_REC_STATUS: "O",
        RESULT: AnalyseResult(i?.student_id) ? "Pass" : "Fail",
        YEAR: i?.e_year?.split("T")[0]?.split("-")[0],
        MONTH: i?.e_year?.split("T")[0]?.split("-")[1],
        DIVISION: "",
        GRADE: calculateGradePointEachSub(
          Number(i?.e_marks) +
            (i?.e_grace_marks ? Number(i?.e_grace_marks) : 0) +
            Number(i?.i_marks),
          Number(i?.e_max) + Number(i?.i_max)
        )?.grade,
        PERCNT:
          (
            reportStat?.marks
              ?.filter(
                (s) => s?.student_id == i?.student_id && !s.is_not_countable
              )
              ?.reduce(
                (total, j) =>
                  total +
                  Number(
                    (calculateGradePointEachSub(
                      j.e_marks + j?.i_marks + j?.e_grace_marks,
                      j.e_max + j?.i_max
                    )
                      ? calculateGradePointEachSub(
                          j.e_marks + j?.i_marks + j?.e_grace_marks,
                          j.e_max + j?.i_max
                        )?.gp
                      : 0) * j?.credit
                  ),

                0
              ) /
            reportStat?.marks
              ?.filter(
                (s) => s?.student_id == i?.student_id && !s.is_not_countable
              )
              ?.reduce((total, j) => total + Number(j?.credit), 0)
          ).toFixed(2) == 0.0
            ? "0.00"
            : (
                (reportStat?.marks
                  ?.filter(
                    (s) => s?.student_id == i?.student_id && !s.is_not_countable
                  )
                  ?.reduce(
                    (total, j) =>
                      total +
                      Number(
                        (calculateGradePointEachSub(
                          j.e_marks + j?.i_marks + j?.e_grace_marks,
                          j.e_max + j?.i_max
                        )
                          ? calculateGradePointEachSub(
                              j.e_marks + j?.i_marks + j?.e_grace_marks,
                              j.e_max + j?.i_max
                            )?.gp
                          : 0) * j?.credit
                      ),

                    0
                  ) /
                  reportStat?.marks
                    ?.filter(
                      (s) =>
                        s?.student_id == i?.student_id && !s.is_not_countable
                    )
                    ?.reduce((total, j) => total + Number(j?.credit), 0) -
                  0.5) *
                10
              ).toFixed(2),
        SEM: "I",
        EXAM_TYPE: "REGULAR",
        TOT: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
            0
          ),
        TOT_MRKS: Number(i?.e_max) + Number(i?.i_max),

        TOT_CREDITS: reportStat.marks
          .filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, j) => total + Number(j?.credit), 0),

        TOT_CREDIT_POINTS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, j) =>
              total +
              Number(
                (calculateGradePointEachSub(
                  j.e_marks + j?.i_marks + j?.e_grace_marks,
                  j.e_max + j?.i_max
                )
                  ? calculateGradePointEachSub(
                      j.e_marks + j?.i_marks + j?.e_grace_marks,
                      j.e_max + j?.i_max
                    )?.gp
                  : 0) * j?.credit
              ),

            0
          )
          .toFixed(2),

        GRAND_TOT_MAX: "",
        GRAND_TOT_CREDIT_POINTS: "",
        CGPA: "",
        SGPA: (
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, j) =>
                total +
                Number(
                  (calculateGradePointEachSub(
                    j.e_marks + j?.i_marks + j?.e_grace_marks,
                    j.e_max + j?.i_max
                  )
                    ? calculateGradePointEachSub(
                        j.e_marks + j?.i_marks + j?.e_grace_marks,
                        j.e_max + j?.i_max
                      )?.gp
                    : 0) * j?.credit
                ),

              0
            ) /
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce((total, j) => total + Number(j?.credit), 0)
        ).toFixed(2),

        ABC_NUMBER: i.abc_number,
        TERM_TYPE: "",
      };
      console.log(Number(i?.e_marks) + "+" + Number(i?.i_marks));
      for (const j of arr2) {
        // obj=["ABC_ACCOUNT_ID"]=j.abc_number;
        console.log(j);
        obj["SUB" + sub + "NM"] = j.subject;
        obj["SUB" + sub] = j.sub_code;

        obj["SUB" + sub + "_TH_MAX"] = j.type == "LECTURE" ? j.e_max : " ";
        obj["SUB" + sub + "_PR_MAX"] = j.type == "PRACTICAL" ? j.e_max : " ";
        obj["SUB" + sub + "_CE_MAX"] = j.i_max;
        obj["SUB" + sub + "_TH_MRKS"] = j.type == "LECTURE" ? j.e_marks : " ";
        obj["SUB" + sub + "_PR_MRKS"] = j.type == "PRACTICAL" ? j.e_marks : " ";
        obj["SUB" + sub + "_CE_MRKS"] = j.i_marks;
        obj["SUB" + sub + "_TOT"] = j.e_marks + j.e_grace_marks + j.i_marks;
        obj["SUB" + sub + "_GRADE"] =
          calculateGradePointEachSub(
            Number(j?.e_marks) + Number(j?.i_marks) + j?.e_grace_marks
              ? Number(j?.e_grace_marks)
              : 0,
            Number(j?.e_max) + Number(j?.i_max)
          )?.grade == null
            ? "--"
            : calculateGradePointEachSub(
                Number(j?.e_marks) +
                  Number(j?.i_marks) +
                  (j?.e_grace_marks ? Number(j?.e_grace_marks) : 0),
                Number(j?.e_max) + Number(j?.i_max)
              )?.grade;

        obj["SUB" + sub + "_GRADE_POINTS"] = calculateGradePointEachSub(
          j.e_marks + j?.i_marks + j?.e_grace_marks,
          j.e_max + j?.i_max
        )?.gp;

        obj["SUB" + sub + "_CREDIT"] = j?.credit;
        obj["SUB" + sub + "_CREDIT_POINTS"] =
          calculateGradePointEachSub(
            j.e_marks + j?.i_marks + j?.e_grace_marks,
            j.e_max + j?.i_max
          )?.gp * Number(j?.credit);
        obj["SUB" + sub + "_REMARKS"] =
          Number(j?.e_marks) + Number(j?.e_grace_marks) + Number(j?.i_marks) >=
          (Number(j?.e_max) + Number(j?.i_max)) / 2
            ? "CL"
            : j?.e_absent || j?.i_absent
            ? "NCL"
            : "NCL";

        sub++;
      }
      json_data.push(obj);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `Pharmacy Exam  Detail Report.csv`);

    console.log(json_data);
  };

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max) => {
    let marks = maptoHundred(Number(num), Number(max));

    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        return i;
      }
    }
  };

  // Ref for table to export in excel
  const tableRef = useRef();

  //Data for adding time table
  const [addData, setAddData] = useState();

  /////Other Data States for

  //main data
  const [data, setData] = useState([]);

  //class data
  const [classOpt, setClassOpt] = useState([]);

  //semster data
  const [semesterOpt, setSemesterOpt] = useState([]);

  //subject/course Data
  const [courseOpt, setCourseOpt] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState([]);

  // Flag to show or hide reports
  const [flag, setFlag] = useState(0);

  ///////Other states for management//////////

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showDepartment, setShowDepartment] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [reportStat, setReportStat] = useState([]);

  const [examId, setExamId] = useState();

  const [timeTableData, setTimeTableData] = useState([]);

  const [ranks, setRanks] = useState([]);

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getBasicData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
    ]);

    if (flag) return toast.error("Something went wrong");
  };

  //Get Exam Details
  const getData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
    await setShowDepartment(user?.department_id);
    setLoading(0);
  };

  const AnalyseResult = (p) => {
    const arr = reportStat?.marks?.filter((s) => s.student_id == p);
    for (const i of arr) {
      if (i?.e_absent == 1) return false;
      if (
        i.e_marks + i.i_marks + Number(i?.e_grace_marks) <
        (i.e_max + i.i_max) / 2
      )
        return false;
    }
    const percentage =
      (reportStat?.marks
        ?.filter((s) => s?.student_id == p && !s.is_not_countable)
        ?.reduce(
          (total, cur) =>
            total +
            (Number(cur?.e_marks) +
              Number(cur?.e_grace_marks) +
              Number(cur?.i_marks)),
          0
        ) *
        100) /
      reportStat?.marks
        ?.filter((s) => s?.student_id == p && !s.is_not_countable)
        ?.reduce(
          (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
          0
        );
    if (percentage < 50) return false;
    return true;
  };

  const calculateTotalBack = (p) => {
    const arr = reportStat?.marks?.filter((s) => s.student_id == p);
    let count = 0;
    for (const i of arr) {
      if (i?.e_absent == 1) count++;
      else if (
        i.e_marks + i.i_marks + Number(i?.e_grace_marks) <
        (i.e_max + i.i_max) / 2
      )
        count++;
    }
    // console.log (count);

    return count;
  };

  const calculateGrade = (p) => {
    const arr = reportStat?.marks?.filter((s) => s.student_id == p);
    let marks = 0;
    let max = 0;
    for (const i of arr) {
      marks += i.e_marks + i.i_marks + Number(i.e_grace_marks);
      max += i.e_max + i.i_max;
    }

    return calculateGradePointEachSub(marks, max);
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    await setExamId();
    setFlag(0);
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          setMainUser((prev) => ({
            ...prev,
            exam_committee_id: res.data.data[0].id,
          }));
          getBasicData();
          getData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    // getBasicData();
  };

  const getReports = async () => {
    setLoading(1);

    const config = {
      method: "get",
      url: `${REPORTS_DETAILED}/${examId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setReportStat(res.data.data);
        const arr = [];
        for (const i of res.data.data?.studentData) {
          const arr2 = res.data.data.marks.filter(
            (s) => s.student_id == i.student_id
          );
          const total = arr2?.reduce(
            (total, j) => total + j.e_max + j.i_max,
            0
          );
          const obtained = arr2?.reduce(
            (total, j) => total + j.e_marks + j.i_marks,
            0
          );
          const p = (obtained * 100) / total;
          const obj = {
            student_id: i.student_id,
            percentage: p || 0,
          };
          arr.push(obj);
        }

        arr.sort((a, b) => b.percentage - a.percentage);
        console.log("sorted", arr);
        setRanks(arr);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${examId}&status=ACTIVE`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Exam Report",
    sheet: "Exam Report",
  });

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  //useEffects
  useEffect(() => {
    getBasicData();
  }, []);

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  return (
    <div className="CreateExamTimetable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Marks Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Students Marks Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {DeptOpt?.filter(
                              (s) => s.college_id == user?.college_id
                            )?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {!flag ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Date</th>

                              <th>Staff</th>

                              <th>Class</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-success btn-sm p-2 text-white mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i.id);
                                        }}
                                      >
                                        <i
                                          class="ri-article-line "
                                          aria-hidden="true"
                                        >
                                          Results
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={() => setFlag(0)}
                          className="btn btn-primary mr-auto p-2 "
                        >
                          Back
                        </button>
                        <button
                          onClick={onDownload}
                          className="btn btn-success mr-2"
                        >
                          Export
                        </button>
                        {/* <button
                          onClick={onExport}
                          className="btn btn-success p-2"
                        >
                          CSV
                        </button> */}
                      </div>
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                          ref={tableRef}
                        >
                          <tr>
                            <th>Sl No</th>
                            <th>Enrollment</th>
                            <th>Name</th>
                            {timeTableData?.map((i, key) => (
                              <th>{i.course_name}</th>
                            ))}
                            <th>Total Marks</th>
                            <th>Obtained Marks</th>
                            <th>Credits</th>
                            <th>Percentage</th>
                            <th>SGPA</th>
                            <th>GPA</th>
                            <th>Overall Grade</th>
                            <th>Result</th>
                            <th>Rank</th>
                          </tr>
                          {reportStat?.studentData?.map((i, key) => (
                            <tr>
                              <td>{key + 1}</td>
                              <td>{i?.student_id}</td>
                              <td>{i?.name}</td>
                              {timeTableData?.map((j, key) => (
                                <td key={key}>
                                  {reportStat?.marks?.find(
                                    (s) =>
                                      s.student_id == i?.student_id &&
                                      s.course_id == j?.course_id
                                  )?.e_marks +
                                    reportStat?.marks?.find(
                                      (s) =>
                                        s.student_id == i?.student_id &&
                                        s.course_id == j?.course_id
                                    )?.i_marks}
                                  - 
                                  {(() => {
                                    const d = reportStat?.marks?.find(
                                      (s) =>
                                        s.student_id === i?.student_id &&
                                        s.course_id === j?.course_id
                                    );

                                    // console.log('d:', d); 

                                    return  <span>
                                        {(d?.e_marks +
                                          d?.i_marks +
                                          d?.e_grace_marks <
                                        (d?.e_max + d?.i_max) / 2) ? (
                                          <span className="text-danger">F</span>
                                        ) : (
                                          calculateGradePointEachSub(
                                            d?.e_marks +
                                              d?.e_grace_marks +
                                              d?.i_marks,
                                            d?.e_max + d?.i_max
                                          )?.grade
                                        )}
                                      </span>
                                  })()}
                                  
                                </td>
                              ))}
                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, cur) =>
                                      total +
                                      (Number(cur?.e_max) + Number(cur?.i_max)),
                                    0
                                  )}
                              </td>
                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, cur) =>
                                      total +
                                      (Number(cur?.e_marks) +
                                        Number(cur?.e_grace_marks) +
                                        Number(cur?.i_marks)),
                                    0
                                  )}
                              </td>
                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, j) => total + Number(j?.credit),
                                    0
                                  )}
                              </td>
                              <td>
                                {(
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) =>
                                        total +
                                        Number(
                                          (calculateGradePointEachSub(
                                            j.e_marks +
                                              j?.i_marks +
                                              j?.e_grace_marks,
                                            j.e_max + j?.i_max
                                          )
                                            ? calculateGradePointEachSub(
                                                j.e_marks +
                                                  j?.i_marks +
                                                  j?.e_grace_marks,
                                                j.e_max + j?.i_max
                                              )?.gp
                                            : 0) * j?.credit
                                        ),

                                      0
                                    ) /
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) => total + Number(j?.credit),
                                      0
                                    )
                                ).toFixed(2) == 0.0
                                  ? "0.00"
                                  : (
                                      (reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total +
                                            Number(
                                              (calculateGradePointEachSub(
                                                j.e_marks +
                                                  j?.i_marks +
                                                  j?.e_grace_marks,
                                                j.e_max + j?.i_max
                                              )
                                                ? calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max
                                                  )?.gp
                                                : 0) * j?.credit
                                            ),

                                          0
                                        ) /
                                        reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id &&
                                              !s.is_not_countable
                                          )
                                          ?.reduce(
                                            (total, j) =>
                                              total + Number(j?.credit),
                                            0
                                          ) -
                                        0.5) *
                                      10
                                    ).toFixed(2)}
                                {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                              </td>
                              <td>
                                {(
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) =>
                                        total +
                                        Number(
                                          (calculateGradePointEachSub(
                                            j.e_marks +
                                              j?.i_marks +
                                              j?.e_grace_marks,
                                            j.e_max + j?.i_max
                                          )
                                            ? calculateGradePointEachSub(
                                                j.e_marks +
                                                  j?.i_marks +
                                                  j?.e_grace_marks,
                                                j.e_max + j?.i_max
                                              )?.gp
                                            : 0) * j?.credit
                                        ),

                                      0
                                    ) /
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) => total + Number(j?.credit),
                                      0
                                    )
                                ).toFixed(2)}
                                {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                              </td>
                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, j) =>
                                      total +
                                      Number(
                                        (calculateGradePointEachSub(
                                          j.e_marks +
                                            j?.i_marks +
                                            j?.e_grace_marks,
                                          j.e_max + j?.i_max
                                        )
                                          ? calculateGradePointEachSub(
                                              j.e_marks +
                                                j?.i_marks +
                                                j?.e_grace_marks,
                                              j.e_max + j?.i_max
                                            )?.gp
                                          : 0) * j?.credit
                                      ),

                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                              <td>
                                {
                                  calculateGrade(i?.student_id)?.grade
                                }
                              </td>
                              <td>
                                {AnalyseResult(i?.student_id) ? (
                                  <span className="badge badge-soft-success">
                                    Pass{" "}
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-danger">
                                    Fail
                                  </span>
                                )}
                              </td>

                              <td>
                                {ranks?.findIndex(
                                  (s) => s.student_id == i?.student_id
                                ) + 1}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishReportPharmacy;
