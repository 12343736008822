import React from "react";
import { monthsArray } from "../../Data/jsonData/data";
import { LOCAL_COLLEGE } from "../../utils/LocalStorageConstants";
import { useState, useEffect } from "react";

const ReExamHallTicket = ({
  student_id,
  session_id,
  class_id,
  semester_id,
  college_id,
  month,
  year,
  department_id,
  class_examination_id,
  timeTableData,
  enrollmentData,
  studentData,
  subjectOpt,
}) => {
  const CollegeList = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const [collgename, setCollegeName] = useState(
    college_id == 1111008
    ? "Shree Swaminarayan College of Pharmacy"
    : college_id == 1111007
    ? "Shree Swaminarayan College of Homeopathy"
    : college_id == 1111000
    ? "Shree Swaminarayan College of Enginering and Technology"
    : college_id == 1111002
    ? "Shree Swaminarayan Law College"
    : college_id == 1111001
    ? "Shree Swaminarayan Ayurvedic College"
    : college_id == 1111003
    ? "Shree Swaminarayan College of Nursing "
    : college_id == 1111009
    ? "Shree Swaminarayan  College of IT and Computer Science"
    : college_id == 1111010
    ? "Shree Swaminarayan B.Ed. College  "
    : college_id == 1111011
    ? "Shree Swaminarayan Science College  "
    : "Shree Swaminarayan University"
      );
  return (
    <div className="row justify-content-center p-5">
      <div className="col-md-10">
        <div className="card">
          <div className="card-body">
            <div>
              <div className="row d-flex justify-content-around align-items-center ">
                <img
                  src="/assets/images/Nexenstial Logo.png"
                  width={150}
                  alt=""
                  srcset=""
                />

                <div className="text-center">
                  <h3 className="text-uppercase mb-3">
                    <b>Swaminarayan University, Kalol</b>
                  </h3>{" "}
                  <br />
                  <h4 className="text-uppercase">
                    {CollegeList?.find((s) => s.id == college_id)?.name}, KALOL
                  </h4>
                  {CollegeList?.find((s) => s.id == college_id)?.name ==
                  "DOCTORAL AND POST DOCTORAL PROGRAMME" ? (
                    <h5>Ph.D.Course Work Examination 2023 (Batch-1)</h5>
                  ) : (
                    ""
                  )}
                  <br />
                </div>
                <p>Student Photo</p>
              </div>
              <div className="row  ">
                <div className="col-md-12 justify-content-center">
                  <div className="">
                    <h5 className="text-center">
                      Admit Card <br /> ({monthsArray[month - 1] + "-" + year})
                    </h5>
                  </div>{" "}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <table className="table table-bordered">
                    <tr>
                      <td>
                        <p>
                          {" "}
                          <b>Enrollment No.:</b> {studentData?.user_id}
                        </p>
                      </td>

                      <td>
                        <p>
                          {" "}
                          <b>Appearance Type:</b> Re-Exam
                        </p>
                      </td>
                      <td>
                        <p>
                          {" "}
                          <b>Attempts:</b> 2
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <p>
                          {" "}
                          <b>Student Name:</b>{" "}
                          <span
                            className="text-uppercase"
                            style={{
                              fontSize: "18px",
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            {studentData?.name}
                          </span>
                        </p>
                      </td>
                      <td colSpan={"2"}>
                        <p>
                          {" "}
                          <b>Gender: </b> {studentData?.gender}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <p>
                          {" "}
                          <b>Examination Center:</b>{" "}
                          <span
                            className="text-uppercase"
                            style={{
                              fontSize: "18px",
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            {/* {CollegeList?.find(s=>s.id==college_id)?.name == "DOCTORAL AND POST DOCTORAL PROGRAMME" ? "Swaminarayan University, Kalol":CollegeList?.find(s=>s.id==college_id)?.name},  */}
                            {collgename}, Kalol.
                          </span>
                        </p>
                      </td>
                    </tr>
                  </table>

                  <h5>Theory Paper Details</h5>

                  <table
                    className="table table-bordered"
                    width={"100%"}
                    style={{ border: "2px, solid, black" }}
                  >
                    <tr>
                      <th width={"40%"}>Paper Name</th>
                      <th className="text-center">Date</th>
                      <th className="text-center"> Time </th>
                      <th className="text-center"> Invigilator Sign</th>
                    </tr>
                    {timeTableData
                      ?.filter(
                        (s) =>
                          s?.id ==
                          enrollmentData?.find((p) => p?.time_table_id == s?.id)
                            ?.time_table_id
                      )
                      ?.sort(function (a, b) {
                        let dateA = new Date(a?.date?.split("T")[0]);
                        let dateB = new Date(b?.date?.split("T")[0]);
                        return dateA - dateB;
                      })
                      ?.map((i, key) => (
                        <tr>
                          <td>
                            {
                              subjectOpt?.find((s) => s?.id == i?.course_id)
                                ?.name
                            }
                          </td>
                          <td className="text-center">
                            {i?.date?.split("T")[0]}
                          </td>
                          <td className="text-center">
                            {" "}
                            {i?.time_from} to {i?.time_to}
                          </td>
                          <td className="text-center"></td>
                        </tr>
                      ))}
                  </table>

                  {/* <h5>Practical Paper Details</h5> */}

                  {/* <table className="table table-bordered " width={"100%"} style={{ border: "2px, solid, black", marginBottom: "100px" }}>
                          <tr>
                            <th width={"40%"}>Paper Name</th>

                            <th> Examinar Sign and Date</th>
                          </tr>
                          <tr>
                            <td>Kayachikitsa </td>

                            <td></td>
                          </tr>

                          <tr>
                            <td>Panchakarma</td>

                            <td></td>
                          </tr>

                        </table> */}
                  <div className="row mt-4 ">
                    <div className=" col-md-12 d-flex justify-content-between">
                      <div className=" mt-2">
                        <img
                          src="/assets/images/signature/coe sign.png"
                          alt=""
                          width={180}
                        />
                        <h6>(Controller of Examination)</h6>
                      </div>
                      {CollegeList?.find((s) => s.id == college_id)?.name ==
                      "DOCTORAL AND POST DOCTORAL PROGRAMME" ? (
                        <div className="mt-2 text-center ">
                          <img
                            src="/assets/images/signature/phddeansign.png"
                            alt=""
                            width={100}
                          />

                          <h6>
                            Signature of Principal /{" "}
                            {CollegeList?.find((s) => s.id == college_id)
                              ?.name == "DOCTORAL AND POST DOCTORAL PROGRAMME"
                              ? "Dean"
                              : "Director"}
                            , with Stamp{" "}
                          </h6>
                        </div>
                      ) : (
                        <div className="mt-2 text-center align-items-center ">
                          <h6 style={{ marginTop: "5rem" }}>
                            Signature of Principal /{" "}
                            {CollegeList?.find((s) => s.id == college_id)
                              ?.name == "DOCTORAL AND POST DOCTORAL PROGRAMME"
                              ? "Dean"
                              : "Director"}
                            , with Stamp{" "}
                          </h6>
                        </div>
                      )}
                      <div className="mt-5">
                        <h6 style={{ marginTop: "50px" }}>
                          (Candidate Signature)
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <h5>Instuctions</h5>
                      <hr />
                      <p>
                        1. Students will have to take their seats in the exam
                        hall before 30 minutes of exam start time with I-Card/
                        Photo ID.
                      </p>
                      <p>
                        2. Students will be allowed to enter in the exam hall
                        only up to 10 minutes after the exam start time.
                      </p>
                      <p>
                        3. Students will be allowed to leave the exam hall only
                        after the exam end time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReExamHallTicket;
