import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import useEmployee from "../../Hooks/Employee/useEmployee";
import { ROUTES } from "../../Router/routerConfig";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
} from "../../utils/Academics.apiConst";
import {
  STUDENT_SESSION_ALL_INVIGILATORS,
  STUDENT_SESSION_ALL,
} from "../../utils/apiConstants";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  PRE_EXAM_ENROLL_STUDENT,
  PRE_EXAM_ENROLL_STUDENT1,
  PRE_EXAM_TIMETABLE,
} from "../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import HallTicket from "../../Components/PreExamination/HallTicket";
import { SESSION_ROLE } from "../../utils/sessionStorageContants";

function ExamEnrollment({ setLoading }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const role = sessionStorage.getItem(SESSION_ROLE);

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [deptOpt, setDeptOpt] = useState([]);

  const [programOpt, setProgramOpt] = useState([]);

  const [collegeOpt, setCollegeOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [studentLoadingMsg, setStudentLoadingMsg] = useState("");

  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    session_id: "",
    department_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    setDeptOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == user?.college_id
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), user?.college_id]);

  useEffect(() => {
    setCollegeOpt(JSON.parse(localStorage.getItem(LOCAL_COLLEGE)));
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [studentData, setStudentData] = useState([]);

  const [selectedStudent, setSelectedStudent] = useState();

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  const [employeeOpt, setEmployeeOpt] = useState([]);

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [addData, setAddData] = useState();

  const [data, setData] = useState([]);

  const [flag, setFlag] = useState(0);

  const [examId, setExamId] = useState();

  const [timeTableData, setTimeTableData] = useState();

  const [enrollmentData, setEnrollmentData] = useState();

  const [subjectOpt, setSubjectOpt] = useState();

  const getBasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config1)
      .then((res) => {
        setSemesterOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  const getData = async () => {
    setStudentLoadingMsg("Please Wait While we load student data");
    setLoading(1);
    const config = {
      method: "get",
      url: `${
        sessionStorage.getItem("role") != "COE"
          ? STUDENT_SESSION_ALL_INVIGILATORS
          : STUDENT_SESSION_ALL
      }?college_id=${user?.college_id}&department_id=${
        user?.department_id
      }&session_id=${user?.session_id}&class_id=${user?.class_id}&semester_id=${
        user?.semester_id
      }&student_status=ACTIVE`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    console.log(config);

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
    setStudentLoadingMsg("");
  };

  //Get Exam Details
  const getDataExamData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));
    setLoading(0);

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    navigate({
      search: `?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}&session_id=${user.session_id}&department_id=${user?.department_id}&class_id=${user.class_id}&semester_id=${user.semester_id}`,
    });
    setLoading(1);

    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          getDataExamData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    getBasicDetails();
  };

  //get time table data
  const getExamTimeTable = async (exam) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${exam}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${PRE_EXAM_ENROLL_STUDENT}?class_examination_id=${exam}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        setLoading(0);
        console.log(res.data);
        setEnrollmentData(res.data?.enrolledStudent);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  const getBasicDetails = async () => {
    setLoading(1);
    const config3 = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?class_id=${user?.class_id}&semester_id=${user?.semester_id}&college_id=${user?.college_id}&department_id=${user?.department_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        setSubjectOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  useEffect(() => {
    getBasicData();
  }, []);

  const setBasicData = async () => {
    await setUser((prev) => ({
      college_id: searchParams.get("college_id"),
      program_id: searchParams.get("program_id"),
      month: searchParams.get("month"),
      year: searchParams.get("year"),
      session_id: searchParams.get("session_id"),
      department_id: searchParams.get("department_id"),
      class_id: searchParams.get("class_id"),
      semester_id: searchParams.get("semester_id"),
    }));
  };

  useEffect(() => {
    if (
      searchParams.get("program_id") &&
      searchParams.get("college_id") &&
      searchParams.get("month") &&
      searchParams.get("year")
    ) {
      setBasicData();
    }
  }, [searchParams]);

  const [infoData, setInfoData] = useState([
    {
      course: "UG-Engineering-ComputerScience",
      regno: "2KA16CS002",

      fullname: "Mr.Abhishek Badiger",
      year: "1st Year - 2nd Sem",

      subjects: "7",
      status: <p className="badge badge-soft-success">Applied</p>,
    },
    {
      course: "UG-Engineering-Mechanical",
      regno: "2KA16CS002",

      fullname: "Mr.Abhishek Badiger",
      year: "1st Year - 2nd Sem",

      subjects: "5",
      status: <p className="badge badge-soft-danger">Not-Applied</p>,
    },
  ]);

  const handleDelete = (key) => {
    setInfoData(infoData.slice(0, key));
  };

  const handleEdit = async (id, exam_id) => {
    console.log(id, exam_id);
    setLoading(1);
    const config = {
      method: "put",
      url: `${PRE_EXAM_ENROLL_STUDENT1}/${id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        class_examination_id: examId,
        status: "ACTIVE",
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res);
        setLoading(0);
        getExamTimeTable(examId);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  return (
    <div className="ExamEnrollment">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Exam Enrollment</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Exam Enrollment
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {deptOpt
                              ?.filter(
                                (s) =>
                                  s.college_id == user?.college_id &&
                                  s.program_id == user?.program_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            name="session_id"
                            value={user?.session_id}
                            onChange={handleChange}
                            className="form-control"
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option key={key} value={i?.id}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <div>
                        {studentData && studentData?.length != 0 && flag ? (
                          <button
                            className="btn btn-warning btn-sm mr-3"
                            onClick={handlePrint}
                          >
                            <i className="fa fa-download"></i> Download Hall
                            Ticket
                          </button>
                        ) : null}
                        {/* <button className='btn btn-primary btn-sm'><i className='fa fa-download'></i> Download Applications</button> */}
                      </div>
                    </div>{" "}
                    <hr />
                    <div>
                      {flag ? (
                        <button
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            setFlag(0);
                          }}
                        >
                          Back
                        </button>
                      ) : null}
                    </div>
                    <div className="table-responsive">
                      {!flag ? (
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Course</th>
                              <th>Specialization</th>
                              <th>Date</th>

                              <th>Faculties</th>

                              <th>Course Year</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-light text-dark mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i?.id);
                                          getExamTimeTable(i?.id);
                                          getData();
                                        }}
                                      >
                                        <i
                                          class="fa fa-edit "
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Add
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <table
                          id="table_id"
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program - Faculty</th>

                              <th>Enrollment No.</th>
                              <th>Full Name</th>
                              <th>Class - Semester</th>
                              <th className="text-center">
                                {" "}
                                Number of Subjects Applied
                              </th>

                              <th className="text-center">Status</th>

                              <th width="25%" className="text-center">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {studentData && studentData?.length != 0
                              ? studentData?.map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>
                                        {
                                          programOpt?.find(
                                            (s) => s.id == showProgram
                                          )?.name
                                        }{" "}
                                        -{" "}
                                        {
                                          collegeOpt?.find(
                                            (s) => s.id == showCollege
                                          )?.name
                                        }
                                      </td>
                                      <td> {data?.user_id}</td>
                                      <td> {data?.name}</td>
                                      <td>
                                        {
                                          classOpt?.find(
                                            (s) => s.id == user?.class_id
                                          )?.name
                                        }{" "}
                                        -{" "}
                                        {
                                          semesterOpt?.find(
                                            (s) => s.id == user.semester_id
                                          )?.name
                                        }
                                      </td>

                                      <td className="text-center">
                                        {" "}
                                        {
                                          enrollmentData?.filter(
                                            (s) =>
                                              s.student_id == data?.user_id &&
                                              s.status == "ACTIVE"
                                          )?.length
                                        }
                                      </td>
                                      <td className="text-center">
                                        {" "}
                                        {enrollmentData?.filter(
                                          (s) => s.student_id == data?.user_id
                                        )[0]?.status == "ACTIVE" ? (
                                          <span className="badge badge-soft-success">
                                            PAYMENT DONE
                                          </span>
                                        ) : (
                                          <span className="badge badge-soft-danger">
                                            PAYMENT NOT DONE
                                          </span>
                                        )}
                                      </td>
                                      <td className="">
                                        {" "}
                                        <a
                                          data-toggle="modal"
                                          onClick={() => {
                                            sessionStorage.getItem("role") ==
                                            "STAFF"
                                              ? navigate(
                                                  ROUTES.Examination.ExamStaff
                                                    .StudentApplicationForm +
                                                    "/" +
                                                    data?.user_id +
                                                    "?session_id=" +
                                                    data?.session_id +
                                                    "&class_id=" +
                                                    user?.class_id +
                                                    "&semester_id=" +
                                                    user?.semester_id +
                                                    "&college_id=" +
                                                    user?.college_id +
                                                    "&month=" +
                                                    user?.month +
                                                    "&year=" +
                                                    user?.year +
                                                    "&department_id=" +
                                                    user?.department_id +
                                                    "&class_examination_id=" +
                                                    examId +
                                                    "&program_id=" +
                                                    user.program_id
                                                )
                                              : navigate(
                                                  ROUTES.Examination
                                                    .StudentApplicationForm +
                                                    "/" +
                                                    data?.user_id +
                                                    "?session_id=" +
                                                    data?.session_id +
                                                    "&studentPrimaryKey=" +
                                                    data?.id +
                                                    "&class_id=" +
                                                    user?.class_id +
                                                    "&semester_id=" +
                                                    user?.semester_id +
                                                    "&college_id=" +
                                                    user?.college_id +
                                                    "&month=" +
                                                    user?.month +
                                                    "&year=" +
                                                    user?.year +
                                                    "&department_id=" +
                                                    user?.department_id +
                                                    "&class_examination_id=" +
                                                    examId +
                                                    "&program_id=" +
                                                    user.program_id,
                                                  {
                                                    state: {
                                                      data: data,
                                                    },
                                                  }
                                                );
                                          }}
                                          data-target="#create"
                                          className="badge badge-light text-dark mr-3"
                                          title="Edit"
                                        >
                                          {" "}
                                          <i
                                            class="fa fa-edit"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Apply
                                        </a>
                                        <a
                                          data-toggle="modal"
                                          data-target="#view"
                                          className="badge badge-warning text-white mr-3"
                                          title="Print"
                                          onClick={() => {
                                            navigate(
                                              (role == "STAFF"
                                                ? ROUTES.Examination.ExamStaff
                                                    .Hallticket
                                                : ROUTES.Examination
                                                    .Hallticket) +
                                                "/" +
                                                data?.user_id +
                                                "?session_id=" +
                                                data?.session_id +
                                                "&class_id=" +
                                                user?.class_id +
                                                "&semester_id=" +
                                                user?.semester_id +
                                                "&college_id=" +
                                                user?.college_id +
                                                "&month=" +
                                                user?.month +
                                                "&year=" +
                                                user?.year +
                                                "&department_id=" +
                                                user?.department_id +
                                                "&class_examination_id=" +
                                                examId
                                            );
                                          }}
                                          // onClick={async()=>{
                                          //   await setSelectedStudent(data)
                                          //   handlePrint()
                                          // }}
                                        >
                                          <i
                                            class="fa fa-download "
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Hall Ticket
                                        </a>
                                        {enrollmentData?.filter(
                                          (s) => s.student_id == data?.user_id
                                        )[0]?.status ==
                                        "ACTIVE" ? null : enrollmentData?.filter(
                                            (s) => s.student_id == data?.user_id
                                          )[0]?.status == "PENDING" ? (
                                          <button
                                            className="btn btn-primary btn-sm"
                                            onClick={() => {
                                              handleEdit(
                                                data?.user_id,
                                                enrollmentData?.filter(
                                                  (s) =>
                                                    s.student_id ==
                                                    data?.user_id
                                                )[0]?.class_examination_id
                                              );
                                            }}
                                          >
                                            Payment Done
                                          </button>
                                        ) : null}
                                      </td>
                                    </tr>
                                  );
                                })
                              : studentLoadingMsg}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {/* Hall Ticket */}
                    <div style={{ display: "none" }}>
                      <div ref={componentRef}>
                        {studentData?.map((i, key) => (
                          <div className="page-break">
                            <HallTicket
                              student_id={i?.data?.student_id}
                              session_id={i?.data?.session_id}
                              class_id={user?.class_id}
                              semester_id={user?.semester_id}
                              college_id={user?.college_id}
                              month={user?.month}
                              year={user?.year}
                              department_id={user?.department_id}
                              class_examination_id={examId}
                              timeTableData={timeTableData}
                              enrollmentData={enrollmentData?.filter(
                                (s) => s.student_id == i?.user_id
                              )}
                              studentData={i}
                              subjectOpt={subjectOpt}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamEnrollment;
