import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { REPORTS_DETAILED } from "../../utils/Examination.apiConst";

function ResultsCompo({
  setLoading,
  examId,
  setX,
  x,
  examMarks,
  count,
  totalCount,
  stuData,
}) {
  const [reportStat, setReportStat] = useState([]);
  const [reportStat2, setReportStat2] = useState([]);
  const [flag, setFlag] = useState(false);

  const countData = ["1-30", "31-60", "61-90", "91-120", "121-150"];
  var arr;
  arr = Array(count).fill(-1);

  const printMarkSheet = (key) => {
    const arr1 = [];
    let i = key * 30;
    for (let j = 0; j < 30; j++) {
      arr1.push(stuData[i]?.student_id);
      i++;
    }
    getReports(key, arr1);
  };

  const printMarkSheet1 = (key) => {
    const arr1 = [];
    let i = key * 30;
    for (let j = 0; j < totalCount % 30; j++) {
      arr1.push(stuData[i]?.student_id);
      i++;
    }
    getReports(key, arr1);
  };

  const getReports = async () => {
    setLoading(1);

    let url = `${REPORTS_DETAILED}/${examId}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Confusing Data", res.data.data);
        let arr = [];
        for (const i of res.data.data.studentData) {
          const one_student_data = res.data.data.marks.filter(
            (s) => s.student_id == i.student_id
          );
          const result_studentData = Object.values(
            one_student_data.reduce((acc, curr) => {
              const subject = curr.subject;
              if (!acc[subject]) {
                acc[subject] = { ...curr };
              } else {
                acc[subject].e_marks += curr.e_marks;
                acc[subject].e_max += curr.e_max;
                acc[subject].e_min += curr.e_min;
                acc[subject].e_grace_marks += Number(curr.e_grace_marks);
              }
              return acc;
            }, {})
          );
          arr = [...arr, ...result_studentData];
        }
        console.log({ marks: arr, studentData: res.data.data.studentData });
        setReportStat({ marks: arr, studentData: res.data.data.studentData });
        setReportStat2(res.data.data);
        setFlag((flag) => !flag);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getReports();
  }, []);

  return (
    <>
      {!flag ? (
        <h5 className="text-center">Please Wait While we Load Student Data</h5>
      ) : (
        <table
          id="table_id"
          className="table table-bordered"
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th>Sl. No.</th>
              <th>Reg. No.</th>
              <th>Full Name</th>
              {reportStat2?.marks
                ?.filter(
                  (s) => s?.student_id == reportStat2?.marks[0]?.student_id
                )
                ?.map((j, key2) => (
                  <th
                    key={`subject-${key2}`}
                    className="text-center"
                    colSpan="2"
                  >
                    {j?.subject}
                  </th>
                ))}
              <th>Obtained Marks</th>
              <th>Total Marks</th>
              <th>Percentage</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              {reportStat2?.marks
                ?.filter(
                  (s) => s?.student_id == reportStat2?.marks[0]?.student_id
                )
                ?.map((j, index) => (
                  <>
                    <th key={`th-${index}`}>Theory</th>
                    <th key={`pr-${index}`}>Practical</th>
                  </>
                ))}
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          {reportStat?.studentData?.map((i, key) => (
            <tbody>
              <th>{key + 1}</th>
              <th>{i?.student_id}</th>
              <th>{i?.name}</th>
              {reportStat2?.marks
                ?.filter((s) => s?.student_id == i?.student_id)
                ?.map((j, index) => (
                  <>
                    <th>{j?.e_marks}</th>
                    <th>{j?.i_marks}</th>
                  </>
                ))}
              <th>
                {reportStat?.marks
                  ?.filter(
                    (s) => s?.student_id == i?.student_id && !s.is_not_countable
                  )
                  ?.reduce((acc, it) => acc + (it.e_max + it.i_max), 0)}
              </th>
              <th>
                {reportStat?.marks
                  ?.filter(
                    (s) => s?.student_id == i?.student_id && !s.is_not_countable
                  )
                  ?.reduce((acc, it) => acc + (it.e_marks + it.i_marks), 0)}
              </th>
              <th>
                {(
                  (reportStat?.marks
                    ?.filter(
                      (s) =>
                        s?.student_id == i?.student_id && !s.is_not_countable
                    )
                    ?.reduce(
                      (total, cur) =>
                        total +
                        (Number(cur?.e_marks) +
                          Number(cur?.i_marks) +
                          Number(cur?.e_grace_marks)),
                      0
                    ) *
                    100) /
                  reportStat?.marks
                    ?.filter(
                      (s) =>
                        s?.student_id == i?.student_id && !s.is_not_countable
                    )
                    ?.reduce(
                      (total, cur) =>
                        total +
                        (Number(cur?.e_max) +
                          Number(cur?.i_max) +
                          Number(cur?.e_grace_marks)),
                      0
                    )
                ).toFixed(2)}{" "}
                %
              </th>
              <th>
                {examMarks?.postMarks?.find(
                  (s) => s.student_id == i?.student_id
                )?.status == "PUBLISHED" ? (
                  <span className="badge badge-success">Published</span>
                ) : (
                  <span className="badge badge-danger">Not Published</span>
                )}
              </th>
              <th>
                <input
                  type="checkbox"
                  readOnly={
                    examMarks?.postMarks?.find(
                      (s) => s.student_id == i?.student_id
                    )?.status == "PUBLISHED"
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      let arr = [...x];
                      arr.push(i?.student_id);
                      setX(arr);
                    } else {
                      let newArr = x?.filter((s) => s != i?.student_id);
                      setX(newArr);
                    }
                  }}
                  checked={
                    x?.find((s) => s == i?.student_id)
                      ? 1
                      : examMarks?.postMarks?.find(
                          (s) => s.student_id == i?.student_id
                        )?.status == "PUBLISHED"
                      ? 1
                      : examMarks?.postMarks?.find(
                          (s) => s.student_id == i?.student_id
                        )?.status == "ACTIVE"
                      ? 0
                      : 0
                  }
                />
              </th>
            </tbody>
          ))}
        </table>
      )}
    </>
  );
}

export default ResultsCompo;
