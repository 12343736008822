import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { STUDENT_ADMISSION_DETAILS, STUDENT_SESSION, STUDENT_SESSION_ALL } from '../../../../utils/apiConstants'
import { NEW_EXAM_MARKS, NEW_EXAM_TIMETABLE, PRE_EXAM_ENROLL_STUDENT, PRE_EXAM_TIMETABLE, RE_EXAM_MARKS, RE_EXAM_TIMETABLE } from '../../../../utils/Examination.apiConst'
import { useEffect } from 'react'
import { Http } from '../../../../Services/Services'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Slider, Switch } from 'antd'
// import { NEW_EXAM_ENROLLMENT, NEW_EXAM_MARKS, NEW_EXAM_TIMETABLE } from '../../../../utils/Examination.apiConst'

const AddMarks = () => {
    const { examId, time_table_id } = useParams()

    const { examData, setLoading } = useOutletContext()
    const [enrollmentData, setEnrollmentData] = useState([])
    const [timeTableData, setTimeTableData] = useState()

    const [data, setData] = useState([])

    const getEnrollmentData = async () => {
        await Http.get(`${PRE_EXAM_ENROLL_STUDENT}?time_table_id=${time_table_id}&status=ACTIVE`)
            .then(async res => {
                setEnrollmentData(res.data.enrolledStudent);
                await Http.get(`${NEW_EXAM_MARKS}?time_table_id=${time_table_id}`)
                    .then(res2 => {
                        const arr2 = []
                        const arr = res2.data.data
                        for (const i of res.data.enrolledStudent) {
                            const obj = {
                                session_id: examData?.session_id,
                                time_table_id: time_table_id,
                                student_id: i?.student_id,
                                enrollment_id: i?.id,
                            }
                            if (!arr.find(j => j.student_id === i.student_id)) {
                                obj.marks = ''
                                obj.absent= ''
                                obj.note=''
                                obj.marks_id=''
                            }
                            else{
                                obj.marks = arr.find(j => j.student_id === i.student_id).marks
                                obj.absent = arr.find(j => j.student_id === i.student_id).absent
                                obj.note = arr.find(j => j.student_id === i.student_id).note
                                obj.marks_id = arr.find(j => j.student_id === i.student_id).id
                            }
                            arr2.push(obj)
                        }
                        setData(arr2)
                    })
            }
            )
            .catch(err => {
                console.log(err);
            }
            )
    }

    const getTimeTableData = async () => {
        await Http.get(`${NEW_EXAM_TIMETABLE}/${time_table_id}`)
            .then(res => {
                setTimeTableData(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSubmit = () => {
        for (const i of data) {
            if (!i.marks && !i.absent) return toast.error('Please enter marks or select absent studentNo :: ' + i.student_id)
            if (i.absent && !i.note) return toast.error('Please enter note for absent studentNo :: ' + i.student_id)
        }

        Http.post(`${NEW_EXAM_MARKS}/bulk`, {data:JSON.stringify(data)})
        .then(res=>{
            toast.success('Marks added successfully')
        })
        .catch(err=>{
            console.log(err);
        })
    }

    useEffect(() => {
        if (examData) {
            getEnrollmentData()
        }
    }, [examData])

    useEffect(() => {
        getTimeTableData()
    }, [])
    return (
        <div className='card'>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="card-header">Enroll students - <strong>Subject Name</strong></h6>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12 d-flex justify-content-end">
                        <button onClick={handleSubmit} className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <tr>
                                <th>Sl.No</th>
                                <th>USN</th>
                                <th>Subject</th>
                                <th>Max Marks</th>
                                <th>Min Marks</th>
                                <th>Obtained Marks</th>
                                <th>Absent</th>
                                <th>Note</th>
                                {/* <th>Action</th> */}
                            </tr>
                            {
                                data?.map((i, key) => (
                                    <tr>
                                        <td>{key + 1}</td>
                                        <td>{i?.student_id}</td>
                                        <td>{timeTableData?.course_name}</td>
                                        <td>{timeTableData?.max_marks}</td>
                                        <td>{timeTableData?.min_marks}</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Marks"
                                                max={timeTableData?.max_marks}
                                                min={timeTableData?.min_marks}
                                                value={i?.marks}
                                                onChange={(e) => {
                                                    if (e.target.value > timeTableData?.max_marks) return toast.error('Marks should be less then max marks');
                                                    if (e.target.value < 0) return toast.error('Marks cannot be negative');
                                                    const arr = [...data]
                                                    arr[key].marks = e.target.value
                                                    arr[key].absent = false
                                                    setData(arr)
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Switch
                                                onChange={(e) => {
                                                    const arr = [...data]
                                                    arr[key].absent = e
                                                    arr[key].marks = ''
                                                    setData(arr)
                                                }}
                                                checked={i?.absent}
                                            />
                                        </td>
                                        <td><input
                                            type="text"
                                            placeholder='Enter note if absent'
                                            className="form-control"
                                            value={i?.note}
                                            onChange={(e) => {
                                                const arr = [...data]
                                                arr[key].note = e.target.value
                                                setData(arr)
                                            }}
                                        /></td>
                                        {/* <td>{i?.studentInfo?.name}</td> */}
                                        {/* <td>
                                            Add Marks
                                        </td> */}
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddMarks