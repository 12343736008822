import React, { useEffect, useState } from "react";
import {
  POST_EXAM_REVALUATION,
  RE_EXAM_RECHECKING,
} from "../../utils/Examination.apiConst";
import { toast } from "react-toastify";
import axios from "axios";

const ModalReExamRechecking = ({
  reData,
  setLoading,
  reloadData,
  time_table_id,
}) => {
  const [reScore, setReScore] = useState(reData?.new_marks || "");

  console.log(reData);
  useEffect(() => {
    setReScore(reData?.new_marks || "");
  }, [reData]);

  const handleSubmit = async () => {
    setLoading(true);
    const bdata = {
      marksId: reData?.marksId,
    };
    if (reData?.id) {
      bdata.new_marks = reScore;
      bdata.old_marks = reData?.new_marks;
    } else {
      bdata.old_marks = reData?.marks;
      bdata.new_marks = reScore;
      bdata.time_table_id = time_table_id;
      bdata.marks_id = reData?.marks_id;
      bdata.new_marks_updated_by = sessionStorage.getItem("employee_id");
    }

    const config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${RE_EXAM_RECHECKING}?id=${reData?.id}`,
      data: {
        ...bdata,
      },
    };

    await axios(config)
      .then((res) => {
        toast.success("Rechecking Done");
        reloadData();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    setLoading(false);
  };

  return (
    <div>
      <div
        className="modal fade"
        id="recheck"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog mw-75 w-100 ">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                Rechecking
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="row p-4">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Student Id</label>
                  <input
                    value={reData?.student_id}
                    type="text"
                    disabled
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">Marks Obtained</label>
                  <input
                    value={reData?.id ? reData?.old_marks : reData?.marks}
                    type="number"
                    disabled
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">New Marks Obtained</label>
                  <input
                    value={reScore}
                    onChange={(e) => {
                      setReScore(e.target.value);
                    }}
                    type="number"
                    className="form-control"
                    placeholder="Enter New Marks Obtained"
                  />
                </div>
              </div>
              <div className="col-12 mt-3 d-flex justify-content-between">
                <button
                  data-dismiss="modal"
                  aria-label="Close"
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success"
                  onClick={handleSubmit}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReExamRechecking;
