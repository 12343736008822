import React, { useEffect, useState } from "react";
import { BrowserRouter as RR, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";

import { ROUTES } from "./routerConfig";
import Navbar from "../Components/Navbar/Navbar";
import Topbar from "../Components/Topbar/Topbar";
import Footer from "../Components/Footer/Footer";
import { ALL_DATA, EMPLOYEE } from "../utils/apiConstants";
import {
  LOCAL_COLLEGE,
  LOCAL_COLLEGE_SPECIALIZATION,
  LOCAL_COLLEGE_TYPES,
  LOCAL_DEPARTMENT,
  LOCAL_EMPLOYEE,
  LOCAL_JOBROLES,
  LOCAL_PROGRAM,
  LOCAL_SPECIALIZATION,
  LOCAL_USER_ROLES,
} from "../utils/LocalStorageConstants";

import Loader from "../Components/Loader/Loader";
import {
  SESSION_AUTH,
  SESSION_COLLEGE_ID,
  SESSION_ROLE,
} from "../utils/sessionStorageContants";

///////////////////////////////////////////////////Admission/////////////////////////////////////////////////

import { ExamNavbar } from "../Data/navbar/Examination/ExamNavbar";

import HomeHallTicket from "../Pages/Examination/ReExamination/pages/HomeHallTicket";
import Commitee from "../Pages/Examination/Exam_committee";
import AssignStaff from "../Pages/Examination/Create_Exams";
import CreateQuestionPaper from "../Pages/Examination/CreateQuestionPaper";
import CreateExamTimetable from "../Pages/Examination/CreateExamTimetable";
import ExamSchedule from "../Pages/Examination/ExamSchedule";
import ExamSeatingArrangements from "../Pages/Examination/ExamSeatingArrangements";
import PostExamination from "../Pages/Examination/PostExaminations";
import DownloadQuestionPaper from "../Pages/Examination/DownloadQuestionPaper";
import AssignAnswersheets from "../Pages/Examination/AssignAnswersheets";
import ExamEnrollment from "../Pages/Examination/ExamEnrollment";
import StudentApplicationForm from "../Pages/Examination/StudentApplicationForm";
import DownloadHallTicket from "../Pages/Examination/DownloadHallTicket";

import AssignCommitteeStaff from "../Pages/Examination/CommitteeMembers";
import AddMarkSheetNo from "../Pages/InfoUploading/AddMarkSheetNo.tsx";
import AddInvigilators from "../Pages/Examination/AddInvigilators";
import AddEvaluators from "../Pages/Examination/AddEvaluators";
import HallTickets from "../Pages/Examination/HallTickets";
import DownloadAttendanceList from "../Pages/Examination/DownloadAttendanceList";
import AttendanceList from "../Pages/Examination/AttendanceList";
import AddExamSetter from "../Pages/Examination/AddExamSetter";
import QuestionPaperPattern from "../Pages/Examination/QuestionPaperPattern";
import QuestionPaperPattern2 from "../Pages/Examination/QuestionPaperPattern2";
import AddExamSetter2 from "../Pages/Examination/AddExamSetter2";

//Verification
import ExamSeterVerificationPage from "../Pages/Examination/Verification/ExamSetter";

//ExamSetter
import ExamSetterUpload from "../Pages/Examination/ExamSetter/Upload";
import ExamSetterUploadReExam from "../Pages/Examination/ExamSetter/UploadReExam";
import { NavExamSetter } from "../Data/navbar/Examination/ExamSetter";
import Register from "../Pages/Register/Register";
import RegisterSetter from "../Pages/Register/RegisterSetter";
import GenerateBarcodes from "../Pages/Examination/GenerateBarcodes";
import FromAdmin from "../Pages/RedirectLogins/FromAdmin";
import AddMarks from "../Pages/Examination/AddMarks";

import TimeTableDashboard from "./../Pages/Examination/Timetable/Index";
import PublishMarks from "../Pages/Examination/PublishMarks";
import PharmacyPublishMarks from "../Pages/Examination/PharmacyPublishResult";
import RegisterExamStaff from "../Pages/Register/RegisterExamStaff";
import { NavExamCommittee } from "../Data/navbar/Examination/ExamCommittee";
import BulkBarcode from "../Pages/Examination/BulkBarcode";
import ResultsReport from "../Pages/Examination/Reports/ResultsReport";

//////////////////////////REPORTS////////////////////////////
//////Student Wise

import ReportStudent from "../Pages/Examination/Reports/ReportStudentWise";
import ReportDetailed from "../Pages/Examination/Reports/ReportDetailed";
import RankedEnggReport from "../Pages/Examination/Reports/RankedEnggReport";
import ReportDetailedEng from "../Pages/Examination/Reports/ReportDetailedEng";
import ReportDetailedPharm from "../Pages/Examination/Reports/ReportDetailedPharm";
import PharmacyResultsReport from "../Pages/Examination/PharmacyResultReport";
import SubjectEnrollments from "../Pages/Examination/SubjectEnrollments";
import DigiReportDetailed from "../Pages/Examination/Reports/DigilockerReportDetailed";
import Revaluation from "../Pages/Examination/Revaluation/Revaluation";
import RetrieveBarcode from "../Pages/Examination/RetrieveBarcode";

//////////////////////////////////
////// New Examination  //////////
//////////////////////////////////
import NewExam from "../Pages/Examination/ExamNew/Index";
import NewExamDashboard from "../Pages/Examination/ExamNew/Pages/Dashboard";
import NewExamExamination from "../Pages/Examination/ExamNew/Pages/Create";
import NewExamTimeTable from "../Pages/Examination/ExamNew/Pages/TimeTable";
import NewExamEnrollment from "../Pages/Examination/ExamNew/Pages/Enrollment";
import NewExamEnrollmentTimeTable from "../Pages/Examination/ExamNew/Pages/EnrollmentTimeTable";
import NewExamMarks from "../Pages/Examination/ExamNew/Pages/Marks";
import NewExamAddMarks from "../Pages/Examination/ExamNew/Pages/AddMarks";
import NewExamGraceMarks from "../Pages/Examination/ExamNew/Pages/GraceMarks";
import NewExamAddGraceMarks from "../Pages/Examination/ExamNew/Pages/AddGraceMarks";
import NewExamBarcode from "../Pages/Examination/ExamNew/Pages/Barcode";

import NewExamHomeHallTicket from "../Pages/Examination/ExamNew/Pages/HomeHallTicket";
// import NewExamReportIndex from "../Pages/Examination/ExamNew/Reports/Index";
// import NewExamReportDetailed from "../Pages/Examination/ExamNew/Reports/DetailedReports";

import NewExamPublish from "../Pages/Examination/ExamNew/Pages/Publish";
import NewExamReChecking from "../Pages/Examination/ExamNew/Pages/ReChecking";
import NewExamReCheckingAddMarks from "../Pages/Examination/ExamNew/Pages/AddRecheckingMarks";
import NewExamQuestionPaperPattern from "../Pages/Examination/ExamNew/Pages/QuestionPaperPattern";
import NewExamQuestionPaperSetter from "../Pages/Examination/ExamNew/Pages/QuestionPaperSetter";
import NewExamEvaluator from "../Pages/Examination/ExamNew/Pages/Evaluator";
import NewExamInvigilator from "../Pages/Examination/ExamNew/Pages/Invigilator";

//////////////////////////////////
////// Re-Examination  ///////////
//////////////////////////////////
import ReExam from "../Pages/Examination/ReExamination/Index";
import ReExamExamination from "../Pages/Examination/ReExamination/pages/Create";
import ReExamDashboard from "../Pages/Examination/ReExamination/pages/Dashboard";
import ReExamTImeTable from "../Pages/Examination/ReExamination/pages/TimeTable";
import ReExamEnrollment from "../Pages/Examination/ReExamination/pages/Enrollment";
import ReExamEnrollmentTimeTable from "../Pages/Examination/ReExamination/pages/EnrollmentTimeTable";
import ReExamMarks from "../Pages/Examination/ReExamination/pages/Marks";
import ReExamAddMarks from "../Pages/Examination/ReExamination/pages/AddMarks";
import ReExamGraceMarks from "../Pages/Examination/ReExamination/pages/GraceMarks";
import ReExamAddGraceMarks from "../Pages/Examination/ReExamination/pages/AddGraceMarks";
import ReExamBarcode from "../Pages/Examination/ReExamination/pages/Barcode";
import ReExamReportIndex from "../Pages/Examination/ReExamination/Reports/Index";
import ReExamReportDetailed from "../Pages/Examination/ReExamination/Reports/DetailedReports";
import ReExamMarksSheet from "../Pages/Examination/ReExamination/Reports/MarksSheet.jsx";
import ReExamPublish from "../Pages/Examination/ReExamination/pages/Publish";
import ReExamReChecking from "../Pages/Examination/ReExamination/pages/ReChecking";
import ReExamReCheckingAddMarks from "../Pages/Examination/ReExamination/pages/AddRecheckingMarks";
import ReExamQuestionPaperPattern from "../Pages/Examination/ReExamination/pages/QuestionPaperPattern";
import ReExamQuestionPaperSetter from "../Pages/Examination/ReExamination/pages/QuestionPaperSetter";

//InfoUploading
import ExamNotifications from "../Pages/InfoUploading/ExamNotifications";
import TimeTableNoty from "../Pages/InfoUploading/TimeTableNoty";
import RegisterSetterReExam from "../Pages/Register/RegisterSetterReExam";
import PublishReportPharmacy from "../Pages/Examination/Reports/PublishReportPharmacy";
import PublishReport from "../Pages/Examination/Reports/PublishReport";
import PublishReportEng from "../Pages/Examination/Reports/PublishReportEng";
import Master from "../Pages/Examination/Master/Master";
import PrevYearQuestionPaper from "../Pages/InfoUploading/PrevYearQuestionPaper";
import ViewPrevPaper from "../Pages/InfoUploading/ViewPrevPaper";
import ViewExamNotify from "../Pages/InfoUploading/ViewExamNotify";
import ViewTTNoty from "../Pages/InfoUploading/ViewTTNoty";
import AddGraceMarks from "../Pages/Examination/AddGraceMarks.jsx";
import Create_Exams from "../Pages/Examination/Create_Exams";
import ReportDetailed1 from "../Pages/Examination/Reports/ReportDetailed1.jsx";
import MarksSheet1 from "../Pages/Examination/ReExamination/Reports/MarkSheet1.jsx";

function Router() {
  const [role, setRole] = useState("");
  const [colleges, setColleges] = useState();

  const getRoles = async () => {
    setRole(sessionStorage.getItem("role"));
  };

  const changeCollege = (id) => {
    sessionStorage.setItem(SESSION_COLLEGE_ID, id);
    setCollegeId(id);
  };

  const getCollegeId = () => {
    if (sessionStorage.getItem(SESSION_COLLEGE_ID)) {
      return new Promise((resolve, reject) => {
        console.log(
          "college id yes",
          sessionStorage.getItem(SESSION_COLLEGE_ID)
        );
        resolve(sessionStorage.getItem(SESSION_COLLEGE_ID));
      });
    }
    return new Promise((resolve, reject) => {
      resolve("college id yes", null);
    });
  };

  const [collegeId, setCollegeId] = useState(getCollegeId());

  const getAllData = async () => {
    var config = {
      method: "get",
      url: ALL_DATA,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        localStorage.setItem("ALL_DATA", JSON.stringify(res.data));
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_COLLEGE, JSON.stringify(res.data.college));
        localStorage.setItem(
          LOCAL_COLLEGE_SPECIALIZATION,
          JSON.stringify(res.data.collegeSpecialization)
        );
        localStorage.setItem(
          LOCAL_COLLEGE_TYPES,
          JSON.stringify(res.data.collegeType)
        );
        localStorage.setItem(
          LOCAL_DEPARTMENT,
          JSON.stringify(res.data.department)
        );
        localStorage.setItem(LOCAL_JOBROLES, JSON.stringify(res.data.jobRoles));
        localStorage.setItem(LOCAL_PROGRAM, JSON.stringify(res.data.program));
        localStorage.setItem(
          LOCAL_SPECIALIZATION,
          JSON.stringify(res.data.specialization)
        );
        localStorage.setItem(
          LOCAL_USER_ROLES,
          JSON.stringify(res.data.userRoles)
        );
        localStorage.setItem(LOCAL_EMPLOYEE, JSON.stringify(res.data.employee));
        setColleges(res.data.college);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeeList = async () => {
    var config = {
      method: "get",
      url: `${EMPLOYEE}?college_id=${collegeId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      },
    };

    axios(config).then((res) => {
      localStorage.setItem(LOCAL_EMPLOYEE, JSON.stringify(res.data.data));
    });
  };

  const RouteWithExam = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          setCollegeId(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false) return <Navigate replace to="/login" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar />
        <Navbar data={ExamNavbar} />
        <Element setLoading={setLoading} collegeId={collegeId} />
        <Footer />
      </>
    );
  };

  const RouteWithExamSetter = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          setCollegeId(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar />
        <Navbar data={NavExamSetter} />
        <Element setLoading={setLoading} />
        <Footer />
      </>
    );
  };

  const RouteWithExamCommittee = ({ Element }) => {
    const [loading, setLoading] = useState(0);

    const getAuth = () => {
      return sessionStorage.getItem(SESSION_AUTH) ? true : false;
    };

    const [auth, setAuth] = useState(getAuth());

    const setupCollege = async () => {
      await getCollegeId()
        .then((data) => {
          setCollegeId(data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setupCollege();
    }, []);

    if (auth == false) return <Navigate replace to="/" />;

    return (
      <>
        <Loader loading={loading} />
        <Topbar />
        <Navbar data={NavExamCommittee} />
        <Element setLoading={setLoading} />
        <Footer />
      </>
    );
  };

  useEffect(() => {
    getRoles();
    getAllData();
  }, []);

  return (
    <div>
      <Routes>
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        ////////////////Examination//////////////////////////
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        /////////////////////////////////////////////////////
        <Route
          exact
          path="/verify/setter/:id"
          element={<ExamSeterVerificationPage />}
        />
        <Route exact path="/login" element={<Register />} />
        <Route exact path="/login/setter" element={<RegisterSetter />} />
        <Route
          exact
          path="/login/setter/re-exam"
          element={<RegisterSetterReExam />}
        />
        <Route exact path="/login/exam-staff" element={<RegisterExamStaff />} />
        <Route
          exact
          path={ROUTES.Examination.redirectAuth.FromAdmin + "/:id"}
          element={<FromAdmin />}
        />
        //Upload Information
        <Route
          exact
          path={ROUTES.Admin.InfoUploading.ExamNotification}
          element={<RouteWithExam Element={ExamNotifications} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.InfoUploading.ViewExamNotification}
          element={<RouteWithExam Element={ViewExamNotify} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.InfoUploading.TimeTableUpload}
          element={<RouteWithExam Element={TimeTableNoty} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.InfoUploading.ViewTimeTableUpload}
          element={<RouteWithExam Element={ViewTTNoty} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.InfoUploading.PrevQuestionPaper}
          element={<RouteWithExam Element={PrevYearQuestionPaper} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Admin.InfoUploading.ViewPrevQuestionPaper}
          element={<RouteWithExam Element={ViewPrevPaper} />}
        ></Route>
        <Route
          exact
          path={"/"}
          element={<RouteWithExam Element={Commitee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Commitee}
          element={<RouteWithExam Element={Commitee} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.CreateExams}
          element={<RouteWithExam Element={Create_Exams} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AssignStaff}
          element={<RouteWithExam Element={AssignStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.CreateQuestionPaper}
          element={<RouteWithExam Element={CreateQuestionPaper} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.CreateExamTimetable}
          element={<RouteWithExam Element={CreateExamTimetable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamSchedules}
          element={<RouteWithExam Element={ExamSchedule} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamSeatingArrangements}
          element={<RouteWithExam Element={ExamSeatingArrangements} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.PostExaminations}
          element={<RouteWithExam Element={PostExamination} />}
        ></Route>
        {/* <Route exact path={ROUTES.Examination.OtpVerification} element={<RouteWithExam Element={OtpVerification}/>}></Route> */}
        <Route
          exact
          path={ROUTES.Examination.DownloadQuestionPaper}
          element={<RouteWithExam Element={DownloadQuestionPaper} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AssignAnswerSheets}
          element={<RouteWithExam Element={AssignAnswersheets} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamEnrollment}
          element={<RouteWithExam Element={ExamEnrollment} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.StudentApplicationForm + "/:id"}
          element={<RouteWithExam Element={StudentApplicationForm} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.DownloadHallTickets}
          element={<RouteWithExam Element={DownloadHallTicket} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AssignCommitteeMembers}
          element={<RouteWithExam Element={AssignCommitteeStaff} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AddInvigilators}
          element={<RouteWithExam Element={AddInvigilators} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AddEvaluators}
          element={<RouteWithExam Element={AddEvaluators} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Hallticket + "/:id"}
          element={<RouteWithExam Element={HallTickets} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AttendanceList}
          element={<RouteWithExam Element={AttendanceList} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.DownloadAttendanceList}
          element={<RouteWithExam Element={DownloadAttendanceList} />}
        ></Route>
        {/* <Route exact path={ROUTES.Examination.AddExamSetter} element={<RouteWithExam Element={AddExamSetter}/>}></Route> */}
        {/* <Route exact path={ROUTES.Examination.QuestionPaperPattern} element={<RouteWithExam Element={QuestionPaperPattern}/>}></Route> */}
        <Route
          exact
          path={ROUTES.Examination.QuestionPaperPattern}
          element={<RouteWithExam Element={QuestionPaperPattern2} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AddExamSetter}
          element={<RouteWithExam Element={AddExamSetter2} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.addMarksheetNo}
          element={<RouteWithExam Element={AddMarkSheetNo} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.GenerateBarcodes}
          element={<RouteWithExam Element={GenerateBarcodes} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.RetrieveBarcodes}
          element={<RouteWithExam Element={RetrieveBarcode} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.BulkBarcode}
          element={<RouteWithExam Element={BulkBarcode} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ResultReport}
          element={<RouteWithExam Element={ResultsReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.SubjectEnrollments}
          element={<RouteWithExam Element={SubjectEnrollments} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Addmarks}
          element={<RouteWithExam Element={AddMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.AddGraceMarks}
          element={<RouteWithExam Element={AddGraceMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.PublishResult}
          element={<RouteWithExam Element={PublishMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.PharmacyPublishResult}
          element={<RouteWithExam Element={PharmacyPublishMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Revaluation}
          element={<RouteWithExam Element={Revaluation} />}
        ></Route>
        <Route
          exact
          path={"/tt"}
          element={<RouteWithExam Element={TimeTableDashboard} />}
        ></Route>
        ///////////////////////////Reports ////Student StudentWise
        <Route
          exact
          path={ROUTES.Examination.Reports.StudentWise}
          element={<RouteWithExam Element={ReportStudent} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.Detailed}
          element={<RouteWithExam Element={ReportDetailed} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.Detailed1}
          element={<RouteWithExam Element={ReportDetailed1} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.DetailedEng}
          element={<RouteWithExam Element={ReportDetailedEng} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.DetailedPharm}
          element={<RouteWithExam Element={ReportDetailedPharm} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.PublishPharmReport}
          element={<RouteWithExam Element={PublishReportPharmacy} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.PublishReport}
          element={<RouteWithExam Element={PublishReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.PublishEngReport}
          element={<RouteWithExam Element={PublishReportEng} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.RankedEnggReport}
          element={<RouteWithExam Element={RankedEnggReport} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.Reports.DetailedPharmReport}
          element={<RouteWithExam Element={PharmacyResultsReport} />}
        ></Route>
        //////////////////////Digilocker Reports/////
        <Route
          exact
          path={ROUTES.Examination.Reports.DigiReportDetailed}
          element={<RouteWithExam Element={DigiReportDetailed} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.master}
          element={<RouteWithExam Element={Master} />}
        ></Route>
        {/* Setter */}
        <Route
          exact
          path={ROUTES.Examination.ExamSetter.Home}
          element={<RouteWithExamSetter Element={ExamSetterUpload} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamSetter.HomeReExam}
          element={<RouteWithExamSetter Element={ExamSetterUploadReExam} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.TImeTable}
          element={<RouteWithExamCommittee Element={CreateExamTimetable} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.StudentApplicationForm + "/:id"}
          element={<RouteWithExamCommittee Element={StudentApplicationForm} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.Enrollment}
          element={<RouteWithExamCommittee Element={ExamEnrollment} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.AddEvaluators}
          element={<RouteWithExamCommittee Element={AddEvaluators} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.AddInvigilators}
          element={<RouteWithExamCommittee Element={AddInvigilators} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.Hallticket + "/:id"}
          element={<RouteWithExamCommittee Element={HallTickets} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.HallticketPage}
          element={<RouteWithExamCommittee Element={DownloadHallTicket} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.Addmarks}
          element={<RouteWithExamCommittee Element={AddMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.PublishResult}
          element={<RouteWithExamCommittee Element={PublishMarks} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.PrintBarcode}
          element={<RouteWithExamCommittee Element={GenerateBarcodes} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.StudentApplicationForm + "/:id"}
          element={<RouteWithExamCommittee Element={StudentApplicationForm} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ExamStaff.BulkBarcode}
          element={<RouteWithExamCommittee Element={BulkBarcode} />}
        ></Route>
        <Route
          exact
          path={ROUTES.Examination.ReExamination.Home}
          element={<RouteWithExam Element={ReExamExamination} />}
        />
        <Route
          exact
          path={ROUTES.Examination.NewExamination.Home}
          element={<RouteWithExam Element={NewExamExamination} />}
        />
        {/* Examination new */}
        <Route
          exact
          path={ROUTES.Examination.NewExamination.Home + "/:examId"}
          element={<RouteWithExam Element={NewExam} />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.HomeHallticket}
          element={<NewExamHomeHallTicket />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Dashboard}
          element={<NewExamDashboard />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.TImeTable}
          element={<NewExamTimeTable />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Enrollment}
          element={<NewExamEnrollment />}
        />
        <Route
          path={
            ROUTES.Examination.NewExamination.EnrollmentTimeTable +
            "/:time_table_id"
          }
          element={<NewExamEnrollmentTimeTable />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Marks}
          element={<NewExamMarks />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.AddMarks + "/:time_table_id"}
          element={<NewExamAddMarks />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.graceMarks}
          element={<NewExamGraceMarks />}
        />
        <Route
          path={
            ROUTES.Examination.NewExamination.AddgraceMarks + "/:time_table_id"
          }
          element={<NewExamAddGraceMarks />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.ReChecking}
          element={<NewExamReChecking />}
        />
        <Route
          path={
            ROUTES.Examination.NewExamination.ReChecking + "/:time_table_id"
          }
          element={<NewExamReCheckingAddMarks />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Barcode}
          element={<NewExamBarcode />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Pattern}
          element={<NewExamQuestionPaperPattern />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Setter}
          element={<NewExamQuestionPaperSetter />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Evaluator}
          element={<NewExamEvaluator />}
        />
        <Route
          path={ROUTES.Examination.NewExamination.Invigilator}
          element={<NewExamInvigilator />}
        />
        {/* <Route
            path={ROUTES.Examination.NewExamination.Reports.Home}
            element={<NewExamReportIndex />}
          />
          <Route
            path={ROUTES.Examination.NewExamination.Reports.ReportDetailed}
            element={<NewExamReportDetailed />}
          />
          <Route
            path={ROUTES.Examination.NewExamination.Publish}
            element={<NewExamPublish />}
          />
        </Route>
        {/* Re- Examination */}
        <Route
          exact
          path={ROUTES.Examination.ReExamination.Home + "/:examId"}
          element={<RouteWithExam Element={ReExam} />}
        >
          <Route
            path={ROUTES.Examination.ReExamination.HomeHallticket}
            element={<HomeHallTicket />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Dashboard}
            element={<ReExamDashboard />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.TImeTable}
            element={<ReExamTImeTable />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Enrollment}
            element={<ReExamEnrollment />}
          />
          <Route
            path={
              ROUTES.Examination.ReExamination.EnrollmentTimeTable +
              "/:time_table_id"
            }
            element={<ReExamEnrollmentTimeTable />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Marks}
            element={<ReExamMarks />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.AddMarks + "/:time_table_id"}
            element={<ReExamAddMarks />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.graceMarks}
            element={<ReExamGraceMarks />}
          />
          <Route
            path={
              ROUTES.Examination.ReExamination.AddgraceMarks + "/:time_table_id"
            }
            element={<ReExamAddGraceMarks />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.ReChecking}
            element={<ReExamReChecking />}
          />
          <Route
            path={
              ROUTES.Examination.ReExamination.ReChecking + "/:time_table_id"
            }
            element={<ReExamReCheckingAddMarks />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Barcode}
            element={<ReExamBarcode />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Pattern}
            element={<ReExamQuestionPaperPattern />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Setter}
            element={<ReExamQuestionPaperSetter />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Reports.Home}
            element={<ReExamReportIndex />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Reports.MarksSheet1}
            element={<MarksSheet1 />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Reports.ReportDetailed}
            element={<ReExamReportDetailed />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Reports.MarksSheet}
            element={<ReExamMarksSheet />}
          />
          <Route
            path={ROUTES.Examination.ReExamination.Publish}
            element={<ReExamPublish />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default Router;
