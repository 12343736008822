import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../../../Router/routerConfig";

const Index = () => {
  const { examId } = useParams();
  const navigate = useNavigate();
  const base_navigate =
    ROUTES.Examination.ReExamination.Home + "/" + examId + "/";

  const ChangeDir = (dir) => {
    navigate(base_navigate + dir);
  };
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <h6 className="card-header">Reports</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-12">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3">
              <div
                className="card cursor-pointer"
                onClick={() => {
                  ChangeDir(
                    ROUTES.Examination.ReExamination.Reports.ReportDetailed
                  );
                }}
              >
                <div className="card-body">Detailed Report</div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="card cursor-pointer"
                onClick={() => {
                  ChangeDir(
                    ROUTES.Examination.ReExamination.Reports.MarksSheet
                  );
                }}
              >
                <div className="card-body">Marks Sheet</div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                className="card cursor-pointer"
                onClick={() => {
                  ChangeDir(
                    ROUTES.Examination.ReExamination.Reports.MarksSheet1
                  );
                }}
              >
                <div className="card-body">All Sem Marks Sheet</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
