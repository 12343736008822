
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './app/Router/Router';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from './app/Components/ScrollToTop/ScrollToTop';
//Datatable Modules
import $ from "jquery";

function App() {

  useEffect(() => {
    // $(document).ready(function () {
    //   setTimeout(function () {
    //     $("#datatable").DataTable().destroy();
    //     $("#datatable").DataTable({
    //       columnDefs: [{
    //         "defaultContent": "-",
    //         "targets": "_all"
    //       }]
    //     });
    //   }, 2000);
    // });
  }, [])


  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Router />

        {/* <MainRouter/> */}
        <ToastContainer autoClose={2000} />
      </BrowserRouter>
    </div>
  );
}

export default App;
