import React from "react";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { STUDENT_SESSION_ALL } from "../../../../utils/apiConstants";

import { PRE_EXAM_ENROLL_STUDENT, PRE_EXAM_ENROLL_STUDENT_NEW } from "../../../../utils/Examination.apiConst";

import { useEffect } from "react";
import { Http } from "../../../../Services/Services";
import { useState } from "react";
import { toast } from "react-toastify";
import { NEW_EXAM_ENROLLMENT } from "../../../../utils/Examination.apiConst";

const EnrollmentTimeTable = () => {
  const { examId, time_table_id } = useParams();

  const { examData, setLoading } = useOutletContext();

  const [studentData, setStudentData] = useState([]);
  const [enrollmentData, setEnrollmentData] = useState([]);

  const {state} = useLocation()

  const getEnrollmentData = async () => {
    await Http.get(`${PRE_EXAM_ENROLL_STUDENT}?time_table_id=${time_table_id}`)
      .then((res) => {
        setEnrollmentData(res.data.enrolledStudent);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudentData = async () => {
    setLoading(1);
    await Http.get(
      `${STUDENT_SESSION_ALL}?class_id=${examData?.class_id}&semester_id=${examData?.semester_id}&session_id=${examData?.session_id}`
    )
      .then((res) => {
        setStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const onEnroll = (student_id) => {
    const obj = {
      student_id: student_id,
      time_table_id: time_table_id,
      class_examination_id:examId
    };
    Http.post(`${PRE_EXAM_ENROLL_STUDENT_NEW}`, obj)
      .then((res) => {
        toast.success(res.data.message);
        getEnrollmentData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEnrollAll = async() => {
    const studentArr = studentData?.map((i)=>i.data.student_id)
    const obj = {
      students: studentArr,
      time_table_id: time_table_id,
      class_examination_id:examId
    }

    Http.post(`${PRE_EXAM_ENROLL_STUDENT_NEW}/bulk`, obj)
      .then((res) => {
        toast.success(res.data.message);
        getEnrollmentData();
      })
      .catch((err) => {
        console.log(err);
      });
    

    console.log(studentArr);
  }

  const upDateEnrollment = (id, status) => {
    Http.put(`${PRE_EXAM_ENROLL_STUDENT}/${id}`, { status: status })
      .then((res) => {
        toast.success(res.data.message);
        getEnrollmentData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (examData) {
      getStudentData();
      getEnrollmentData();
    }
  }, [examData]);
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <h6 className="card-header d-flex justify-content-between align-items-center">
              <div>
                Enroll students - <strong>{state?.subject}</strong>
              </div>
              <button onClick={handleEnrollAll} className="btn btn-sm btn-primary">
                Enroll All
              </button>
            </h6>
          </div>
        </div>

        <div className="row mt-2">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tr>
                <th>Sl.No</th>
                <th>USN</th>
                <th>Student Name</th>
                <th>Action</th>
              </tr>
              {studentData?.map((i, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>{i?.data.student_id}</td>
                  <td>{i?.studentInfo?.name}</td>
                  <td>
                    {
                    
                    enrollmentData?.find(
                      (s) =>
                        s.time_table_id == time_table_id &&
                        i.data.student_id == s.student_id
                    ) ? (
                      <button
                        onClick={() => {

                          console.log(enrollmentData?.find(
                            (s) =>
                              s.time_table_id == time_table_id &&
                              i.data.student_id == s.student_id
                          ));
                          if (
                            enrollmentData?.find(
                              (s) =>
                                s.time_table_id == time_table_id &&
                                i.data.student_id == s.student_id
                            ).status == "ACTIVE"
                          ) {
                            upDateEnrollment(
                              enrollmentData?.find(
                                (s) =>
                                  s.time_table_id == time_table_id &&
                                  i.data.student_id == s.student_id
                              ).id,
                              "INACTIVE"
                            );
                          } else
                            upDateEnrollment(
                              enrollmentData?.find(
                                (s) =>
                                  s.time_table_id == time_table_id &&
                                  i.data.student_id == s.student_id
                              ).id,
                              "ACTIVE"
                            );
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        {enrollmentData?.find(
                          (s) =>
                            s.time_table_id == time_table_id &&
                            i.data.student_id == s.student_id
                        ).status == "ACTIVE"
                          ? "Un-Enroll"
                          : "Enroll"}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          onEnroll(i?.data?.student_id);
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        Enroll
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentTimeTable;
