import React from "react";
import "./Topbar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LOCAL_COLLEGE } from "./../../utils/LocalStorageConstants";
import { SESSION_COLLEGE_ID } from "../../utils/sessionStorageContants";
import { ROUTES } from "../../Router/routerConfig";
function Topbar({ changeCollege }) {
  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState();
  const [collegeTitle, setCollegeTitle] = useState("College");
  // const [collegeOpt, setCollegeOpt] = useState()

  const Logout = () => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
    console.log("enterd function");
    sessionStorage.clear();
    navigate("/login");
  };

  const getUserRole = () => {
    return sessionStorage.getItem("role")
      ? sessionStorage.getItem("role")
      : null;
  };

  const getColleges = () => {
    return localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  };

  const [localCollege, setLocalCollege] = useState();

  const [collegeOpt, setCollegeOpt] = useState(getColleges);

  useEffect(() => {
    setRole(getUserRole());
  }, [sessionStorage.getItem("role")]);

  useEffect(() => {
    setCollegeOpt(getColleges);
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  const getCollegeId = () => {
    return sessionStorage.getItem(SESSION_COLLEGE_ID)
      ? sessionStorage.getItem(SESSION_COLLEGE_ID)
      : null;
  };

  const [selectedCollege, setSelectedCollege] = useState(getCollegeId);

  const changeCollegeId = (id) => {
    setSelectedCollege(id);
    changeCollege(id);
  };

  return (
    location.pathname !== "/login" && (
      <div className="Topbar">
        <header id="page-topbar">
          <div className=" d-flex justify-content-between">
            <div className="d-flex justify-content-start mh-100">
              {/* LOGO */}
              <div className="d-flex justify-content-start">
                <a
                  href="home.php"
                  className="d-flex justify-content-center align-items-center px-2"
                  style={{ background: "white" }}
                >
                  <img
                    src="/assets/images/logoWide.png"
                    alt=""
                    className=""
                    width={180}
                  />
                </a>
              </div>
              <button
                type="button"
                className="btn btn-sm px-3 font-size-24 d-lg-none header-item"
                data-toggle="collapse"
                data-target="#topnav-menu-content"
                style={{ marginLeft: "auto" }}
              >
                <i className="ri-menu-2-line align-middle" />
              </button>
            </div>
            {/* <h3 className="text-light text-left d-none d-lg-block">
            SWAMINARAYAN UNIVERSITY, KALOL
          </h3> */}
            <div className="d-flex">
              {role === "REGISTRAR" || role === "SHR" ? (
                <div>
                  <select
                    className="header-item text-right  bg-transparent text-light  border-0 d-sm-block d-none"
                    style={{ outline: "none" }}
                    // id="page-header-user-dropdown"
                    value={selectedCollege}
                    onChange={(e) => {
                      changeCollegeId(e.target.value);
                    }}
                  >
                    {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
                    {collegeOpt?.map((i, key) => (
                      <option className="text-secondary" value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              <div className="">
                <button
                  type="button"
                  className="btn action-btn"
                  onClick={() => {
                    setDropdownOpen((dropdownOpen) => !dropdownOpen);
                    console.log(dropdownOpen);
                  }}
                >
                  <img
                    className="rounded-circle header-profile-user"
                    src="/assets/images/users/avatar-2.jpg"
                    alt="Header Avatar"
                  />
                  <span className="d-none d-xl-inline-block ml-1">
                    {/*?php echo $_SESSION['username'] ?*/}
                  </span>
                  <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
                </button>

                {dropdownOpen ? (
                  <div className="action-list">
                    <ul>
                      <div
                        className="dropdown-item text-danger w-100 cursor-pointer"
                        onClick={() => {
                          Logout();
                        }}
                      >
                        <i className="ri-shut-down-line align-middle mr-1 text-danger" />{" "}
                        Logout
                      </div>
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </header>
      </div>
    )
  );
}

export default Topbar;
