import React from 'react'

const Index = () => {
  return (
    <div className='main-content'>
        <div className="page-content">
            <div className="container-fluid">
                <div className='row'>
                    <div className="col-3">
                        <div className="card p-3 rounded-lg">
                            <div className='d-flex flex-column' style={{gap:'20px'}}>
                                <div className='cursor-pointer bg-primary text-light p-2 pl-3 rounded-pill'>Dashboard</div>
                                <hr className='bg-danger w-75' height={2}/>
                                <div className=' p-2 rounded-pill cursor-pointer'>Evaluator</div>
                                <div className=' p-2 rounded-pill cursor-pointer'>Invaligator</div>
                                <div className=' p-2 rounded-pill cursor-pointer'>Paper Setter</div>
                                <div className=' p-2 rounded-pill cursor-pointer'>Pattern</div>
                                <div className=' p-2 rounded-pill cursor-pointer'></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="card">
                            a
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Index