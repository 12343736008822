import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { TimePicker } from 'antd';
import { Http } from '../../../../Services/Services';
import { NEW_EXAM_TIMETABLE, RE_EXAM_TIMETABLE } from '../../../../utils/Examination.apiConst';
import { ACADEMICS_ADD_SUBJECT } from '../../../../utils/Academics.apiConst';
import { toast } from 'react-toastify';
import { ROUTES } from '../../../../Router/routerConfig';


const Enrollment = () => {
    const { examId } = useParams()
    const navigete = useNavigate()

    const {examData} = useOutletContext()

    console.log(examData);

    const [data, setData] = useState([])
    const [subjectOpt, setSubjectOpt] = useState([])

    const getData = () => {
        Http.get(`${NEW_EXAM_TIMETABLE}?class_examination_id=${examId}`)
            .then(res => {
                setData(res.data.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getSubjectData = () => {
        Http.get(`${ACADEMICS_ADD_SUBJECT}?semester_id=${examData?.semester_id}`)
        .then(res=>{
            setSubjectOpt(res.data.data);
        })
        .catch(err=>{
            console.log(err);
        }
        )
    }


    useEffect(() => {
       if(examId) getData();
    }, [examId])

    useEffect(()=>{
        if(examData?.semester_id) getSubjectData();
    },[examData?.semester_id])
    return (
        <div className='card' style={{maxWidth:'100%',overflow:'scroll'}}>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="card-header">Student Enrollment</h6>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <tr>
                                <th>Sl. No</th>
                                <th style={{minWidth:'150px'}}>Subjects</th>
                                <th>Date</th>
                                <th>Enrolled Students</th>
                                <th >Action</th>
                            </tr>
                            {
                                data && data.map((i, key) => (
                                    <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{subjectOpt?.find(s=>s.id==i?.course_id)?.name}</td>
                                        <td>{i?.date?.split("T")[0]}</td>
                                       <td>{i?.enrollStudentsCount||0}</td>

                                        <th >
                                            <button onClick={()=>{
                                                navigete(ROUTES.Examination.NewExamination.Home+'/'+examId+'/'+ROUTES.Examination.NewExamination.EnrollmentTimeTable+'/'+i?.id, {state:{...i, subject:subjectOpt?.find(s=>s.id==i?.course_id)?.name}})
                                            }} className="btn btn-sm btn-primary">
                                                Enrollment
                                            </button>
                                        </th>
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Enrollment