import React, { useState } from "react";
import { useEffect } from "react";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  PRE_EXAM_TIMETABLE,
  REPORTS_DETAILED,
} from "../../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
//other libraries
import { toast } from "react-toastify";
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";

import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";

function PublishReport({ setLoading }) {
  //get data from local storage

  //////program data//////

  //function to get data
  const getLocalProgramData = () =>
    localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  useEffect(() => {
    setProgramOpt(getLocalProgramData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  //////College Data//////

  //function to get data
  const getLocalCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  //////Department Data//////

  //function to get data
  const getLocalDeptData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College Engineering and Technology,Kalol",
    },
    {
      id: 1111001,
      name: "FACULTY OF AYURVEDA",
      college_type_id: "AYU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Ayurvedic College,Kalol",
    },
    {
      id: 1111002,
      name: "FACULTY OF LAW",
      college_type_id: "LAW",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Law College, Kalol",
    },
    {
      id: 1111003,
      name: "FACULTY OF NURSING",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  College of Nursing, Kalol",
    },
    {
      id: 1111004,
      name: "FACULTY OF ARTS AND HUMANITIES ",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Arts  College, Kalol",
    },
    {
      id: 1111005,
      name: "FACULTY OF COMMERCE AND MANAGEMENT",
      college_type_id: "COM",
      status: "ACTIVE",
      colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT,Kalol",
    },
    {
      id: 1111010,
      name: "Faculty of Education",
      college_type_id: "EDU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan B.Ed College, Kalol",
      program_name: "Bachelor of Education ",
    },
    {
      id: 1111011,
      name: "FACULTY OF SCIENCE",
      college_type_id: "SCI",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  Science College, Kalol",

      program_name: "Bachelor of Science ",
    },
    {
      id: 1111007,
      name: "FACULTY OF HOMEOPATHY",
      college_type_id: "HOMEOPATHY",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Homoeopathy College, Kalol",
    },
  ];

  var converter = require("number-to-words");

  const onExport = () => {
    let json_data = [];
    for (const i of reportStat?.studentData) {
      const arr2 = reportStat.marks.filter((s) => s.student_id == i.student_id);
      const collegeId = arr2[0]?.college_id;
      const deptId = arr2[0]?.department_id;

      let dob = new Date(i?.dob);

      let dodate =
        dob.getDate() +
        "-" +
        parseInt(dob.getMonth() + 1) +
        "-" +
        dob.getFullYear();
      console.log(dodate);
      // console.log(i?.e_session.substring(0));
      let session = i?.e_session?.toString();
      console.log(typeof i?.e_session);
      session = session.slice(2, 4);

      let sub = 1;
      const obj = {
        ORG_NAME: facultyData?.find((s) => s.id == collegeId)?.colloge_name,
        ORG_NAME_L: "",
        ORG_ADDRESS:
          "Swaminarayan University,Shree Swaminarayan Vishvamangal Gurukul,Ahmedabad-Mehsana Highway,At&Po-Saij, Tal.-Kalol Dist-Gandhinagar-382725,Gujarat,India",
        ORG_CITY: "Kalol",
        ORG_STATE: "Gujarat",
        ORG_PIN: "382725",
        ACADEMIC_COURSE_ID: i?.student_id.substring(5, 9),

        COURSE_NAME: DeptOpt?.find((s) => s.id == deptId)?.name,
        COURSE_NAME_L: DeptOpt?.find((s) => s.id == deptId)?.name,
        STREAM: "",
        STREAM_L: "",
        STREAM_SECOND: "",
        STREAM_SECOND_L: "",
        SPECIALIZATION_MAJOR: "",
        SPECIALIZATION_MINOR: "",
        SESSION: i?.e_session - 1 + "-" + session,
        REGN_NO: i?.student_id,
        RROLL: i?.student_id,
        AADHAAR_NO: Number(i?.aadhar_number),
        LOCKER_ID: "",
        ABC_ACCOUNT_ID: Number(i?.abc_number),
        CNAME: i?.name,
        GENDER: i?.gender?.charAt(0).toUpperCase(),
        DOB: dodate?.split("T")[0],
        BLOOD_GROUP: "",
        CASTE: i?.caste,
        RELIGION: "",
        NATIONALITY: i?.nationality,
        PH: "",
        MOBILE: i?.phone,
        EMAIL: i?.email,
        FNAME: i?.father_name,
        MNAME: i?.mother_name,
        GNAME: "",
        STUDENT_ADDRESS:
          i?.current_address == "" || i?.current_address == null
            ? i?.address
            : i?.current_address,
        PHOTO: "",

        MRKS_REC_STATUS: "O",
        RESULT: AnalyseResult(i?.student_id) ? "Pass" : "Fail",
        RESULT_TH: "",
        RESULT_PR: "",
        YEAR: i?.e_year.split("T")[0].split("-")[0],
        MONTH: i?.e_year.split("T")[0].split("-")[1],
        DIVISION: "",
        GRADE: calculateGradePointEachSub(
          Number(i?.e_marks) +
            (i?.e_grace_marks ? Number(i?.e_grace_marks) : 0) +
            Number(i?.i_marks),
          Number(i?.e_max) + Number(i?.i_max)
        )?.grade,
        PERCENT: (
          (reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, cur) =>
                total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
              0
            ) *
            100) /
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
              0
            )
        ).toFixed(2),
        DOR: "",
        DOI: "",
        DOV: "",
        DOP: "",
        DOQ: "",
        DOS: "",
        THESIS: "",
        CERT_NO: "",
        SEM: "I",

        EXAM_TYPE: "REGULAR",
        TERM_TYPE: "",
        TOT: Number(i?.e_max) + Number(i?.i_max),

        TOT_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
            0
          ),

        TOT_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_min) + Number(cur?.i_min)),
            0
          ),
        TOT_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
            0
          ),
        TOT_MRKS_WRDS: converter.toWords(
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, cur) =>
                total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
              0
            )
        ),
        TOT_TH_MAX: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
            0
          ),
        TOT_TH_MIN: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_min) + Number(cur?.i_min)),
            0
          ),
        TOT_TH_MRKS: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce(
            (total, cur) =>
              total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
            0
          ),
        TOT_PR_MAX: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
            0
          ),
        TOT_PR_MIN: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_min) + Number(cur?.i_min)),
            0
          ),
        TOT_PR_MRKS: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce(
            (total, cur) =>
              total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
            0
          ),
        TOT_CE_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.i_max), 0),
        TOT_CE_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.i_min), 0),
        TOT_CE_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.i_marks), 0),
        TOT_VV_MAX: "",
        TOT_VV_MIN: "",
        TOT_VV_MRKS: "",
        GRAND_TOT_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
            0
          ),
        GRAND_TOT_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_min) + Number(cur?.i_min)),
            0
          ),
        GRAND_TOT_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
            0
          ),
        TOT_CREDITS: reportStat.marks
          .filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, j) => total + Number(j?.credit), 0),

        TOT_CREDIT_POINTS:
          i?.credit == 0 || i?.credit == null
            ? ""
            : reportStat?.marks
                ?.filter(
                  (s) => s?.student_id == i?.student_id && !s.is_not_countable
                )
                ?.reduce(
                  (total, j) =>
                    total +
                    Number(
                      calculateGradePoint(
                        ((Number(j?.i_marks) +
                          Number(j?.e_marks) +
                          Number(j?.e_grace_marks)) /
                          (Number(j?.e_max) + Number(j?.i_max))) *
                          Number(j?.credit)
                      )
                    ),

                  0
                )
                .toFixed(2),

        GRAND_TOT_CREDIT: "",
        GRAND_TOT_CREDIT_POINTS: "",
        CGPA: "",
        CGPA_SCALE: "",
        SGPA: (
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, j) =>
                total +
                Number(
                  calculateGradePoint(
                    ((Number(j?.i_marks) +
                      Number(j?.e_marks) +
                      Number(j?.e_grace_marks)) /
                      (Number(j?.e_max) + Number(j?.i_max))) *
                      Number(j?.credit)
                  )
                ),

              0
            ) /
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce((total, j) => total + Number(j?.credit), 0)
        ).toFixed(2),

        //   TOT_GRADE_POINTS: (calculateGradePointEachSub(
        //     Number(i?.e_marks) +
        //     (i?.e_grace_marks ? Number(i?.e_grace_marks) : 0)+Number(i?.i_marks),
        //     Number(i?.e_max) + Number(i?.i_max)
        //   )?.gp).toFixed(2)
      };
      // console.log(reportStat.marks)
      console.log(Number(i?.e_marks) + "+" + Number(i?.i_marks));
      for (const j of arr2) {
        // obj=["ABC_ACCOUNT_ID"]=j.abc_number;

        obj["SUB" + sub + "NM"] = j.subject;
        obj["SUB" + sub] = j.sub_code;
        obj["SUB" + sub + "_MAX"] = j.e_max + j.i_max;
        obj["SUB" + sub + "_MIN"] = j.e_min + j.i_min;
        obj["SUB" + sub + "_TH_MAX"] =
          j.type == "LECTURE" ? j.e_max + j.i_max : " ";
        obj["SUB" + sub + "_TH_MIN"] =
          j.type == "LECTURE" ? j.e_min + j.i_min : " ";
        obj["SUB" + sub + "_PR_MAX"] =
          j.type == "PRACTICAL" ? j.e_max + j.i_max : " ";
        obj["SUB" + sub + "_CE_MAX"] = j.i_max;
        obj["SUB" + sub + "_CE_MIN"] = j.i_min;
        obj["SUB" + sub + "_VV_MAX"] = "";
        obj["SUB" + sub + "_VV_MIN"] = "";
        obj["SUB" + sub + "_TH_MRKS"] =
          j.type == "LECTURE" ? j.e_marks + j.i_marks : " ";
        obj["SUB" + sub + "_PR_MRKS"] =
          j.type == "PRACTICAL" ? j.e_marks + j.i_marks : " ";
        obj["SUB" + sub + "_CE_MRKS"] = j.i_marks;
        obj["SUB" + sub + "_VV_MRKS"] = "";
        obj["SUB" + sub + "_TOT"] = j.e_marks + j.e_grace_marks + j.i_marks;
        obj["SUB" + sub + "_REMARKS"] =
          Number(j?.e_marks) + Number(j?.e_grace_marks) + Number(j?.i_marks) >
          Number(j?.e_min) + Number(j?.i_min)
            ? "PASS"
            : j?.e_absent || j?.i_absent
            ? "FAIL"
            : "FAIL";
        obj["SUB" + sub + "_GRADE"] =
          calculateGradePointEachSub(
            Number(j?.e_marks) + Number(j?.i_marks) + j?.e_grace_marks
              ? Number(j?.e_grace_marks)
              : 0,
            Number(j?.e_max) + Number(j?.i_max)
          )?.grade == null
            ? "--"
            : calculateGradePointEachSub(
                Number(j?.e_marks) +
                  Number(j?.i_marks) +
                  (j?.e_grace_marks ? Number(j?.e_grace_marks) : 0),
                Number(j?.e_max) + Number(j?.i_max)
              )?.grade;

        obj["SUB" + sub + "_GRADE_POINTS"] =
          j?.e_grace_marks != null
            ? (
                ((Number(j?.e_marks) +
                  Number(j?.e_grace_marks) +
                  Number(j?.i_marks)) *
                  100) /
                (Number(j?.e_max) + Number(j?.i_max))
              ).toFixed(2) / 10
            : (
                ((Number(j?.e_marks) + Number(j?.i_marks)) * 100) /
                (Number(j?.e_max) + Number(j?.i_max)) /
                10
              ).toFixed(2);

        obj["SUB" + sub + "_CREDIT"] = j?.credit;
        obj["SUB" + sub + "_CREDIT_POINTS"] = calculateGradePoint(
          ((Number(j?.i_marks) +
            Number(j?.e_marks) +
            Number(j?.e_grace_marks)) /
            (Number(j?.e_max) + Number(j?.i_max))) *
            Number(j?.credit)
        );

        // obj["SUB" + sub + "_REMARKS"] =
        //   Number(j?.e_marks) + Number(j?.e_grace_marks) > Number(j?.e_min) &&
        //   Number(j?.i_marks >= j?.i_min)
        //     ? "CL"
        //     : j?.e_absent || j?.i_absent
        //     ? "NCL"
        //     : "NCL";
        // console.log("Subject Name" + j?.e_absent);
        obj["SUB" + sub + "_GRACE"] = Number(j?.e_grace_marks);
        sub++;
        // submarks++
      }
      json_data.push(obj);
    }
    // var worksheet = XLSX.utils.json_to_sheet(json_data);
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, worksheet);
    // XLSX.writeFile(wb, `Exam  Detail Report.csv`);

    console.log(json_data);
  };
  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max) => {
    // console.log(d);
    let marks = maptoHundred(Number(num), Number(max));
    // console.log(num, max);

    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        // console.log(i, marks);
        return i;
      }
    }
  };

  const gparef = [
    {
      min: 85,
      max: 100,
      grade: "O+",
      gp: 10,
    },

    {
      min: 70,
      max: 84.99,
      grade: "O",
      gp: 8,
    },
    {
      min: 60,
      max: 69.99,
      grade: "A",
      gp: 7,
    },
    {
      min: 55,
      max: 59.99,
      grade: "B+",
      gp: 6,
    },
    {
      min: 48,
      max: 54.99,
      grade: "B",
      gp: 5,
    },
    {
      min: 36,
      max: 47.99,
      grade: "C",
      gp: 4,
    },
    {
      min: 0,
      max: 35.99,
      grade: "D",
      gp: 0,
    },
  ];

  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
    department_id: "",
  });

  //main object for data manipulation
  const [mainUser, setMainUser] = useState({
    class_examination_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  // Ref for table to export in excel
  const tableRef = useRef();

  //Data for adding time table
  const [addData, setAddData] = useState();

  /////Other Data States for

  //main data
  const [data, setData] = useState([]);

  //class data
  const [classOpt, setClassOpt] = useState([]);

  //semster data
  const [semesterOpt, setSemesterOpt] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState([]);

  // Flag to show or hide reports
  const [flag, setFlag] = useState(0);

  ///////Other states for management//////////

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showDepartment, setShowDepartment] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [reportStat, setReportStat] = useState([]);
  const [reportStat2, setReportStat2] = useState([]);

  const [examId, setExamId] = useState();

  const [timeTableData, setTimeTableData] = useState([]);

  const [ranks, setRanks] = useState([]);

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateGradePoint = (p) => {
    // if(p>=85) return "O+"
    // if (p>=70 && p<=84.99 ) return "O"
    // if (p>=60    && p<=69.99 ) return "A"
    // if (p>=55 && p<=59.99 ) return "B+"
    // if (p>=48 && p<=54.99 ) return "B"
    // if (p>=36 && p<=47.99 ) return "C"
    // if (p<36) return "D"

    // console.log(p.toFixed(2))

    return parseFloat(p.toFixed(2) * 10).toFixed(2);
  };

  /////////function calls from db to fe///////////
  //get all required data for creating a exam time tabel i.e, class and semester
  const getBasicData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
    ]);

    if (flag) return toast.error("Something went wrong");
  };

  //Get Exam Details
  const getData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
    await setShowDepartment(user?.department_id);
    setLoading(0);
  };

  const AnalyseResult = (p) => {
    const arr = reportStat?.marks?.filter((s) => s.student_id == p);
    for (const i of arr) {
      if (i?.e_absent == 1) return false;
      if (i?.e_min > i?.e_marks + Number(i?.e_grace_marks)) return false;
    }
    return true;
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    await setExamId();
    setFlag(0);
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          setMainUser((prev) => ({
            ...prev,
            exam_committee_id: res.data.data[0].id,
          }));
          getBasicData();
          getData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    // getBasicData();
  };

  const getReports = async () => {
    setLoading(1);

    const config = {
      method: "get",
      url: `${REPORTS_DETAILED}/${examId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Confusing Data", res.data.data);
        let arr = [];
        let arr2 = [];
        for (const i of res.data.data.studentData) {
          const one_student_data = res.data.data.marks.filter(
            (s) => s.student_id == i.student_id
          );
          const result_studentData = Object.values(
            one_student_data.reduce((acc, curr) => {
              const subject = curr.subject;
              if (!acc[subject]) {
                acc[subject] = { ...curr };
              } else {
                acc[subject].e_marks += curr.e_marks;
                acc[subject].e_max += curr.e_max;
                acc[subject].e_min += curr.e_min;
                acc[subject].e_grace_marks += Number(curr.e_grace_marks);
              }
              return acc;
            }, {})
          );
          arr = [...arr, ...result_studentData];

          const total = i.i_max + i.e_max;
          const obtained = i.i_marks + i.e_marks;
          const p = (obtained * 100) / total;
          const obj = {
            student_id: i.student_id,
            percentage: p || 0,
          };
          arr2.push(obj);
        }
        // console.log({ marks: arr, studentData: res.data.data.studentData })
        setReportStat({ marks: arr, studentData: res.data.data.studentData });
        setReportStat2(res.data.data);

        arr2.sort((a, b) => b.percentage - a.percentage);
        setRanks(arr2);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${examId}&status=ACTIVE`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  //useEffects
  useEffect(() => {
    getBasicData();
  }, []);

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  return (
    <div className="CreateExamTimetable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Marks Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Students Marks Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {DeptOpt?.filter(
                              (s) => s.college_id == user?.college_id
                            )?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {!flag ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Date</th>

                              <th>Staff</th>

                              <th>Class</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        semesterOpt
                                          ?.filter(
                                            (s) => s.class_id == user?.class_id
                                          )
                                          ?.filter(
                                            (s) => s.id == user?.semester_id
                                          )[0]?.name
                                      }
                                      {/* {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      } */}
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-success btn-sm p-2 text-white mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i.id);
                                        }}
                                      >
                                        <i
                                          class="ri-article-line "
                                          aria-hidden="true"
                                        >
                                          Results
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={() => setFlag(0)}
                          className="btn btn-primary mr-auto p-2"
                        >
                          Back
                        </button>
                        <button
                          onClick={onDownload}
                          className="btn btn-success mr-2"
                        >
                          Export
                        </button>
                        <button
                          onClick={onExport}
                          className="btn btn-success p-2"
                        >
                          CSV
                        </button>
                      </div>
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                          ref={tableRef}
                        >
                          <tr>
                            <th>Sl No</th>
                            <th>Enrollment No.</th>
                            <th>Name</th>
                            {timeTableData?.map((i, key) => (
                              <th>{i.course_name}</th>
                            ))}
                            <th>Total Marks</th>
                            <th>Obtained Marks</th>
                            <th>Credits</th>
                            <th>Percentage</th>
                            <th>SGPA</th>
                            <th>GPA</th>
                            <th>Result</th>
                            <th>Rank</th>
                          </tr>
                          {reportStat?.studentData?.map((i, key) => (
                            <tr>
                              <td>{key + 1}</td>
                              <td>{i?.student_id}</td>
                              <td>{i?.name}</td>
                              {timeTableData?.map((j, key) => (
                                <td key={key}>
                                  {reportStat?.marks?.find(
                                    (s) =>
                                      s.student_id == i?.student_id &&
                                      s.course_id == j?.course_id
                                  )?.e_marks +
                                    reportStat?.marks?.find(
                                      (s) =>
                                        s.student_id == i?.student_id &&
                                        s.course_id == j?.course_id
                                    )?.i_marks || (
                                    <span className="badge badge-soft-warning">
                                      N/A
                                    </span>
                                  )}
                                </td>
                              ))}
                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, cur) =>
                                      total +
                                      (Number(cur?.e_max) + Number(cur?.i_max)),
                                    0
                                  )}
                              </td>
                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, cur) =>
                                      total +
                                      (Number(cur?.e_marks) +
                                        Number(cur?.e_grace_marks) +
                                        Number(cur?.i_marks)),
                                    0
                                  )}
                              </td>
                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, j) => total + Number(j?.credit),
                                    0
                                  )}
                              </td>
                              <td>
                                {(
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) =>
                                        total +
                                        Number(
                                          (calculateGradePointEachSub(
                                            j.e_marks +
                                              j?.i_marks +
                                              j?.e_grace_marks,
                                            j.e_max + j?.i_max
                                          )
                                            ? calculateGradePointEachSub(
                                                j.e_marks +
                                                  j?.i_marks +
                                                  j?.e_grace_marks,
                                                j.e_max + j?.i_max
                                              )?.gp
                                            : 0) * j?.credit
                                        ),

                                      0
                                    ) /
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) => total + Number(j?.credit),
                                      0
                                    )
                                ).toFixed(2) == 0.0
                                  ? "0.00"
                                  : (
                                      (reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total +
                                            Number(
                                              (calculateGradePointEachSub(
                                                j.e_marks +
                                                  j?.i_marks +
                                                  j?.e_grace_marks,
                                                j.e_max + j?.i_max
                                              )
                                                ? calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max
                                                  )?.gp
                                                : 0) * j?.credit
                                            ),

                                          0
                                        ) /
                                        reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id &&
                                              !s.is_not_countable
                                          )
                                          ?.reduce(
                                            (total, j) =>
                                              total + Number(j?.credit),
                                            0
                                          ) -
                                        0.5) *
                                      10
                                    ).toFixed(2)}
                                {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                              </td>
                              <td>
                                {(
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) =>
                                        total +
                                        Number(
                                          (calculateGradePointEachSub(
                                            j.e_marks +
                                              j?.i_marks +
                                              j?.e_grace_marks,
                                            j.e_max + j?.i_max
                                          )
                                            ? calculateGradePointEachSub(
                                                j.e_marks +
                                                  j?.i_marks +
                                                  j?.e_grace_marks,
                                                j.e_max + j?.i_max
                                              )?.gp
                                            : 0) * j?.credit
                                        ),

                                      0
                                    ) /
                                  reportStat?.marks
                                    ?.filter(
                                      (s) =>
                                        s?.student_id == i?.student_id &&
                                        !s.is_not_countable
                                    )
                                    ?.reduce(
                                      (total, j) => total + Number(j?.credit),
                                      0
                                    )
                                ).toFixed(2)}
                                {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                              </td>

                              <td>
                                {reportStat?.marks
                                  ?.filter(
                                    (s) =>
                                      s?.student_id == i?.student_id &&
                                      !s.is_not_countable
                                  )
                                  ?.reduce(
                                    (total, j) =>
                                      total +
                                      Number(
                                        (calculateGradePointEachSub(
                                          j.e_marks +
                                            j?.i_marks +
                                            j?.e_grace_marks,
                                          j.e_max + j?.i_max
                                        )
                                          ? calculateGradePointEachSub(
                                              j.e_marks +
                                                j?.i_marks +
                                                j?.e_grace_marks,
                                              j.e_max + j?.i_max
                                            )?.gp
                                          : 0) * j?.credit
                                      ),

                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                              <td>
                                {AnalyseResult(i?.student_id) ? (
                                  <span className="badge badge-soft-success">
                                    Pass{" "}
                                  </span>
                                ) : (
                                  <span className="badge badge-soft-danger">
                                    Fail
                                  </span>
                                )}
                              </td>
                              <td>
                                {ranks?.findIndex(
                                  (s) => s.student_id == i?.student_id
                                ) + 1}
                              </td>
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishReport;
