//Sessio or Year
export const SessionOpt1 = [
  {
    id: 2015,
    name: "2015 - 2016",
  },
  {
    id: 2016,
    name: "2016 - 2017",
  },
  {
    id: 2017,
    name: "2017 - 2018",
  },
  {
    id: 2018,
    name: "2018 - 2019",
  },
  {
    id: 2019,
    name: "2019 - 2020",
  },
  {
    id: 2020,
    name: "2020 - 2021",
  },
  {
    id: 2021,
    name: "2021 - 2022",
  },
  {
    id: 2022,
    name: "2022 - 2023",
  },
  {
    id: 2023,
    name: "2023 - 2024",
  },
  {
    id: 2024,
    name: "2024 - 2025",
  },
  {
    id: 2025,
    name: "2025 - 2026",
  },
];
