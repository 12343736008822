import React from "react";
import { Http } from "../../../../Services/Services";
import { useState, useEffect } from "react";
import { STUDENT_SESSION_ALL } from "../../../../utils/apiConstants";
import { ACADEMICS_ADD_SUBJECT } from "../../../../utils/Academics.apiConst";
import { useParams, useOutletContext } from "react-router-dom";
import HallTicket from "../../../../Components/PreExamination/HallTicket";
import { useReactToPrint } from "react-to-print";
import {
  NEW_EXAM_TIMETABLE,
  NEW_EXAM,
  PRE_EXAM_ENROLL_STUDENT_NEW,
  PRE_EXAM_ENROLL_STUDENT,
} from "../../../../utils/Examination.apiConst";
import { useRef } from "react";

const HomeHallTicket = () => {
  const [data, setData] = useState([]);
  const [subjectOpt, setSubjectOpt] = useState();

  const [x, setX] = useState();

  const params = useParams();

  const [timeTableData, setTimeTableData] = useState();

  const [enrollmentData, setEnrollmentData] = useState();

  const { examData, setLoading } = useOutletContext();

  const componentRef = useRef();

  const componentRef1 = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });

  const getData = async () => {
    console.log("Hello")
    setLoading(1);
    await Http.get(
      `${STUDENT_SESSION_ALL}?class_id=${examData?.class_id}&semester_id=${examData?.semester_id}&session_id=${examData?.session_id}`
    )
      .then(async (res) => {
        await setData(res.data.data);
        await Http.get(`${PRE_EXAM_ENROLL_STUDENT}?class_examination_id=${params.examId}`)
          .then((res1) => {
            console.log("Enrollment", res1.data.enrolledStudent);
            setEnrollmentData(res1.data.enrolledStudent);
            console.log(data);
            let x = res.data.data?.filter((element) => {
              return res1.data.enrolledStudent.some(
                (s) => s.student_id === element?.data?.student_id
              );
            });
            setData(x);
          })
          .catch((err) => {
            console.log(err);
            setLoading(0);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    await Http.get(
      `${ACADEMICS_ADD_SUBJECT}?class_id=${examData?.class_id}&semester_id=${examData?.semester_id}&college_id=${examData?.college_id}`
    )
      .then((res) => {
        setSubjectOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });


    await Http.get(`${NEW_EXAM_TIMETABLE}?class_examination_id=${params.examId}`)

      .then((res) => {
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    await Http.get(`${NEW_EXAM}/${params.examId}`)
      .then((res) => {
        setX(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const [visibleStudentIndex, setVisibleStudentIndex] = useState(null);

  const handleDownloadClick = async (index) => {
    console.log(index);
    await setVisibleStudentIndex(index);
    handlePrint1();
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h4 className="card-title text-uppercase ">List</h4>
            <div>
              {data && data?.length != 0 ? (
                <button
                  className="btn btn-warning btn-sm mr-3"
                  onClick={handlePrint}
                >
                  <i className="fa fa-download"></i> Download Hall Ticket
                </button>
              ) : null}
              {/* <button className='btn btn-primary btn-sm'><i className='fa fa-download'></i> Download Applications</button> */}
            </div>
          </div>{" "}
          <div className="row">
            <div className="col-md-12">
              <h6 className="card-header">Hall Ticket Of Students</h6>
            </div>
          </div>
          <div className="row mt-2">
            <div className="table-responsive">
              <table className="table table-bordered">
                <tr>
                  <th>Sl.No</th>
                  <th>USN</th>
                  <th>Student Name</th>
                  <th>Action</th>
                </tr>
                {data &&
                  data.map((item, key) => {
                    const isStudentVisible = key === visibleStudentIndex;
                    return (
                      <>
                        <tr>
                          <td>{key + 1}</td>
                          <td>{item?.data?.student_id}</td>
                          <td>{item?.studentInfo?.name}</td>
                          <td >
                            <span className="badge badge-warning p-2" onClick={() => handleDownloadClick(key)}>

                            <i
                              class="fa fa-download"
                              aria-hidden="true"
                              ></i>
                              </span>
                          </td>
                        </tr>
                        {isStudentVisible ? (
                          <div style={{ display: "none" }}>
                            <div ref={componentRef1}>
                              <div className="page-break">
                                <HallTicket
                                  student_id={item?.data?.student_id}
                                  session_id={item?.data?.session_id}
                                  class_id={examData?.class_id}
                                  semester_id={examData?.semester_id}
                                  college_id={examData?.college_id}
                                  month={x?.month}
                                  year={x?.year}
                                  department_id={item?.data?.department_id}
                                  class_examination_id={params.examId}
                                  timeTableData={timeTableData}
                                  enrollmentData={enrollmentData?.filter(
                                    (s) =>
                                      s.student_id == item?.data?.student_id
                                  )}
                                  studentData={item}
                                  subjectOpt={subjectOpt}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
              </table>
            </div>
            {/* Hall Ticket */}
            {enrollmentData && enrollmentData.length > 0 ? (
              <div style={{ display: "none" }}>
                <div ref={componentRef}>
                  {data?.map((i, key) => (
                    <div className="page-break">
                      <HallTicket
                        student_id={i?.data?.student_id}
                        session_id={i?.data?.session_id}
                        class_id={examData?.class_id}
                        semester_id={examData?.semester_id}
                        college_id={examData?.college_id}
                        month={x?.month}
                        year={x?.year}
                        department_id={i?.data?.department_id}
                        class_examination_id={params.examId}
                        timeTableData={timeTableData}
                        enrollmentData={enrollmentData?.filter(
                          (s) => s.student_id == i?.data?.student_id
                        )}
                        studentData={i}
                        subjectOpt={subjectOpt}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default HomeHallTicket;
