import { BASE_URL } from "./apiConstants";

//Academics

//Add Subject
export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/api/academics/course`

//Add Lesson
export const ACADEMICS_ADD_LESSON = `${BASE_URL}/api/academics/lesson`

//Add Topic
export const ACADEMICS_ADD_TOPIC = `${BASE_URL}/api/academics/topic`

//Add Class
export const ACADEMICS_ADD_CLASS = `${BASE_URL}/api/academics/class`

//Add Semester
export const ACADEMICS_ADD_SEMESTER = `${BASE_URL}/api/academics/semester`

//Add Semester
export const ACADEMICS_ADD_SECTION = `${BASE_URL}/api/academics/section`

//Class Time Table
export const ACADEMICS_CLASS_TIME_TABLE = `${BASE_URL}/api/academics/timetable`

//Add Subject
// export const ACADEMICS_ADD_SUBJECT = `${BASE_URL}/api/academics/course`

export const ACADEMICS_ADD_SYLLABUS = `${BASE_URL}/api/academics/course-session`


export const ACADEMICS_ADD_LESSON_PLAN = `${BASE_URL}/api/academics/lessonplan`


export const ACADEMICS_PROMOTE_STUDENT = `${BASE_URL}/api/student/session`
