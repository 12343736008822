//Employer
export const ALL_DATA = 'ALL_DATA'
export const LOCAL_COLLEGE_TYPES = 'COLLEGE_TYPES'
export const LOCAL_COLLEGE_SPECIALIZATION = 'COLLEGE_SPECIALIZATION'
export const LOCAL_COLLEGE = 'COLLEGE'
export const LOCAL_DEPARTMENT = 'DEPARTMENT'
export const LOCAL_EMPLOYEE = 'EMPLOYEE'
export const LOCAL_JOBROLES = 'JOB_ROLES'
export const LOCAL_PROGRAM = 'PROGRAM'
export const LOCAL_SPECIALIZATION = 'SPECIALIZATION'
export const LOCAL_USER_ROLES = 'USER_ROLES'


export const LOCAL_HOLIDAY_TYPE = 'HOLIDAY_TYPE'