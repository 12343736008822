import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { STUDENT_ADMISSION_DETAILS, STUDENT_SESSION, STUDENT_SESSION_ALL } from '../../../../utils/apiConstants'
import { NEW_EXAM_MARKS, NEW_EXAM_TIMETABLE, PRE_EXAM_ENROLL_STUDENT, RE_EXAM_ENROLLMENT, RE_EXAM_MARKS, RE_EXAM_TIMETABLE } from '../../../../utils/Examination.apiConst'
import { useEffect } from 'react'
import { Http } from '../../../../Services/Services'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Slider, Switch } from 'antd'
// import { NEW_EXAM_ENROLLMENT, NEW_EXAM_TIMETABLE } from '../../../../utils/Examination.apiConst'

const AddGraceMarks = () => {
    const { examId, time_table_id } = useParams()

    const { examData, setLoading } = useOutletContext()
    const [enrollmentData, setEnrollmentData] = useState([])
    const [timeTableData, setTimeTableData] = useState()

    const [data, setData] = useState([])

    const getEnrollmentData = async () => {
        await Http.get(`${PRE_EXAM_ENROLL_STUDENT}?time_table_id=${time_table_id}&status=ACTIVE`)
            .then(async res => {
                setEnrollmentData(res.data.enrolledStudent);
                await Http.get(`${NEW_EXAM_MARKS}?time_table_id=${time_table_id}`)
                    .then(res2 => {
                        const arr2 = []
                        const arr = res2.data.data
                        for (const i of res.data.enrolledStudent) {
                            const obj = {
                                session_id: examData?.session_id,
                                time_table_id: time_table_id,
                                student_id: i?.student_id,
                                enrollment_id: i?.id,
                            }
                            if (!arr.find(j => j.student_id === i.student_id)) {
                                obj.marks = ''
                                obj.absent = ''
                                obj.note = ''
                                obj.marks_id = ''
                            }
                            else {
                                obj.marks = arr.find(j => j.student_id === i.student_id).marks
                                obj.absent = arr.find(j => j.student_id === i.student_id).absent
                                obj.note = arr.find(j => j.student_id === i.student_id).note
                                obj.marks_id = arr.find(j => j.student_id === i.student_id).id
                                obj.grace_marks = arr.find(j => j.student_id === i.student_id).grace_marks
                            }
                            arr2.push(obj)
                        }
                        setData(arr2)
                    })
            }
            )
            .catch(err => {
                console.log(err);
            }
            )
    }

    const getTimeTableData = async () => {
        await Http.get(`${NEW_EXAM_TIMETABLE}/${time_table_id}`)
            .then(res => {
                setTimeTableData(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSubmit = () => {
        console.log(data);
        Http.post(`${NEW_EXAM_MARKS}/bulk`, {data:JSON.stringify(data)})
        .then(res=>{
            toast.success('Marks added successfully')
        })
        .catch(err=>{
            console.log(err);
        })
    }

    useEffect(() => {
        if (examData) {
            getEnrollmentData()
        }
    }, [examData])

    useEffect(() => {
        getTimeTableData()
    }, [])
    return (
        <div className='card'>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="card-header">Enroll students - <strong>Subject Name</strong></h6>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12 d-flex justify-content-end">
                        <button onClick={handleSubmit} className="btn btn-primary">
                            Submit
                        </button>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <tr>
                                <th>Sl.No</th>
                                <th>USN</th>
                                <th>Subject</th>
                                <th>Max Marks</th>
                                <th>Min Marks</th>
                                <th>Obtained Marks</th>
                                <th>Grace Marks</th>
                                <th>Absent</th>
                                <th>Note</th>
                                {/* <th>Action</th> */}
                            </tr>
                            {
                                data?.map((i, key) => (
                                    <tr>
                                        <td>{key + 1}</td>
                                        <td>{i?.student_id}</td>
                                        <td>{timeTableData?.course_name}</td>
                                        <td>{timeTableData?.max_marks}</td>
                                        <td>{timeTableData?.min_marks}</td>
                                        <td>
                                            {i?.marks}
                                        </td>
                                        <td>
                                            {
                                                i?.absent
                                                ?
                                                "Grace marks is not available for absent students"
                                                :
                                                <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Enter Grace Marks"
                                                value={i?.grace_marks}
                                                onChange={(e) => {
                                                    const arr = [...data]
                                                    if(arr[key].absent) return toast.error('Cannot add grace marks for absent student')
                                                    if (!arr[key].marks) return toast.error('Please add marks first')
                                                    const obj = arr[key]
                                                    obj.grace_marks = e.target.value
                                                    console.log(arr[key]);
                                                    arr[key] = { ...obj }
                                                    setData(arr)
                                                }}
                                            />
                                            }
                                            
                                        </td>
                                        <td>
                                            {i?.absent?"Yes":"No"}
                                        </td>
                                        <td>
                                            {
                                                i?.note
                                            }
                                        </td>
                                        {/* <td>{i?.studentInfo?.name}</td> */}
                                        {/* <td>
                                            Add Marks
                                        </td> */}
                                    </tr>
                                ))
                            }
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddGraceMarks