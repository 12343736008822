import React, { useState } from "react";
import { useEffect } from "react";
import useEmployee from "../../../Hooks/Employee/useEmployee";

import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  REPORTS_BY_NUMBERS,
  REPORTS_DETAILED,
} from "../../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
//other libraries
import { toast } from "react-toastify";
import axios from "axios";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";

function ResultsReport({ setLoading, collegeId }) {
  //get data from local storage

  //COnditions
  const FortyPercentColleges = [1111010, 1111009];
  const FiftyPercentColleges = [1111001, 1111000, 1111003, 1111007];
  const ThirtyPercentColleges = [1111005, 1111004];

  //////program data//////

  //function to get data
  const getLocalProgramData = () =>
    localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  useEffect(() => {
    setProgramOpt(getLocalProgramData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  //////College Data//////

  //function to get data
  const getLocalCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  //////Department Data//////

  //function to get data
  const getLocalDeptData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
    department_id: "",
  });

  //main object for data manipulation
  const [mainUser, setMainUser] = useState({
    class_examination_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  // Ref for table to export in excel
  const tableRef = useRef();

  //main data
  const [data, setData] = useState([]);

  //class data
  const [classOpt, setClassOpt] = useState([]);

  //semster data
  const [semesterOpt, setSemesterOpt] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState([]);

  // Flag to show or hide reports
  const [flag, setFlag] = useState(0);

  ///////Other states for management//////////

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();
  const [passedStudents, setPassedStudents] = useState(0);
  const [absentStudents, setAbsentStudents] = useState(0);

  const [examId, setExamId] = useState();

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  /////////function calls from db to fe///////////
  //get all required data for creating a exam time tabel i.e, class and semester
  const getBasicData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
    ]);

    if (flag) return toast.error("Something went wrong");
  };

  //Get Exam Details
  const getData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
    setLoading(0);
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");

    await setFlag(0);
    await setPassedStudents(0);
    await setAbsentStudents(0);
    await setExamId();
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          setMainUser((prev) => ({
            ...prev,
            exam_committee_id: res.data.data[0].id,
          }));
          getBasicData();
          getData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    // getBasicData();
  };

  const [count, setCount] = useState([]);

  const getReports = async () => {
    setLoading(1);

    const config1 = {
      method: "get",
      url: `${REPORTS_DETAILED}/${examId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    axios(config1)
      .then(async (res) => {
        console.log("Confusing Data", res.data.data);
        let arr = [];
        let absent = 0,
          passed = 0;
        try {
          let stu = res.data.data.studentData;
          let mar = res.data.data.marks;
          for (let i of stu) {
            let flag = false;
            let subjects = mar?.filter((s) => s.student_id == i.student_id);
            let obt_m = 0,
              max_m = 0;
            for (let j of subjects) {
              if (j?.e_absent) {
                absent++;
                flag = true;
                break;
              }
              if (!j.is_not_countable) {
                let x = j.e_grace_marks ? j.e_grace_marks : 0;
                let y = j.i_marks ? j.i_marks : 0;
                let z = j.i_max ? j.i_max : 0;
                obt_m = j.e_marks + x + y;
                max_m = j.e_max + z;
              }
            }
            if (!flag) {
              let percent = (obt_m / max_m) * 100;
              passed++;
              flag = false;
              arr.push(percent);
            }
          }

          console.log(arr);
          setCount(arr);
          setAbsentStudents(absent);
          setPassedStudents(passed);
          setLoading(0);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  //useEffects
  useEffect(() => {
    getBasicData();
  }, []);

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  // console.log(getReports);

  return (
    <div className="CreateExamTimetable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Rank Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active"> Rank Report</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {DeptOpt?.filter(
                              (s) => s.college_id == user?.college_id
                            )?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s.college_id == user?.college_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {!flag ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Date</th>

                              <th>Staff</th>

                              <th>Class</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-success btn-sm p-2 text-white mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i.id);
                                        }}
                                      >
                                        <i
                                          class="ri-article-line "
                                          aria-hidden="true"
                                        >
                                          Results
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={() => setFlag(0)}
                          className="btn btn-primary"
                        >
                          Back
                        </button>
                        <button
                          onClick={onDownload}
                          className="btn btn-success"
                        >
                          Export
                        </button>
                      </div>
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        {FortyPercentColleges?.some((s) => s == showCollege) ? (
                          <table
                            id=""
                            className="display table table-bordered  nowrap table-hover "
                            style={{
                              borderCollapse: "collapse",
                              borderSpacing: 0,
                              width: "100%",
                            }}
                            ref={tableRef}
                          >
                            <thead>
                              <tr>
                                <th colSpan={3} className="text-center">
                                  BCA,B.Ed.,Diploma, Degree RESULT ANALYSIS
                                  University exam
                                </th>
                              </tr>
                              <tr>
                                <th>Percentage</th>
                                <th>Result</th>
                                <th>Number of Students</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>85 and Above</th>
                                <th rowspan="2">First Class & Distinction</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 85 && it <= 100) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>70 and 84.99</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 70 && it < 85) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>60-69.99</th>
                                <th>First Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 60 && it < 70) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>50 and 59.99</th>
                                <th>Higher Second Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 50 && it < 60) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>40 and 49.99</th>
                                <th>Second Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 40 && it < 50) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              {/* <tr>
                              <th>36 and 47.99</th>
                              <th>Pass Class</th>
                              <th>
                                {count.reduce((acc, it) => {
                                  if (it >= 36 && it < 48) {
                                    acc++;
                                  }
                                  return acc;
                                }, 0)}
                              </th>
                            </tr> */}
                              <tr>
                                <th> Below 40</th>
                                <th>Dropped</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 0 && it <= 40) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>Absent</th>
                                <th>Absent</th>
                                <th>{absentStudents}</th>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <th>Total Number of Passed Students </th>
                                <td colSpan={2}>{passedStudents}</td>
                              </tr>
                              <tr>
                                <th>Total Number of Failed Students </th>
                                <td colSpan={2}>
                                  {count?.reduce((acc, it) => {
                                    if (it < 36) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Number of Studets</th>
                                <td colSpan={2}>
                                  {absentStudents +
                                    passedStudents +
                                    count?.reduce((acc, it) => {
                                      if (it < 36) {
                                        acc++;
                                      }
                                      return acc;
                                    }, 0)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : FiftyPercentColleges?.some(
                            (s) => s == showCollege
                          ) ? (
                          <table
                            id=""
                            className="display table table-bordered  nowrap table-hover "
                            style={{
                              borderCollapse: "collapse",
                              borderSpacing: 0,
                              width: "100%",
                            }}
                            ref={tableRef}
                          >
                            <thead>
                              <tr>
                                <th colSpan={3} className="text-center">
                                  Pharmacy, Nursing, ME, Homoeopathy, Ayurveda
                                  RESULT ANALYSIS University exam
                                </th>
                              </tr>
                              <tr>
                                <th>Percentage</th>
                                <th>Result</th>
                                <th>Number of Students</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>85 and Above</th>
                                <th rowspan="2">First Class & Distinction</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 85 && it <= 100) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>70 and 84.99</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 70 && it < 85) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>60-69.99</th>
                                <th>First Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 60 && it < 70) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>50 and 59.99</th>
                                <th>Higher Second Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 50 && it < 60) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th> Below 50</th>
                                <th>Dropped</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 0 && it <= 50) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>Absent</th>
                                <th>Absent</th>
                                <th>{absentStudents}</th>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <th>Total Number of Passed Students </th>
                                <td colSpan={2}>{passedStudents}</td>
                              </tr>
                              <tr>
                                <th>Total Number of Failed Students </th>
                                <td colSpan={2}>
                                  {count?.reduce((acc, it) => {
                                    if (it < 36) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Number of Studets</th>
                                <td colSpan={2}>
                                  {absentStudents +
                                    passedStudents +
                                    count?.reduce((acc, it) => {
                                      if (it < 36) {
                                        acc++;
                                      }
                                      return acc;
                                    }, 0)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : ThirtyPercentColleges?.some(
                            (s) => s == showCollege
                          ) ? (
                          <table
                            id=""
                            className="display table table-bordered  nowrap table-hover "
                            style={{
                              borderCollapse: "collapse",
                              borderSpacing: 0,
                              width: "100%",
                            }}
                            ref={tableRef}
                          >
                            <thead>
                              <tr>
                                <th colSpan={3} className="text-center">
                                  BA,B.Com., BBA, MBA, B.Sc., M.Sc., RESULT
                                  ANALYSIS University exam
                                </th>
                              </tr>
                              <tr>
                                <th>Percentage</th>
                                <th>Result</th>
                                <th>Number of Students</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>85 and Above</th>
                                <th rowspan="2">First Class & Distinction</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 85 && it <= 100) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>70 and 84.99</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 70 && it < 85) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>60-69.99</th>
                                <th>First Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 60 && it < 70) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>50 and 59.99</th>
                                <th>Higher Second Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 50 && it < 60) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>36 and 49.99</th>
                                <th>Second Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 36 && it < 50) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              {/* <tr>
                                <th>36 and 47.99</th>
                                <th>Pass Class</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 36 && it < 48) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr> */}
                              <tr>
                                <th> Below 36</th>
                                <th>Dropped</th>
                                <th>
                                  {count.reduce((acc, it) => {
                                    if (it >= 0 && it <= 36) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </th>
                              </tr>
                              <tr>
                                <th>Absent</th>
                                <th>Absent</th>
                                <th>{absentStudents}</th>
                              </tr>
                              <tr></tr>
                              <tr></tr>
                              <tr>
                                <th>Total Number of Passed Students </th>
                                <td colSpan={2}>{passedStudents}</td>
                              </tr>
                              <tr>
                                <th>Total Number of Failed Students </th>
                                <td colSpan={2}>
                                  {count?.reduce((acc, it) => {
                                    if (it < 36) {
                                      acc++;
                                    }
                                    return acc;
                                  }, 0)}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Number of Studets</th>
                                <td colSpan={2}>
                                  {absentStudents +
                                    passedStudents +
                                    count?.reduce((acc, it) => {
                                      if (it < 36) {
                                        acc++;
                                      }
                                      return acc;
                                    }, 0)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : 
                        <div>
                          <h6 className="alert alert-warning">
                            If your report deont appear it means your college is not in our list for this report.
                          </h6>
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultsReport;
