import React, { useState } from "react";
import { useEffect } from "react";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import * as XLSX from "xlsx/xlsx.mjs";
import "./template.css";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  REPORTS_DETAILED_ENG,
} from "../../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
//other libraries
import { toast } from "react-toastify";
import axios from "axios";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import { useReactToPrint } from "react-to-print";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import { SessionOpt1 } from "../../../Data/student/sessionData1";

function ReportDetailedEng({ setLoading }) {
  //get data from local storage

  //////program data//////

  const PrintReceipt = useReactToPrint({
    content: () => tableRef1.current,
  });

  const today = new Date();
  const day = today.getDate().toString().padStart(2, "0");
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const year = today.getFullYear();

  // Format the date as dd-mm-yyyy
  const formattedDate = `${day}-${month}-${year}`;

  //function to get data
  const getLocalProgramData = () =>
    localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  useEffect(() => {
    setProgramOpt(getLocalProgramData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  //////College Data//////

  //function to get data
  const getLocalCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  //////Department Data//////

  //function to get data
  const getLocalDeptData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
    department_id: "",
  });

  const [flag1, setFlag1] = useState(false);

  //main object for data manipulation
  const [mainUser, setMainUser] = useState({
    class_examination_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  const tableRef1 = useRef();

  const gparef = [
    {
      min: 85,
      max: 100,
      grade: "AA",
      gp: 10,
    },
    {
      min: 75,
      max: 84.99,
      grade: "AB",
      gp: 9,
    },
    {
      min: 65,
      max: 74.99,
      grade: "BB",
      gp: 8,
    },
    {
      min: 55,
      max: 64.99,
      grade: "BC",
      gp: 7,
    },
    {
      min: 45,
      max: 54.99,
      grade: "CC",
      gp: 6,
    },
    {
      min: 40,
      max: 44.99,
      grade: "CD",
      gp: 5,
    },
    {
      min: 35,
      max: 39.99,
      grade: "DD",
      gp: 4,
    },
    {
      min: 0,
      max: 34.99,
      grade: "FF",
      gp: 0,
    },
  ];

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max, student_id) => {
    // console.log(d);
    let marks = maptoHundred(num, max);
    // console.log(num, max);

    // if (student_id == "SUK220704CA001") {
    //   console.log(reportStat.marks.filter((s) => s?.student_id == student_id));
    // }

    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        return i;
      }
    }
  };

  // Ref for table to export in excel
  const tableRef = useRef();

  //Data for adding time table
  const [addData, setAddData] = useState();

  /////Other Data States for

  //main data
  const [data, setData] = useState([]);

  //class data
  const [classOpt, setClassOpt] = useState([]);

  //semster data
  const [semesterOpt, setSemesterOpt] = useState([]);

  //subject/course Data
  const [courseOpt, setCourseOpt] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState([]);

  // Flag to show or hide reports
  const [flag, setFlag] = useState(0);

  ///////Other states for management//////////

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showDepartment, setShowDepartment] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [report, setReport] = useState([]);

  const [reportStat, setReportStat] = useState([]);
  const [reportStat1, setReportStat1] = useState([]);
  const [passedStudents, setPassedStudents] = useState(0);
  const [failedStudents, setFailesStudents] = useState(0);

  const [examId, setExamId] = useState();

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (flag1) {
      setTimeout(() => {
        setFlag1(false);
      }, 10000);
    }
  }, [flag1]);

  const calculateGradePoint = (p) => {
    // if(p>=85) return "O+"
    // if (p>=70 && p<=84.99 ) return "O"
    // if (p>=60    && p<=69.99 ) return "A"
    // if (p>=55 && p<=59.99 ) return "B+"
    // if (p>=48 && p<=54.99 ) return "B"
    // if (p>=36 && p<=47.99 ) return "C"
    // if (p<36) return "D"

    // console.log(p.toFixed(2));

    return parseFloat(p * 10).toFixed(2);
  };

  const analyseResults = (examResults) => {
    for (const i of examResults) {
      if (i?.e_min > i?.e_marks + i?.e_grace) return false;
      if (i?.i_min > i?.i_marks) return false;
      if (i?.mt_min > i?.mt_marks) return false;
      if (i?.viva_min > i?.viva_marks) return false;
      if (calculateGradePointEachSub(i?.viva_marks, i?.viva_max)?.gp == 0)
        return false;
      if (calculateGradePointEachSub(i?.i_marks, i?.i_max)?.gp == 0)
        return false;
      if (
        calculateGradePointEachSub(i?.e_marks + i?.e_grace, i?.e_max)?.gp == 0
      )
        return false;
      if (calculateGradePointEachSub(i?.mt_marks, i?.mt_max)?.gp == 0)
        return false;
    }
    // console.log("result is true");

    return true;
  };

  /////////function calls from db to fe///////////
  //get all required data for creating a exam time tabel i.e, class and semester
  const getBasicData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
    ]);

    if (flag) return toast.error("Something went wrong");
  };

  //Get Exam Details
  const getData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
    await setShowDepartment(user?.department_id);
    setLoading(0);
  };

  const AnalyseResult = (p) => {
    const arr = reportStat?.marks?.filter((s) => s.student_id == p);
    for (const i of arr) {
      if (i?.e_absent == 1) return false;
      if (i?.e_min > i?.e_marks + Number(i?.e_grace_marks)) return false;
    }
    const percentage =
      (reportStat?.marks
        ?.filter((s) => s?.student_id == p && !s.is_not_countable)
        ?.reduce(
          (total, cur) =>
            total +
            (Number(cur?.e_marks) +
              Number(cur?.e_grace_marks) +
              Number(cur?.i_marks) +
              Number(cur?.mt_marks) +
              Number(cur?.viva_marks)),
          0
        ) *
        100) /
      reportStat?.marks
        ?.filter((s) => s?.student_id == p && !s.is_not_countable)
        ?.reduce(
          (total, cur) =>
            total +
            (Number(cur?.e_max) +
              Number(cur?.i_max) +
              Number(cur?.mt_max) +
              Number(cur?.viva_max)),
          0
        );
    // console.log(percentage);
    // console.log(p);
    if (percentage < 50) return false;
    return true;
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    await setExamId();
    setFlag(0);
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          setMainUser((prev) => ({
            ...prev,
            exam_committee_id: res.data.data[0].id,
          }));
          getBasicData();
          getData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    // getBasicData();
  };

  const getReports = async () => {
    setLoading(1);

    const config = {
      method: "get",
      url: `${REPORTS_DETAILED_ENG}/${examId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setReportStat(res.data.data);
        // res.data.data.marks?.forEach((item)=>{
        //     let x = arr?.find((s)=>s.student_id == item?.student_id && s.sub_code == item?.sub_code);
        //     if()
        //     if(!obj[item.student_id][item?.sub_code] && item.is_not_countable == 0)
        //     {
        //       obj[item.student_id][item?.sub_code] = item;
        //     }
        //     else{

        //       if()
        //       {

        //       }
        //     }
        // })
        // arr = Object.values(obj)
        // console.log("New One",arr)
        let x = new Set();
        res.data.data.marks?.forEach((item) => {
          let y = item?.sub_code;
          x.add(y);
        });
        console.log(x);
        const result = Object.values(
          Object.values(
            res.data.data.marks.reduce((acc, curr) => {
              const student = curr.student_id;
              const subject = curr.sub_code;
              let con = student + subject;
              if (!acc[con]) {
                acc[con] = { ...curr };
              } else {
                acc[con].e_marks += curr.e_marks;
                acc[con].e_max += curr.e_max;
                acc[con].e_min += curr.e_min;
                if (acc[con].e_grace != null || acc[con].e_grace == 0) {
                  acc[con].e_grace += curr.e_grace;
                }
                acc[con].credit += curr.credit;
                acc[con].i_marks += curr.i_marks;
                acc[con].i_max += curr.i_max;
                acc[con].i_min += curr.i_min;
                acc[con].viva_marks += curr.viva_marks;
                acc[con].viva_max += curr.viva_max;
                acc[con].viva_min += curr.viva_min;
                acc[con].mt_max += curr.mt_max;
                acc[con].mt_marks += curr.mt_marks;
              }
              return acc;
            }, {})
          )
        );
        console.log(result?.filter((s) => s.student_id == "SUK220504CE002"));
        setReportStat1({ ...res.data.data, marks: result });
        console.log(reportStat1);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College Engineering and Technology, Kalol",
    },
    {
      id: 1111009,
      name: "FACULTY OF IT AND COMPUTER SCIENCE",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name:
        "Shree Swaminarayan College of IT and Computer Science, Kalol",
    },
  ];

  var converter = require("number-to-words");
  const onExport = () => {
    let json_data = [];
    for (const i of reportStat?.studentData) {
      const arr2 = reportStat.marks.filter((s) => s.student_id == i.student_id);
      const collegeId = arr2[0]?.college_id;
      const deptId = arr2[0]?.department_id;
      //   console.log(collegeId);
      // console.log(i?.e_session.substring(0));
      let session = i?.e_session?.toString();
      // console.log(typeof i?.e_session);
      session = session?.slice(2, 4);
      let dob = new Date(i?.dob);

      let dodate =
        dob.getDate() +
        "-" +
        parseInt(dob.getMonth() + 1) +
        "-" +
        dob.getFullYear();
      // console.log(dodate)
      let sub = 1;
      const obj = {
        ORG_NAME: facultyData?.find((s) => s.id == collegeId)?.colloge_name,
        // ORG_NAME_L: "",
        // ORG_ADDRESS:"Swaminarayan University,Shree Swaminarayan Vishvamangal Gurukul,Ahmedabad-Mehsana Highway,At&Po-Saij, Tal.-Kalol Dist-Gandhinagar-382725,Gujarat,India",
        // ORG_CITY:"Kalol",
        // ORG_STATE:"Gujarat",
        // ORG_PIN:"382725",
        ACADEMIC_COURSE_ID: i?.student_id.substring(5, 9),

        COURSE_NAME: DeptOpt?.find((s) => s.id == deptId)?.name,
        // COURSE_NAME_L: DeptOpt?.find((s) => s.id == deptId)?.name,
        STREAM: "",

        SESSION: showYear - 1 + "-" + showYear,
        REGN_NO: i?.student_id,
        RROLL: i?.student_id,

        CNAME: i?.name,
        GENDER: i?.gender?.charAt(0).toUpperCase(),
        DOB: dodate?.split("T")[0],

        // EMAIL: i?.email,
        FNAME: i?.father_name,
        MNAME: i?.mother_name,

        PHOTO: "",

        MRKS_REC_STATUS: "O",
        RESULT: AnalyseResult(i?.student_id) ? "Pass" : "Fail",
        YEAR: user?.year,
        MONTH: user?.month,
        // DIVISION: "",
        // GRADE:  calculateGradePointEachSub(
        //   i?.e_marks +
        //     i?.e_grace_marks +
        //     i?.i_marks,
        //   i?.e_max + i?.i_max
        // )?.grade,
        PERCNT:
          (
            reportStat?.marks
              ?.filter(
                (s) => s?.student_id == i?.student_id && !s.is_not_countable
              )
              ?.reduce(
                (total, j) =>
                  total +
                  Number(
                    (calculateGradePointEachSub(
                      j.e_marks + j?.i_marks + j?.e_grace_marks,
                      j.e_max + j?.i_max
                    )
                      ? calculateGradePointEachSub(
                          j.e_marks + j?.i_marks + j?.e_grace_marks,
                          j.e_max + j?.i_max
                        )?.gp
                      : 0) * j?.credit
                  ),

                0
              ) /
            reportStat?.marks
              ?.filter(
                (s) => s?.student_id == i?.student_id && !s.is_not_countable
              )
              ?.reduce((total, j) => total + Number(j?.credit), 0)
          ).toFixed(2) == 0.0
            ? "0.00"
            : (
                (reportStat?.marks
                  ?.filter(
                    (s) => s?.student_id == i?.student_id && !s.is_not_countable
                  )
                  ?.reduce(
                    (total, j) =>
                      total +
                      Number(
                        (calculateGradePointEachSub(
                          j.e_marks + j?.i_marks + j?.e_grace_marks,
                          j.e_max + j?.i_max
                        )
                          ? calculateGradePointEachSub(
                              j.e_marks + j?.i_marks + j?.e_grace_marks,
                              j.e_max + j?.i_max
                            )?.gp
                          : 0) * j?.credit
                      ),

                    0
                  ) /
                  reportStat?.marks
                    ?.filter(
                      (s) =>
                        s?.student_id == i?.student_id && !s.is_not_countable
                    )
                    ?.reduce((total, j) => total + Number(j?.credit), 0) -
                  0.5) *
                10
              ).toFixed(2),

        DOI: "",

        CERT_NO: "",
        SEM:
          user?.college_id == 1111001
            ? classOpt?.filter((s) => s.id == user?.class_id)[0]?.name
            : semesterOpt?.filter((s) => s.id == user?.semester_id)[0]?.name,

        // EXAM_TYPE: "REGULAR",
        // TERM_TYPE:"",

        TOT:
          Number(i?.e_max) +
          Number(i?.i_max) +
          Number(i?.viva_max) +
          Number(i?.mt_max),

        TOT_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total +
              (Number(cur?.e_max) +
                Number(cur?.i_max) +
                Number(cur?.viva_max) +
                Number(cur?.mt_max)),
            0
          ),

        TOT_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total +
              (Number(cur?.e_min) +
                Number(cur?.i_min) +
                Number(cur?.mt_min) +
                Number(cur?.viva_min)),
            0
          ),
        TOT_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total +
              (Number(cur?.e_marks) +
                Number(cur?.i_marks) +
                +Number(cur?.mt_marks) +
                Number(cur?.viva_marks) +
                Number(cur?.e_grace_marks)),
            0
          ),
        // TOT_MRKS_WRDS: converter.toWords(reportStat?.marks
        //   ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        //   ?.reduce(
        //     (total, cur) =>
        //       total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
        //     0
        //   )) ,
        TOT_TH_MAX: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_max), 0),
        TOT_TH_MIN: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_min), 0),
        TOT_TH_MRKS: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_marks), 0),
        TOT_PR_MAX: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.i_max), 0),
        TOT_PR_MIN: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.i_min), 0),
        TOT_PR_MRKS: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.i_marks), 0),
        TOT_CE_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.mt_max), 0),
        TOT_CE_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.mt_min), 0),
        TOT_CE_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.mt_marks), 0),
        TOT_VV_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.viva_max), 0),
        TOT_VV_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.viva_min), 0),
        TOT_VV_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.viva_marks), 0),
        TOT_CREDIT: reportStat?.marks
          .filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, j) => total + Number(j?.credit), 0),

        TOT_CREDIT_POINTS:
          i?.credit == 0 || i?.credit == null
            ? ""
            : reportStat?.marks
                ?.filter(
                  (s) => s?.student_id == i?.student_id && !s.is_not_countable
                )
                ?.reduce(
                  (total, j) =>
                    total +
                    Number(
                      calculateGradePoint(
                        ((Number(j?.i_marks) +
                          Number(j?.e_marks) +
                          Number(j?.viva_marks) +
                          Number(j?.mt_marks) +
                          Number(j?.e_grace_marks)) /
                          (Number(j?.e_max) +
                            Number(j?.i_max) +
                            Number(j?.viva_max) +
                            Number(j?.mt_max))) *
                          Number(j?.credit)
                      )
                    ),

                  0
                )
                .toFixed(2),
        TOT_GRADE_POINTS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, j) =>
              total +
              Number(
                (j?.e_min > j?.e_grace_marks + j?.e_marks
                  ? 0
                  : j?.i_min > j?.i_marks
                  ? 0
                  : j?.mt_min > j?.mt_marks
                  ? 0
                  : j?.viva_min > j?.viva_marks
                  ? 0
                  : j?.e_absent
                  ? 0
                  : j?.i_absent
                  ? 0
                  : j?.mt_absent
                  ? 0
                  : j?.viva_absent
                  ? 0
                  : calculateGradePointEachSub(
                      j?.e_marks + j?.e_grace_marks,
                      j?.e_max
                    )?.gp == 0
                  ? 0
                  : calculateGradePointEachSub(j?.i_marks, j?.i_max)?.gp == 0
                  ? 0
                  : calculateGradePointEachSub(j?.mt_marks, j?.mt_max)?.gp == 0
                  ? 0
                  : calculateGradePointEachSub(j?.viva_marks, j?.viva_max)
                      ?.gp == 0
                  ? 0
                  : calculateGradePointEachSub(
                      j?.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
                      j.e_max + j?.i_max + j?.mt_max + j?.viva_max
                    )
                  ? calculateGradePointEachSub(
                      j.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
                      j.e_max + j?.i_max + j?.mt_max + j?.viva_max
                    )?.gp
                  : 0) * j?.credit
              ),

            0
          )
          .toFixed(2),
        // GRAND_TOT_MAX:reportStat?.marks
        // ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        // ?.reduce(
        //   (total, cur) =>
        //     total + (Number(cur?.e_max) + Number(cur?.i_max) + Number(cur?.viva_max) + Number(cur?.mt_max)),
        //   0
        // ),
        //           GRAND_TOT_MIN:reportStat?.marks
        // ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        // ?.reduce(
        //   (total, cur) =>
        //     total + (Number(cur?.e_min) + Number(cur?.i_min)),
        //   0
        // ),
        // GRAND_TOT_MRKS:reportStat?.marks
        // ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        // ?.reduce(
        //   (total, cur) =>
        //     total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
        //   0
        // ),

        CGPA: "",
        REMARKS: "",
        SGPA: (
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, j) =>
                total +
                Number(
                  (j?.e_min > j?.e_grace_marks + j?.e_marks
                    ? 0
                    : j?.i_min > j?.i_marks
                    ? 0
                    : j?.mt_min > j?.mt_marks
                    ? 0
                    : j?.viva_min > j?.viva_marks
                    ? 0
                    : j?.e_absent
                    ? 0
                    : j?.i_absent
                    ? 0
                    : j?.mt_absent
                    ? 0
                    : j?.viva_absent
                    ? 0
                    : calculateGradePointEachSub(
                        j?.e_marks + j?.e_grace_marks,
                        j?.e_max
                      )?.gp == 0
                    ? 0
                    : calculateGradePointEachSub(j?.i_marks, j?.i_max)?.gp == 0
                    ? 0
                    : calculateGradePointEachSub(j?.mt_marks, j?.mt_max)?.gp ==
                      0
                    ? 0
                    : calculateGradePointEachSub(j?.viva_marks, j?.viva_max)
                        ?.gp == 0
                    ? 0
                    : calculateGradePointEachSub(
                        j?.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
                        j.e_max + j?.i_max + j?.mt_max + j?.viva_max
                      )
                    ? calculateGradePointEachSub(
                        j.e_marks + j?.i_marks + j?.mt_marks + j?.viva_marks,
                        j.e_max + j?.i_max + j?.mt_max + j?.viva_max
                      )?.gp
                    : 0) * j?.credit
                ),

              0
            ) /
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce((total, j) => total + Number(j?.credit), 0)
        ).toFixed(2),

        ABC_ACCOUNT_ID: i.abc_number,
        TERM_TYPE: "",
        TOT_GRADE: "",
      };

      // console.log(Number(i?.e_marks) + "+" + Number(i?.i_marks));
      for (const j of arr2) {
        // obj=["ABC_ACCOUNT_ID"]=j.abc_number;
        // console.log(j);

        obj["SUB" + sub + "NM"] = j?.subject;
        obj["SUB" + sub] = j?.sub_code;
        obj["SUB" + sub + "MAX"] =
          j?.e_max + j?.i_max + j?.viva_max + j?.mt_max;
        obj["SUB" + sub + "MIN"] =
          j?.e_min + j?.i_min + j?.viva_min + j?.mt_min;
        obj["SUB" + sub + "_TH_MAX"] = j?.type == "LECTURE" ? j?.e_max : " ";
        obj["SUB" + sub + "_TH_MIN"] = j?.type == "LECTURE" ? j?.e_min : " ";

        obj["SUB" + sub + "_PR_MAX"] = j?.type == "PRACTICAL" ? j?.i_max : "-";
        obj["SUB" + sub + "_PR_MIN"] = j?.type == "PRACTICAL" ? j?.i_min : "-";

        obj["SUB" + sub + "_CE_MAX"] = j?.mt_max;
        obj["SUB" + sub + "_CE_MIN"] = j?.mt_min;

        let gdth =
          !j?.e_marks && j?.e_absent
            ? "absent"
            : j?.e_marks + j?.e_grace_marks < j?.e_min
            ? "FF"
            : calculateGradePointEachSub(
                Number(j?.e_marks) +
                  (j?.e_grace_marks ? Number(j?.e_grace_marks) : 0),
                Number(j?.e_max)
              )?.grade == null
            ? "--"
            : calculateGradePointEachSub(
                Number(j?.e_marks) +
                  (j?.e_grace_marks ? Number(j?.e_grace_marks) : 0),
                Number(j?.e_max)
              )?.grade;

        let gdpr =
          !j?.i_marks && j?.i_absent
            ? "absent"
            : j?.i_marks < j?.i_min
            ? "FF"
            : calculateGradePointEachSub(Number(j?.i_marks), Number(j?.i_max))
                ?.grade == null
            ? "--"
            : calculateGradePointEachSub(Number(j?.i_marks), Number(j?.i_max))
                ?.grade;

        let gdmt =
          !j?.mt_marks && j?.mt_absent
            ? "absent"
            : j?.mt_marks < j?.mt_min
            ? "FF"
            : calculateGradePointEachSub(Number(j?.mt_marks), Number(j?.mt_max))
                ?.grade == null
            ? "--"
            : calculateGradePointEachSub(Number(j?.mt_marks), Number(j?.mt_max))
                ?.grade;

        let gdviva =
          !j?.viva_marks && j?.viva_absent
            ? "absent"
            : j?.viva_marks < j?.viva_min
            ? "FF"
            : calculateGradePointEachSub(
                Number(j?.viva_marks),
                Number(j?.viva_max)
              )?.grade == null
            ? "--"
            : calculateGradePointEachSub(
                Number(j?.viva_marks),
                Number(j?.viva_max)
              )?.grade;

        if (j?.type != "LECTURE") {
          gdth = "-";
        }
        if (j?.type == "LECTURE") {
          gdpr = "-";
        }

        obj["SUB" + sub + "_TH_MRKS"] = gdth; //Theory grade externals
        obj["SUB" + sub + "_PR_MRKS"] = gdpr; //practicla grade only internals
        obj["SUB" + sub + "_CE_MRKS"] = gdmt; //mid-term grade
        obj["SUB" + sub + "_VV_MRKS"] = gdviva; //viva grade

        obj["SUB" + sub + "_TOT"] =
          j.e_marks + j.e_grace_marks + j.i_marks + j.mt_marks + j.viva_marks;

        obj["SUB" + sub + "_STATUS"] = "";

        obj["SUB" + sub + "_GRADE"] =
          calculateGradePointEachSub(
            Number(j?.e_marks) +
              Number(j?.i_marks) +
              Number(j?.mt_marks) +
              Number(j?.viva_marks) +
              j?.e_grace_marks
              ? Number(j?.e_grace_marks)
              : 0,
            Number(j?.e_max) +
              Number(j?.i_max) +
              Number(j?.mt_max) +
              Number(j?.viva_max)
          )?.grade == null
            ? "--"
            : calculateGradePointEachSub(
                Number(j?.e_marks) +
                  Number(j?.i_marks) +
                  Number(j?.mt_marks) +
                  Number(j?.viva_marks) +
                  (j?.e_grace_marks ? Number(j?.e_grace_marks) : 0),
                Number(j?.e_max) +
                  Number(j?.i_max) +
                  Number(j?.mt_max) +
                  Number(j?.viva_max)
              )?.grade;

        obj["SUB" + sub + "_GRADE_POINTS"] = calculateGradePointEachSub(
          j.e_marks + j?.i_marks + j.mt_max + j?.viva_max + j?.e_grace_marks,
          j.e_max + j?.i_max + j.mt_max + j?.viva_max
        )?.gp;

        obj["SUB" + sub + "_CREDIT"] = j?.credit;
        obj["SUB" + sub + "_CREDIT_POINTS"] =
          calculateGradePointEachSub(
            j.e_marks +
              j?.i_marks +
              j.mt_marks +
              j.viva_marks +
              j?.e_grace_marks,
            j.e_max + j?.i_max + j.mt_max + j?.viva_max
          )?.gp * Number(j?.credit);

        obj["SUB" + sub + "_GRACE"] = Number(j?.e_grace_marks);
        obj["SUB" + sub + "_REMARKS"] =
          j?.type == "PRACTICAL"
            ? Number(j?.viva_marks) >= Number(j?.viva_min) &&
              Number(j?.i_marks >= j?.i_min)
              ? "CL"
              : j?.viva_absent || j?.i_absent
              ? "NCL"
              : "NCL"
            : Number(j?.e_marks) + Number(j?.e_grace_marks) >=
                Number(j?.e_min) && Number(j?.mt_marks >= j?.mt_min)
            ? "CL"
            : j?.e_absent || j?.mt_absent
            ? "NCL"
            : "NCL";
        obj["SUB" + sub + "_VV_MIN"] = j?.type != "LECTURE" ? j?.viva_min : " ";
        obj["SUB" + sub + "_VV_MAX"] = j?.type != "LECTURE" ? j?.viva_max : " ";

        obj["SUB" + sub + "_CREDIT_ELIGIBILITY"] = "";

        sub++;
      }

      // console.log(obj);
      let obj1 = {
        ...obj,
        AADHAAR_NAME: i?.name,
        ADMISSION_YEAR: i?.year_of_admission,
      };
      json_data.push(obj1);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, ` Exam  Detail Report.csv`);

    console.log(json_data);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const handlePrint = () => {
    let x = true;
    setFlag1(x);
    PrintReceipt();
  };

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //useEffects
  useEffect(() => {
    getBasicData();
  }, []);

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  console.log(
    reportStat?.marks
      ?.filter((s) => s?.student_id == "SUK230504CV003" && !s.is_not_countable)
      ?.reduce((total, j) => total + Number(j?.credit), 0)
  );

  return (
    <div className="CreateExamTimetable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Marks Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Students Marks Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt
                              ?.filter(
                                (s) => s.id == 1111000 || s.id == 1111009
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {DeptOpt?.filter(
                              (s) =>
                                s.college_id == user?.college_id &&
                                s.program_id == user?.program_id
                            )?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {!flag ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                            fontSize: "14px",
                            padding: "0px",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Date</th>

                              <th>Staff</th>

                              <th>Class</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {monthsOfYear[
                                        parseInt(showMonth, 10) - 1
                                      ] +
                                        "," +
                                        showYear}
                                    </td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-success btn-sm p-2 text-white mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i.id);
                                        }}
                                      >
                                        <i
                                          class="ri-article-line "
                                          aria-hidden="true"
                                        >
                                          Results
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={() => setFlag(0)}
                          className="btn btn-primary mr-auto p-2 "
                        >
                          Back
                        </button>
                        <button
                          onClick={onDownload}
                          className="btn btn-success mr-2"
                        >
                          Export
                        </button>
                        <button
                          onClick={onExport}
                          className="btn btn-success p-2 mr-2"
                        >
                          CSV
                        </button>
                        <button
                          onClick={handlePrint}
                          className="btn btn-success p-2"
                        >
                          Print Marksheet
                        </button>
                      </div>
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                            fontSize: "14px",
                            padding: "0px",
                          }}
                          ref={tableRef}
                        >
                          <tbody>
                            {reportStat?.studentData?.map((i, key) => (
                              <>
                                <tr>
                                  {/* <td rowSpan={8}>{key + 1}</td>*/}
                                  <td rowSpan={8}>{i?.student_id}</td>

                                  <td colSpan={2}>
                                    <table
                                      className="reportmarkstable"
                                      style={{
                                        fontSize: "14px",
                                        padding: "0px",
                                      }}
                                    >
                                      <tr>
                                        <th
                                          colSpan={
                                            reportStat?.marks?.filter(
                                              (s) =>
                                                s?.student_id == i?.student_id
                                            ).length - 3
                                          }
                                        >
                                          {i?.name}
                                        </th>
                                        <th className="text-center">
                                          {user?.college_id == 1111001
                                            ? classOpt?.filter(
                                                (s) => s.id == user?.class_id
                                              )[0]?.name
                                            : semesterOpt?.filter(
                                                (s) => s.id == user?.semester_id
                                              )[0]?.name}
                                        </th>

                                        <th className="text-center">
                                          {" "}
                                          {monthsOfYear[
                                            parseInt(showMonth, 10) - 1
                                          ] +
                                            "," +
                                            showYear}
                                        </th>

                                        <th className="text-center" colSpan={2}>
                                          {showYear - 1 + "-" + showYear}{" "}
                                        </th>
                                      </tr>

                                      <tr>
                                        <th>Summary</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th className="text-center">
                                              {j?.subject} <br />{" "}
                                              <span className="text-primary">
                                                ({j?.sub_code})
                                              </span>
                                            </th>
                                          ))}
                                      </tr>

                                      <tr>
                                        <th>Subject Type</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th className="text-center">
                                              {j?.type}
                                            </th>
                                          ))}
                                      </tr>

                                      <tr>
                                        <th>External Max</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.e_max == 0 || j?.e_max == null
                                                ? "--"
                                                : j?.e_max}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>External Min</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.e_min == 0 || j?.e_min == null
                                                ? "--"
                                                : j?.e_min}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>External Marks Obtained</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.e_min == 0
                                                  ? "text-danger"
                                                  : j?.e_marks +
                                                      Number(
                                                        j?.e_grace_marks
                                                      ) >=
                                                    j?.e_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {!j?.e_marks && j?.e_absent ? (
                                                "absent"
                                              ) : (
                                                <>
                                                  {j?.e_marks == null
                                                    ? "--"
                                                    : j?.e_marks}{" "}
                                                  {j?.e_grace_marks
                                                    ? " + " + j?.e_grace_marks
                                                    : null}
                                                </>
                                              )}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>External Marks GP</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.e_min == 0
                                                  ? "text-danger"
                                                  : j?.e_marks +
                                                      Number(
                                                        j?.e_grace_marks
                                                      ) >=
                                                    j?.e_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {!j?.e_marks && j?.e_absent ? (
                                                "absent"
                                              ) : j?.e_marks +
                                                  j?.e_grace_marks <
                                                j?.e_min ? (
                                                "FF"
                                              ) : (
                                                <>
                                                  {/* {j?.e_marks} {j?.e_grace_marks ? ' + ' + j?.e_grace_marks : null} */}
                                                  {calculateGradePointEachSub(
                                                    Number(j?.e_marks) +
                                                      (j?.e_grace_marks
                                                        ? Number(
                                                            j?.e_grace_marks
                                                          )
                                                        : 0),
                                                    Number(j?.e_max)
                                                  )?.grade == null
                                                    ? "--"
                                                    : calculateGradePointEachSub(
                                                        Number(j?.e_marks) +
                                                          (j?.e_grace_marks
                                                            ? Number(
                                                                j?.e_grace_marks
                                                              )
                                                            : 0),
                                                        Number(j?.e_max)
                                                      )?.grade}
                                                </>
                                              )}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Midterm Max</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.mt_max == 0 ||
                                              j?.mt_max == null
                                                ? "--"
                                                : j?.mt_max}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Midterm Min</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.mt_min == 0 ||
                                              j?.mt_min == null
                                                ? "--"
                                                : j?.mt_min}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Midterm Marks Obtained</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.mt_min == 0
                                                  ? "text-danger"
                                                  : j?.mt_marks >= j?.mt_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {!j?.mt_marks && j?.mt_absent ? (
                                                "absent"
                                              ) : (
                                                <>
                                                  {j?.mt_marks == 0 ||
                                                  j?.mt_marks == null
                                                    ? "--"
                                                    : j?.mt_marks}
                                                </>
                                              )}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Midterm Marks GP</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.mt_min == 0
                                                  ? "text-danger"
                                                  : j?.mt_marks >= j?.mt_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {!j?.mt_marks && j?.mt_absent ? (
                                                "absent"
                                              ) : (
                                                <>
                                                  {calculateGradePointEachSub(
                                                    Number(j?.mt_marks),
                                                    Number(j?.mt_max)
                                                  )?.grade == null
                                                    ? "--"
                                                    : calculateGradePointEachSub(
                                                        Number(j?.mt_marks),
                                                        Number(j?.mt_max)
                                                      )?.grade}
                                                </>
                                              )}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Viva Max</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.viva_max == 0 ||
                                              j?.viva_max == null
                                                ? "--"
                                                : j?.viva_max}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Viva Min</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.viva_min == 0 ||
                                              j?.viva_min == null
                                                ? "--"
                                                : j?.viva_min}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Viva Marks Obtained</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.viva_min == 0
                                                  ? "text-danger"
                                                  : j?.viva_marks >= j?.viva_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {!j?.viva_marks &&
                                              j?.viva_absent ? (
                                                "absent"
                                              ) : (
                                                <>
                                                  {j?.viva_marks == 0 ||
                                                  j?.viva_marks == null
                                                    ? "--"
                                                    : j?.viva_marks}
                                                </>
                                              )}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Viva Marks GP</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.viva_min == 0
                                                  ? "text-danger"
                                                  : j?.viva_marks >= j?.viva_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {!j?.viva_marks &&
                                              j?.viva_absent ? (
                                                "absent"
                                              ) : (
                                                <>
                                                  {calculateGradePointEachSub(
                                                    Number(j?.viva_marks),
                                                    Number(j?.viva_max)
                                                  )?.grade == null
                                                    ? "--"
                                                    : calculateGradePointEachSub(
                                                        Number(j?.viva_marks),
                                                        Number(j?.viva_max)
                                                      )?.grade}
                                                </>
                                              )}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Internal Max</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.i_max == 0 || j?.i_max == null
                                                ? "--"
                                                : j?.i_max}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Internal Min</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.i_min == 0 || j?.i_min == null
                                                ? "--"
                                                : j?.i_min}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Internal Marks Obtained</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.i_marks >= j?.i_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {j?.i_marks == 0 ||
                                              j?.i_marks == null
                                                ? "--"
                                                : j?.i_marks}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Internal Marks GP</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.i_marks >= j?.i_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {j?.i_marks == 0
                                                ? "--"
                                                : calculateGradePointEachSub(
                                                    Number(j?.i_marks),
                                                    Number(j?.i_max)
                                                  )?.grade}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Credits</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.credit}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Grade</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th className="text-center">
                                              {j.e_marks + j.e_grace_marks <
                                              j.e_min
                                                ? "FF"
                                                : j.mt_marks < j.mt_min
                                                ? "FF"
                                                : j.viva_marks < j.viva_min
                                                ? "FF"
                                                : j.i_marks < j.i_min
                                                ? "FF"
                                                : calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.mt_marks +
                                                      j?.viva_marks,
                                                    j.e_max +
                                                      j?.i_max +
                                                      j?.mt_max +
                                                      j?.viva_max
                                                  )?.grade}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Grade Points: </th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j.e_marks + j.e_grace_marks <
                                              j.e_min
                                                ? 0
                                                : j.mt_marks < j.mt_min
                                                ? 0
                                                : j.viva_marks < j.viva_min
                                                ? 0
                                                : j.i_marks < j.i_min
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.mt_marks +
                                                      j?.viva_marks,
                                                    j.e_max +
                                                      j?.i_max +
                                                      j?.mt_max +
                                                      j?.viva_max
                                                  )?.gp}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>CGP</th>
                                        {reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j.e_marks + j.e_grace_marks <
                                              j.e_min
                                                ? 0
                                                : j.mt_marks < j.mt_min
                                                ? 0
                                                : j.viva_marks < j.viva_min
                                                ? 0
                                                : j.i_marks < j.i_min
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.mt_marks +
                                                      j?.viva_marks,
                                                    j.e_max +
                                                      j?.i_max +
                                                      j?.mt_max +
                                                      j?.viva_max
                                                  )?.gp * j?.credit}
                                            </td>
                                          ))}
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Maximum Marks</th>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, cur) =>
                                          total +
                                          (Number(cur?.e_max) +
                                            Number(cur?.i_max) +
                                            Number(cur?.mt_max) +
                                            Number(cur?.viva_max)),
                                        0
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Obtained Marks</th>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, cur) =>
                                          total +
                                          (Number(cur?.e_marks) +
                                            Number(cur?.i_marks) +
                                            Number(cur?.mt_marks) +
                                            Number(cur?.viva_marks)),
                                        0
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Total Credits</th>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, j) => total + Number(j?.credit),
                                        0
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Total GPA</th>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, j) =>
                                          total +
                                          Number(
                                            (j?.e_min >
                                            j?.e_grace_marks + j?.e_marks
                                              ? 0
                                              : j?.i_min > j?.i_marks
                                              ? 0
                                              : j?.mt_min > j?.mt_marks
                                              ? 0
                                              : j?.viva_min > j?.viva_marks
                                              ? 0
                                              : j?.e_absent
                                              ? 0
                                              : j?.i_absent
                                              ? 0
                                              : j?.mt_absent
                                              ? 0
                                              : j?.viva_absent
                                              ? 0
                                              : calculateGradePointEachSub(
                                                  j?.e_marks + j?.e_grace_marks,
                                                  j?.e_max
                                                )?.gp == 0
                                              ? 0
                                              : calculateGradePointEachSub(
                                                  j?.i_marks,
                                                  j?.i_max
                                                )?.gp == 0
                                              ? 0
                                              : calculateGradePointEachSub(
                                                  j?.mt_marks,
                                                  j?.mt_max
                                                )?.gp == 0
                                              ? 0
                                              : calculateGradePointEachSub(
                                                  j?.viva_marks,
                                                  j?.viva_max
                                                )?.gp == 0
                                              ? 0
                                              : calculateGradePointEachSub(
                                                  j?.e_marks +
                                                    j?.i_marks +
                                                    j?.mt_marks +
                                                    j?.viva_marks,
                                                  j.e_max +
                                                    j?.i_max +
                                                    j?.mt_max +
                                                    j?.viva_max
                                                )
                                              ? calculateGradePointEachSub(
                                                  j.e_marks +
                                                    j?.i_marks +
                                                    j?.mt_marks +
                                                    j?.viva_marks,
                                                  j.e_max +
                                                    j?.i_max +
                                                    j?.mt_max +
                                                    j?.viva_max
                                                )?.gp
                                              : 0) * j?.credit
                                          ),

                                        0
                                      )
                                      .toFixed(2)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Percentage</th>
                                  <td>
                                    {(
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total +
                                            Number(
                                              (j?.e_min >
                                              j?.e_grace_marks + j?.e_marks
                                                ? 0
                                                : j?.i_min > j?.i_marks
                                                ? 0
                                                : j?.mt_min > j?.mt_marks
                                                ? 0
                                                : j?.viva_min > j?.viva_marks
                                                ? 0
                                                : j?.e_absent
                                                ? 0
                                                : j?.i_absent
                                                ? 0
                                                : j?.mt_absent
                                                ? 0
                                                : j?.viva_absent
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.e_marks +
                                                      j?.e_grace_marks,
                                                    j?.e_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.i_marks,
                                                    j?.i_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.mt_marks,
                                                    j?.mt_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.viva_marks,
                                                    j?.viva_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.e_marks +
                                                      j?.i_marks +
                                                      j?.mt_marks +
                                                      j?.viva_marks,
                                                    j.e_max +
                                                      j?.i_max +
                                                      j?.mt_max +
                                                      j?.viva_max
                                                  )
                                                ? calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.mt_marks +
                                                      j?.viva_marks,
                                                    j.e_max +
                                                      j?.i_max +
                                                      j?.mt_max +
                                                      j?.viva_max
                                                  )?.gp
                                                : 0) * j?.credit
                                            ),
                                          0
                                        ) /
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total + Number(j?.credit),
                                          0
                                        )
                                    ).toFixed(2) == 0.0
                                      ? "0.00"
                                      : (
                                          (reportStat?.marks
                                            ?.filter(
                                              (s) =>
                                                s?.student_id ==
                                                  i?.student_id &&
                                                !s.is_not_countable
                                            )
                                            ?.reduce(
                                              (total, j) =>
                                                total +
                                                Number(
                                                  (j?.e_min >
                                                  j?.e_grace_marks + j?.e_marks
                                                    ? 0
                                                    : j?.i_min > j?.i_marks
                                                    ? 0
                                                    : j?.mt_min > j?.mt_marks
                                                    ? 0
                                                    : j?.viva_min >
                                                      j?.viva_marks
                                                    ? 0
                                                    : j?.e_absent
                                                    ? 0
                                                    : j?.i_absent
                                                    ? 0
                                                    : j?.mt_absent
                                                    ? 0
                                                    : j?.viva_absent
                                                    ? 0
                                                    : calculateGradePointEachSub(
                                                        j?.e_marks +
                                                          j?.e_grace_marks,
                                                        j?.e_max
                                                      )?.gp == 0
                                                    ? 0
                                                    : calculateGradePointEachSub(
                                                        j?.i_marks,
                                                        j?.i_max
                                                      )?.gp == 0
                                                    ? 0
                                                    : calculateGradePointEachSub(
                                                        j?.mt_marks,
                                                        j?.mt_max
                                                      )?.gp == 0
                                                    ? 0
                                                    : calculateGradePointEachSub(
                                                        j?.viva_marks,
                                                        j?.viva_max
                                                      )?.gp == 0
                                                    ? 0
                                                    : calculateGradePointEachSub(
                                                        j?.e_marks +
                                                          j?.i_marks +
                                                          j?.mt_marks +
                                                          j?.viva_marks,
                                                        j.e_max +
                                                          j?.i_max +
                                                          j?.mt_max +
                                                          j?.viva_max
                                                      )
                                                    ? calculateGradePointEachSub(
                                                        j.e_marks +
                                                          j?.i_marks +
                                                          j?.mt_marks +
                                                          j?.viva_marks,
                                                        j.e_max +
                                                          j?.i_max +
                                                          j?.mt_max +
                                                          j?.viva_max
                                                      )?.gp
                                                    : 0) * j?.credit
                                                ),

                                              0
                                            ) /
                                            reportStat?.marks
                                              ?.filter(
                                                (s) =>
                                                  s?.student_id ==
                                                    i?.student_id &&
                                                  !s.is_not_countable
                                              )
                                              ?.reduce(
                                                (total, j) =>
                                                  total + Number(j?.credit),
                                                0
                                              ) -
                                            0.5) *
                                          10
                                        ).toFixed(2)}{" "}
                                    %
                                    {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                                  </td>
                                </tr>
                                <tr>
                                  <th>SPI</th>
                                  <td>
                                    {(
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total +
                                            Number(
                                              (j?.e_min >
                                              j?.e_grace_marks + j?.e_marks
                                                ? 0
                                                : j?.i_min > j?.i_marks
                                                ? 0
                                                : j?.mt_min > j?.mt_marks
                                                ? 0
                                                : j?.viva_min > j?.viva_marks
                                                ? 0
                                                : j?.e_absent
                                                ? 0
                                                : j?.i_absent
                                                ? 0
                                                : j?.mt_absent
                                                ? 0
                                                : j?.viva_absent
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.e_marks +
                                                      j?.e_grace_marks,
                                                    j?.e_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.i_marks,
                                                    j?.i_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.mt_marks,
                                                    j?.mt_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.viva_marks,
                                                    j?.viva_max
                                                  )?.gp == 0
                                                ? 0
                                                : calculateGradePointEachSub(
                                                    j?.e_marks +
                                                      j?.i_marks +
                                                      j?.mt_marks +
                                                      j?.viva_marks,
                                                    j.e_max +
                                                      j?.i_max +
                                                      j?.mt_max +
                                                      j?.viva_max
                                                  )
                                                ? calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.mt_marks +
                                                      j?.viva_marks,
                                                    j.e_max +
                                                      j?.i_max +
                                                      j?.mt_max +
                                                      j?.viva_max
                                                  )?.gp
                                                : 0) * j?.credit
                                            ),

                                          0
                                        ) /
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total + Number(j?.credit),
                                          0
                                        )
                                    ).toFixed(2)}
                                    {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Result</th>
                                  <th>
                                    {AnalyseResult(i?.student_id) ? (
                                      <span className="text-success">
                                        Pass{" "}
                                      </span>
                                    ) : (
                                      <span className="text-danger">Fail</span>
                                    )}
                                  </th>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {flag1 ? (
                        <div className="mt-3 container" ref={tableRef1}>
                          <div className="image-container">
                            <div className="row overlay-container">
                              {reportStat1?.studentData?.map((j, key) => (
                                <div className="col-md-12 bg">
                                  <div className="card">
                                    <div className="card-body">
                                      <div className="table-responsive res">
                                        <table className="table table-bordered result overlay-table">
                                          <thead className="thead-dark bgimg">
                                            <tr>
                                              <th>Academic Year</th>
                                              <th>
                                                Month and year of Examination
                                              </th>
                                              <th>Year/Sem</th>
                                              <th>Enrollment Number</th>
                                            </tr>
                                            <tr>
                                              <td>
                                                {
                                                  SessionOpt1?.find(
                                                    (s) => s.id == j?.e_session
                                                  )?.name
                                                }
                                              </td>
                                              <td>
                                                {monthsOfYear[user?.month - 1]}{" "}
                                                - {user?.year}
                                              </td>
                                              <td>
                                                {
                                                  semesterOpt?.filter(
                                                    (s) =>
                                                      s.id == user?.semester_id
                                                  )[0]?.name
                                                }
                                              </td>
                                              <td>{j?.student_id}</td>
                                            </tr>
                                            <tr>
                                              <th colSpan="2">Faculty Name</th>
                                              <th colSpan="2">College Name</th>
                                            </tr>

                                            <tr>
                                              <td colSpan="2">
                                                {
                                                  facultyData?.filter(
                                                    (s) =>
                                                      s.id ==
                                                      reportStat?.marks?.filter(
                                                        (s) =>
                                                          s.student_id ==
                                                          j?.student_id
                                                      )[0]?.college_id
                                                  )[0]?.name
                                                }
                                              </td>
                                              <td colSpan="2">
                                                {
                                                  facultyData?.filter(
                                                    (s) =>
                                                      s.id ==
                                                      reportStat?.marks?.filter(
                                                        (s) =>
                                                          s.student_id ==
                                                          j?.student_id
                                                      )[0]?.college_id
                                                  )[0]?.colloge_name
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <th colSpan="2">Program</th>
                                              <td colSpan="2">
                                                {
                                                  programOpt?.find(
                                                    (s) =>
                                                      s.id ==
                                                      reportStat?.marks?.filter(
                                                        (s) =>
                                                          s.student_id ==
                                                          j?.student_id
                                                      )[0]?.program_id
                                                  )?.name
                                                }
                                              </td>
                                            </tr>
                                            <tr>
                                              <th colSpan="2">Name</th>
                                              <td colSpan="2">{j?.name}</td>
                                            </tr>
                                          </thead>
                                        </table>

                                        <table className="table result table-bordered ">
                                          <thead>
                                            <tr>
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                Subject Code
                                              </th>
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                Course Name
                                              </th>
                                              {/* {totalcredits?.credits != 0 ? (
                                          <th
                                            rowSpan="2"
                                            className="text-center"
                                          >
                                            Credit
                                          </th>
                                        ) : (
                                          ""
                                        )} */}
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                Credits
                                              </th>
                                              <th
                                                colSpan={2}
                                                className="text-center"
                                                rowSpan={1}
                                              >
                                                Theory Component
                                              </th>
                                              <th
                                                colSpan={2}
                                                className="text-center"
                                                rowSpan={1}
                                              >
                                                Practical Component
                                              </th>
                                              <th
                                                rowSpan={2}
                                                className="text-center"
                                              >
                                                Theory Grade
                                              </th>
                                              <th
                                                rowSpan={2}
                                                className="text-center"
                                              >
                                                Practical Grade
                                              </th>
                                              <th
                                                rowSpan={2}
                                                className="text-center"
                                              >
                                                Subject Grade
                                              </th>
                                            </tr>
                                            <tr>
                                              <th className="text-center">
                                                ESE
                                              </th>
                                              <th className="text-center">
                                                PA
                                              </th>
                                              <th className="text-center">
                                                ESE
                                              </th>
                                              <th className="text-center">
                                                PA
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {reportStat1?.marks
                                              ?.filter(
                                                (s) =>
                                                  s?.student_id == j?.student_id
                                              )
                                              ?.map((i, key) => (
                                                <tr key={key}>
                                                  <td>{i?.sub_code}</td>
                                                  <td>{i?.subject}</td>
                                                  {reportStat1?.marks
                                                    ?.filter(
                                                      (s) =>
                                                        s.student_id ==
                                                        j.student_id
                                                    )
                                                    ?.reduce(
                                                      (total, j) =>
                                                        total +
                                                        Number(j?.absent),
                                                      0
                                                    ) != 0 ? (
                                                    <td className="text-center">
                                                      {i?.credit}{" "}
                                                    </td>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* {
                                                      if(i.student_id == "SUK050422CE002")
                                                      {
                                                        console.log();
                                                      }
                                                    } */}
                                                  <td className="text-center">
                                                    {i?.e_min >
                                                    i.e_marks + i?.e_grace_marks
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks,
                                                          i?.e_max
                                                        )
                                                      ? calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks,
                                                          i?.e_max
                                                        )?.grade
                                                      : "-"}
                                                    {i?.e_grace_marks != 0 &&
                                                    i?.e_grace_marks != null
                                                      ? "*"
                                                      : ""}
                                                  </td>
                                                  <td className="text-center">
                                                    {i?.mt_min > i.mt_marks
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.mt_marks,
                                                          i?.mt_max
                                                        )
                                                      ? calculateGradePointEachSub(
                                                          i?.mt_marks,
                                                          i?.mt_max
                                                        )?.grade
                                                      : "-"}
                                                  </td>
                                                  <td className="text-center">
                                                    {i?.i_min > i.i_marks
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.i_marks,
                                                          i?.i_max
                                                        )
                                                      ? calculateGradePointEachSub(
                                                          i?.i_marks,
                                                          i?.i_max
                                                        )?.grade
                                                      : "-"}
                                                  </td>
                                                  <td className="text-center">
                                                    {i?.viva_min > i.viva_marks
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.viva_marks,
                                                          i?.viva_max
                                                        )
                                                      ? calculateGradePointEachSub(
                                                          i?.viva_marks,
                                                          i?.viva_max
                                                        )?.grade
                                                      : "-"}
                                                  </td>
                                                  <td className="text-center">
                                                    {i?.e_min >
                                                    i.e_marks + i?.e_grace_marks
                                                      ? "FF"
                                                      : i?.mt_min > i.mt_marks
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks,
                                                          i?.e_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.mt_marks,
                                                          i?.mt_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks +
                                                            i?.mt_marks,
                                                          i?.e_max + i?.mt_max
                                                        )
                                                      ? calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks +
                                                            i?.mt_marks,
                                                          i?.e_max + i?.mt_max
                                                        )?.grade
                                                      : "-"}
                                                  </td>
                                                  <td className="text-center">
                                                    {i?.i_min > i.i_marks
                                                      ? "FF"
                                                      : i?.viva_min >
                                                        i.viva_marks
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.viva_marks,
                                                          i?.viva_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.i_marks,
                                                          i?.i_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.i_marks +
                                                            i?.viva_marks,
                                                          i?.i_max + i?.viva_max
                                                        )
                                                      ? calculateGradePointEachSub(
                                                          i?.i_marks +
                                                            i?.viva_marks,
                                                          i?.i_max + i?.viva_max
                                                        )?.grade
                                                      : "-"}
                                                  </td>
                                                  <td className="text-center">
                                                    {/* {i.subject} */}
                                                    {i.subject ==
                                                    "Induction Program"
                                                      ? "PS"
                                                      : i?.e_min >
                                                        i.e_marks +
                                                          i?.e_grace_marks
                                                      ? "FF"
                                                      : i?.mt_min > i.mt_marks
                                                      ? "FF"
                                                      : i?.i_min > i.i_marks
                                                      ? "FF"
                                                      : i?.viva_min >
                                                        i.viva_marks
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks,
                                                          i?.e_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.mt_marks,
                                                          i?.mt_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.viva_marks,
                                                          i?.viva_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.i_marks,
                                                          i?.i_max
                                                        )?.gp == 0
                                                      ? "FF"
                                                      : calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks +
                                                            i?.mt_marks +
                                                            i?.i_marks +
                                                            i?.viva_marks,
                                                          i?.i_max +
                                                            i?.viva_max +
                                                            i?.e_max +
                                                            i?.mt_max
                                                        )
                                                      ? calculateGradePointEachSub(
                                                          i?.e_marks +
                                                            i?.e_grace_marks +
                                                            i?.mt_marks +
                                                            i?.i_marks +
                                                            i?.viva_marks,
                                                          i?.i_max +
                                                            i?.viva_max +
                                                            i?.e_max +
                                                            i?.mt_max
                                                        )?.grade
                                                      : "-"}
                                                  </td>
                                                </tr>
                                              ))}

                                            <tr>
                                              <th className="text-center">
                                                Total
                                              </th>
                                              {reportStat1?.marks
                                                ?.filter(
                                                  (s) =>
                                                    s.student_id == j.student_id
                                                )
                                                ?.reduce(
                                                  (total, j) =>
                                                    total + Number(j?.credit),
                                                  0
                                                ) != 0 ? (
                                                <th
                                                  colSpan={4}
                                                  className="text-center"
                                                >
                                                  {" "}
                                                  Credits :{" "}
                                                  {reportStat1?.marks
                                                    ?.filter(
                                                      (s) =>
                                                        s.student_id ==
                                                        j.student_id
                                                    )
                                                    ?.reduce(
                                                      (total, j) =>
                                                        total +
                                                        Number(j?.credit),
                                                      0
                                                    )}{" "}
                                                </th>
                                              ) : (
                                                ""
                                              )}
                                              {reportStat1?.marks
                                                ?.filter(
                                                  (s) =>
                                                    s.student_id == j.student_id
                                                )
                                                ?.reduce(
                                                  (total, j) =>
                                                    total + Number(j?.credit),
                                                  0
                                                ) != 0 ? (
                                                <th
                                                  colSpan={5}
                                                  className="text-center"
                                                >
                                                  {" "}
                                                  Grade Points :
                                                  {reportStat1?.marks
                                                    ?.filter(
                                                      (s) =>
                                                        s?.student_id ==
                                                        j?.student_id
                                                    )
                                                    ?.reduce(
                                                      (total, j) =>
                                                        total +
                                                        Number(
                                                          (j?.e_min >
                                                          j?.e_grace_marks +
                                                            j?.e_marks
                                                            ? 0
                                                            : j?.i_min >
                                                              j?.i_marks
                                                            ? 0
                                                            : j?.mt_min >
                                                              j?.mt_marks
                                                            ? 0
                                                            : j?.viva_min >
                                                              j?.viva_marks
                                                            ? 0
                                                            : j?.e_absent
                                                            ? 0
                                                            : j?.i_absent
                                                            ? 0
                                                            : j?.mt_absent
                                                            ? 0
                                                            : j?.viva_absent
                                                            ? 0
                                                            : calculateGradePointEachSub(
                                                                j?.e_marks +
                                                                  j?.e_grace_marks,
                                                                j?.e_max
                                                              )?.gp == 0
                                                            ? 0
                                                            : calculateGradePointEachSub(
                                                                j?.i_marks,
                                                                j?.i_max
                                                              )?.gp == 0
                                                            ? 0
                                                            : calculateGradePointEachSub(
                                                                j?.mt_marks,
                                                                j?.mt_max
                                                              )?.gp == 0
                                                            ? 0
                                                            : calculateGradePointEachSub(
                                                                j?.viva_marks,
                                                                j?.viva_max
                                                              )?.gp == 0
                                                            ? 0
                                                            : calculateGradePointEachSub(
                                                                j?.e_marks +
                                                                  j?.i_marks +
                                                                  j?.mt_marks +
                                                                  j?.viva_marks +
                                                                  j?.e_grace_marks,
                                                                j.e_max +
                                                                  j?.i_max +
                                                                  j?.mt_max +
                                                                  j?.viva_max
                                                              )
                                                            ? calculateGradePointEachSub(
                                                                j.e_marks +
                                                                  j?.i_marks +
                                                                  j?.mt_marks +
                                                                  j?.viva_marks +
                                                                  j?.e_grace_marks,
                                                                j.e_max +
                                                                  j?.i_max +
                                                                  j?.mt_max +
                                                                  j?.viva_max
                                                              )?.gp
                                                            : 0) * j?.credit
                                                        ),
                                                      0
                                                    )
                                                    .toFixed(2)}
                                                </th>
                                              ) : (
                                                ""
                                              )}
                                            </tr>
                                            <tr className="text-center">
                                              <th className="text-center">
                                                {" "}
                                                Result{" "}
                                              </th>
                                              <td
                                                colSpan={5}
                                                className="text-center"
                                              >
                                                {!analyseResults(
                                                  reportStat1?.marks?.filter(
                                                    (s) =>
                                                      s?.student_id ==
                                                      j?.student_id
                                                  )
                                                ) ||
                                                reportStat1?.marks
                                                  ?.filter(
                                                    (s) =>
                                                      s.student_id ==
                                                      j.student_id
                                                  )
                                                  ?.reduce(
                                                    (total, j) =>
                                                      total + Number(j?.absent),
                                                    0
                                                  ) >= 1 ||
                                                reportStat1?.marks
                                                  ?.filter(
                                                    (s) =>
                                                      s?.student_id ==
                                                      j?.student_id
                                                  )
                                                  ?.reduce(
                                                    (total, i) =>
                                                      total +
                                                      (calculateGradePoint(
                                                        (Number(i?.i_marks) +
                                                          Number(i?.e_marks) +
                                                          Number(
                                                            i?.e_grace_marks
                                                          ) +
                                                          Number(i?.mt_marks) +
                                                          Number(
                                                            i?.viva_marks
                                                          )) /
                                                          (Number(i?.e_max) +
                                                            Number(i?.i_max) +
                                                            Number(i?.mt_max) +
                                                            Number(i?.viva_max))
                                                      ) <
                                                        3.6),
                                                    0
                                                  ) ? (
                                                  <p className="text-success">
                                                    <b>FAIL </b>
                                                  </p>
                                                ) : (
                                                  <p className="text-danger">
                                                    <b> PASS </b>{" "}
                                                  </p>
                                                )}
                                              </td>

                                              {reportStat1?.marks
                                                ?.filter(
                                                  (s) =>
                                                    s.student_id == j.student_id
                                                )
                                                ?.reduce(
                                                  (total, j) =>
                                                    total + Number(j?.credit),
                                                  0
                                                ) != 0 ? (
                                                <th>SPI:</th>
                                              ) : (
                                                ""
                                              )}

                                              {reportStat1?.marks
                                                ?.filter(
                                                  (s) =>
                                                    s.student_id ==
                                                      j.student_id &&
                                                    !s.is_not_countable
                                                )
                                                ?.reduce(
                                                  (total, j) =>
                                                    total + Number(j?.credit),
                                                  0
                                                ) != 0 ? (
                                                <th
                                                  colSpan={3}
                                                  className="text-center"
                                                >
                                                  {reportStat1?.marks
                                                    ?.filter(
                                                      (s) =>
                                                        s.student_id ==
                                                          j.student_id &&
                                                        !s.is_not_countable
                                                    )
                                                    ?.reduce(
                                                      (total, j) =>
                                                        total +
                                                        Number(j?.absent),
                                                      0
                                                    ) >= 1 ||
                                                  reportStat1?.marks
                                                    ?.filter(
                                                      (s) =>
                                                        s?.student_id ==
                                                          j?.student_id &&
                                                        !s.is_not_countable
                                                    )
                                                    ?.reduce(
                                                      (total, i) =>
                                                        i?.is_not_countable
                                                          ? total + 0
                                                          : total +
                                                            (calculateGradePoint(
                                                              (Number(
                                                                i?.i_marks
                                                              ) +
                                                                Number(
                                                                  i?.e_marks
                                                                ) +
                                                                Number(
                                                                  i?.e_grace_marks
                                                                ) +
                                                                Number(
                                                                  i?.mt_marks
                                                                ) +
                                                                Number(
                                                                  i?.viva_marks
                                                                )) /
                                                                (Number(
                                                                  i?.e_max
                                                                ) +
                                                                  Number(
                                                                    i?.i_max
                                                                  ) +
                                                                  Number(
                                                                    i?.mt_max
                                                                  ) +
                                                                  Number(
                                                                    i?.viva_max
                                                                  ))
                                                            ) <
                                                              3.6),
                                                      0
                                                    )
                                                    ? "-"
                                                    : reportStat1?.marks
                                                        ?.filter(
                                                          (s) =>
                                                            s.student_id ==
                                                              j.student_id &&
                                                            !s.is_not_countable
                                                        )
                                                        ?.reduce(
                                                          (total, j) =>
                                                            total +
                                                            Number(j?.absent),
                                                          0
                                                        ) >= 1
                                                    ? "-"
                                                    : (
                                                        reportStat1?.marks
                                                          ?.filter(
                                                            (s) =>
                                                              s?.student_id ==
                                                                j?.student_id &&
                                                              !s.is_not_countable
                                                          )
                                                          ?.reduce(
                                                            (total, j) =>
                                                              total +
                                                              Number(
                                                                (j?.e_min >
                                                                j?.e_grace_marks +
                                                                  j?.e_marks
                                                                  ? 0
                                                                  : j?.i_min >
                                                                    j?.i_marks
                                                                  ? 0
                                                                  : j?.mt_min >
                                                                    j?.mt_marks
                                                                  ? 0
                                                                  : j?.viva_min >
                                                                    j?.viva_marks
                                                                  ? 0
                                                                  : j?.e_absent
                                                                  ? 0
                                                                  : j?.i_absent
                                                                  ? 0
                                                                  : j?.mt_absent
                                                                  ? 0
                                                                  : j?.viva_absent
                                                                  ? 0
                                                                  : calculateGradePointEachSub(
                                                                      j?.e_marks +
                                                                        j?.e_grace_marks,
                                                                      j?.e_max
                                                                    )?.gp == 0
                                                                  ? 0
                                                                  : calculateGradePointEachSub(
                                                                      j?.i_marks,
                                                                      j?.i_max
                                                                    )?.gp == 0
                                                                  ? 0
                                                                  : calculateGradePointEachSub(
                                                                      j?.mt_marks,
                                                                      j?.mt_max
                                                                    )?.gp == 0
                                                                  ? 0
                                                                  : calculateGradePointEachSub(
                                                                      j?.viva_marks,
                                                                      j?.viva_max
                                                                    )?.gp == 0
                                                                  ? 0
                                                                  : calculateGradePointEachSub(
                                                                      j?.e_marks +
                                                                        j?.i_marks +
                                                                        j?.mt_marks +
                                                                        j?.viva_marks,
                                                                      j.e_max +
                                                                        j?.i_max +
                                                                        j?.mt_max +
                                                                        j?.viva_max
                                                                    )
                                                                  ? calculateGradePointEachSub(
                                                                      j.e_marks +
                                                                        j?.i_marks +
                                                                        j?.mt_marks +
                                                                        j?.viva_marks,
                                                                      j.e_max +
                                                                        j?.i_max +
                                                                        j?.mt_max +
                                                                        j?.viva_max
                                                                    )?.gp
                                                                  : 0) *
                                                                  j?.credit
                                                              ),

                                                            0
                                                          )
                                                          .toFixed(2) /
                                                        reportStat?.marks
                                                          ?.filter(
                                                            (s) =>
                                                              s.student_id ==
                                                                j.student_id &&
                                                              !s.is_not_countable
                                                          )
                                                          ?.reduce(
                                                            (total, j) =>
                                                              total +
                                                              Number(j?.credit),
                                                            0
                                                          )
                                                      )?.toFixed(2)}
                                                </th>
                                              ) : (
                                                ""
                                              )}
                                            </tr>
                                          </tbody>
                                        </table>

                                        <p className="engdate">
                                          {formattedDate}
                                        </p>
                                        <img
                                          src="/assets/images/signature/coe sign.png"
                                          alt=""
                                          className="coesign"
                                          width={180}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDetailedEng;
