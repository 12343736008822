import React, { useEffect, useState } from 'react'
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT, LOCAL_PROGRAM } from '../../../utils/LocalStorageConstants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { POST_EXAM_FETCH_DATA_FOR_RECHECKING, PRE_CLASS_EXAM, PRE_EXAM_COMMITTEE, PRE_EXAM_TIMETABLE } from '../../../utils/Examination.apiConst'
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SEMESTER, ACADEMICS_ADD_SUBJECT } from '../../../utils/Academics.apiConst'
import Nodata from '../../../Components/NoData/Nodata'
import ModalRechecking from '../../../modals/Examinations/ModalRechecking'

const Revaluation = ({ setLoading }) => {
    const ProgramOpt = JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
    const CollegeOpt = JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
    const DeptOpt = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))

    const [classOpt, setClassOpt] = useState([])
    const [semesterOpt, setSemesterOpt] = useState([])
    const [data, setData] = useState([])
    const [committeeData, setCommitteeData] = useState()
    const [studentData, setStudentData] = useState([])
    const [timeTableData, setTimeTableData] = useState([])
    const [subjectOpt, setSubjectOpt] = useState([])
    const [stdArray, setStdArray] = useState([])
    const [currentTimeTableId, setCurrentTimeTableId] = useState()
    const [exam_marks_id, setExamMarksId] = useState()
    const [reData, setReData] = useState()

    const [flag, setFlag] = useState(0)


    const [user, setUser] = useState({
        program_id: '',
        college_id: '',
        department_id: '',
        month: '',
        year: '',
        class_id: '',
        semester_id: ''
    })

    const handleChange = e => {
        const { name, value } = e.target
        setUser(prev => ({ ...prev, [name]: value }))
    }

    const getBasicData = async () => {
        const config = {
            method: 'get',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        }

        var flag = 0;

        const [data1, data2] = await Promise.all([
            axios({ ...config, url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}` })
                .then(res => {
                    setClassOpt(res.data.data)
                })
                .catch(err => flag = 1)
            ,
            axios({ ...config, url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}` })
                .then(res => {
                    setSemesterOpt(res.data.data)
                })
                .catch(err => flag = 1)
        ])

        if (flag) return toast.error('Something went wrong')
    }

    //Get Exam Details
    const getData = async (exam_id) => {
        setLoading(1)
        const config = {
            method: 'get',
            url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            }
        }

        await axios(config)
            .then(res => {
                console.log(res);
                setData(res.data.data)
            })
            .catch(err => console.log(err))
        setLoading(0)
    }

    //Get Committee Data
    const getCommiittee = async () => {
        if (!user.program_id || !user.college_id || !user.month || !user.year || !user?.class_id || !user?.semester_id) return toast.error('All fileds are required for search')
        setLoading(1)
        const config = {
            method: 'get',
            url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        }
        await axios(config)
            .then(res => {
                if (res.data.data.length != 0) {

                    const m = []
                    // getBasicData()
                    getData(res.data.data[0].id);
                    setCommitteeData(res.data.data[0])
                }
                else {
                    toast.warning('Committe is not exist')
                }
            })
            .catch(err => {
                toast.error('Something Went Wrong')
            })

        setLoading(0)
        // getBasicData();

        
    }

    const getTimeTable = async (examId) => {
        setLoading(1)
        const config = {
            method: 'get',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        }

        const [data1,data2] = await Promise.all([
            await axios({...config, url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${examId}&status=ACTIVE`,})

            .then(res => {
                console.log(res);
                return res.data.data
            })
            .catch(err => console.log(err))
            ,
            axios({ ...config, url: `${ACADEMICS_ADD_SUBJECT}?class_id=${user?.class_id}&semester_id=${user?.semester_id}` })
                .then(res => {
                    console.log(res);
                    return res.data.data
                })
                .catch(err => { console.log(err) })
        ])

        setTimeTableData(data1)
        setSubjectOpt(data2)
        setLoading(0)
        setFlag(1)
    }
    const getDataforRevaluation = async (time_table_id) => {
        setLoading(1)
        const config = {
            method: 'get',
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        }
        const [data1] = await Promise.all([
            axios({ ...config, url: `${POST_EXAM_FETCH_DATA_FOR_RECHECKING}/${time_table_id}` })
                .then(res => {
                    console.log(res);
                    return res.data.data
                })
                .catch(err => { console.log(err) })
        ])
        setStudentData(data1)
        const stdSet = new Set();
        for (const i of data1) {
            stdSet.add(i.student_id)
        }
        const stdArray = Array.from(stdSet);
        setStdArray(stdArray)
        console.log(stdArray);
        setLoading(0)
        setFlag(2)
    }

    useEffect(() => {
        if (user.college_id) getBasicData()
    }, [user.college_id])

    return (
        <div>
            <ModalRechecking
                reData={reData}
                setLoading={setLoading}
                exam_marks_id={exam_marks_id}
                reloadData={()=>{getDataforRevaluation(currentTimeTableId)}}
            />
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-header">Revaluation</h5>
                                        <div className="row mt-2">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Program</label>
                                                    <select
                                                        name="program_id"
                                                        onChange={handleChange}
                                                        value={user.program_id}
                                                        id=""
                                                        className="form-control"

                                                    >

                                                        <option value="">Select Program</option>
                                                        {
                                                            ProgramOpt && ProgramOpt.map((item, index) => {
                                                                return <option key={index} value={item.id}>{item.name}</option>
                                                            }
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Faculty</label>
                                                    <select
                                                        name="college_id"
                                                        id=""
                                                        className="form-control"
                                                        value={user.college_id}
                                                        onChange={handleChange}
                                                    >

                                                        <option value="">Select Faculty</option>
                                                        {
                                                            CollegeOpt && CollegeOpt.map((item, index) => {
                                                                return <option key={index} value={item.id}>{item.name}</option>
                                                            }
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Month Year</label>
                                                    <input
                                                        onChange={e => {
                                                            setUser(prev => ({
                                                                ...prev,
                                                                month: e.target.value?.split("-")[1],
                                                                year: e.target.value?.split("-")[0],
                                                            }))
                                                        }}
                                                        type="month"
                                                        name=""
                                                        id=""
                                                        className="form-control"
                                                        value={user?.year + '-' + user?.month}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Department</label>
                                                    <select
                                                        name="department_id"
                                                        onChange={handleChange}
                                                        value={user?.department_id}
                                                        className="form-control">
                                                        <option value="">Select Department</option>
                                                        {
                                                            DeptOpt?.filter(s => s.college_id == user?.college_id)?.map((i, key) => (
                                                                <option value={i?.id} key={key}>{i?.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Class</label>
                                                    <select
                                                        name="class_id"
                                                        value={user?.class_id}
                                                        onChange={handleChange}
                                                        id=""
                                                        className="form-control">
                                                        <option value="">Select Class</option>
                                                        {
                                                            classOpt?.filter(s => s.department_id == user?.department_id)?.map((i, key) => (
                                                                <option value={i?.id} key={key}>{i?.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="">Semester</label>
                                                    <select
                                                        name="semester_id"
                                                        value={user?.semester_id}
                                                        onChange={handleChange}
                                                        id=""
                                                        className="form-control">
                                                        <option value="">Select Semester</option>
                                                        {
                                                            semesterOpt?.filter(s => s.class_id == user?.class_id)?.map((i, key) => (
                                                                <option value={i?.id} key={key}>{i?.name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-3 d-flex justufy-content-end">
                                                <button onClick={getCommiittee} className=" btn btn-primary">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            !flag
                                ?
                                <div className="row mt-4">

                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-header">
                                                    Select Examination
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-md-12">
                                                        <div className="table-responsive">
                                                            <table className='table table-bordered'>
                                                                <tr>
                                                                    <th> Sl. No.</th>
                                                                    <th>Program</th>
                                                                    <th>Faculty</th>
                                                                    <th>Date</th>
                                                                    <th>Class</th>
                                                                    <th>Semester</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                                <tbody>



                                                                    {
                                                                        data && data?.length != 0 ? data?.map((i, key) => {
                                                                            return <tr key={key}>
                                                                                <td>{key + 1}</td>
                                                                                <td>{ProgramOpt?.find(s => s.id == committeeData.program_id)?.name}</td>
                                                                                <td> {CollegeOpt?.find(s => s.id == committeeData.college_id)?.name}</td>
                                                                                <td > {committeeData.month + ',' + committeeData.year}</td>
                                                                                <td> {classOpt?.find(s => s.id == i.class_id)?.name}</td>
                                                                                <td> {semesterOpt?.find(s => s.id == i.semester_id)?.name}</td>


                                                                                <td>
                                                                                    <a
                                                                                        data-toggle="modal"
                                                                                        data-target="#create"
                                                                                        className='btn btn-success btn-sm p-2 text-white mr-3'
                                                                                        title="Create Timetable"
                                                                                        onClick={() => getTimeTable(i.id)}
                                                                                    >
                                                                                        Get Data <i class="fa fa-arrow-right " aria-hidden="true"> </i>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>

                                                                        })
                                                                            :
                                                                            <tr>
                                                                                <td colSpan={7} className="text-center"><Nodata /></td>
                                                                            </tr>


                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                :
                                flag === 1 ?
                                    <div className="row mt-4">

                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="card-header">
                                                        <button className="btn btn-danger mr-2" onClick={() => setFlag(0)}>go Back</button> Select Subject
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-12">
                                                            <div className="table-responsive">
                                                                <table className='table table-bordered'>
                                                                    <tr>
                                                                        <th>Sl. No.</th>
                                                                        <th>Subject</th>
                                                                        <th>Action</th>

                                                                    </tr>
                                                                    <tbody>
                                                                        {
                                                                            timeTableData && timeTableData?.map((i, key) => (
                                                                                <tr key={key}>
                                                                                    <td>{key + 1}</td>
                                                                                    <td>{subjectOpt?.find(s => s.id == i.course_id)?.name}</td>
                                                                                    <td>
                                                                                        <button onClick={() => {
                                                                                            setCurrentTimeTableId(i.id);
                                                                                            setFlag(2)
                                                                                            getDataforRevaluation(i.id)
                                                                                        }} className="btn btn-success">View</button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>


                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    <div className="row mt-4">
                                        <div className="col-md-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="card-header">
                                                    <button className="btn btn-danger mr-2" onClick={() => setFlag(1)}> go Back</button>  Select Student
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-md-12">
                                                            <div className="table-responsive">
                                                                <table className="table table-bordered">
                                                                    <tr>
                                                                        <th>SL.No</th>
                                                                        <th>Student</th>
                                                                        <th>Previous Marks</th>
                                                                        <th>Revaluated Marks</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                    {
                                                                        studentData && studentData?.map((i, key) => (
                                                                            <tr key={key}>
                                                                                <td>{key + 1}</td>
                                                                                <td>{i.student_id}</td>
                                                                                {
                                                                                    i.re_id
                                                                                    ?
                                                                                    <>
                                                                                        <td>{i.old_marks}</td>
                                                                                        <td>{i.new_marks}</td>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <td>{i.m_marks}</td>
                                                                                        <td><span className='badge badge-soft-primary'>Not Revaluated</span></td>
                                                                                    </>


                                                                                }
                                                                                <td><button className='btn btn-primary' data-toggle="modal" data-target="#recheck" onClick={()=>{setReData(i)}}>View</button></td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Revaluation