import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { sessionOpt } from '../../Data/jsonData/Academics/Academics';
import { ACADEMICS_ADD_SUBJECT } from '../../utils/Academics.apiConst';
import { PRE_EXAM_TIMETABLE } from '../../utils/Examination.apiConst';

function ModalPublishTimeTable(props) {

  //Destructuring props
  const {
    addData,
    data,
    programOpt,
    collegeOpt,
    classOpt,
    semesterOpt,
    showCollege,
    showProgram,
    showMonth,
    showYear,
    setLoading,
    reloadData
  } = props;

  //Main obj
  const [user, setUser] = useState({
    class_examination_id: addData?.id,
    session_id: '',
    course_id: '',
    date: '',
    time_from: '',
    time_to: "",
    duration: "",
    max_marks: '',
    min_marks: ''
  })

  //Other States for data management
  const [subOpt, setSubOpt] = useState([])

  //states holding created time table data
  const [timeTableData, setTimeTableData] = useState([])


  
  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prev => ({
      ...prev,
      [name]: value
    }));
  }


  //////get Subject Data
  const getbasicData = async() => {
    setLoading(1)
    const config = {
      method:'get',
      url:`${ACADEMICS_ADD_SUBJECT}?college_id=${showCollege}&semester_id=${addData?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      }
    }

    await axios(config)
    .then(res=>{
      setSubOpt(res.data.data)
    })
    .catch(err=>{
      console.log(err);
    })

    setLoading(0)
  }

  //get time table data
  const getData = async() => {
    setLoading(1)
    const config = {
      method:'get',
      url:`${PRE_EXAM_TIMETABLE}?class_examination_id=${addData?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      }
    }

    await axios(config)
    .then(res=>{
      console.log(res);
      setTimeTableData(res.data.data)
    })
    .catch(err=>{
      console.log(err);
    })
    setLoading(0)
  }

//PublishExam
const PublishExam = async() => {
  setLoading(1)
  const config = {
    method:'put',
    url:`${PRE_EXAM_TIMETABLE}/${addData?.id}`,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
      "Content-Type": "application/json",
    },
    data:{
      status:'PUBLISHED'
    }
  }

  await axios(config)
  .then(res=>{
    console.log(res);
    toast.success('Published success')
    reloadData()
  })
  .catch(err=>{
    console.log(err);
  })
  setLoading(0)
}

  //submit form
  const handleSubmit = async() => {
    setLoading(1)
    const config = {
      method:'post',
      url:PRE_EXAM_TIMETABLE,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data:user
    }

    await axios(config)
    .then(res=>{
      console.log(res)
    })
    .catch(err=>{
      console.log(err);
    })

    setLoading(0)
    getData();

  }


  ////useEffects
  useEffect(()=>{
    if(addData){
      setUser({
        class_examination_id: addData?.id,
        session_id: '',
        course_id: '',
        date: '',
        time_from: '',
        time_to: "",
        duration: "",
        max_marks: '',
        min_marks: ''
      })
      getbasicData();
      getData();
    }
  },[addData])


  return (
    <div
      className="modal fade"
      id="create"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-100 w-100 ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Publish Exam
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Program<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                    readOnly={true}
                    disabled={true}
                    value={showProgram}
                  >
                    <option value="">Select Program</option>
                    {
                      programOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Faculty<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                    readOnly={true}
                    value={showCollege}
                  >
                    <option value="">Select Faculty</option>
                    {
                      collegeOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Class<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                    readOnly={true}
                    disabled={true}
                    value={addData?.class_id}
                  >
                    <option value="">Select Class</option>
                    {
                      classOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="course">
                    Semester<span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    class="form-control"
                    name="program_id"
                    id="course"
                    readOnly={true}
                    disabled={true}
                    value={addData?.semester_id}
                  >
                    <option value="">Select Semester</option>
                    {
                      semesterOpt?.map((i, key) => (
                        <option value={i?.id} key={key}>{i?.name}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              <div className="col-md-2">
                <label htmlFor="">Date</label>

                <input
                  type="text"
                  name="date"
                  id="date"
                  readOnly
                  value={showMonth + '.' + showYear}
                  className="form-control" 
                  />
              </div>
            </div>



            <div className="row mt-4">
              <div className="col-lg-12  table-responsive ">
                <table className="table table-bordered nowrap table-hover " id="tab_logic">
                  <thead>
                    <tr>
                      <th>Subjects</th>
                      <th>Date</th>
                      <th>Time From</th>
                      <th>Time To</th>
                      <th>
                        Duration <br /> (in min)
                      </th>
                     
                      <th>Marks (Min..)</th>
                      <th> Marks (Max..)</th>
                    </tr>
                  </thead>
                  <tbody id="examsub">
                    {/* <div></div> */}
                    {
                      timeTableData?.map((i,key)=>(
                        <tr key={key}>
                          <td>{subOpt?.find(s=>s.id==i?.course_id)?.name}</td>
                          <td>{i?.date?.split("T")[0]}</td>
                          <td>{i?.time_from}</td>
                          <td>{i?.time_to}</td>
                          <td>{i?.duration}</td>
                          <td>{i?.min_marks}</td>
                          <td>{i?.max_marks}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

            </div>

          </div>
          <div className="modal-footer">
            <button 
            type="button" 
            className="btn btn-primary" 
            data-dismiss="modal"
            aria-label="Close" 
            onClick={PublishExam}
            >Publish</button>
          </div>
        </div>

        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  )
}

export default ModalPublishTimeTable