import { ROUTES } from "./../../../Router/routerConfig";

const drop = "dropdown";
const stat = "static";
const sheet = "sheet";
export const NavExamCommittee = [
  {
    title: "Create Time-Table",
    type: stat,
    icon: <i className="ri-file-list-3-line mr-2" />,
    route: ROUTES.Examination.ExamStaff.TImeTable,
    // drop: [
    //   {
    //     title: "Section 1",
    //     elements: [
    //       {
    //         title: "Create Time-Table",
    //         route: ROUTES.Examination.ExamStaff.TImeTable,
    //       },

    //       // {
    //       //     title:'Exam Schedules',
    //       //     route:ROUTES.Examination.ExamSchedules
    //       // },
    //       //   {
    //       //     title: "Add Invigilators",
    //       //     route: ROUTES.Examination.ExamStaff.AddInvigilators,
    //       //   },
    //       //   {
    //       //     title: "Add Evaluators",
    //       //     route: ROUTES.Examination.ExamStaff.AddEvaluators,
    //       //   },
    //     ],
    //   },
    //   {
    //     title: "Section 2",
    //     elements: [
    //       {
    //         title: "Enrollment",
    //         route: ROUTES.Examination.ExamStaff.Enrollment,
    //       },
    //       {
    //         title: "Download Hall Tickets",
    //         route: ROUTES.Examination.ExamStaff.Hallticket,
    //       },
    //       //   {
    //       //     title: "Print Barcodes",
    //       //     route: ROUTES.Examination.ExamStaff.BulkBarcode,
    //       //   },
    //     ],
    //   },
    //   // {
    //   //     title:'Section 3',
    //   //     elements:[
    //   //         {
    //   //             title:'Enrollments',
    //   //             route:ROUTES.dashboard
    //   //         },
    //   //         {
    //   //             title:'Generate Regular Hall Ticket',
    //   //             route:ROUTES.dashboard
    //   //         },
    //   //         {
    //   //             title:'Generate Repeater Hall Ticket',
    //   //             route:ROUTES.dashboard
    //   //         },
    //   //         {
    //   //             title:'Generate Bar Code No',
    //   //             route:ROUTES.dashboard
    //   //         },
    //   //         {
    //   //             title:'Print Barcode No',
    //   //             route:ROUTES.dashboard
    //   //         },
    //   //         {
    //   //             title:'Download Pre Exam Report',
    //   //             route:ROUTES.dashboard
    //   //         },
    //   //     ]
    //   // },
    // ],
  },
  {
    title: "Enrollment",
    type: stat,
    icon: <i className="ri-file-list-3-line mr-2" />,
    route: ROUTES.Examination.ExamStaff.Enrollment,
  },
  // {
  //   title: "Download Hall Tickets",
  //   type: stat,
  //   icon: <i className="ri-file-list-3-line mr-2" />,
  //   route: ROUTES.Examination.ExamStaff.Hallticket,
  // },
  // {
  //     title:'During Examination',
  //     type:drop,
  //     icon:<i className="ri-store-2-line mr-2" />,
  //     drop:[
  //         {
  //             title:'Attendance',
  //             type:stat,
  //             route:ROUTES.Examination.AttendanceList
  //             },
  //         {
  //         title:'Add Withheld',
  //         type:stat,
  //         route:ROUTES.dashboard
  //         },
  //         {
  //         title:'Mark Absents',
  //         type:stat,
  //         route:ROUTES.dashboard
  //         },
  //         {
  //         title:'Manage Withheld',
  //         type:stat,
  //         route:ROUTES.dashboard
  //         },

  //     ]
  // },
  // {
  //     title: 'Post Examination',
  //     type: drop,
  //     icon: <i className="ri-store-2-line mr-2" />,
  //     drop: [

  //         {
  //             title: 'Add Marks',
  //             type: stat,
  //             route: ROUTES.Examination.ExamStaff.Addmarks
  //         },
  //         {
  //             title: 'Publish Result',
  //             type: stat,
  //             route: ROUTES.Examination.ExamStaff.PublishResult
  //         },
  //     ]
  // },
];
