import React from 'react'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import publishImage1 from '../../../../assets/images/re_exam_publish_1.png'
import publishImage2 from '../../../../assets/images/re_exam_publish_2.png'
import { Modal } from 'antd'
import { RE_EXAM_EXAM } from '../../../../utils/Examination.apiConst'
import { toast } from 'react-toastify'
import { Http } from '../../../../Services/Services'

const Publish = () => {


    const { examData, getData } = useOutletContext()

    console.log(examData);

    const [isModalOpen, setIsModalOpen] = useState(false)

    const handlePublishExam = () => {
        console.log(`publishing exam`);
        Http.put(`${RE_EXAM_EXAM}/${examData?.id}`, {is_published:'PUBLISHED'})
        .then(res=>{
            toast.success(res.data.message);
        })
        .catch(err=>{
            toast.error(err.response.data.message);
        })
        getData()
        setIsModalOpen(false)
    }
    return (
        <div className="card">
            <Modal style={{marginTop:"60px"}} open={isModalOpen} onCancel={()=>{setIsModalOpen(false)}} onOk={handlePublishExam}>
                DO you realy want to publish the Exam
            </Modal>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-12">
                        <h6 className="card-header">Publish Results</h6>
                    </div>
                </div>
                {
                    examData &&
                    <div className="row mt-3">
                        <div className="col-md-12">
                            {
                                examData?.is_published == 'UNPUBLISHED'
                                &&
                                <div>
                                    <div className="d-flex justify-content-center">
                                        <img src={publishImage1} style={{width:'200px'}} alt="" />
                                    </div>
                                    <h6 className='text-center'>
                                        we are onclick away from publishing the results
                                    </h6>
                                    <p className='text-danger'>NOTE: Be carefull check if you are ready to publish, confirm once before publishing that you were added students marks and every requirements required</p>
                                    <div className='d-flex justify-content-center'>
                                        <button className='btn btn-success' onClick={()=>{setIsModalOpen(true)}}> Publish Now</button>
                                    </div>
                                </div>
                            }

                            {
                                examData?.is_published == 'PUBLISHED'
                                &&
                                <div>
                                    <div className="d-flex justify-content-center">
                                        <img src={publishImage2} style={{width:'200px'}} alt="" />
                                    </div>
                                    <h4 className='text-center text-success'>We Are Live Now</h4>
                                    <p className='text-danger text-center'>To Unpublish the exam please contact the ADMIN</p>
                                </div>
                            }

                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default Publish