export const ROUTES = {
  dashboard: "/dashboard",
  Admin: {
    dashboard: "/dashboard/admin",
    InfoUploading: {
      ExamNotification: "/admin/examNotification",
      ViewExamNotification: "/admin/viewexamNotification",
      TimeTableUpload: "/admin/timetableUpload",
      ViewTimeTableUpload: "/admin/viewtimetableUpload",
      PrevQuestionPaper: "/admin/prevyearpaper",
      ViewPrevQuestionPaper: "/admin/viewprevyearpaper",
    },
    frontOffice: {
      AdmissionEnquiry: "/admin/admissionEnquiry",
      VisitorsBook: "/admin/visitorsBook",
      PhoneCallLog: "/admin/phoneCallLog",
      PostalDispatch: "/admin/postalDispatch",
      PostalRecieve: "/admin/postalRecieve",
      Complain: "/admin/complain",
      SetupOffice: "/admin/setupOffice",
    },
    Student: {
      StudentAdmission: "/studentAdmission",
      StudentDetails: "/studentDetails",
      DisableStudents: "/disableStudents",
      EditStudentDetails: "/editStudentDetails",
      StudentProfile: "/studentProfile",
    },
    HR: {
      AddNewStaff: "/addNewStaff",
      ViewStaff: "/viewStaff",
      StaffAttendance: "/staffAttendance",
      AddDesignation: "/addDesignation",
      AddDepartment: "/addDepartment",
      Feedback: "/feedback",
    },
    Accounts: {
      Income: {
        View: "/viewIncome",
        Add: "/addIncome",
        AddSource: "/addIncomeSource",
      },
      Expense: {
        View: "/viewExpense",
        Add: "/addExpense",
        AddSource: "/addExpenseSource",
      },
      FeeCollection: {
        CollegeFee: "/feeCollectionCollegeFee",
        SearchFee: "/feeCollectionSearchFee",
        SearchDue: "/feeCollectionSearchDue",
        FeeMaster: "/feeCollectionFeeMaster",
        FeeGroup: "/feeCollectionFeeGroup",
        FeeType: "/feeCollectionFeeType",
        FeeDiscount: "/feeCollectionFeeDiscount",
      },
    },
    Academics: {
      ClassTimeTable: "/classTimeTable",
      CreateTimeTable: "/createTimeTable",

      TeacherTimeTable: "/teacherTimeTable",
      PromoteStudents: "/promoteStudents",
      AddBatch: "/addBatch",
      AssignStudents: "/assignStudents",

      AddSubject: "/addSubject",
      AddClass: "/addClass",
      ManageLessonPlan: "/manageLessonPlan",
      SyllabusStatus: "/syllabusStatus",
      AddTopic: "/addTopic",
      AddLesson: "/addLesson",
    },
    Document: {
      UploadContent: "/documentUploadContent",
      DownloadContent: {
        Assignment: "/documentAssignment",
        Syllabus: "/documentSyllabus",
        Studymaterial: "/documentStudyMaterial",
        OtherDownload: "/documentOtherDownload",
      },
    },
    Transport: {
      Route: "/transportRoutes",
      Vehicles: "/transportVehicles",
      AssignVehicles: "/transportAssignVehicles",
    },

    Examination: {
      Commitee: "/exam_committee",
      CreateExams: "/CreateExams",
      CreateQuestionPaper: "/createquestionpapers",
      CreateExamTimetable: "/createexamtimetable",
      ExamSchedules: "/examSchedules",
      ExamSeatingArrangements: "/examseatingarrangments",
      PostExaminations: "/postexaminations",
      DownloadQuestionPaper: "/downloadQuestionPaper",

      OtpVerification: "/otpverification",
      InvigilatorLogin: "/invigilatorLogin",
      AssignAnswerSheets: "/assignAnswersheets",
      AnswersheetChecking: "/answersheetEvaluation",
      StudentList: "/studentsList",
    },

    Hostel: {
      HostelRooms: "/HostelRooms",
      RoomType: "/RoomTypes",
      Hostel: "/hostel",
    },
    Inventory: {
      IssueItems: "/IssueItems",
      AddItemstock: "/AddItemstock",
      AddItem: "/AddItem",
      ItemCategory: "/ItemCategory",
      ItemStore: "/ItemStore",
      ItemSupplier: "/ItemSupplier",
    },
    Certificates: {
      DesignHallticket: "/DesignHallticket",
      DesignMarkscard: "/DesignMarkscard",
    },
  },
  Examination: {
    master: "/master",
    addMarksheetNo: "/addMarkSheetNo",
    Commitee: "/exam_committee",
    AssignStaff: "/AssignStaff",
    CreateQuestionPaper: "/createquestionpapers",
    CreateExamTimetable: "/createexamtimetable",
    ExamSchedules: "/examSchedules",
    ExamSeatingArrangements: "/examseatingarrangments",
    PostExaminations: "/postexaminations",
    DownloadQuestionPaper: "/downloadQuestionPaper",
    OtpVerification: "/otpverification",
    InvigilatorLogin: "/invigilatorLogin",
    AssignAnswerSheets: "/assignAnswersheets",
    AnswersheetChecking: "/answersheetEvaluation",
    StudentList: "/studentsList",
    ExamEnrollment: "/examEnrollment",
    StudentApplicationForm: "/studentApplicationForm",
    DownloadHallTickets: "/downloadHallTickets",
    AssignCommitteeMembers: "/assignCommitteeMambers",
    AddInvigilators: "/inviteInvigilator",
    AddEvaluators: "/inviteEvaluators",
    Hallticket: "/hallTicket",
    AttendanceList: "/attendanceList",
    DownloadAttendanceList: "/downloadAttendanceList",
    AddExamSetter: "/addExamQuestionPaperSetter",
    QuestionPaperPattern: "/addQuestionPaperPattern",
    GenerateBarcodes: "/generateBarcodes",
    RetrieveBarcodes: "/retrieveBarcodes",
    Addmarks: "/addMarks",
    AddGraceMarks: "/addGraceMarks",
    PublishResult: "/publishResult",
    PharmacyPublishResult: "/pharmacypublishResult",
    Revaluation: "/revaluation",
    BulkBarcode: "/bulkBarcode",
    ResultReport: "/resultReports",
    SubjectEnrollments: "/subjectEnrollments",
    ExamSetter: {
      Home: "/exam-setter/upload",
      HomeReExam: "/exam-setter/upload/re-exam",
    },
    redirectAuth: {
      FromAdmin: "/login/auth",
    },
    ExamMember: {
      Home: "/exam-member/upload",
    },
    ExamStaff: {
      Enrollment: "/exam-staff/enrollment",
      TImeTable: "/exam-staff/create-time-table",
      AddInvigilators: "/exam-staff/add-invigilators",
      AddEvaluators: "/exam-staff/add-evaluators",
      HallticketPage: "/exam-staff/hall-ticket-page",
      StudentApplicationForm: "/exam-staff/studentApplicationform",
      Hallticket: "/exam-staff/hall-ticket",
      Addmarks: "/exam-staff/add-marks",
      PublishResult: "/exam-staff/publish-result",
      PrintBarcode: "/exam-staff/print-barcode",
      StudentApplicationForm: "/exam-staff/student-application-form",
      BulkBarcode: "/bulk-Barcode",
    },
    Reports: {
      StudentWise: "/report/bystudent",
      Detailed: "/report/detailed",
      Detailed1: "/report/detailed1",
      DigiReportDetailed: "/report/Digidetailed",
      DetailedEng: "/report/detailed-eng",
      DetailedPharm: "/report/detailed-pharm",
      DetailedPharmReport: "/report/pharmaReport",
      PublishPharmReport: "/report/publishReportPha",
      PublishEngReport: "/report/publishReportEng",
      PublishReport: "/report/publishReport",
      RankedEnggReport: "/report/rankedEnggReport",
    },
    ReExamination: {
      Home: "/re-examination",
      Dashboard: "dashboard",
      TImeTable: "time-table",
      Enrollment: "enrollment",
      EnrollmentTimeTable: "enrollment-time-table",
      Marks: "marks",
      AddMarks: "add-marks",
      Barcode: "add-barcode",
      HomeHallticket: "hallTickets",
      Reports: {
        Home: "reports",
        ReportDetailed: "report-detailed",
        MarksSheet: "marks-sheet",
        MarksSheet1: "marks-sheet1",
      },
      Publish: "publish",
      graceMarks: "grace-marks",
      AddgraceMarks: "add-grace-marks",
      ReChecking: "rechecking",
      Pattern: "pattern",
      Setter: "setter",
    },
    NewExamination: {
      Home: "/new-examination",
      Dashboard: "dashboard",
      TImeTable: "time-table",
      Enrollment: "enrollment",
      EnrollmentTimeTable: "enrollment-time-table",
      Marks: "marks",
      AddMarks: "add-marks",
      Barcode: "add-barcode",
      HomeHallticket: "hallTickets",
      Reports: {
        Home: "reports",
        ReportDetailed: "report-detailed",
      },
      Publish: "publish",
      graceMarks: "grace-marks",
      AddgraceMarks: "add-grace-marks",
      ReChecking: "rechecking",
      Pattern: "pattern",
      Setter: "setter",
      Evaluator: "evaluator",
      Invigilator: "invigilator",
    },
  },
};
