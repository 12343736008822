import React, { useEffect } from 'react'
import { Http } from '../../Services/Services'
import { RE_EXAM_QPDS_PATTERN } from '../../utils/Examination.apiConst'
import { useState } from 'react'
import { toast } from 'react-toastify'

const ModalPaperPatter = ({ time_table_id }) => {

    const [addNewObj, setAddNewObj] = useState({
        question: '',
        marks: ''
    })

    const clearAddNewObj = () => {
        setAddNewObj({
            question: '',
            marks: ''
        })
    }

    const [data, setData] = useState([])

    const getData = async () => {
        await Http.get(`${RE_EXAM_QPDS_PATTERN}?time_table_id=${time_table_id}`)
            .then((res) => {
                setData(res.data.data)
            }
            )
            .catch((err) => {
                toast.error('Something went wrong');
            }
            )
    }

    const handleAddNewData = async () => {
        if(!addNewObj?.question || !addNewObj?.marks) return toast.error('Please fill all the fields')
        if (data && data?.length != 0) {
            const bdata = {
                pattern: [
                    ...data.pattern,
                    {
                        question: addNewObj?.question,
                        marks: addNewObj?.marks
                    }
                ]
            }
            await Http.put(`${RE_EXAM_QPDS_PATTERN}/${data.id}`, bdata)
                .then(res => {
                    toast.success('Added Successfully')
                    clearAddNewObj()
                    getData()
                })
                .catch(err => {
                    toast.error('Something went wrong')
                }
                )
        }
        else {
            const bdata = {
                time_table_id: time_table_id,
                pattern: [
                    {
                        question: addNewObj?.question,
                        marks: addNewObj?.marks
                    }
                ]
            }

            await Http.post(`${RE_EXAM_QPDS_PATTERN}`, bdata)
                .then(res => {
                    toast.success('Added Successfully')
                    clearAddNewObj()
                    getData()
                }
                )
                .catch(err => {
                    toast.error('Something went wrong')
                }
                )
        }
    }

    const handleDeleteAddNewData = async (key) => {
        const bdata = {
            pattern:[
                ...data?.pattern?.filter((s,index)=>index!==key),
            ]
        }

        await Http.put(`${RE_EXAM_QPDS_PATTERN}/${data.id}`, bdata)
            .then(res => {
                toast.success('Deleted Successfully')
                getData()
            }
            )
            .catch(err => {
                toast.error('Something went wrong')
            }
            )
    }

    useEffect(()=>{
        if(time_table_id)
        getData()
    },[time_table_id])
    return (
        <div
            className="modal fade"
            id="patter_modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby=""
            aria-hidden="true"
        >
            <div className="modal-dialog mw-75 w-100 modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                            Paper
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <button
                            className="btn btn-primary"
                        >
                            Back
                        </button>
                        <table className="table mt-4">
                            <tbody className="text-center">
                                <tr>
                                    <th>Marks</th>
                                    <th>No. of Questions</th>

                                    <th>Total Marks</th>
                                    <th></th>
                                </tr>

                                {
                                    data
                                        &&
                                        data?.pattern?.length != 0
                                        ?
                                        data?.pattern?.map((i, key) => (


                                            <tr>
                                                <th>
                                                    {i?.marks}
                                                </th>
                                                <th>
                                                    {i?.question}
                                                </th>
                                                <th>
                                                    {parseInt(i?.question) * parseInt(i?.marks)}
                                                </th>
                                                <td>
                                                    <button className='mr-2 btn btn-danger' onClick={() => { handleDeleteAddNewData(key) }}>Delete</button>
                                                </td>
                                            </tr>
                                        ))
                                        :
                                        <tr>
                                            <td>
                                                Please add A pattern
                                            </td>
                                        </tr>
                                }

                                <tr>
                                    <th>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={addNewObj?.marks}
                                            onChange={(e) => {
                                                setAddNewObj(prev => ({
                                                    ...prev,
                                                    marks: e.target.value
                                                }))
                                            }}
                                        />

                                    </th>
                                    <th>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="questions"
                                            id="questions"
                                            placeholder='eg. 10'
                                            value={addNewObj?.question}
                                            onChange={(e) => {
                                                setAddNewObj(prev => ({
                                                    ...prev,
                                                    question: e.target.value
                                                }))
                                            }}
                                        />

                                    </th>
                                    <th>
                                        <span id="tq"><b>{parseInt(addNewObj?.marks || 0) * parseInt(addNewObj?.question || 0)}</b></span>

                                    </th>
                                    <td>
                                        <button className='mr-2 btn btn-success' onClick={handleAddNewData}>Add</button>

                                    </td>
                                </tr>
                                <tr className='bg-light'  >
                                    <th> Total</th>
                                    <th  >{data?.total_question}</th>
                                    <th >{data?.total_marks}</th>
                                    <th></th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalPaperPatter