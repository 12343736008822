import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import { ACADEMICS_ADD_SUBJECT } from "../../utils/Academics.apiConst";
import {
  PRE_EXAM_TIMETABLE,
  QPDS_QP_PATTERN,
} from "../../utils/Examination.apiConst";

function ModalQPDSAllExams(props) {
  //Destructuring props
  const {
    addData,
    data,
    programOpt,
    collegeOpt,
    classOpt,
    semesterOpt,
    showCollege,
    showProgram,
    showMonth,
    showYear,
    setLoading,
  } = props;

  //Main obj
  const [user, setUser] = useState({
    class_examination_id: addData?.id,
    session_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  //Other States for data management
  const [subOpt, setSubOpt] = useState([]);

  //states holding created time table data
  const [timeTableData, setTimeTableData] = useState([]);

  const [addNew, setAddNew] = useState(0);

  const [addNewId, setAddNewId] = useState("");

  const [addNewData, setAddNewData] = useState([]);

  const [addNewObj, setAddNewObj] = useState({
    question: "",
    marks: "",
  });

  const clearAddNewObj = () => {
    setAddNewObj({
      question: "",
      marks: "",
    });
  };

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  //////get Subject Data
  const getbasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?college_id=${showCollege}&semester_id=${addData?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setSubOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  //get time table data
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${addData?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const getAddNewData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${QPDS_QP_PATTERN}?time_table_id=${addNewId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setAddNewData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const handleAddNewData = async () => {
    if (!addNewObj?.marks || !addNewObj?.question)
      return toast.error(
        "Both marks and question required to add add the pattern"
      );
    setLoading(1);

    const data = addNewData
      ? {
          pattern: [
            ...addNewData?.pattern,
            {
              question: addNewObj?.question,
              marks: addNewObj?.marks,
            },
          ],
        }
      : {
          time_table_id: addNewId,
          pattern: [
            {
              question: addNewObj?.question,
              marks: addNewObj?.marks,
            },
          ],
        };

    const config = {
      method: addNewData ? "put" : "post",
      url: `${QPDS_QP_PATTERN}${addNewData ? "/" + addNewData?.id : ""}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getAddNewData();
    clearAddNewObj();
    setLoading(0);
  };

  const handleDeleteAddNewData = async (dd) => {
    setLoading(1);

    const data = {
      pattern: [...addNewData?.pattern?.filter((s, index) => index !== dd)],
    };

    const config = {
      method: addNewData ? "put" : "post",
      url: `${QPDS_QP_PATTERN}${addNewData ? "/" + addNewData?.id : ""}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    getAddNewData();
    clearAddNewObj();
    setLoading(0);
  };

  ////useEffects
  useEffect(() => {
    if (addData) {
      setUser({
        class_examination_id: addData?.id,
        session_id: "",
        course_id: "",
        date: "",
        time_from: "",
        time_to: "",
        duration: "",
        max_marks: "",
        min_marks: "",
      });
      getbasicData();
      getData();
    }
    setAddNew(0);
  }, [addData]);

  useEffect(() => {
    if (addNew && addNewId) {
      getAddNewData();
    }
  }, [addNew, addNewId]);

  return (
    <div
      className="modal fade"
      id="create"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-100 w-100 ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Create Time Table
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {addNew ? (
            <div className="modal-body">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setAddNew(0);
                }}
              >
                Back
              </button>
              <table className="table mt-4">
                <tbody className="text-center">
                  <tr>
                    <th>Marks</th>
                    <th>No. of Questions</th>

                    <th>Total Marks</th>
                    <th></th>
                  </tr>

                  {addNewData && addNewData?.pattern?.length != 0 ? (
                    addNewData?.pattern?.map((i, key) => (
                      <tr>
                        <th>{i?.marks}</th>
                        <th>{i?.question}</th>
                        <th>{parseInt(i?.question) * parseInt(i?.marks)}</th>
                        <td>
                          <button
                            className="mr-2 btn btn-danger"
                            onClick={() => {
                              handleDeleteAddNewData(key);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Please add A pattern</td>
                    </tr>
                  )}

                  <tr>
                    <th>
                      <input
                        type="number"
                        className="form-control"
                        value={addNewObj?.marks}
                        onChange={(e) => {
                          setAddNewObj((prev) => ({
                            ...prev,
                            marks: e.target.value,
                          }));
                        }}
                      />
                    </th>
                    <th>
                      <input
                        type="number"
                        className="form-control"
                        name="questions"
                        id="questions"
                        placeholder="eg. 10"
                        value={addNewObj?.question}
                        onChange={(e) => {
                          setAddNewObj((prev) => ({
                            ...prev,
                            question: e.target.value,
                          }));
                        }}
                      />
                    </th>
                    <th>
                      <span id="tq">
                        <b>
                          {parseInt(addNewObj?.marks || 0) *
                            parseInt(addNewObj?.question || 0)}
                        </b>
                      </span>
                    </th>
                    <td>
                      <button
                        className="mr-2 btn btn-success"
                        onClick={handleAddNewData}
                      >
                        Add
                      </button>
                    </td>
                  </tr>
                  <tr className="bg-light">
                    <th> Total</th>
                    <th>{addNewData?.total_question}</th>
                    <th>{addNewData?.total_marks}</th>
                    <th></th>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div className="modal-body">
              <div className="row mt-4">
                <div className="col-lg-12  table-responsive ">
                  <table
                    className="table table-bordered nowrap table-hover "
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        <th>Subjects</th>
                        <th>Date</th>
                        <th>Time From</th>
                        <th>Time To</th>
                        <th>
                          Duration <br /> (in min)
                        </th>

                        <th> Marks (Max..)</th>
                        <th>Marks (Min..)</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody id="examsub">
                      {/* <div></div> */}
                      {timeTableData?.map((i, key) => (
                        <tr key={key}>
                          <td>
                            {subOpt?.find((s) => s.id == i?.course_id)?.name}
                          </td>
                          <td>{i?.date?.split("T")[0]}</td>
                          <td>{i?.time_from}</td>
                          <td>{i?.time_to}</td>
                          <td>{i?.duration}</td>
                          <td>{i?.max_marks}</td>
                          <td>{i?.min_marks}</td>
                          <td className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn badge badge-light text-success p-1 mr-2"
                              title="Add Pattern"
                              onClick={() => {
                                setAddNew(1);
                                setAddNewId(i?.id);
                              }}
                            >
                              <i class="ri-add-line"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {/* <div className="modal-footer">
                        <button
                            type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            aria-label="Close"
                        >Save changes</button>
                    </div> */}
        </div>

        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default ModalQPDSAllExams;
