import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT, LOCAL_PROGRAM } from '../../utils/LocalStorageConstants';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SEMESTER, ACADEMICS_ADD_SUBJECT } from '../../utils/Academics.apiConst';
import useEmployee from '../../Hooks/Employee/useEmployee';
import { Http } from '../../Services/Services';
import { sessionOpt } from '../../Data/jsonData/Academics/Academics';
import { ASSET_MEDIA } from '../../utils/AssetsReferenceTypes';
import { getFileUrl } from '../../Helpers/Helpers';
import { PREV_QUESTION_PAPER_UPLOAD } from '../../utils/InfoUploadingApiConst';
import { ROUTES } from '../../Router/routerConfig';

function PrevYearQuestionPaper( { setLoading } ) {

  const navigate = useNavigate();

  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    department_id: "",
    class_id: "",
    semester_id: "",
    session_id: "",
    subject_id: "",
    attachments:"",
    title: "",
  });

  const fileref = useRef(null);

  const programOpt = JSON.parse(localStorage.getItem(LOCAL_PROGRAM));
  const collegeOpt = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));
  const deptOpt = JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT));
  const [lessonOpt, setLessonOpt] = useState([]);

  const [classOpt, setClassOpt] = useState([]);
  const [semesterOpt, setSemesterOpt] = useState([]);
  const [subjectOpt, setSubjectOpt] = useState([]);
  let [arr] = useEmployee();
  const arr2 = [];
  for (const i of arr) {
    arr2.push({ value: i.id, label: i.first_name + " " + i.last_name });
  }
  const [data, setData] = useState([]);

  const employeeOpt = arr2;

  console.log(employeeOpt);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getClassAndSemData = () => {
    Http.get(ACADEMICS_ADD_CLASS)
      .then((res) => {
        console.log(res);
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    Http.get(ACADEMICS_ADD_SEMESTER)
      .then((res) => {
        console.log(res);
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    Http.get(ACADEMICS_ADD_SUBJECT)
      .then((res) => {
        console.log(res);
        setSubjectOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

  };

  const clearData = () => {
    setUser({
      program_id: "",
      college_id: "",
      department_id: "",
      class_id: "",
      semester_id: "",
      session_id: "",
      subject_id: "",
      title: "",
    });
    fileref.current.value = null;
  };

  const handleChange1 = async (e) => {
    try {
      const d = await getFileUrl(
        ASSET_MEDIA,
        "media",
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      user.attachments = d;
    } catch (error) {
      console.log(error);
    }
  };


  const handleSubmit = () => {
    if (
      !user.college_id ||
      !user.department_id ||
      !user.program_id ||
      !user.class_id ||
      !user.semester_id ||
      !user.session_id ||
      !user.subject_id ||
      !user.attachments ||
      !user.title
    ) return toast.error("Please fill all the fields");
    const obj = {
      college_id: user.college_id,
      class_id: user.class_id,
      semester_id: user.semester_id,
      session_id: user.session_id,
      subject_id: user.subject_id,
      department_id: user.department_id,
      program_id: user.program_id,
      attachments: user.attachments,
      title: user.title,
    };
    Http.post(PREV_QUESTION_PAPER_UPLOAD, obj)
      .then((res) => {
        console.log(res);
        clearData()
        toast.success("Successfully Created");
        // getData();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message || "Something went wrong");
      });
  };

  useEffect(() => {
    getClassAndSemData();
  }, [user.department_id, user.college_id]);

  return (
    <div>

        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">

                    <div className="row">
                      <div className="col-12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                          <h3 className="mb-0">PREVIOUS YEAR QUESTION PAPERS</h3>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-12">
                        <div className="card">

                          <div className="card-body">
                            <h2 className="card-title">Select Criteria</h2>
                            <br />

                           
                            <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Program</label>
                          <select
                            name="program_id"
                            className="form-control"
                            value={user.program_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Faculty</label>
                          <select
                            name="college_id"
                            className="form-control"
                            value={user.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            className="form-control"
                            value={user.department_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Department</option>
                            {deptOpt?.filter(s=>s.college_id==user.college_id)?.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            className="form-control"
                            value={user.class_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            className="form-control"
                            value={user.semester_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user.class_id)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            name="session_id"
                            className="form-control"
                            value={user.session_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Session</option>
                            {sessionOpt.map((i, key) => (
                              <option value={i.id} key={key}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="validationCustom02">
                            Subject<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            name="subject_id"
                            className="form-control"
                            value={user.subject_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Subject</option>
                            {subjectOpt
                              ?.filter((s) => s.semester_id == user.semester_id)
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>{" "}                          
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Title</label>
                          <input
                            type="text"
                            placeholder="Enter the Title"
                            className="form-control"
                            name="title"
                            value={user.title}
                            onChange={handleChange}
                          />
                        </div>
                      </div>                      
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Upload File</label>
                          <input
                            type="file"
                            placeholder="Attach the file"
                            className="form-control"
                            name="attachments"
                            ref={fileref}
                            // value={user.attachments}
                            // onChange={handleChange}
                            onChange={(e) => {
                              handleChange1(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 d-flex justify-content-end pt-3">
                        <button
                          onClick={handleSubmit}
                          className="btn btn-primary "
                        >
                          Submit
                        </button>
                      </div>
                      
                      <div className="col-12 d-flex justify-content-end pt-3">
                        <button
                          onClick={() => navigate(ROUTES.Admin.InfoUploading.ViewPrevQuestionPaper)}
                          className="btn btn-success"
                          style={{ width: '75px' }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

                </div>
            </div>
        </div>
      
    </div>
  )
}

export default PrevYearQuestionPaper;
