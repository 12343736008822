import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import useEmployee from "../../Hooks/Employee/useEmployee";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../utils/Academics.apiConst";
import { STUDENT_SESSION_ALL } from "../../utils/apiConstants";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  PRE_EXAM_ENROLL_STUDENT,
  PRE_EXAM_TIMETABLE,
  STUDENT_INTERNAL_MARKS,
  GET_ALL_MARKS,
  POST_EXAM_ADD_MARKS_BULK,
} from "../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import { ROUTES } from "../../Router/routerConfig";
import EnggResults from "../../Components/PublishResults/EnggResults";
import ResultsCompo from "../../Components/PublishResults/Results";

function PublishMarks({ setLoading }) {
  const navigate = useNavigate();

  const [deptOpt, setDeptOpt] = useState([]);

  const [x, setX] = useState([]);

  const [programOpt, setProgramOpt] = useState([]);

  const [internal, setInternal] = useState([]);

  const [collegeOpt, setCollegeOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [studentLoadingMsg, setStudentLoadingMsg] = useState("");

  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    session_id: "",
    department_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    setDeptOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == user?.college_id
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), user?.college_id]);

  useEffect(() => {
    setCollegeOpt(JSON.parse(localStorage.getItem(LOCAL_COLLEGE)));
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  // useEffect(() => {

  //   if (searchParams.get('program_id') && searchParams.get('college_id') && searchParams.get('month') && searchParams.get('year')) {

  //     setUser(prev => ({
  //       college_id: searchParams.get('college_id'),
  //       program_id: searchParams.get('program_id'),
  //       month: searchParams.get('month'),
  //       year: searchParams.get('year'),
  //       session_id: searchParams.get('session_id'),
  //       department_id: searchParams.get('department_id'),
  //       class_id: searchParams.get('class_id'),
  //       semester_id: searchParams.get('semester_id'),

  //     }))

  //     getCommiitteeWithVal(searchParams.get('program_id'), searchParams.get('college_id'), searchParams.get('month'), searchParams.get('year'))
  //   }
  // }, [searchParams])

  const [alert, setAlert] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (user?.college_id == 1111008) {
      setAlert(true);
    } else {
      setAlert(false);
    }
  }, [user?.college_id]);

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [studentData, setStudentData] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  const [employeeOpt, setEmployeeOpt] = useState([]);

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [data, setData] = useState([]);

  const [flag, setFlag] = useState(0);

  const [examId, setExamId] = useState();

  const [timeTableData, setTimeTableData] = useState();

  const [enrollmentData, setEnrollmentData] = useState();

  const [subjectOpt, setSubjectOpt] = useState();

  const [examMarks, setExamMarks] = useState([]);

  const getBasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config1)
      .then((res) => {
        setSemesterOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  const getData = async () => {
    setStudentLoadingMsg("Please wait while we load student data");
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_SESSION_ALL}?college_id=${user?.college_id}&status_student=ACTIVE&department_id=${user?.department_id}&session_id=${user?.session_id}&class_id=${user?.class_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Here", res.data.data);
        setStudentData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${STUDENT_INTERNAL_MARKS}?semester_id=${user?.semester_id}&session_id=${user?.session_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        setStudentLoadingMsg("");
        setLoading(0);
        console.log(res.data.data);
        setInternal(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  //Get Exam Details
  const getDataExamData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Data", res.data.data);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));
    setLoading(0);

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    navigate({
      search: `?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}&session_id=${user.session_id}&department_id=${user?.department_id}&class_id=${user.class_id}&semester_id=${user.semester_id}`,
    });
    setLoading(1);

    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          // setMainUser(prev => ({
          //   ...prev,
          //   exam_committee_id: res.data.data[0].id
          // }))
          // getBasicData()
          getDataExamData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
  };

  //get time table data
  const getExamTimeTable = async (exam) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${exam}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Check Here", res.data.data);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${PRE_EXAM_ENROLL_STUDENT}?session_id=${user?.session_id}&year=${user?.year}&class_id=${user?.class_id}&semester_id=${user?.semester_id}&class_examination_id=${exam}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        console.log("Enrollment Data", res.data.data);
        setEnrollmentData(res.data?.enrolledStudent);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: `${GET_ALL_MARKS}?class_examination_id=${exam}&session_id=${user?.session_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        setLoading(0);
        console.log("Exam Marks", res.data);
        setExamMarks(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    getBasicData();
  }, []);

  const publishMarks = async () => {
    var arr = [];
    studentData?.map((s) => {
      arr.push(s?.user_id);
    });
    const config = {
      method: "put",
      url: `${POST_EXAM_ADD_MARKS_BULK}/update`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: { data: arr },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Marks Published");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const publishIndividualMarks = async () => {
    setLoading(1);
    const config = {
      method: "put",
      url: `${POST_EXAM_ADD_MARKS_BULK}/update`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: { data: x },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Marks Published");
        getExamTimeTable(examId);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  return (
    <div className="PublishMarks">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Publish Marks</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active"> Publish Marks</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />
                    {alert ? (
                      <div className="alert alert-warning">
                        {user?.college_id == "1111008" ? (
                          <h4>
                            Please Check Pharmacy Results{" "}
                            <a href={ROUTES.Examination.PharmacyPublishResult}>
                              Here
                            </a>
                          </h4>
                        ) : null}
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {deptOpt
                              ?.filter(
                                (s) =>
                                  s.college_id == user?.college_id &&
                                  s.program_id == user?.program_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s.college_id == user?.college_id)
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            name="session_id"
                            value={user?.session_id}
                            onChange={handleChange}
                            className="form-control"
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option key={key} value={i?.id}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h4 className="card-title text-uppercase ">List</h4>
                      {flag ? (
                        <div>
                          <button
                            onClick={publishMarks}
                            className="btn btn-success btn-sm mr-3"
                          >
                            <i className="fa fa-check"></i> Publish Results
                          </button>

                          <button
                            onClick={() => {
                              publishIndividualMarks();
                            }}
                            className="btn btn-success btn-sm mr-3"
                          >
                            <i className="fa fa-check"></i> Publish Selected
                            Results
                          </button>

                          {/* <button className='btn btn-primary btn-sm'><i className='fa fa-download'></i> Download Applications</button> */}
                        </div>
                      ) : null}
                    </div>{" "}
                    <hr />
                    <div>
                      {flag ? (
                        <button
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            setFlag(0);
                            setX([]);
                          }}
                        >
                          Back
                        </button>
                      ) : null}
                    </div>
                    <div className="table-responsive">
                      {!flag ? (
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Course</th>
                              <th>Specialization</th>
                              <th>Date</th>

                              <th>Faculties</th>

                              <th>Course Year</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        data-toggle="modal"
                                        data-target="#create"
                                        className="badge badge-light text-dark mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i?.id);
                                          getExamTimeTable(i?.id);
                                          getData();
                                        }}
                                      >
                                        <i
                                          class="fa fa-edit "
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : user?.college_id == "1111000" ? (
                        <EnggResults
                          studentLoadingMsg={studentLoadingMsg}
                          studentData={studentData}
                          internal={internal}
                          examMarks={examMarks}
                          setX={setX}
                          x={x}
                          timeTableData={timeTableData}
                        />
                      ) : (
                        <ResultsCompo
                          setLoading={setLoading}
                          examId={examId}
                          examMarks={examMarks}
                          setX={setX}
                          x={x}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishMarks;
