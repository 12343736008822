import React from 'react'
import Barcode from 'react-barcode';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import './style.css';
function GenerateBarcodes() {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const numberOfStudents =25
  function mapNumber(n) {
    return n < 10 ? `00${n}` : n < 100 ? `0${n}` : `${n}`;
  }

  const rollNumberPattern = 'SUK220404ED'
  const subjectCode = 'S1'


  return (
    <div >


      <div className="container-fluid  mb-5" ref={componentRef}>
        <div className="d-flex justify-content-center  d-print-none mb-5  mt-5">
          <button className="btn btn-primary" onClick={handlePrint}>
            Print
          </button>
        </div>

{/* <table className='table table-bordered border-none text-center '>
  <tr>
   
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO002" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO002" displayValue="false" /></td>
 
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO003" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO003" displayValue="false" /></td>
                                       
  </tr>
  <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO004" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO004" displayValue="false" /></td>
                      
 
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO007" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO007" displayValue="false" /></td>

</tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO013" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO013" displayValue="false" /></td>
                      
 
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO015" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO015" displayValue="false" /></td>
                  

</tr> <tr>
                  <td>
                    
                     <Barcode width={1.06}
                  height={97} value="SUK210604HO017" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO017" displayValue="false" /></td>
                      
 
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO023" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO023" displayValue="false" /></td>
                   </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO024" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO024" displayValue="false" /></td>
                      
  
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO029" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO029" displayValue="false" /></td>
                  </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO032" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO032" displayValue="false" /></td>
                      
  
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO038" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO038" displayValue="false" /></td>
                  </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO046" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO046" displayValue="false" /></td>
                      
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO047" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO047" displayValue="false" /></td>
                  </tr> <tr>
  
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO048" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO048" displayValue="false" /></td>
                      
 
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO049" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO049" displayValue="false" /></td>
                   </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO053" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO053" displayValue="false" /></td>
                      
 
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO056" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO056" displayValue="false" /></td>
                   </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO057" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO057" displayValue="false" /></td>
                      
 
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO070" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO070" displayValue="false" /></td>
                   </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO071" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO071" displayValue="false" /></td>
                      
  
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO074" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO074" displayValue="false" /></td>
                  </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO076" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO076" displayValue="false" /></td>
                      
  
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO085" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO085" displayValue="false" /></td>
                  </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO083" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO083" displayValue="false" /></td>
                      
  
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO088" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO088" displayValue="false" /></td>
                  </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO088" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO088" displayValue="false" /></td>
                      
  
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO089" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO089" displayValue="false" /></td>
                  </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO097" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO097" displayValue="false" /></td>
                      
  
    <td width="50%"> <Barcode width={1.06}
                  height={97} value="SUK210604HO099" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO099" displayValue="false" /></td>
                  </tr> <tr>
                  <td> <Barcode width={1.06}
                  height={97} value="SUK210604HO100" /> <Barcode width={0.8}
                  height={97} value="SUK210604HO100" displayValue="false" /></td>
                      
  </tr>
</table> */}
<br></br>
        <div className="d-flex  flex-row flex-wrap ml-5">
         
        
              <div className='d-flex flex-no-wrap mb-4'>
                <Barcode width={1.04}
                  height={97} value="SUK210604HO013-HP" />
              <span  style={{marginRight:"32px"}}>  <Barcode width={0.7}
                  height={97} value="SUK210604HO013-HP" displayValue="false" /></span>
              </div>
           
              <div className='d-flex flex-no-wrap mb-4'>
                <Barcode width={1.04}
                  height={97} value="SUK210604HO015-HP" />
              <span  style={{marginRight:"32px"}}>  <Barcode width={0.7}
                  height={97} value="SUK210604HO015-HP" displayValue="false" /></span>
              </div>
              <div className='d-flex flex-no-wrap mb-4'>
                <Barcode width={1.04}
                  height={97} value="SUK210604HO038-HP" />
              <span  style={{marginRight:"32px"}}>  <Barcode width={0.7}
                  height={97} value="SUK210604HO038-HP" displayValue="false" /></span>
              </div>
           
              <div className='d-flex flex-no-wrap mb-4'>
                <Barcode width={1.04}
                  height={97} value="SUK210604HO056-HP" />
              <span  style={{marginRight:"32px"}}>  <Barcode width={0.7}
                  height={97} value="SUK210604HO056-HP" displayValue="false" /></span>
              </div>

              <div className='d-flex flex-no-wrap mb-4'>
                <Barcode width={1.04}
                  height={97} value="SUK210604HO070-HP" />
              <span  style={{marginRight:"32px"}}>  <Barcode width={0.7}
                  height={97} value="SUK210604HO070-HP" displayValue="false" /></span>
              </div>
           
              <div className='d-flex flex-no-wrap mb-4'>
                <Barcode width={1.04}
                  height={97} value="SUK210604HO099-HP" />
              <span  style={{marginRight:"32px"}}>  <Barcode width={0.7}
                  height={97} value="SUK210604HO099-HP" displayValue="false" /></span>
              </div>
              <div className='d-flex flex-no-wrap mb-4'>
                <Barcode width={1.04}
                  height={97} value="SUK210604HO100-HP" />
              <span  style={{marginRight:"32px"}}>  <Barcode width={0.7}
                  height={97} value="SUK210604HO100-HP" displayValue="false" /></span>
              </div>
           
              
           
             
           
             
           
           
              
        </div>
      </div>

    </div>
  )
}

export default GenerateBarcodes