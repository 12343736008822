import React, { useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Menu } from "antd";
import { MdExposurePlus1, MdOutlineSchool } from "react-icons/md";
import {
  AiFillAlipayCircle,
  AiOutlineAppstoreAdd,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineBarcode,
  AiOutlineCalendar,
  AiOutlineNumber,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GrDocumentConfig, GrDocumentUpdate } from "react-icons/gr";
import { MdOutlinePublish } from "react-icons/md";
import { BiMessageSquareCheck } from "react-icons/bi";
import { Http } from "../../../Services/Services";
import { ROUTES } from "../../../Router/routerConfig";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { NEW_EXAM } from "../../../utils/Examination.apiConst";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
    render: (item) => console.log(key),
  };
}

const Index = ({ setLoading }) => {
  const navigate = useNavigate();

  //re-Examination Id
  const { examId } = useParams();

  //usestate to toggle sidebar
  const [collapsed, setCollapsed] = useState(false);

  const base_navigation =
    ROUTES.Examination.NewExamination.Home + "/" + examId + "/";

  const keyRouteMap = {
    1: base_navigation + ROUTES.Examination.NewExamination.TImeTable,
    2: base_navigation + ROUTES.Examination.NewExamination.Enrollment,
    3: base_navigation + ROUTES.Examination.NewExamination.Marks,
    4: base_navigation + ROUTES.Examination.NewExamination.Barcode,
    5: base_navigation + ROUTES.Examination.NewExamination.Reports.Home,
    6: base_navigation + ROUTES.Examination.NewExamination.graceMarks,
    7: base_navigation + ROUTES.Examination.NewExamination.ReChecking,
    8: base_navigation + ROUTES.Examination.NewExamination.Pattern,
    9: base_navigation + ROUTES.Examination.NewExamination.Setter,
    10: base_navigation + ROUTES.Examination.NewExamination.HomeHallticket,
    11: base_navigation + ROUTES.Examination.NewExamination.Evaluator,
    12: base_navigation + ROUTES.Examination.NewExamination.Invigilator,
    20: base_navigation + ROUTES.Examination.NewExamination.Publish,
    // '2' : base_navigation+ROUTES.Examination.NewExamination.Enrollment,
  };

  const items = [
    // getItem('Navigation One', 'sub1', <MdOutlineSchool />, [
    //     getItem('Item 1', 'g1', null, [getItem('Option 1', '1'), getItem('Option 2', '2')], 'group'),
    //     getItem('Item 2', 'g2', null, [getItem('Option 3', '3'), getItem('Option 4', '4')], 'group'),
    // ]),
    // getItem('Navigation Two', 'sub2', <MdOutlineSchool />, [
    //     getItem('Option 5', '5'),
    //     getItem('Option 6', '6'),
    //     getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
    // ]),
    // {
    //     type: 'divider',
    // },
    // getItem('Navigation Three', 'sub4', <MdOutlineSchool />, [
    //     getItem('Option 9', '9'),
    //     getItem('Option 10', '10'),
    //     getItem('Option 11', '11'),
    //     getItem('Option 12', '12'),
    // ]),
    {
      key: "1",
      icon: <AiOutlineCalendar />,
      label: "TimeTable",
    },
    {
      key: "2",
      icon: <AiOutlineUserAdd />,
      label: "Enrollment",
    },
    {
      key: "3",
      icon: <AiOutlineNumber />,
      label: "Marks",
    },
    {
      key: "4",
      icon: <AiOutlineBarcode />,
      label: "Barcode",
    },
    {
      key: "5",
      icon: <HiOutlineDocumentReport />,
      label: "Reports",
    },
    {
      key: "6",
      icon: <MdExposurePlus1 />,
      label: "Grace Marks",
    },
    {
      key: "7",
      icon: <BiMessageSquareCheck />,
      label: "Re-Checking",
    },
    {
      key: "8",
      icon: <GrDocumentConfig />,
      label: "Pattern",
    },
    {
      key: "9",
      icon: <GrDocumentUpdate />,
      label: "Setter",
    },
    {
      key: "10",
      icon: <AiOutlineUserAdd />,
      label: "Hall Ticket",
    },
    {
      key: "11",
      icon: <AiOutlineUserAdd />,
      label: "Evaluator",
    },
    {
      key: "12",
      icon: <AiOutlineUserAdd />,
      label: "Invigilator",
    },
    {
      type: "divider",
    },
    {
      key: "20",
      icon: <MdOutlinePublish />,
      label: "Publish",
      danger: true,
    },
    {
      key: "999",
      label: "more coming soon",
    },
    // {
    //     key: '1',
    //     icon: <AiOutlineAppstoreAdd style={styleIcon} />,
    //     label: 'Grace marks',
    // },
    // getItem('TimeTable', '1', <AiOutlineCalendar />),
    // getItem('Enrollment', '2', <AiOutlineUserAdd />),
    // getItem('Marks', '3', <AiOutlineNumber />),
    // getItem('Graceks', '4', <AiOutlineAppstoreAdd />),
  ];

  //function to toggle sidebar
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  //Menu call back
  const onClick = (e) => {
    console.log("click ", e);
    console.log(items);
  };

  const [data, setData] = useState();

  //Get Re examination ddta
  const getData = async () => {
    await Http.get(`${NEW_EXAM}/exam/${examId}`)
      .then((res) => {
        setData(res.data.data);
        if (!res.data.data) {
          toast.error("No Examination found");
          navigate(ROUTES.Examination.NewExamination.Home);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectedKey, setSelectedKey] = useState("1");

  const onClickMenuItem = (e) => {
    setSelectedKey(e.key);
    navigate(keyRouteMap[e.key]);
  };

  useEffect(() => {
    const path = window.location.pathname;
    const key = Object.keys(keyRouteMap).find(
      (key) => keyRouteMap[key] === path
    );
    setSelectedKey(key);
  }, []);

  useEffect(() => {
    if (examId) getData();
  }, [examId]);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-md-12"
                style={{ minHeight: "100vh", minWidth: "100vw" }}
              >
                <div className="row">
                  <div
                    className={`p-3 card p-1 col-${collapsed ? "1" : "2"}`}
                    style={{ transition: "all ease-in 100ms" }}
                  >
                    <button
                      className="btn btn-primary mb-3"
                      onClick={toggleCollapsed}
                    >
                      {" "}
                      {collapsed ? (
                        <AiOutlineArrowRight />
                      ) : (
                        <>
                          {" "}
                          <AiOutlineArrowLeft /> Close{" "}
                        </>
                      )}
                    </button>
                    {collapsed ? null : <h5>Exam</h5>}
                    <Menu
                      onClick={onClickMenuItem}
                      defaultSelectedKeys={["1"]}
                      defaultOpenKeys={["sub1"]}
                      mode="inline"
                      style={{ width: "auto" }}
                      selectedKeys={[selectedKey]}
                      items={items}
                      inlineCollapsed={collapsed}
                    />
                  </div>
                  <div className={`px-3 col-${collapsed ? "11" : "10"}`}>
                    <Outlet context={{ examData: data, setLoading, getData }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
