import React, { useEffect, useState } from 'react'
import { POST_EXAM_REVALUATION } from '../../utils/Examination.apiConst'
import { toast } from 'react-toastify'
import axios from 'axios'

const ModalRechecking = ({
    reData,
    setLoading,
    reloadData
}) => {

    const [reScore, setReScore] = useState(reData?.new_marks||'')

    useEffect(() => {
        setReScore(reData?.new_marks||'')
    }, [reData])

    const handleSubmit = async () => {
        setLoading(true)
        const bdata = {}
        if(reData?.re_id){
            bdata.new_marks=reScore
        }
        else{
            bdata.old_marks=reData?.m_marks
            bdata.new_marks=reScore
            bdata.student_id=reData?.student_id
            bdata.exam_marks_id=reData?.marks_id
        }
        const config = {
            method:reData?.re_id?'PUT':'POST',
            headers:{
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            url:`${POST_EXAM_REVALUATION}/${reData?.re_id?reData?.re_id:''}`,
            data:{
                ...bdata
            }
        }

        await axios(config)
        .then((res)=>{
            toast.success('Rechecking Done');
            reloadData()
        })
        .catch((err)=>{
            toast.error('Something went wrong');
        }
        )
        setLoading(false)
    }

    return (
        <div>
            <div
                className="modal fade"
                id="recheck"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog mw-75 w-100 ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                                Rechecking
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="row p-4">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Student Id</label>
                                    <input value={reData?.student_id} type="text" disabled className="form-control" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Marks Obtained</label>
                                    <input value={reData?.re_id?reData?.old_marks:reData?.m_marks} type="number" disabled className="form-control" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">New Marks Obtained</label>
                                    <input value={reScore} onChange={(e)=>{setReScore(e.target.value)}} type="number" className="form-control" placeholder='Enter New Marks Obtained' />
                                </div>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-between">
                                <button 
                                data-dismiss="modal"
                                aria-label="Close" 
                                className='btn btn-danger'
                                >
                                    Cancel
                                </button>
                                <button 
                                className='btn btn-success'
                                onClick={handleSubmit}
                                data-dismiss="modal"
                                aria-label="Close" 
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalRechecking