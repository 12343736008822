import { BASE_URL } from "./apiConstants";
//Exam Notifications
export const EXAM_NOTY_UPLOAD = `${BASE_URL}/api/infoUploading/exam_noty`;

export const  EXAM_NOTY_GET = `${BASE_URL}/api/infoUploading/get_exam_noty`;

export const EXAM_NOTY_UPDATE = `${BASE_URL}/api/infoUploading/update_exam_noty`;

//Time Table Notifications
export const TT_NOTY_UPLOAD = `${BASE_URL}/api/infoUploading/TTnotycreate`;

export const TT_NOTY_GET = `${BASE_URL}/api/infoUploading/getTTnoty`;

export const TT_NOTY_UPDATE = `${BASE_URL}/api/infoUploading/updateTTnoty`;

//Previous Year Question Papers
export const PREV_QUESTION_PAPER_UPLOAD = `${BASE_URL}/api/infoUploading/createPrevQuestionPaper`;

export const PREV_QUESTION_PAPER_GET = `${BASE_URL}/api/infoUploading/getPrevQuestionPaper`;

export const PREV_QUESTION_PAPER_PUT = `${BASE_URL}/api/infoUploading/updatePrevQuestionPaper`;
