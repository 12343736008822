import React, { useState } from "react";
import { useEffect } from "react";
import useEmployee from "../../Hooks/Employee/useEmployee";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  REPORTS_DETAILED,
  GET_STUDENT,
  REPORTS_DETAILED_ENG,
  CREATE_MARKS_SHEET,
  GET_MARKS_SHEET,
} from "../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../utils/Academics.apiConst";
import { useRef } from "react";

function AddMarkSheetNo({ setLoading }) {
  //get data from local storage

  //////program data//////

  //function to get data
  const getLocalProgramData = (): any | null => {
    const localProgramItem = localStorage.getItem(LOCAL_PROGRAM);
    return localProgramItem ? JSON.parse(localProgramItem) : null;
  };

  const [edit, setEdit] = useState<boolean>(false);

  const [save, setSave] = useState<boolean>(false);

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  useEffect(() => {
    setProgramOpt(getLocalProgramData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  //////College Data//////

  //function to get data
  const getLocalCollegeData = (): any | null => {
    let x = localStorage.getItem(LOCAL_COLLEGE);
    return x ? JSON.parse(x) : null;
  };

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  //////Department Data//////

  //function to get data
  const getLocalDeptData = (): any | null => {
    let x = localStorage.getItem(LOCAL_DEPARTMENT);
    return x ? JSON.parse(x) : null;
  };

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const [flag1, setFlag1] = useState(false);

  const [flag2, setFlag2] = useState(false);

  useEffect(() => {
    if (flag1) {
      setTimeout(() => {
        setFlag1(false);
      }, 10000);
    }
  }, [flag1]);

  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
    department_id: "",
  });

  // Ref for table to export in excel
  const tableRef = useRef<any>();
  //main data
  const [data, setData] = useState<any>([]);

  const [data1, setData1] = useState<any>([]);

  const [data2, setData2] = useState<any>([]);

  const [studentdata, setStudentData] = useState<any>([]);

  //class data
  const [classOpt, setClassOpt] = useState<any>([]);

  //semster data
  const [semesterOpt, setSemesterOpt] = useState<any>([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState<any>([]);

  // Flag to show or hide reports
  const [flag, setFlag] = useState(0);

  ///////Other states for management//////////

  const [showProgram, setShowProgram] = useState<string>("");
  const [showCollege, setShowCollege] = useState<string>("");
  const [showDepartment, setShowDepartment] = useState<string>("");
  const [showMonth, setShowMonth] = useState<string>("");
  const [showYear, setShowYear] = useState<string>("");

  const [examId, setExamId] = useState<number>(0);

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  /////////function calls from db to fe///////////
  //get all required data for creating a exam time tabel i.e, class and semester
  const getBasicData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
    ]);

    if (flag) return toast.error("Something went wrong");
  };

  //Get Exam Details
  const getData = async (exam_id: any) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
    await setShowDepartment(user?.department_id);
    setLoading(0);
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    await setExamId(0);
    setFlag(0);
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length != 0) {
          const m: any[] = [];
          empOpt?.map((s: any) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          getBasicData();
          getData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
  };

  const getReports = async () => {
    setLoading(1);

    let url =
      user?.college_id == "1111000"
        ? `${REPORTS_DETAILED_ENG}/${examId}`
        : `${REPORTS_DETAILED}/${examId}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log("Confusing Data", res.data.data.studentData);
        setStudentData(res.data.data.studentData);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });

    const config1 = {
      method: "get",
      url: `${GET_STUDENT}?semester_id=${user?.semester_id}&class_id=${user?.class_id}&college_id=${user?.college_id}&department_id=${user?.department_id}&session_id=${data[0]?.session_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        console.log(res.data);
        setData1(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });

    await getMarkSheetNo();
  };

  const getMarkSheetNo = async () => {
    const config2 = {
      method: "get",
      url: `${GET_MARKS_SHEET}?class_id=${user?.class_id}&department_id=${user?.department_id}&session_id=${data[0]?.session_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        console.log(res.data);
        setLoading(0);
        setData2(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
      });
  };

  const handleSubmit = async () => {
    setLoading(1);

    let x: any[] = [];
    for (let i of studentdata) {
      let obj = {
        session_id: data[0]?.session_id,
        student_session_id: data1?.find((s: any) => s.usn == i?.student_id)
          ?.student_session_id,
        name: i?.name,
        number:
          i?.number ||
          data2?.find(
            (s: any) =>
              s.student_session_id ==
              data1?.find((s: any) => s.usn == i?.student_id)
                ?.student_session_id
          )?.number,
        id:
          data2?.find(
            (s: any) =>
              s.student_session_id ==
              data1?.find((s: any) => s.usn == i?.student_id)
                ?.student_session_id
          )?.id || null,
      };
      x.push(obj);
    }
    console.log(x);
    // return;
    let url = `${CREATE_MARKS_SHEET}`;
    const config = {
      method: "post",
      url: url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: x,
    };

    await axios(config)
      .then(async (res) => {
        console.log(res);
        setLoading(0);
        toast.success("Successfully Updated Details");
        await getMarkSheetNo();
      })
      .catch((err) => {
        toast.error("Something went wrong");
        console.log(err);
        setLoading(0);
      });
  };

  useEffect(() => {
    getBasicData();
  }, []);

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  return (
    <div className="CreateExamTimetable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Marks Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Students Marks Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i: any, key: any) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {DeptOpt?.filter(
                              (s) => s.college_id == user?.college_id
                            )?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s: any) =>
                                  s.department_id == user?.department_id
                              )
                              ?.map((i: any, key: any) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s: any) => s.class_id == user?.class_id)
                              ?.map((i: any, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {!flag ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Date</th>

                              <th>Staff</th>

                              <th>Class</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i: any, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s: any) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        semesterOpt
                                          ?.filter(
                                            (s: any) =>
                                              s.class_id == user?.class_id
                                          )
                                          ?.filter(
                                            (s: any) =>
                                              s.id == user?.semester_id
                                          )[0]?.name
                                      }
                                      {/* {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      } */}
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-success btn-sm p-2 text-white mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i?.id);
                                        }}
                                      >
                                        <i
                                          className="ri-article-line "
                                          aria-hidden="true"
                                        >
                                          Results
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4">
                          <button
                            onClick={() => setFlag(0)}
                            className="btn btn-primary mr-auto p-2"
                          >
                            Back
                          </button>
                        </div>
                        <div className="col-md-8">
                          <span className="float-right">
                            {!edit ? (
                              <button
                                onClick={() => {
                                  setEdit(true);
                                  setSave(true);
                                }}
                                className="btn btn-primary mr-2 p-2"
                              >
                                Edit
                              </button>
                            ) : null}
                            {save ? (
                              <>
                                <button
                                  onClick={() => {
                                    setEdit(false);
                                    setSave(false);
                                    handleSubmit();
                                  }}
                                  className="btn btn-primary mr-2 p-2"
                                >
                                  Save
                                </button>

                                <button
                                  onClick={() => {
                                    setEdit(false);
                                    setSave(false);
                                  }}
                                  className="btn btn-primary mr-auto p-2"
                                >
                                  Cancel
                                </button>
                              </>
                            ) : null}
                          </span>
                        </div>
                      </div>
                      <h4 className="card-title text-uppercase mt-2">
                        Student List
                      </h4>
                      <hr />
                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                          ref={tableRef}
                        >
                          <thead>
                            <tr></tr>
                            <td>Sl.no</td>
                            <td>Name</td>
                            <td>Enrollment No</td>
                            <td>Faculty</td>
                            <td>Department</td>
                            <td>Class</td>
                            <td>Marks Sheet No</td>
                          </thead>
                          <tbody>
                            {studentdata &&
                              studentdata?.map((item: any, key: any) => {
                                return (
                                  <tr>
                                    <td>{key + 1}</td>
                                    <td>{item?.name}</td>
                                    <td>{item?.student_id}</td>
                                    <td>
                                      {
                                        collegeOpt?.find(
                                          (s: any) => s.id == user?.college_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        DeptOpt?.find(
                                          (s: any) =>
                                            s.id == user?.department_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        classOpt?.find(
                                          (s: any) => s.id == user?.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      <input
                                        className="form-control"
                                        type="text"
                                        readOnly={edit ? false : true}
                                        name="number"
                                        value={
                                          item?.number ||
                                          data2?.find(
                                            (s: any) =>
                                              s.user_id == item?.student_id
                                          )?.number ||
                                          null
                                        }
                                        onChange={(e) => {
                                          console.log(e.target.value);
                                          setFlag2((flag2) => !flag2);
                                          item.number = e.target.value;
                                        }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMarkSheetNo;
