import React from 'react'
import { useParams } from 'react-router-dom';

const Dashboard = () => {

  const {examId} = useParams()
  console.log(examId);
  return (
    <div>Dashboard</div>
  )
}

export default Dashboard