import axios from "axios";
import React, { useState } from "react";
import { LOGIN } from "../../utils/apiConstants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader/Loader";
import {
  SESSION_AUTH,
  SESSION_COLLEGE_ID,
  SESSION_EMPLOYEE_ID,
  SESSION_ROLE,
} from "../../utils/sessionStorageContants";
import { ROUTES } from "../../Router/routerConfig";
import {
  PRE_LOGIN_STAFF,
  QPDS_LOGIN_SETTER,
} from "../../utils/Examination.apiConst";

const RegisterExamStaff = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(0);

  const [selected, setSelected] = useState("login");

  const navigate = useNavigate();

  const signinObj = {
    email: userName,
    password: password,
  };

  var config = {
    method: "post",
    url: PRE_LOGIN_STAFF,
    headers: {
      "Content-Type": "application/json",
    },
    data: signinObj,
  };

  const signup = async () => {
    setLoading(1);
    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res);
        sessionStorage.setItem(SESSION_ROLE, "STAFF");
        sessionStorage.setItem(SESSION_AUTH, res.data.data.token);
        sessionStorage.setItem(SESSION_EMPLOYEE_ID, res.data.data?.employee_id);
        sessionStorage.setItem(
          "exam_committee_id",
          res.data.data?.exam_committee_id
        );
        navigate(ROUTES.Examination.ExamStaff.TImeTable);
        // console.log('here', res.data.data.employee_id);
        toast.success(`Logged in SuccessFully as Examination Staff`);
      })
      .catch((err) => {
        setLoading(0);
        // toast.error(err.response.data.message)
        console.log(err);
      });
  };

  const hadleSubmit = (e) => {
    e.preventDefault();
    if (!userName) {
      toast.error("UserName is required");
      return;
    }
    if (!password) {
      toast.error("Password is required");
      return;
    }

    signup();
  };

  return (
    <div className="Login">
      <Loader loading={loading} />
      <>
        <div>
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              <div className="col-lg-6 m-auto">
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <div className="row justify-content-center">
                      <div className="col-lg-9">
                        <div>
                          <div className="text-center">
                            <div>
                              <a href="javascript:void(0)" className="logo">
                                <img
                                  src="/assets/images/Nexenstial Logo.png"
                                  height={80}
                                  alt="logo"
                                />
                              </a>
                            </div>
                            <h4 className="font-size-18 mt-4">Welcome !</h4>
                            <p className="text-muted">
                              Sign in to continue to <br /> Swaminarayan
                              University Portal.
                            </p>
                          </div>
                          <div className="p-2 mt-5">
                            {selected === "login" ? (
                              <form className="form-horizontal">
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon" />
                                  <label htmlFor="username">Username</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    placeholder="Enter Username"
                                    value={userName}
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-lock-2-line auti-custom-input-icon" />
                                  <i
                                    className={`${
                                      showPassword
                                        ? "ri-eye-line"
                                        : "ri-eye-close-line"
                                    } password-toggler`}
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      right: "15px",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      transform: "translateY(-25%)",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  ></i>
                                  <label htmlFor="userpassword">Password</label>
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    id="userpassword"
                                    name="userpassword"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => {
                                      setPassword(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="mt-4 text-center">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                    name="submit"
                                    value="login"
                                    onClick={hadleSubmit}
                                  >
                                    Log In
                                  </button>
                                </div>
                                <div className="mt-4 text-center">
                                  <a href="" className="text-muted">
                                    <i className="mdi mdi-lock mr-1" /> Forgot
                                    your password?
                                  </a>
                                </div>

                                {/* <div className="mt-4 text-center">
                                                                    <a href='JavaScript:Void(0)' className="text-muted" onClick={() => setSelected("register")}>
                                                                        Register
                                                                    </a>
                                                                </div> */}
                              </form>
                            ) : (
                              <form className="form-horizontal">
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-user-2-line auti-custom-input-icon" />
                                  <label htmlFor="username">Name</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    placeholder="Enter Name"
                                    value={userName}
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i class="ri-mail-line auti-custom-input-icon"></i>
                                  <label htmlFor="username">Email</label>
                                  <input
                                    type="type"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    placeholder="Enter Email"
                                    value={userName}
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group auth-form-group-custom mb-0">
                                  <i className="ri-phone-line auti-custom-input-icon" />
                                  <label htmlFor="username">Phone</label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    placeholder="Enter Phone"
                                    value={phone}
                                    onChange={(e) => {
                                      setPhone(e.target.value);
                                    }}
                                  />
                                  {/* <i className="ri-phone-line auti-custom-input-icon" /> */}
                                </div>

                                <div
                                  className={`mt-0 p-0 mb-3 d-flex justify-content-end `}
                                >
                                  <a
                                    href="#"
                                    className={`mt-0 p-0  ${
                                      phone.length >= 10 ? "" : "cursor-disable"
                                    }`}
                                  >
                                    GET OTP
                                  </a>
                                </div>

                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-smartphone-line auti-custom-input-icon" />
                                  <label htmlFor="username">OTP</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    name="username"
                                    placeholder="Enter OTP"
                                    value={userName}
                                    onChange={(e) => {
                                      setUserName(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-stack-fill auti-custom-input-icon" />
                                  <label htmlFor="username">
                                    Select Program
                                  </label>
                                  <select
                                    id="role"
                                    name="role"
                                    className="form-control"
                                    autoComplete="off"
                                  >
                                    <option value="">Select</option>
                                    <option value={1}>UG</option>
                                    <option value={2}>PG</option>
                                  </select>
                                </div>

                                <div className="form-group auth-form-group-custom mb-4">
                                  <i className="ri-briefcase-3-line auti-custom-input-icon" />
                                  <label htmlFor="username">
                                    Select Specialization
                                  </label>
                                  <select
                                    id="role"
                                    name="role"
                                    className="form-control"
                                    autoComplete="off"
                                  >
                                    <option value="">Select</option>
                                    <option value={1}>MTECH</option>
                                    <option value={2}>BTECH</option>
                                  </select>
                                </div>

                                <div className="mt-4 text-center">
                                  <button
                                    className="btn btn-primary w-md waves-effect waves-light"
                                    type="submit"
                                    name="submit"
                                    value="login"
                                    onClick={hadleSubmit}
                                  >
                                    Register
                                  </button>
                                </div>
                                <div className="mt-4 text-center">
                                  <a href="/" className="text-muted">
                                    <i className="mdi mdi-lock mr-1" /> Forgot
                                    your password?
                                  </a>
                                </div>
                                <div className="mt-4 text-center">
                                  <a
                                    href="JavaScript:Void(0)"
                                    className="text-muted"
                                    onClick={() => setSelected("login")}
                                  >
                                    Login
                                  </a>
                                </div>
                              </form>
                            )}
                          </div>
                          <div className="mt-5 text-center">
                            <p>
                              © {new Date().getFullYear()} Swaminarayan
                              University. Crafted with{" "}
                              <i className="mdi mdi-heart text-danger" /> by{" "}
                              <a href="https://www.nexenstial.com/">
                                {" "}
                                Nexenstial{" "}
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-8 d-flex align-items-center justify-content-center">
                                    <img src="/assets/images/univ/add.png" alt="" />
                                <div className="authentication-bg">
                                    <div className="bg-overlay" />
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default RegisterExamStaff;
