import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_ENROLL_STUDENT,
  PRE_EXAM_TIMETABLE,
  POST_EXAM_ADD_MARKS,
} from "../../utils/Examination.apiConst";
import { Http } from "../../Services/Services";
import { Switch } from "antd";

const RetrieveBarcode = () => {
  const [barcode, setBarcode] = useState("");
  const [data, setData] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [flag, setFlag] = useState(false);
  const [timeTableData, setTimeTableData] = useState();
  const [marksData, setMarksData] = useState();
  const [dataExist, setDataExist] = useState(false);

  const [user, setUser] = useState({
    time_table_id: "",
    student_id: "",
    marks: "",
    absent: "",
    note: "",
  });

  const handleSubmit = async (e) => {
    await setDataExist(false);
    e.preventDefault();
    if (barcode.split("SUK").length < 2) return toast.error("Invalid Barcode");
    console.log(barcode.split("SUK")[0]);
    setData("Loading...");
    const config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      url: `${PRE_EXAM_ENROLL_STUDENT}/${barcode.split("SUK")[0]}`,
    };

    await axios(config)
      .then(async (res) => {
        await setUser({
          time_table_id: "",
          student_id: "",
          marks: "",
          absent: "",
          note: "",
        });
        await setFlag(false);
        setData(res.data.data.student_id);
        setUser({
          ...user,
          student_id: res.data.data.student_id,
        });
        console.log(res.data);

        Http.get(
          `${PRE_EXAM_TIMETABLE}?class_examination_id=${res.data.data.class_examination_id}&course_id=${res.data.data.subject}`
        ).then(async (res2) => {
          console.log(res);
          if (res2.data.data.length == 0) return toast.error("Data Not Found");
          setTimeTableData(res2.data.data[0]);
          setUser((prev) => ({
            ...prev,
            time_table_id: res2.data.data[0].id,
          }));
          Http.get(
            `${POST_EXAM_ADD_MARKS}?student_id=${res.data.data.student_id}&time_table_id=${res2.data.data[0].id}&course_id=${res.data.data.subject}`
          ).then((res3) => {
            console.log(res3.data);
            if (res3.data.length == 0) {
              setAddNew(true);
              console.log("here");
            } else {
              setDataExist(true);
              return toast.error("Marks already added");
              // setAddNew(false)
              // setMarksData(res3.data[0])
              // setUser({
              //     time_table_id: res3.data[0].time_table_id,
              //     student_id: res3.data[0].student_id,
              //     marks: res3.data[0].marks,
              //     absent: res3.data[0].absent,
              //     note: res3.data[0].note
              // })
            }
            setFlag(true);
          });
        });
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setData("");
      });
  };

  const UpdateChanges = async (e) => {
    e.preventDefault();
    if (!user.time_table_id || !user.student_id)
      return toast.error("Please Reload and try again");
    if (!user.marks && !user.absent)
      return toast.error("Please Add marks or mark as absent");
    if (!user.note && user.absent) return toast.error("Add Note if absent");

    if (addNew) {
      await Http.post(POST_EXAM_ADD_MARKS, user)
        .then((res) => {
          toast.success("Marks added successfully");
          setFlag(false);
          setAddNew(false);
          setUser({
            time_table_id: "",
            student_id: "",
            marks: "",
            absent: "",
            note: "",
          });
          setMarksData();
          setTimeTableData();
          setBarcode("");
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
    if (!addNew) {
      if (!marksData.id) return toast.error("Please Reload and try again");

      await Http.put(`${POST_EXAM_ADD_MARKS}/${marksData.id}`, user)
        .then((res) => {
          toast.success("Marks added successfully");
          setFlag(false);
          setAddNew(false);
          setUser({
            time_table_id: "",
            student_id: "",
            marks: "",
            absent: "",
            note: "",
          });
          setMarksData();
          setBarcode("");
          setTimeTableData();
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };
  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="card-header">
                      <h4 className="card-title">Retrieve Barcode</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="">Barcode</label>
                            <input
                              value={barcode}
                              onChange={(e) => setBarcode(e.target.value)}
                              type="text"
                              className="form-control"
                              placeholder="Scan or enter barcode number here"
                            />
                            <p>Student Id is:</p>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" className="btn btn-success">
                            {" "}
                            Get
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    {flag && (
                      <form onSubmit={UpdateChanges}>
                        <table className="table">
                          <tr>
                            {/* <th>Student Id</th> */}
                            <th>Subject</th>
                            <th>Max Marks</th>
                            <th>Min Marks</th>
                            <th>Obtained Marks</th>
                            <th>Absent</th>
                            <th>Note</th>
                            <th>Action</th>
                          </tr>
                          <tr>
                            {/* <td>{data}</td> */}
                            <td>{timeTableData?.course_name}</td>
                            <td>{timeTableData?.max_marks}</td>
                            <td>{timeTableData?.min_marks}</td>
                            <td>
                              <input
                                type="number"
                                placeholder="Enter Marks"
                                className="form-control"
                                value={user.marks}
                                onChange={(e) => {
                                  if (e.target.value > timeTableData?.max_marks)
                                    return toast.error(
                                      "Marks should be less than max marks"
                                    );
                                  if (e.target.value < 0)
                                    return toast.error(
                                      "Marks should be positive"
                                    );
                                  setUser((prev) => ({
                                    ...prev,
                                    marks: e.target.value,
                                  }));
                                }}
                              />
                            </td>
                            <td>
                              <Switch
                                checked={user.absent}
                                onChange={(e) =>
                                  setUser((prev) => ({ ...prev, absent: e }))
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Note if absent"
                                value={user.note}
                                onChange={(e) =>
                                  setUser((prev) => ({
                                    ...prev,
                                    note: e.target.value,
                                  }))
                                }
                              />
                            </td>
                            <td>
                              <button
                                type="submit"
                                onClick={UpdateChanges}
                                className="btn btn-primary btn-sm"
                              >
                                Save
                              </button>
                            </td>
                          </tr>
                        </table>
                      </form>
                    )}

                    {dataExist && <div>Marks are already added</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetrieveBarcode;
