import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
} from "../../utils/Academics.apiConst";
import { STUDENT_SESSION_ALL } from "../../utils/apiConstants";
import {
  PRE_EXAM_ENROLL_STUDENT,
  PRE_EXAM_TIMETABLE,
} from "../../utils/Examination.apiConst";
// import { LOCAL_COLLEGE } from "../../utils/LocalStorageConstants";
import { monthsArray } from "../../Data/jsonData/data";
import { useRef } from "react";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
} from "../../utils/LocalStorageConstants";

import { useReactToPrint } from "react-to-print";

function HallTickets({ setLoading }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();

  const session_id = searchParams.get("session_id");
  const class_id = searchParams.get("class_id");
  const semester_id = searchParams.get("semester_id");
  const college_id = searchParams.get("college_id");
  const month = searchParams.get("month");
  const year = searchParams.get("year");
  const department_id = searchParams.get("department_id");
  const class_examination_id = searchParams.get("class_examination_id");

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [subjectOpt, setSubjectOpt] = useState([]);

  const [enrollmentData, setEnrollmentData] = useState();

  const [timeTableData, setTimeTableData] = useState([]);

  const [studentData, setStudentData] = useState();

  const getStudentData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_SESSION_ALL}?student_id=${params?.id}&session_id=${session_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setStudentData(res.data.data[0]);
      })
      .catch((err) => console.log(err));
    setLoading(0);
  };

  const CollegeList = JSON.parse(localStorage.getItem(LOCAL_COLLEGE));

  const getCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;
  const getDepartmentData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getCollegeData());
  const [departmentOpt, setDepartmentOpt] = useState(getDepartmentData());
  const [collgename, setCollegeName] = useState(
    college_id == 1111008
    ? "Shree Swaminarayan College of Pharmacy"
    : college_id == 1111007
    ? "Shree Swaminarayan College of Homeopathy"
    : college_id == 1111000
    ? "Shree Swaminarayan College of Enginering and Technology"
    : college_id == 1111002
    ? "Shree Swaminarayan Law College"
    : college_id == 1111001
    ? "Shree Swaminarayan Ayurvedic College"
    : college_id == 1111003
    ? "Shree Swaminarayan College of Nursing "
    : college_id == 1111010
    ? "Shree Swaminarayan B.Ed. College  "
    : college_id == 1111011
    ? "Shree Swaminarayan Science College  "
    : "Shree Swaminarayan University"
      );

  const getBasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_CLASS}?college_id=${college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config1)
      .then((res) => {
        setSemesterOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  const getSubjects = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?class_id=${class_id}&semester_id=${semester_id}&college_id=${college_id}&department_id=${department_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setSubjectOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  //get time table data
  const getExamTimeTable = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${class_examination_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const getStudentEnrollmentDetails = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_ENROLL_STUDENT}?student_id=${params?.id}&session_id=${session_id}&year=${year}&class_id=${class_id}&semester_id=${semester_id}&class_examination_id=${class_examination_id}&status=ACTIVE`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setEnrollmentData(res.data?.enrolledStudent);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  useEffect(() => {
    getBasicData();
  }, [session_id, college_id]);

  useEffect(() => {
    if (class_id && semester_id && college_id && department_id) getSubjects();
  }, [class_id, semester_id, college_id, department_id]);

  useEffect(() => {
    if (
      session_id &&
      params?.id &&
      class_examination_id &&
      year &&
      class_id &&
      semester_id
    ) {
      getStudentEnrollmentDetails();
      getStudentData();
    }
  }, [
    params?.id,
    session_id,
    class_examination_id,
    year,
    class_id,
    semester_id,
  ]);

  useEffect(() => {
    getExamTimeTable();
  }, [class_examination_id]);

  console.log(timeTableData, enrollmentData);

  return (
    <div>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid"></div>
          <div className="row justify-content-center px-5" ref={componentRef}>
            <div className="col-md-10">
              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="row d-flex justify-content-around align-items-center ">
                      <img
                        src="/assets/images/Nexenstial Logo.png"
                        width={150}
                        alt=""
                        srcset=""
                      />

                      <div className="text-center">
                        <h3 className="text-uppercase mb-3">
                          <b>Swaminarayan University, Kalol</b>
                        </h3>{" "}
                        <br />
                        <h4>
                          {CollegeList?.find((s) => s.id == college_id)?.name},
                          Kalol
                        </h4>{" "}
                        <br />
                        {/* <h5>Faculty of Arts  and Humanities </h5> <br /> */}
                      </div>

                      {/* <img
                      
                        src="https://bootdey.com/img/Content/avatar/avatar7.png"
                        calss="img-fluid ml-auto"
                        alt="Maxwell Admin"
                        style={{ borderRadius: "50%" }}
                        width="100"
                      /> */}
                      <div className="">
                     <p
  className="border d-flex align-items-center text-center"
  style={{ height: 154 }}
>
  Student Photo <br />
  Signature of Principal with seal
</p>

                      </div>
                    </div>
                    <div className="row  ">
                      <div className="col-md-12 justify-content-center">
                        <div className="">
                          <h5 className="text-center">
                            Admit Card <br /> (
                            {monthsArray[month - 1] + "-" + year})
                          </h5>
                        </div>{" "}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-bordered">
                          <tr>
                            <td>
                              <p>
                                {" "}
                                <b>Enrollment No.:</b> {params?.id}
                              </p>
                            </td>

                            <td>
                              <p>
                                {" "}
                                <b>Appearance Type:</b> Fresh
                              </p>
                            </td>
                            <td>
                              <p>
                                {" "}
                                <b>Attempts:</b> 1
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2">
                              <p>
                                {" "}
                                <b>Student Name:</b>{" "}
                                <span
                                  className="text-uppercase"
                                  style={{
                                    fontSize: "18px",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  {studentData?.name}
                                </span>
                              </p>
                            </td>
                            <td colSpan={"2"}>
                              <p>
                                {" "}
                                <b>Gender: </b>{" "}
                                {studentData?.gender}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4">
                              <p>
                                {" "}
                                <b>Examination Center:</b>{" "}
                                <span
                                  className="text-uppercase"
                                  style={{
                                    fontSize: "18px",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  {collgename}, Kalol
                                  {/* {CollegeList?.find(s=>s.id==college_id)?.name}, Kalol */}
                                  {/* Shree Swaminarayan College of Nursing, Kalol. */}
                                </span>
                              </p>
                            </td>
                          </tr>
                        </table>

                        <h5> Paper Details</h5>

                        <table
                          className="table table-bordered"
                          width={"100%"}
                          style={{ border: "2px, solid, black" }}
                        >
                          <tr>
                            <th width={"40%"}>Paper Name</th>
                            <th>Paper Type</th>
                            <th className="text-center">Date</th>
                            <th className="text-center"> Time </th>
                            <th className="text-center"> Invigilator Sign</th>
                          </tr>
                          {timeTableData
                            ?.filter(
                              (s) =>
                                s?.course_id ==
                                enrollmentData?.find(
                                  (p) => p?.subject == s?.course_id
                                )?.subject
                            )
                            ?.sort(function (a, b) {
                              let dateA = new Date(a?.date?.split("T")[0]);
                              let dateB = new Date(b?.date?.split("T")[0]);
                              return dateA - dateB;
                            })
                            ?.map((i, key) => (
                              <tr>
                                <td>
                                  {
                                    subjectOpt?.find(
                                      (s) => s?.id == i?.course_id
                                    )?.name
                                  }
                                </td>
                                <td>
                                  {
                                    subjectOpt?.find(
                                      (s) => s?.id == i?.course_id
                                    )?.type
                                  }
                                </td>
                                <td className="text-center">
                                  {i?.date?.split("T")[0]}
                                </td>
                                <td className="text-center">
                                  {" "}
                                  {i?.time_from} to {i?.time_to}
                                </td>
                                <td className="text-center"></td>
                              </tr>
                            ))}
                        </table>

                        {/* <h5>Practical Paper Details</h5> */}

                        {/* <table className="table table-bordered " width={"100%"} style={{ border: "2px, solid, black", marginBottom: "100px" }}>
                          <tr>
                            <th width={"40%"}>Paper Name</th>

                            <th> Examinar Sign and Date</th>
                          </tr>
                          <tr>
                            <td>Kayachikitsa </td>

                            <td></td>
                          </tr>

                          <tr>
                            <td>Panchakarma</td>

                            <td></td>
                          </tr>

                        </table> */}
                        <div className="row ">
                          <div className=" col-md-12 d-flex justify-content-between">
                            <div className=" mt-2">
                              <img
                                src="/assets/images/signature/coe sign.png"
                                alt=""
                                width={180}
                              />
                              <h6>(Controller of Examination)</h6>
                            </div>

                            {CollegeList?.find((s) => s.id == college_id)
                              ?.name ==
                            "DOCTORAL AND POST DOCTORAL PROGRAMME" ? (
                              <div className="mt-2 text-center ">
                                <img
                                  src="/assets/images/signature/phddeansign.png"
                                  alt=""
                                  width={100}
                                />

                                <h6>
                                  Signature of Principal /{" "}
                                  {CollegeList?.find((s) => s.id == college_id)
                                    ?.name ==
                                  "DOCTORAL AND POST DOCTORAL PROGRAMME"
                                    ? "Dean"
                                    : "Director"}
                                  , with Stamp{" "}
                                </h6>
                              </div>
                            ) : (
                              <div className="mt-2 text-center align-items-center ">
                                <h6 style={{ marginTop: "5rem" }}>
                                  Signature of Principal /{" "}
                                  {CollegeList?.find((s) => s.id == college_id)
                                    ?.name ==
                                  "DOCTORAL AND POST DOCTORAL PROGRAMME"
                                    ? "Dean"
                                    : "Director"}
                                  , with Stamp{" "}
                                </h6>
                              </div>
                            )}
                            <div className="mt-5">
                              <h6 style={{ marginTop: "50px" }}>
                                (Candidate Signature)
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-5">
                          <div className="col-md-12">
                            <h5>Instuctions</h5>
                            <hr />
                            <p>
                              1. Students will have to take their seats in the
                              exam hall before 30 minutes of exam start time
                              with I-Card/ Photo ID.
                            </p>
                            <p>
                              2. Students will be allowed to enter in the exam
                              hall only up to 10 minutes after the exam start
                              time.
                            </p>
                            <p>
                              3. Students will be allowed to leave the exam hall
                              only after the exam end time.
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center d-print-none">
                          <button
                            className="btn btn-primary"
                            onClick={handlePrint}
                          >
                            Print
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HallTickets;
