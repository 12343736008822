import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import useEmployee from "../../Hooks/Employee/useEmployee";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
} from "../../utils/Academics.apiConst";
import { STUDENT_SESSION_ALL } from "../../utils/apiConstants";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  PRE_EXAM_ENROLL_STUDENT,
  PRE_EXAM_TIMETABLE,
  POST_EXAM_ADD_MARKS,
  STUDENT_INTERNAL_MARKS,
  REPORTS_DETAILED,
  POST_EXAM_ADD_MARKS_BULK,
  GET_ALL_MARKS,
} from "../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";

function PharmacyPublishMarks({ setLoading }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const navigate = useNavigate();

  const [deptOpt, setDeptOpt] = useState([]);

  const [programOpt, setProgramOpt] = useState([]);

  const [examMarks, setExamMarks] = useState([]);

  const [reportStat, setReportStat] = useState([]);

  const [collegeOpt, setCollegeOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [studentLoadingMsg, setStudentLoadingMsg] = useState("");

  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    session_id: "",
    department_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    setDeptOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == user?.college_id
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), user?.college_id]);

  useEffect(() => {
    setCollegeOpt(JSON.parse(localStorage.getItem(LOCAL_COLLEGE)));
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [x, setX] = useState([]);

  const [studentData, setStudentData] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  const [employeeOpt, setEmployeeOpt] = useState([]);

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [data, setData] = useState([]);

  const [flag, setFlag] = useState(0);

  const [examId, setExamId] = useState();

  const [timeTableData, setTimeTableData] = useState();

  const [credits, setCredits] = useState();

  const [subjectOpt, setSubjectOpt] = useState();

  const getBasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config1)
      .then((res) => {
        setLoading(0);
        setSemesterOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);

        console.log(err);
      });
  };

  //Get Exam Details
  const getDataExamData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        setData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    navigate({
      search: `?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}&session_id=${user.session_id}&department_id=${user?.department_id}&class_id=${user.class_id}&semester_id=${user.semester_id}`,
    });
    setLoading(1);

    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          getDataExamData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });

    getBasicDetails();
  };

  //get time table data
  const getExamTimeTable = async (exam) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${exam}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const getBasicDetails = async () => {
    setLoading(1);
    const config3 = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?class_id=${user?.class_id}&semester_id=${user?.semester_id}&college_id=${user?.college_id}&department_id=${user?.department_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then(async (res) => {
        setLoading(0);
        setSubjectOpt(res.data.data);
        let arr = await res.data.data?.filter(
          (s) =>
            s.id ==
              timeTableData?.filter((t) => t.course_id == s.id)[0]?.course_id &&
            s.status == "ACTIVE"
        );
        let cre = arr?.reduce((acc, it) => acc + it?.credit, 0);
        setCredits(cre);
      })
      .catch((err) => {
        setLoading(0);

        console.log(err);
      });
  };

  useEffect(() => {
    getBasicData();
  }, []);

  const getReports = async () => {
    setStudentLoadingMsg("Please Wait While we load student data");
    setLoading(1);

    const config2 = {
      method: "get",
      url: `${GET_ALL_MARKS}?class_examination_id=${examId}&session_id=${user?.session_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        console.log("Exam Marks", res.data);
        setExamMarks(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config = {
      method: "get",
      url: `${REPORTS_DETAILED}/${examId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setStudentLoadingMsg("");
        setLoading(0);
        console.log("Result Deails Here - ", res.data.data);
        setStudentData(res.data.data?.studentData);
        setReportStat(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  const publishMarks = async () => {
    var arr = [];
    studentData?.map((s) => {
      arr.push(s?.student_id);
    });

    const config = {
      method: "put",
      url: `${POST_EXAM_ADD_MARKS_BULK}/update`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: { data: arr },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Marks Published");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something Went Wrong");
      });
  };

  const publishIndividualMarks = async () => {
    setLoading(1);
    let newData = [];

    const config = {
      method: "put",
      url: `${POST_EXAM_ADD_MARKS_BULK}/update`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: { data: x },
    };

    await axios(config)
      .then((res) => {
        getReports();
        toast.success("Marks Published");
        getExamTimeTable(examId);
      })
      .catch((err) => {
        console.log(err);
        setLoading(0);
        toast.error("Something Went Wrong");
      });
  };

  const gparef = [
    {
      min: 90,
      max: 100,
      grade: "O",
      gp: 10,
    },
    {
      min: 80,
      max: 89.99,
      grade: "A",
      gp: 9,
    },
    {
      min: 70,
      max: 79.99,
      grade: "B",
      gp: 8,
    },
    {
      min: 60,
      max: 69.99,
      grade: "C",
      gp: 7,
    },
    {
      min: 50,
      max: 59.99,
      grade: "D",
      gp: 6,
    },
    {
      min: 0,
      max: 49.99,
      grade: "F",
      gp: 0,
    },
  ];

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max) => {
    let marks = maptoHundred(Number(num), Number(max));

    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        return i;
      }
    }
  };

  return (
    <div className="PublishMarks">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Pharmacy Publish Marks</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active"> Publish Marks</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt
                              ?.filter((s) => s.id == "1111008")
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {deptOpt
                              ?.filter((s) => s.college_id == user?.college_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter((s) => s.college_id == user?.college_id)
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            name="session_id"
                            value={user?.session_id}
                            onChange={handleChange}
                            className="form-control"
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option key={key} value={i?.id}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h4 className="card-title text-uppercase ">List</h4>
                      {flag ? (
                        <div>
                          <button
                            onClick={publishMarks}
                            className="btn btn-success btn-sm mr-3"
                          >
                            <i className="fa fa-check"></i> Publish Results
                          </button>

                          <button
                            onClick={() => {
                              publishIndividualMarks();
                            }}
                            className="btn btn-success btn-sm mr-3"
                          >
                            <i className="fa fa-check"></i> Publish Selected
                            Results
                          </button>
                        </div>
                      ) : null}
                    </div>{" "}
                    <hr />
                    <div>
                      {flag ? (
                        <button
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            setFlag(0);
                          }}
                        >
                          Back
                        </button>
                      ) : null}
                    </div>
                    <div className="table-responsive">
                      {!flag ? (
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Course</th>
                              <th>Specialization</th>
                              <th>Date</th>

                              <th>Faculties</th>

                              <th>Course Year</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        data-toggle="modal"
                                        data-target="#create"
                                        className="badge badge-light text-dark mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i?.id);
                                          getExamTimeTable(i?.id);
                                        }}
                                      >
                                        <i
                                          class="fa fa-edit "
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <table
                          id="table_id"
                          className="table table-bordered"
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>

                              <th>Enrollment No.</th>
                              <th>Full Name</th>

                              {timeTableData &&
                                timeTableData?.map((data) => {
                                  return (
                                    <th>
                                      {
                                        subjectOpt?.find(
                                          (s) => s.id == data?.course_id
                                        )?.name
                                      }
                                    </th>
                                  );
                                })}
                              <th>Total Marks</th>
                              <th>Total Obtained Marks</th>
                              <th>percentage</th>
                              <th>Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {studentData && studentData?.length != 0
                              ? studentData?.map((data, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td> {data?.student_id}</td>
                                      <td> {data?.name}</td>
                                      {timeTableData &&
                                        timeTableData?.map((i) => {
                                          const d = reportStat?.marks?.find(
                                            (s) =>
                                              s?.course_id == i?.course_id &&
                                              s?.student_id == data?.student_id
                                          );
                                          if (d) {
                                            return (
                                              <td>
                                                {d?.absent ? (
                                                  <span className="badge badge-soft-danger">
                                                    Absent
                                                  </span>
                                                ) : (
                                                  Number(d?.e_marks) +
                                                  Number(d?.i_marks)
                                                )}
                                              </td>
                                            );
                                          } else {
                                            return (
                                              <td>
                                                <span className="badge badge-soft-warning">
                                                  N/A
                                                </span>
                                              </td>
                                            );
                                          }
                                        })}

                                      <td>
                                        {reportStat?.marks
                                          .filter(
                                            (s) =>
                                              s?.student_id ==
                                                data?.student_id &&
                                              timeTableData?.some(
                                                (t) =>
                                                  t.course_id == s.course_id
                                              )
                                          )
                                          ?.reduce(
                                            (sum, cur) =>
                                              sum +
                                              Number(cur?.e_max) +
                                              Number(cur?.i_max),
                                            0
                                          )}
                                      </td>

                                      <td>
                                        {reportStat?.marks
                                          .filter(
                                            (s) =>
                                              s?.student_id ==
                                                data?.student_id &&
                                              timeTableData?.some(
                                                (t) =>
                                                  t.course_id == s.course_id
                                              )
                                          )
                                          ?.reduce(
                                            (sum, cur) =>
                                              sum +
                                              Number(cur?.e_marks) +
                                              Number(cur?.i_marks),
                                            0
                                          )}
                                      </td>
                                      <td>
                                        {(
                                          reportStat?.marks
                                            ?.filter(
                                              (s) =>
                                                s?.student_id ==
                                                  data?.student_id &&
                                                !s.is_not_countable
                                            )
                                            ?.reduce(
                                              (total, j) =>
                                                total +
                                                Number(
                                                  (calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max
                                                  )
                                                    ? calculateGradePointEachSub(
                                                        j.e_marks +
                                                          j?.i_marks +
                                                          j?.e_grace_marks,
                                                        j.e_max + j?.i_max
                                                      )?.gp
                                                    : 0) * j?.credit
                                                ),

                                              0
                                            ) /
                                          reportStat?.marks
                                            ?.filter(
                                              (s) =>
                                                s?.student_id ==
                                                  data?.student_id &&
                                                !s.is_not_countable
                                            )
                                            ?.reduce(
                                              (total, j) =>
                                                total + Number(j?.credit),
                                              0
                                            )
                                        ).toFixed(2) == 0.0
                                          ? "0.00"
                                          : (
                                              (reportStat?.marks
                                                ?.filter(
                                                  (s) =>
                                                    s?.student_id ==
                                                      data?.student_id &&
                                                    !s.is_not_countable
                                                )
                                                ?.reduce(
                                                  (total, j) =>
                                                    total +
                                                    Number(
                                                      (calculateGradePointEachSub(
                                                        j.e_marks +
                                                          j?.i_marks +
                                                          j?.e_grace_marks,
                                                        j.e_max + j?.i_max
                                                      )
                                                        ? calculateGradePointEachSub(
                                                            j.e_marks +
                                                              j?.i_marks +
                                                              j?.e_grace_marks,
                                                            j.e_max + j?.i_max
                                                          )?.gp
                                                        : 0) * j?.credit
                                                    ),

                                                  0
                                                ) /
                                                reportStat?.marks
                                                  ?.filter(
                                                    (s) =>
                                                      s?.student_id ==
                                                        data?.student_id &&
                                                      !s.is_not_countable
                                                  )
                                                  ?.reduce(
                                                    (total, j) =>
                                                      total + Number(j?.credit),
                                                    0
                                                  ) -
                                                0.5) *
                                              10
                                            ).toFixed(2)}
                                        {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          readOnly={
                                            examMarks?.postMarks?.find(
                                              (s) =>
                                                s.student_id == data?.student_id
                                            )?.status == "PUBLISHED"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              let arr = [...x];
                                              arr.push(data?.student_id);
                                              setX(arr);
                                            } else {
                                              let newArr = x?.filter(
                                                (s) => s != data?.student_id
                                              );
                                              setX(newArr);
                                            }
                                          }}
                                          checked={
                                            x?.find(
                                              (s) => s == data?.student_id
                                            )
                                              ? 1
                                              : examMarks?.postMarks?.find(
                                                  (s) =>
                                                    s.student_id ==
                                                    data?.student_id
                                                )?.status == "PUBLISHED"
                                              ? 1
                                              : examMarks?.postMarks?.find(
                                                  (s) =>
                                                    s.student_id ==
                                                    data?.student_id
                                                )?.status == "ACTIVE"
                                              ? 0
                                              : 0
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : studentLoadingMsg}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PharmacyPublishMarks;
