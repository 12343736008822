import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { STUDENT_SESSION_ALL } from "../../../../utils/apiConstants";
import { RE_EXAM_ENROLLMENT } from "../../../../utils/Examination.apiConst";
import { useEffect } from "react";
import { Http } from "../../../../Services/Services";
import { useState } from "react";
import { toast } from "react-toastify";

const EnrollmentTimeTable = () => {
  const { examId, time_table_id } = useParams();

  const { examData, setLoading } = useOutletContext();

  const [studentData, setStudentData] = useState([]);
  const [enrollmentData, setEnrollmentData] = useState([]);

  const getEnrollmentData = async () => {
    await Http.get(`${RE_EXAM_ENROLLMENT}?time_table_id=${time_table_id}`)
      .then((res) => {
        console.log(res.data.data);
        setEnrollmentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStudentData = async () => {
    setLoading(1);
    await Http.get(
      `${STUDENT_SESSION_ALL}?class_id=${examData?.class_id}&semester_id=${examData?.semester_id}&session_id=${examData?.session_id}&status=ACTIVE`
    )
      .then((res) => {
        setStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const onEnroll = (student_id) => {
    const obj = {
      student_id: student_id,
      time_table_id: time_table_id,
    };
    Http.post(`${RE_EXAM_ENROLLMENT}`, obj)
      .then((res) => {
        toast.success(res.data.message);
        getEnrollmentData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const upDateEnrollment = (id, status) => {
    Http.put(`${RE_EXAM_ENROLLMENT}/${id}`, { status: status })
      .then((res) => {
        toast.success(res.data.message);
        getEnrollmentData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (examData) {
      getStudentData();
      getEnrollmentData();
    }
  }, [examData]);
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <h6 className="card-header">
              Enroll students - <strong>Subject Name</strong>
            </h6>
          </div>
        </div>

        <div className="row mt-2">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tr>
                <th>Sl.No</th>
                <th>Enrollmnt Number</th>
                <th>Student Name</th>
                <th>Action</th>
              </tr>
              {studentData?.map((i, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>{i?.user_id}</td>
                  <td>{i?.name}</td>
                  <td>
                    {enrollmentData?.find(
                      (s) =>
                        s.time_table_id == time_table_id &&
                        i?.user_id == s?.student_id
                    ) ? (
                      <button
                        onClick={() => {
                          if (
                            enrollmentData?.find(
                              (s) =>
                                s.time_table_id == time_table_id &&
                                i.user_id == s.student_id
                            ).status == "ACTIVE"
                          ) {
                            upDateEnrollment(
                              enrollmentData?.find(
                                (s) =>
                                  s.time_table_id == time_table_id &&
                                  i?.user_id == s.student_id
                              ).id,
                              "INACTIVE"
                            );
                          } else
                            upDateEnrollment(
                              enrollmentData?.find(
                                (s) =>
                                  s.time_table_id == time_table_id &&
                                  i?.user_id == s.student_id
                              ).id,
                              "ACTIVE"
                            );
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        {enrollmentData?.find(
                          (s) =>
                            s.time_table_id == time_table_id &&
                            i?.user_id == s.student_id
                        ).status == "ACTIVE"
                          ? "Un-Enroll"
                          : "Enroll"}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          onEnroll(i?.user_id);
                        }}
                        className="btn btn-primary btn-sm"
                      >
                        Enroll
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentTimeTable;
