import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Http } from "../../../../Services/Services";
import { RE_EXAM_RECHECKING } from "../../../../utils/Examination.apiConst";
import { useState } from "react";
import { Switch } from "antd";
import Loader from "../../../../Components/Loader/Loader";
import ModalReExamRechecking from "../../../../modals/Examinations/ModalReExamRechecking";

const AddRecheckingMarks = () => {
  const { time_table_id } = useParams();

  const [selectedData, setSelectedData] = useState({});

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  const getData = async () => {
    Http.get(RE_EXAM_RECHECKING + "/" + time_table_id)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="card" style={{ maxWidth: "100%", overflow: "scroll" }}>
      <Loader loading={loading} />
      <ModalReExamRechecking
        reloadData={getData}
        setLoading={setLoading}
        reData={selectedData}
        time_table_id={time_table_id}
      />
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <h6 className="card-header">Student Marks ReChecking</h6>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered">
                <tr>
                  <th>Sl.No</th>
                  <th>USN</th>
                  <th>Subject</th>
                  <th>Max Marks</th>
                  <th>Min Marks</th>
                  <th>Old Marks</th>
                  <th>New Marks</th>
                  <th>Absent</th>
                  <th>Note</th>
                  <th>Action</th>
                </tr>

                {data?.map((i, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>{i.student_id}</td>
                    <td>{i.subject_name}</td>
                    <td>{i.max_marks}</td>
                    <td>{i.min_marks}</td>
                    <td>{i.id ? i.old_marks : i.marks}</td>
                    <td>{i.new_marks || "Not Added"}</td>
                    <td>
                      <Switch value={i.absent} disabled />
                    </td>
                    <td>{i.note}</td>
                    <td>
                      <button
                        data-toggle="modal"
                        data-target="#recheck"
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setSelectedData(i);
                        }}
                      >
                        Recheck
                      </button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRecheckingMarks;
