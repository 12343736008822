import React, { useEffect, useState } from 'react'
import { Http } from '../../../Services/Services'
import { EXAM_MASTER } from '../../../utils/Examination.apiConst'
import { toast } from 'react-toastify'
import { getFileUrl } from '../../../Helpers/Helpers'
import { ASSET_EMPLOYEE_IMAGE } from '../../../utils/AssetsReferenceTypes'

const Master = ({setLoading}) => {
    const [title, setTitle] = useState('')
    const [file, setFile] = useState('')
    const [data, setData] = useState([])

    const handleFileUplaod = async (e, str,value) => {
        try {
            const d = await getFileUrl(ASSET_EMPLOYEE_IMAGE, `Examination_${str}`, e.target.value.split(".")[1], setLoading, e.target.files[0]);
            setFile(d?d:'')
        } catch (error) {
            console.log(error);
        }
    }

    const getData = async() => {
        await Http.get(EXAM_MASTER)
        .then((res) => {
            console.log(res)
            setData(res.data.data)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleSubmit = async() => {
        if(!title || !file) return alert('Please fill all the fields');
        setLoading(true)
        const body = {
            title,
            url:file
        }
        await Http.post(EXAM_MASTER,body)
        .then((res) => {
            console.log(res)
            setTitle('')
            setFile('')
            toast.success(res.data.message)
            getData()
        })
        .catch((err) => {
            console.log(err)
            toast.error(err.message||'Something went wrong')
        })
        setLoading(false)
    }

    useEffect(() => {
        getData()
    },[])
  return (
    <div className='main-content'>
        <div className="page-contentn">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h4 className="card-header">
                            Master Files
                        </h4>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">Title</label>
                                            <input value={title} onChange={e=>setTitle(e.target.value)} type="text" className="form-control" placeholder='Enter title of the master file' />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">FIle</label>
                                            <input onChange={handleFileUplaod} type="file" className="form-control" placeholder='Upload the master file' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button onClick={handleSubmit} className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-header">
                                    Master FIles
                                </h4>
                                <div className="table-responsive mt-2">
                                    <table className="table table-bordered">
                                        <tr>
                                            <th>
                                                Sl.No
                                            </th>
                                            <th>
                                                Title
                                            </th>
                                            <th>
                                                File
                                            </th>
                                        </tr>
                                        {
                                            data?.map((d,i) => (
                                                <tr>
                                                    <td>
                                                        {i+1}
                                                    </td>
                                                    <td>
                                                        {d?.title}
                                                    </td>
                                                    <td>
                                                        <a href={d?.url} target="_blank">Click Here</a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Master