import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { sessionOpt } from "../../Data/jsonData/Academics/Academics";
import useEmployee from "../../Hooks/Employee/useEmployee";

import { ROUTES } from "../../Router/routerConfig";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
  ACADEMICS_ADD_SUBJECT,
} from "../../utils/Academics.apiConst";
import { STUDENT_SESSION, STUDENT_SESSION_ALL } from "../../utils/apiConstants";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  PRE_EXAM_ENROLL_STUDENT,
  PRE_EXAM_TIMETABLE,
} from "../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../utils/LocalStorageConstants";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import HallTicket from "../../Components/PreExamination/HallTicket";
import Barcode from "react-barcode";

function BulkBarcode({ setLoading }) {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const navigate = useNavigate();

  const { search } = useLocation();

  const [searchParams] = useSearchParams();

  const [deptOpt, setDeptOpt] = useState([]);

  const [programOpt, setProgramOpt] = useState([]);

  const [collegeOpt, setCollegeOpt] = useState(
    JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
  );

  const [studentLoadingMsg, setStudentLoadingMsg] = useState("");

  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    session_id: "",
    department_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    setDeptOpt(
      JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))?.filter(
        (itemt) => itemt.college_id == user?.college_id
      )
    );
  }, [localStorage.getItem(LOCAL_DEPARTMENT), user?.college_id]);

  useEffect(() => {
    setCollegeOpt(JSON.parse(localStorage.getItem(LOCAL_COLLEGE)));
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  useEffect(() => {
    setProgramOpt(JSON.parse(localStorage.getItem(LOCAL_PROGRAM)));
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  useEffect(() => {
    if (
      searchParams.get("program_id") &&
      searchParams.get("college_id") &&
      searchParams.get("month") &&
      searchParams.get("year")
    ) {
      setUser((prev) => ({
        college_id: searchParams.get("college_id"),
        program_id: searchParams.get("program_id"),
        month: searchParams.get("month"),
        year: searchParams.get("year"),
        session_id: searchParams.get("session_id"),
        department_id: searchParams.get("department_id"),
        class_id: searchParams.get("class_id"),
        semester_id: searchParams.get("semester_id"),
      }));

      getCommiitteeWithVal(
        searchParams.get("program_id"),
        searchParams.get("college_id"),
        searchParams.get("month"),
        searchParams.get("year")
      );
    }
  }, [searchParams]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [classOpt, setClassOpt] = useState([]);

  const [semesterOpt, setSemesterOpt] = useState([]);

  const [studentData, setStudentData] = useState([]);

  const [selectedStudent, setSelectedStudent] = useState();

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  const [employeeOpt, setEmployeeOpt] = useState([]);

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [addData, setAddData] = useState();

  const [data, setData] = useState([]);

  const [flag, setFlag] = useState(0);

  const [examId, setExamId] = useState();

  const [timeTableData, setTimeTableData] = useState();

  const [enrollmentData, setEnrollmentData] = useState([]);

  const [subjectOpt, setSubjectOpt] = useState();

  const [barcodeSubject, setBarcodeSubject] = useState();

  const getBasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        setClassOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config1)
      .then((res) => {
        setSemesterOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  const getData = async () => {
    setStudentLoadingMsg("Please Wait While we load student data");
    setLoading(1);
    const config = {
      method: "get",
      url: `${STUDENT_SESSION_ALL}?college_id=${user?.college_id}&department_id=${user?.department_id}&session_id=${user?.session_id}&class_id=${user?.class_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setStudentData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
    setStudentLoadingMsg("");
  };

  //Get Exam Details
  const getDataExamData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));
    setLoading(0);

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    navigate({
      search: `?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}&session_id=${user.session_id}&department_id=${user?.department_id}&class_id=${user.class_id}&semester_id=${user.semester_id}`,
    });
    setLoading(1);

    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          // setMainUser(prev => ({
          //   ...prev,
          //   exam_committee_id: res.data.data[0].id
          // }))
          // getBasicData()
          getDataExamData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    // getBasicData();
    getBasicDetails();
  };

  const getCommiitteeWithVal = async (program_id, college_id, month, year) => {
    // if (!program_id || !college_id || !month || !year) return toast.error('All fileds are required for search')
    setLoading(1);

    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${program_id}&college_id=${college_id}&month=${month}&year=${year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          // setMainUser(prev => ({
          //   ...prev,
          //   exam_committee_id: res.data.data[0].id
          // }))
          // getBasicData()
          getDataExamData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    // getBasicData()
    getBasicDetails();
  };

  //get time table data
  const getExamTimeTable = async (exam) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${exam}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);

    setLoading(1);
    const config1 = {
      method: "get",
      url: `${PRE_EXAM_ENROLL_STUDENT}?session_id=${user?.session_id}&year=${user?.year}&class_id=${user?.class_id}&semester_id=${user?.semester_id}&class_examination_id=${exam}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        console.log(res);
        setEnrollmentData(res.data?.enrolledStudent);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const getBasicDetails = async () => {
    setLoading(1);
    const config3 = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?class_id=${user?.class_id}&semester_id=${user?.semester_id}&college_id=${user?.college_id}&department_id=${user?.department_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        setSubjectOpt(res.data.data);
      })
      .catch((err) => console.log(err));

    setLoading(0);
  };

  useEffect(() => {
    getBasicData();
  }, []);

  const [infoData, setInfoData] = useState([
    {
      course: "UG-Engineering-ComputerScience",
      regno: "2KA16CS002",

      fullname: "Mr.Abhishek Badiger",
      year: "1st Year - 2nd Sem",

      subjects: "7",
      status: <p className="badge badge-soft-success">Applied</p>,
    },
    {
      course: "UG-Engineering-Mechanical",
      regno: "2KA16CS002",

      fullname: "Mr.Abhishek Badiger",
      year: "1st Year - 2nd Sem",

      subjects: "5",
      status: <p className="badge badge-soft-danger">Not-Applied</p>,
    },
  ]);

  const handleDelete = (key) => {
    setInfoData(infoData.slice(0, key));
  };

  return (
    <div className="ExamEnrollment">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Exam Enrollment</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Exam Enrollment
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {deptOpt
                              ?.filter((s) => s.college_id == user?.college_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                             <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i.id} key={key}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Session</label>
                          <select
                            name="session_id"
                            value={user?.session_id}
                            onChange={handleChange}
                            className="form-control"
                          >
                            <option value="">Select Session</option>
                            {sessionOpt?.map((i, key) => (
                              <option key={key} value={i?.id}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <hr />

                    <div>
                      {flag ? (
                        <button
                          className="btn btn-primary mb-3"
                          onClick={() => {
                            setFlag(0);
                          }}
                        >
                          Back
                        </button>
                      ) : null}
                    </div>

                    <div className="table-responsive">
                      {!flag ? (
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Exam Month</th>

                              <th>Employees</th>

                              <th> Year</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        data-toggle="modal"
                                        data-target="#create"
                                        className="badge badge-success text-light mr-3 p-2"
                                        title="Get Barcodes"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i?.id);
                                          getExamTimeTable(i?.id);
                                          getData();
                                        }}
                                        style={{cursor:"Pointer"}}
                                      >
                                        <i
                                          class="fa fa-edit "
                                          aria-hidden="true"
                                        ></i> Get Barcodes
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      ) : (
                        <>
                          {studentLoadingMsg == "" ? (
                            <table className="display table table-bordered  nowrap table-hover ">
                              <tr>
                                <th>Sl.No</th>
                                <th>Course </th>
                                <th>Course Code</th>
                                <th>Action</th>
                              </tr>
                              {timeTableData?.map((i, key) => (
                                <tr>
                                  <td>{key + 1}</td>
                                  <td>
                                    {
                                      subjectOpt?.find(
                                        (s) => s?.id == i?.course_id
                                      )?.name
                                    }
                                  </td>
                                  <td>
                                    {
                                      subjectOpt?.find(
                                        (s) => s?.id == i?.course_id
                                      )?.code
                                    }
                                  </td>
                                  <td>
                                    <button className="btn badge">
                                      <i
                                        className="ri-barcode-line"
                                        style={{ fontSize: "20px" }}
                                        onClick={async () => {
                                          await setBarcodeSubject(i?.course_id);
                                          handlePrint();
                                        }}
                                      ></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td></td>
                              </tr>
                            </table>
                          ) : (
                            <div className="p-4">{studentLoadingMsg}</div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div >
             <div className="row" style={{display:"none"}}>
              <div className="col-md-12">
              <div
                className="d-flex flex-row flex-start flex-wrap px-5 mr-2  "
                style={{ marginTop: "10px" }}
                ref={componentRef}
              >
                <div className="row mb-3" style={{ marginBottom: "55px" }}>
                  {enrollmentData
                    ?.filter(
                      (s) => s.subject == barcodeSubject && s.status == "ACTIVE"
                    )
                    ?.map((i, key) => {
                      const uid = i.id;
                      const sid = subjectOpt
                        ?.find((s) => s?.id == barcodeSubject)
                        ?.code?.replace(/\s/g, "");
                      console.log(uid, sid);
                      let zeros = new Array(

// new function
                        Math.abs(9 -
                          Number(uid?.length || 0) -
                            Number(sid?.length || 0) +
                           1) 
                         )?.join("0");

                        //  old Function

                      // 9 -
                      //  Number(uid?.length || 0) -
                      //    Number(sid?.length || 0) +
                      //   1 
                      // )?.join("0");

                     // 9 -
                      //   Number(uid?.length || 0) -
                      //   Number(sid?.length || 0) +
                      //   1 
                      // for(let iterator = 0 ; iterator < 12 - uid?.length - sid?.length ; iterator++){
                      //     zeros += '0'
                      // }

                      const val = uid + "SUK" + zeros + sid;

                      return (
                        <div
                          className="col-6 px-4 d-flex  align-items-center "
                          style={{ marginBottom: "35px", marginTop: "12px" }}
                        >
                          <div className="barcode-small">
                            <p style={{ fontSize: "12px" }}>{val} </p>
                            <p style={{ fontSize: "12px" }}> {i?.student_id}</p>
                            <p
                              className="text-truncate"
                              style={{ fontSize: "12px", maxWidth: "100px" }}
                            >
                              {" "}
                              {
                                subjectOpt?.find((s) => s?.id == barcodeSubject)
                                  ?.name
                              }
                            </p>
                          </div>

                          <div
                            className="barcode-large text-right"
                            style={{ marginLeft: "26px" }}
                          >
                            <p style={{ fontSize: "12px",marginBottom:"-3px" }}>{val} </p>
                            <Barcode
                              width={1.1}
                              height={55}
                              value={val}
                              displayValue="false"
                            />{" "}
                            <br />
                            <p className="text-truncate text-right" style={{ fontSize: "10px",maxWidth: "200px"  }}   >
                              {" "}
                              {
                                subjectOpt?.find((s) => s?.id == barcodeSubject)
                                  ?.name
                              }
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              </div>
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkBarcode;
