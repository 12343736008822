import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { RE_EXAM_REPORT_DETAILED } from "../../../../utils/Examination.apiConst";
import { useEffect } from "react";
import { Http } from "../../../../Services/Services";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";

const ReportRow = ({ data }) => {
  const [isPass, setIsPass] = useState(true);

  useEffect(() => {
    let flag = 0;
    const mergedData = {};

    for (const obj of data) {
      const courseName = obj.course_name;
      if (!mergedData[courseName]) {
        // Initialize merged entry if it doesn't exist
        mergedData[courseName] = {
          ...obj,
          course_id: [obj.course_id], // Convert course_id to an array
        };
      } else {
        // Merge entry with existing mergedData
        mergedData[courseName].course_id.push(obj.course_id);
        mergedData[courseName].max_marks += obj.max_marks;
        mergedData[courseName].min_marks += obj.min_marks;
        mergedData[courseName].grace_marks += obj.grace_marks;
        mergedData[courseName].marks += obj.marks;
        mergedData[courseName].absent += obj.absent;
      }
    }

    // Convert mergedData back to an array of objects
    const mergedArray = Object.values(mergedData);

    for (const i of mergedArray) {
      if (i.marks + i.grace_marks < i.min_marks || i.absent) {
        flag = 1;
        break;
      }
    }

    if (flag === 0) {
      setIsPass(true);
    } else setIsPass(false);
  }, [data]);
  return (
    <div className="table-reponsive" style={{ overflowX: "auto" }}>
      <table className="table table-bordered " style={{ padding: "1rem" }}>
        <tr>
          <th></th>
          {data?.map((i, key) => (
            <th className="text-center" key={key}>
              {i.course_name}
            </th>
          ))}
        </tr>
        <tr>
          <th>Maximum Marks</th>
          {data?.map((i, key) => (
            <td className="text-center" key={key}>
              {i.max_marks}
            </td>
          ))}
        </tr>
        <tr>
          <th>Minimum Marks</th>
          {data?.map((i, key) => (
            <td className="text-center" key={key}>
              {i.min_marks}
            </td>
          ))}
        </tr>
        <tr>
          <th>Obtained Marks</th>
          {data?.map((i, key) => (
            <th
              className={`${
                i.min_marks > i.marks + i.grace_marks
                  ? "text-danger text-center"
                  : "text-success text-center"
              }`}
              key={key}
            >
              {!i?.marks && i?.absent ? (
                "absent"
              ) : (
                <>
                  {i?.marks} {i?.grace_marks ? " + " + i?.grace_marks : null}
                </>
              )}
            </th>
          ))}
        </tr>
        <tr>
          <th>Result</th>
          <th
            colSpan={data?.length}
            className={`text-${isPass ? "success" : "danger"}`}
          >
            {isPass ? "Pass" : "Fail"}
          </th>
        </tr>
      </table>
    </div>
  );
};

const DetailedReports = () => {
  const tableRef = useRef();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const { examId } = useParams();

  const [studentSet, setStudentSet] = useState([]);
  const [reportData, setReportData] = useState([]);

  const getData = async () => {
    await Http.get(`${RE_EXAM_REPORT_DETAILED}/${examId}`)
      .then((res) => {
        console.log(res);
        setReportData(res.data.data);
        for (const i of res.data.data) {
          console.log(i);
        }
        const stdSet = new Set();
        res.data.data.forEach((item) => {
          stdSet.add(item.student_id);
        });
        setStudentSet(Array.from(stdSet));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <button
              onClick={onDownload}
              className="btn btn-success float-right mr-2"
            >
              Export
            </button>
          </div>{" "}
          <br />
          <div className="col-md-12 mt-2">
            <h6 className="card-header">Detailed Report</h6>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <div className="table-responsive">
              <table ref={tableRef} className="table table-bordered">
                <tr>
                  <th>Sl No</th>
                  <th>Student</th>
                  <th>Report</th>
                </tr>
                {studentSet?.map((i, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{i}</td>
                    <td>
                      <ReportRow
                        data={reportData.filter((s) => s.student_id == i)}
                      />
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedReports;
