import React, { useState } from "react";
import { useEffect } from "react";
import useEmployee from "../../../Hooks/Employee/useEmployee";

import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  PRE_EXAM_TIMETABLE,
  REPORTS_BY_NUMBERS,
  REPORTS_DETAILED,
  REPORTS_DETAILED_ENG,
} from "../../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
//other libraries
import { toast } from "react-toastify";
import axios from "axios";
import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";

function PublishReportEng({ setLoading }) {
  //get data from local storage

  //////program data//////

  //function to get data
  const getLocalProgramData = () =>
    localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  useEffect(() => {
    setProgramOpt(getLocalProgramData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  //////College Data//////

  //function to get data
  const getLocalCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  //////Department Data//////

  //function to get data
  const getLocalDeptData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
    department_id: "",
  });

  //main object for data manipulation
  const [mainUser, setMainUser] = useState({
    class_examination_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  const gparef = [
    {
      min: 85,
      max: 100,
      grade: "AA",
      gp: 10,
    },
    {
      min: 75,
      max: 84.99,
      grade: "AB",
      gp: 9,
    },
    {
      min: 65,
      max: 74.99,
      grade: "BB",
      gp: 8,
    },
    {
      min: 55,
      max: 64.99,
      grade: "BC",
      gp: 7,
    },
    {
      min: 45,
      max: 54.99,
      grade: "CC",
      gp: 6,
    },
    {
      min: 40,
      max: 44.99,
      grade: "CD",
      gp: 5,
    },
    {
      min: 35,
      max: 39.99,
      grade: "DD",
      gp: 4,
    },
    {
      min: 0,
      max: 34.99,
      grade: "FF",
      gp: 0,
    },
  ];

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const getGpaSubjectwise = (d, m) => {
    console.log(d);
    const max = Number(d?.e_max)
      ? Number(d?.e_max)
      : 0 + Number(d?.i_max)
      ? Number(d?.i_max)
      : 0 + Number(d?.mt_max)
      ? Number(d?.mt_max)
      : 0 + Number(d?.viva_max)
      ? Number(d?.viva_max)
      : 0;
    const num = Number(d?.e_marks)
      ? Number(d?.e_marks)
      : 0 + Number(d?.e_grace_marks)
      ? Number(d?.e_grace_marks)
      : 0 + Number(d?.i_marks)
      ? Number(d?.i_marks)
      : 0 + Number(d?.mt_marks)
      ? Number(d?.mt_marks)
      : 0 + Number(d?.viva_marks)
      ? Number(d?.viva_marks)
      : 0;
    let marks = maptoHundred(num, max);
    // console.log(m,max,num,marks);
    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        // console.log(i, marks);
        return i;
      }
    }
  };

  const calculateGradePointEachSub = (num, max) => {
    // console.log(d);
    let marks = maptoHundred(Number(num), Number(max));
    // console.log(num, max);

    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        // console.log(i, marks);
        return i;
      }
    }
  };

  // Ref for table to export in excel
  const tableRef = useRef();

  //Data for adding time table
  const [addData, setAddData] = useState();

  /////Other Data States for

  //main data
  const [data, setData] = useState([]);

  //class data
  const [classOpt, setClassOpt] = useState([]);

  //semster data
  const [semesterOpt, setSemesterOpt] = useState([]);

  //subject/course Data
  const [courseOpt, setCourseOpt] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState([]);

  // Flag to show or hide reports
  const [flag, setFlag] = useState(0);

  ///////Other states for management//////////

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showDepartment, setShowDepartment] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [report, setReport] = useState([]);

  const [reportStat, setReportStat] = useState([]);
  const [passedStudents, setPassedStudents] = useState(0);
  const [failedStudents, setFailesStudents] = useState(0);

  const [examId, setExamId] = useState();

  const [timeTableData, setTimeTableData] = useState([]);

  const [ranks, setRanks] = useState([])

  const [timeTableSet, setTimeTableSet] = useState([])

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateGradePoint = (p) => {
    // if(p>=85) return "O+"
    // if (p>=70 && p<=84.99 ) return "O"
    // if (p>=60    && p<=69.99 ) return "A"
    // if (p>=55 && p<=59.99 ) return "B+"
    // if (p>=48 && p<=54.99 ) return "B"
    // if (p>=36 && p<=47.99 ) return "C"
    // if (p<36) return "D"

    console.log(p.toFixed(2));

    return parseFloat(p.toFixed(2) * 10).toFixed(2);
  };

  /////////function calls from db to fe///////////
  //get all required data for creating a exam time tabel i.e, class and semester
  const getBasicData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
    ]);

    if (flag) return toast.error("Something went wrong");
  };

  //Get Exam Details
  const getData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
    await setShowDepartment(user?.department_id);
    setLoading(0);
  };

  const AnalyseResult = (p) => {
    const arr = reportStat?.marks?.filter((s) => s.student_id == p);
    for (const i of arr) {
      if (i?.e_absent == 1) return false;
      if (i?.e_min > i?.e_marks + Number(i?.e_grace_marks)) return false;
    }
    const percentage =
      (reportStat?.marks
        ?.filter((s) => s?.student_id == p && !s.is_not_countable)
        ?.reduce(
          (total, cur) =>
            total +
            (Number(cur?.e_marks) +
              Number(cur?.e_grace_marks) +
              Number(cur?.i_marks) +
              Number(cur?.mt_marks) +
              Number(cur?.viva_marks)),
          0
        ) *
        100) /
      reportStat?.marks
        ?.filter((s) => s?.student_id == p && !s.is_not_countable)
        ?.reduce(
          (total, cur) =>
            total +
            (Number(cur?.e_max) +
              Number(cur?.i_max) +
              Number(cur?.mt_max) +
              Number(cur?.viva_max)),
          0
        );
    // console.log(percentage);
    // console.log(p);
    if (percentage < 50) return false;
    return true;
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    await setExamId();
    setFlag(0);
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          setMainUser((prev) => ({
            ...prev,
            exam_committee_id: res.data.data[0].id,
          }));
          getBasicData();
          getData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        toast.error("Something Went Wrong");
      });

    setLoading(0);
    // getBasicData();
  };

  const getReports = async () => {
    setLoading(1);

    const config = {
      method: "get",
      url: `${REPORTS_DETAILED_ENG}/${examId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        setReportStat(res.data.data);
        const arr = []
        for (const i of res.data.data?.studentData) {
          const total = i.i_max+i.e_max
          const obtained = i.i_marks+i.e_marks
          const p = (obtained*100)/total
          const obj = {
            student_id: i.student_id,
            percentage: p||0,
          }
          arr.push(obj)
        }

        const marksSet = new Set();
        for (const i of res.data.data.marks) {
          marksSet.add(i.c_id)
        }

        const newArr11 = [...marksSet]
        console.log(newArr11);
        const dd=[]
        for (const i of newArr11) {
          const obj = {
            course_id:i,
            subject_name:res.data.data.marks?.find(s=>s.c_id==i)?.subject
          }
          dd.push(obj)
        }
        setTimeTableSet(dd)

        arr.sort((a,b)=>b.percentage-a.percentage)
        setRanks(arr)

      })
      .catch((err) => {
        console.log(err);
      });

      const config2 = {
        method: "get",
        url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${examId}&status=ACTIVE`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
          "Content-Type": "application/json",
        },
      };
  
      await axios(config2)
        .then((res) => {
          setTimeTableData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });

    setLoading(0);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //useEffects
  useEffect(() => {
    getBasicData();
  }, []);

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  return (
    <div className="CreateExamTimetable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Marks Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Students Marks Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {DeptOpt?.filter(
                              (s) => s.college_id == user?.college_id
                            )?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {!flag ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Date</th>

                              <th>Staff</th>

                              <th>Class</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {monthsOfYear[
                                        parseInt(showMonth, 10) - 1
                                      ] +
                                        "," +
                                        showYear}
                                    </td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      }
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-success btn-sm p-2 text-white mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i.id);
                                        }}
                                      >
                                        <i
                                          class="ri-article-line "
                                          aria-hidden="true"
                                        >
                                          Results
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={() => setFlag(0)}
                          className="btn btn-primary"
                        >
                          Back
                        </button>
                        <button
                          onClick={onDownload}
                          className="btn btn-success"
                        >
                          Export
                        </button>
                      </div>
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                      <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                          ref={tableRef}
                        >
                          <tr>
                            <td>Sl No</td>
                            <td>USN</td>
                            <td>Name</td>
                            {timeTableData?.map((i, key) => (
                              <td>{i.course_name}</td>
                            ))}
                            <td>Total Marks</td>
                            <td>Obtained Marks</td>
                            <td>Credits</td>
                            <td>Percentage</td>
                            <td>SGPA</td>
                            <td>GPA</td>
                            {/* <th>Overall Grade</th> */}
                            <td>Result</td>
                            <td>Rank</td>
                          </tr>
                          {reportStat?.studentData?.map((i, key) => (
                            <tr>
                              <td>{key + 1}</td>
                              <td>{i?.student_id}</td>
                              <td>{i?.name}</td>
                              {timeTableData?.map((j, key) => (
                                <td key={key}>
                                    {
                                        ((reportStat?.marks?.find(s=>s.student_id==i?.student_id&&s.course_id==j?.c_id)?.e_marks)+(reportStat?.marks?.find(s=>s.student_id==i?.student_id&&s.course_id==j?.c_id)?.mt_marks ) + (reportStat?.marks?.find(s=>s.student_id==i?.student_id&&s.course_id==j?.c_id)?.viva_marks ) + (reportStat?.marks?.find(s=>s.student_id==i?.student_id&&s.course_id==j?.c_id)?.i_marks )) || <span className="badge badge-soft-warning">N/A</span>
                                    }
                                    -
                                    {(() => {
                                    const d = reportStat?.marks?.find(
                                      (s) =>
                                        s.student_id === i?.student_id &&
                                        s.course_id === j?.c_id
                                    );

                                    // console.log('d:', d); 

                                    return  <span>
                                              {d.e_marks + d.e_grace_marks <
                                              d.e_min
                                                ? "FF"
                                                : d.mt_marks < d.mt_min
                                                ? "FF"
                                                : d.viva_marks < d.viva_min
                                                ? "FF"
                                                : d.i_marks < d.i_min
                                                ? "FF"
                                                : calculateGradePointEachSub(
                                                    d.e_marks +
                                                      d?.i_marks +
                                                      d?.mt_marks +
                                                      d?.viva_marks,
                                                    d.e_max +
                                                      d?.i_max +
                                                      d?.mt_max +
                                                      d?.viva_max
                                                  )?.grade}
                                      </span>
                                  })()}
                                </td>
                              ))}
                              <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, cur) =>
                                          total +
                                          (Number(cur?.e_max) +
                                            Number(cur?.i_max)),
                                        0
                                      )}
                                  </td>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, cur) =>
                                          total +
                                          (Number(cur?.e_marks) +
                                            Number(cur?.e_grace_marks) +
                                            Number(cur?.i_marks)),
                                        0
                                      )}
                                  </td>
                              <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, j) => total + Number(j?.credit),
                                        0
                                      )}
                                  </td>
                               <td>
                                    {(
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total +
                                            Number(
                                              (calculateGradePointEachSub(
                                                j.e_marks +
                                                      j?.i_marks +
                                                      j?.viva_marks +
                                                      j?.mt_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max + j?.viva_max + j?.mt_max
                                              )
                                                ? calculateGradePointEachSub(
                                                  j.e_marks +
                                                  j?.i_marks +
                                                  j?.viva_marks +
                                                  j?.mt_marks +
                                                  j?.e_grace_marks,
                                                j.e_max + j?.i_max + j?.viva_max + j?.mt_max
                                                  )?.gp
                                                : 0) * j?.credit
                                            ),

                                          0
                                        ) /
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total + Number(j?.credit),
                                          0
                                        )
                                    ).toFixed(2) == 0.0
                                      ? "0.00"
                                      : (
                                          (reportStat?.marks
                                            ?.filter(
                                              (s) =>
                                                s?.student_id ==
                                                  i?.student_id &&
                                                !s.is_not_countable
                                            )
                                            ?.reduce(
                                              (total, j) =>
                                                total +
                                                Number(
                                                  (calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.viva_marks +
                                                      j?.mt_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max + j?.viva_max + j?.mt_max
                                                  )
                                                    ? calculateGradePointEachSub(
                                                      j.e_marks +
                                                      j?.i_marks +
                                                      j?.viva_marks +
                                                      j?.mt_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max + j?.viva_max + j?.mt_max
                                                      )?.gp
                                                    : 0) * j?.credit
                                                ),

                                              0
                                            ) /
                                            reportStat?.marks
                                              ?.filter(
                                                (s) =>
                                                  s?.student_id ==
                                                    i?.student_id &&
                                                  !s.is_not_countable
                                              )
                                              ?.reduce(
                                                (total, j) =>
                                                  total + Number(j?.credit),
                                                0
                                              ) -
                                            0.5) *
                                          10
                                        ).toFixed(2)}
                                    {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                               </td>
                               <td>
                                    {(
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total +
                                            Number(
                                              (calculateGradePointEachSub(
                                                j.e_marks +
                                                      j?.i_marks +
                                                      j?.viva_marks +
                                                      j?.mt_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max + j?.viva_max + j?.mt_max
                                              )
                                                ? calculateGradePointEachSub(
                                                    j.e_marks +
                                                      j?.i_marks +
                                                      j?.viva_marks +
                                                      j?.mt_marks +
                                                      j?.e_grace_marks,
                                                    j.e_max + j?.i_max + j?.viva_max + j?.mt_max
                                                  )?.gp
                                                : 0) * j?.credit
                                            ),

                                          0
                                        ) /
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total + Number(j?.credit),
                                          0
                                        )
                                    ).toFixed(2)}
                                    {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                                  </td>
                                  
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, j) =>
                                          total +
                                          Number(
                                            (calculateGradePointEachSub(
                                              j.e_marks +
                                                j?.i_marks +
                                                j?.e_grace_marks,
                                              j.e_max + j?.i_max
                                            )
                                              ? calculateGradePointEachSub(
                                                  j.e_marks +
                                                    j?.i_marks +
                                                    j?.e_grace_marks,
                                                  j.e_max + j?.i_max
                                                )?.gp
                                              : 0) * j?.credit
                                          ),

                                        0
                                      )
                                      .toFixed(2)}
                                  </td>
                                  <td>
                                  {AnalyseResult(i?.student_id) ? (
                                      <span className="text-success">
                                        Pass{" "}
                                      </span>
                                    ) : (
                                      <span className="text-danger">Fail</span>
                                    )}
                                  </td>
                            <td>
                              {
                                ranks?.findIndex(s=>s.student_id==i?.student_id)+1
                              }
                              </td> 
                            </tr>
                          ))}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishReportEng;
