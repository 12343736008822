import axios from "axios";
import React, { useState } from "react";
import { getFileUrl } from "../../Helpers/Helpers";
import { ASSET_EMPLOYEE_IMAGE } from "../../utils/AssetsReferenceTypes";
import { RE_EXAM_TIMETABLE } from "../../utils/Examination.apiConst";

const ReExamTimeTableRow = ({
  subOpt,
  data,
  handleDeleteExam,
  setLoading,
  getData,
  key1,
}) => {
  //   console.log(data);
  const [user, setUser] = useState({
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
    instructions: "",
    remuneration: "",
  });

  const [flag, setFlag] = useState(0);

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    console.log(user);
    setLoading(1);
    const config = {
      method: "put",
      url: RE_EXAM_TIMETABLE + "/" + data?.id,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        ...user,
      },
    };

    await axios(config)
      .then((res) => {
        setLoading(0);
        console.log(res);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    getData();
  };

  const addAttachment = async (e, str, value) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `Examination_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setUser((prev) => ({
        ...prev,
        [value]: d ? d : "",
      }));
    } catch (error) {
      console.log(error);
    }
  };

  if (flag)
    return (
      <tr id="addr0">
        <td>{key1 + 1}</td>
        <td>
          <select
            name="course_id"
            className="form-control"
            value={user?.course_id}
            onChange={handleChange}
          >
            <option value="">Select Subject</option>
            {subOpt?.map((i, key) => (
              <option key={key} value={i?.id}>
                {i?.name}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input
            type="date"
            name="date"
            className="form-control"
            value={user?.date?.split("T")[0]}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="time"
            name="time_from"
            className="form-control"
            value={user?.time_from}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="time"
            name="time_to"
            className="form-control"
            value={user?.time_to}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="number"
            name="duration"
            className="form-control"
            value={user?.duration}
            onChange={handleChange}
          />
        </td>

        <td>
          <input
            type="number"
            name="max_marks"
            className="form-control"
            value={user?.max_marks}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="number"
            name="min_marks"
            className="form-control"
            value={user?.min_marks}
            onChange={handleChange}
          />
        </td>
        <td>
          <input
            type="file"
            name="attachment"
            className="form-control"
            // value={user?.attachment}
            onChange={(e) => {
              addAttachment(e, "timeTable_instructions", "attachment");
            }}
          />
        </td>
        <td>
          <input
            type="file"
            name="attachment"
            className="form-control"
            // value={user?.attachment}
            onChange={(e) => {
              addAttachment(e, "timeTable_instructions", "attachment1");
            }}
          />
        </td>

        <td style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            id="delete_row"
            className="pull-right btn btn-success text-white btn-sm h-100 mr-2"
            onClick={() => {
              setFlag(0);
            }}
          >
            Cancel
          </a>
          <a
            id="delete_row"
            className="pull-right btn btn-success text-white btn-sm h-100"
            onClick={() => {
              handleEdit();
              setFlag(0);
            }}
          >
            Save
          </a>
        </td>
      </tr>
    );
  else
    return (
      <tr>
        <td>{key1 + 1}</td>
        <td>{subOpt?.find((s) => s.id == data?.course_id)?.name}</td>
        <td>{data?.date?.split("T")[0]}</td>
        <td>{data?.time_from}</td>
        <td>{data?.time_to}</td>
        <td>{data?.duration}</td>
        <td>{data?.max_marks}</td>
        <td>{data?.min_marks}</td>
        <td>
          {data?.attachment ? (
            <a href={data?.attachment} target="_blank">
              Click Here
            </a>
          ) : (
            "No Attachments added"
          )}{" "}
        </td>
        <td>
          {data?.attachment1 ? (
            <a href={data?.attachment1} target="_blank">
              Click Here
            </a>
          ) : (
            "No Attachments added"
          )}{" "}
        </td>
        <td className="d-flex" style={{ minWidth: "100px" }}>
          <button
            className="btn text-primary"
            onClick={() => {
              setFlag(1);
              setUser(data);
            }}
          >
            <i class="fa fa-edit " aria-hidden="true"></i>
          </button>
          <button
            className="btn text-danger"
            onClick={() => {
              handleDeleteExam(data?.id);
            }}
          >
            <i class="fa fa-trash " aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    );
};

export default ReExamTimeTableRow;
