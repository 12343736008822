import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { getFileUrl } from '../../../Helpers/Helpers'
import { ASSET_EMPLOYEE_IMAGE } from '../../../utils/AssetsReferenceTypes'
import { PRE_EXAM_TIMETABLE, QPDS_QP_PATTERN, QPDS_SETTER_UPLOAD_QP, RE_EXAM_QPDS_PATTERN, RE_EXAM_QPDS_SETTER_UPLOAD_QP, RE_EXAM_TIMETABLE } from '../../../utils/Examination.apiConst'
import { SESSION_EMPLOYEE_ID } from '../../../utils/sessionStorageContants'

const UploadReExam = ({ setLoading }) => {

    const [file, setFile] = useState()

    const [pattern, setPattern] = useState()

    const [qpPattern, setQpPattern] = useState()

    const [prevData, setPrevData] = useState()

    const [timeTableData, setTimeTableData] = useState()



    //Function upload attachment to the s3
    const addAttachment = async (e, str) => {
        try {
            const d = await getFileUrl(ASSET_EMPLOYEE_IMAGE, `Employee_${str}`, e.target.value.split(".")[1], setLoading, e.target.files[0]);
            setFile(d ? d : '')
        } catch (error) {
            console.log(error);
        }
    }


    const getInitialData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: RE_EXAM_QPDS_PATTERN + '?time_table_id=' + sessionStorage.getItem('time_table_id'),
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            }
        }

        await axios(config)
            .then(async res => {
                console.log(res);
                await setPattern(res.data.data.id)
                await setQpPattern(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })
        setLoading(0)
    }

    const getPrevData = async () => {
        const config2 = {
            method: 'get',
            url: `${RE_EXAM_QPDS_SETTER_UPLOAD_QP}?qp_setter_id=${sessionStorage.getItem(SESSION_EMPLOYEE_ID)}&qp_pattern_id=${pattern}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            }
        }

        await axios(config2)
            .then(res => {
                console.log(res);
                setPrevData(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getTimeTableData = async () => {
        if (!sessionStorage.getItem('time_table_id')) return;
        setLoading(1)
        const config = {
            method: 'get',
            url: RE_EXAM_TIMETABLE + '/' + sessionStorage.getItem('time_table_id'),
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            }
        }

        await axios(config)
            .then(async res => {
                console.log(res);
                setTimeTableData(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })
        setLoading(0)
    }

    const handleSubmit = async () => {
        if (!file) return toast.error('Please upload file first')
        setLoading(1)
        const config = {
            method: prevData == null ? 'post' : 'put',
            url: `${RE_EXAM_QPDS_SETTER_UPLOAD_QP}${prevData == null ? '' : '/' + prevData?.id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data: {
                qp_pattern_id: pattern,
                qp_setter_id: sessionStorage.getItem(SESSION_EMPLOYEE_ID),
                file_url: file,
                status: 'PENDING'
            }
        }

        await axios(config)
            .then(res => {
                console.log(res);
                getInitialData()
            })
            .catch(err => {
                console.log(err);
            })
        setLoading(0)
    }


    useEffect(() => {
        if (qpPattern)
            getPrevData()
    }, [qpPattern])

    useEffect(() => {
        getInitialData()
        getTimeTableData()
    }, [])
    return (
        <div className='ExamSetterUpload'>
            <div className="row mt-3 p-5">
                {

                    prevData == null
                        ?
                        <div className="col-md-3">
                            <div className='form-group '>
                                <label htmlFor="">Upload Question Paper here</label>

                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    onChange={(e) => addAttachment(e, 'QP')}
                                    className="form-control"
                                />
                            </div>
                            <div>
                                {
                                    pattern
                                        ?
                                        <button
                                            className="btn btn-primary"
                                            onClick={handleSubmit}
                                        >
                                            Upload
                                        </button>
                                        :
                                        'No Pattern has been created for you examincation'
                                }
                            </div>
                        </div>
                        :
                        prevData?.status != 'APPROVED'
                            ?
                            <div className="col-md-3">
                                <div className='form-group '>
                                    <label htmlFor="">Upload Question Paper here</label>

                                    <input
                                        type="file"
                                        name=""
                                        id=""
                                        onChange={(e) => addAttachment(e, 'QP')}
                                        className="form-control"
                                    />
                                </div>
                                <div>
                                    {
                                        pattern
                                            ?
                                            <button
                                                className="btn btn-primary"
                                                onClick={handleSubmit}
                                            >
                                                Upload
                                            </button>
                                            :
                                            'No Pattern has been created for you examincation'
                                    }
                                </div>
                            </div>
                            :
                            <div className="col-md-3"></div>
                }

                <div className="col-md-3">
                    <div className=" mb-6 p-5 pt-0 ">
                        Your Application Status :  {
                            prevData == null
                                ?
                                <div>You Haven't Uploaded Document Yet</div>
                                :
                                <div>
                                    <span className={`text-${prevData?.status == 'PENDING' ? 'warning' : prevData?.status == 'APPROVED' ? 'success' : 'danger'}`}> {prevData?.status} {prevData?.status == 'REJECTED' ? 'due to ' + prevData?.remark : null} </span> <br /><br /> file uploaded can be accessed by  <a href={prevData?.file_url} download={true}>clicking this link </a>
                                </div>
                        }
                    </div>
                </div>

                <div className="file-preview mt-3 col-md-6">
                    <div className="card">
                        <div className="card-body p-4">
                            <div className="card-title">
                                Question Paper Pattern
                            </div>
                            <table className="table">
                                <tr>
                                    <th>Sl.No</th>
                                    <th>Marks per Question</th>
                                    <th>Number of Questions</th>
                                    <th>Total Marks</th>
                                </tr>
                                {
                                    !qpPattern
                                        ?
                                        'No pattern Created'
                                        :
                                        qpPattern?.pattern?.length != 0
                                            ?
                                            <>
                                                {
                                                    qpPattern?.pattern?.map((i, key) => (
                                                        <tr>
                                                            <td>{key + 1}</td>
                                                            <td>{i?.marks}</td>
                                                            <td>{i?.question}</td>
                                                            <td>{parseInt(i?.marks) * parseInt(i?.question)}</td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <td></td>
                                                    <td>Total</td>
                                                    <td>{qpPattern?.total_question}</td>
                                                    <td>{qpPattern?.total_marks}</td>
                                                </tr>
                                            </>
                                            : 'No Pattern Added'

                                }
                            </table>
                            {
                                timeTableData?.attachment1
                                ?
                                <div className="mt-3">
                                To download Remuneration Bill : <a href={timeTableData?.attachment1} target="_blank">Click Here</a>
                            </div>
                            :
                            null

                            }
                            
                            {
                                timeTableData?.attachment
                                    ?
                                    <div className="mt-3">
                                        Please Read the attachment by Controller of Examination : <a href={timeTableData?.attachment} target="_blank">Click Here</a>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                    </div>
                </div>
            </div>




            <div className='mt-4'></div>

        </div>
    )
}

export default UploadReExam