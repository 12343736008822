import React, { useEffect } from "react";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import ReExamTimeTableRow from "../../../../Components/ReExamination/ReexamTimetableRow";
import { Http } from "../../../../Services/Services";
import { RE_EXAM_TIMETABLE } from "../../../../utils/Examination.apiConst";
import Loader from "../../../../Components/Loader/Loader";
import { ACADEMICS_ADD_SUBJECT } from "../../../../utils/Academics.apiConst";
import { toast } from "react-toastify";

const TimeTable = () => {
  const { examId } = useParams();

  const [loading, setLoading] = useState(0);

  const { examData } = useOutletContext();

  console.log(examData);

  const [data, setData] = useState([]);
  const [subjectOpt, setSubjectOpt] = useState([]);
  const [edit, setEdit] = useState(false);

  const [user, setUser] = useState({
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
    instructions: "",
    remuneration: "",
  });

  const [editableRows, setEditableRows] = useState({});

  const handleChange = (e, rowIndex) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getData = () => {
    setLoading(1);
    Http.get(`${RE_EXAM_TIMETABLE}?re_examination_id=${examId}`)
      .then((res) => {
        setLoading(0);
        console.log(res.data.data);
        setData(res.data.data);
        setEditableRows({}); // Clear the editableRows state when data is fetched
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const getSubjectData = () => {
    setLoading(1);
    Http.get(
      `${ACADEMICS_ADD_SUBJECT}?semester_id=${examData?.semester_id}&getInactive=0`
    )
      .then((res) => {
        setLoading(0);
        setSubjectOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  const clearData = () => {
    setUser({
      course_id: "",
      date: "",
      time_from: "",
      time_to: "",
      duration: "",
      max_marks: "",
      min_marks: "",
      instructions: "",
      remuneration: "",
    });
  };

  const handleSubmit = () => {
    setLoading(1);
    Http.post(RE_EXAM_TIMETABLE, { ...user, re_examination_id: examId })
      .then((res) => {
        setLoading(0);
        toast.success("Time Table Added Successfully");
        clearData();
        getData();
      })
      .catch((err) => {
        setLoading(0);
        toast.error(err);
      });
  };

  const handleDeleteExam = async (i) => {
    setLoading(1);
    await Http.put(RE_EXAM_TIMETABLE + "/" + i, {
      status: "INACTIVE",
    })
      .then((res) => {
        setLoading(0);
        console.log(res);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    // setLoading(0);
    getData();
  };

  useEffect(() => {
    if (examId) getData();
  }, [examId]);

  useEffect(() => {
    if (examData?.semester_id) getSubjectData();
  }, [examData?.semester_id]);

  const handleTimeChange = (field, newValue) => {
    setUser((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  useEffect(() => {
    // Calculate duration when time_from and time_to change

    if (user.time_from && user.time_to) {
      const startTime = new Date(`2000-01-01T${user.time_from}`);
      const endTime = new Date(`2000-01-01T${user.time_to}`);
      const timeDiffInMillis = endTime - startTime;
      const durationInMinutes = timeDiffInMillis / (1000 * 60);
      setUser((prev) => ({
        ...prev,
        duration: durationInMinutes,
      }));
    }
  }, [user.time_from, user.time_to]);

  const handleEdit = (rowIndex) => {
    setEditableRows((prev) => ({
      ...prev,
      [rowIndex]: true,
    }));
    setUser({ ...data[rowIndex] }); // Set the user state to the data of the row being edited
  };

  const handleSave = (rowIndex) => {
    // Update the data for the row and toggle off editing mode
    const updatedData = [...data];
    updatedData[rowIndex] = { ...user };
    setData(updatedData);
    setEditableRows((prev) => ({
      ...prev,
      [rowIndex]: false,
    }));

    // Clear the user state
    setUser({
      course_id: "",
      date: "",
      time_from: "",
      time_to: "",
      duration: "",
      max_marks: "",
      min_marks: "",
      instructions: "",
      remuneration: "",
    });
  };

  return (
    <div className="card" style={{ maxWidth: "100%", overflow: "scroll" }}>
      <Loader loading={loading} />
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <h6 className="card-header">Time Table</h6>
          </div>
          <div className="col-md-6">
            <button
              className="btn btn-primary mb-3 float-right"
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </button>
          </div>
        </div>

        <div className="row mt-2">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tr>
                <th>Sl. No</th>
                <th style={{ minWidth: "150px" }}>Subjects</th>
                <th>Date</th>
                <th style={{ minWidth: "150px" }}>Time From</th>
                <th style={{ minWidth: "150px" }}>Time To</th>
                <th>
                  Duration <br /> (in min)
                </th>

                <th style={{ minWidth: "100px" }}> Marks (Max..)</th>
                <th style={{ minWidth: "100px" }}>Marks (Min..)</th>
                <th>Instructions</th>
                <th>Remuneration</th>

                <th>Action</th>
              </tr>
              {/* {/* {data &&
                data.map((i, key) => ( */}
              {/* <tr key={key}>
                    <td>{key + 1}</td> */}
              {/* <td>
                      {subjectOpt?.find((s) => s.id == i?.course_id)?.name}
                    </td>
                    <td>{i?.date?.split("T")[0]}</td>
                    <td>{i?.time_from}</td>
                    <td>{i?.time_to}</td>
                    <td>{i?.duration}</td>
                    <td>{i?.max_marks}</td>
                    <td>{i?.min_marks}</td>
                    <th>Instructions</th>
                    <th>Remuneration</th>

                    <th>Action</th> */}
              {/* {data &&
                data.map((i, key) => (
                  <tr>
                    <td>{key + 1}</td>
                    <td>
                      {editableRows[key] ? (
                        <select
                          name="course_id"
                          className="form-control"
                          value={user.course_id}
                          onChange={(e) => handleChange(e, key)}
                        >
                          <option value="">Select Subject</option>
                          {subjectOpt &&
                            subjectOpt.map((subj, subjKey) => (
                              <option key={subjKey} value={subj?.id}>
                                {subj?.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        subjectOpt?.find((s) => s.id == i?.course_id)?.name
                      )}
                    </td>
                    <td>
                      {editableRows[key] ? (
                        <input
                          type="date"
                          className="form-control"
                          value={user?.date}
                          onChange={(e) => handleChange(e, key)}
                          name="date"
                        />
                      ) : (
                        i?.date?.split("T")[0]
                      )}
                    </td>
                    <td>
                      {editableRows[key] ? (
                        <input
                          type="time"
                          className="form-control"
                          value={user?.time_from}
                          onChange={(e) => handleChange(e, key)}
                          name="time_from"
                        />
                      ) : (
                        i?.time_from
                      )}
                    </td>
                    <td>
                      {editableRows[key] ? (
                        <input
                          type="time"
                          className="form-control"
                          value={user?.time_to}
                          onChange={(e) => handleChange(e, key)}
                          name="time_to"
                        />
                      ) : (
                        i?.time_to
                      )}
                    </td>
                    <td>
                      {editableRows[key] ? (
                        <input
                          type="number"
                          className="form-control"
                          value={user?.duration}
                          onChange={(e) => handleChange(e, key)}
                          name="duration"
                        />
                      ) : (
                        i?.duration
                      )}
                    </td>
                    <td>
                      {editableRows[key] ? (
                        <input
                          type="number"
                          className="form-control"
                          value={user?.max_marks}
                          onChange={(e) => handleChange(e, key)}
                          name="max_marks"
                        />
                      ) : (
                        i?.max_marks
                      )}
                    </td>
                    <td>
                      {editableRows[key] ? (
                        <input
                          type="number"
                          className="form-control"
                          value={user?.min_marks}
                          onChange={(e) => handleChange(e, key)}
                          name="min_marks"
                        />
                      ) : (
                        i?.min_marks
                      )}
                    </td>
                    <th>Instructions</th>
                    <th>Remuneration</th>
                    <td>
                      {editableRows[key] ? (
                        <button
                          className="btn btn-success"
                          onClick={() => handleSave(key)}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEdit(key)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))} */}
              {data &&
                data?.map((item, key) => {
                  return (
                    <ReExamTimeTableRow
                      handleDeleteExam={handleDeleteExam}
                      subOpt={subjectOpt}
                      key1={key}
                      getData={getData}
                      data={item}
                      setLoading={setLoading}
                    />
                  );
                })}
              <tr>
                <td>{data.length + 1}</td>
                <td>
                  <select
                    name="course_id"
                    className="form-control"
                    value={user.course_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Subject</option>
                    {subjectOpt &&
                      subjectOpt.map((i, key) => (
                        <option key={key} value={i?.id}>
                          {i?.name}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    value={user.date}
                    onChange={handleChange}
                    name="date"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control"
                    id=""
                    value={user.time_from}
                    onChange={handleChange}
                    name="time_from"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control"
                    id=""
                    value={user.time_to}
                    onChange={handleChange}
                    name="time_to"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.duration}
                    onChange={handleChange}
                    name="duration"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.max_marks}
                    onChange={handleChange}
                    placeholder="Enter Max marks"
                    name="max_marks"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.min_marks}
                    onChange={handleChange}
                    placeholder="Enter Min marks"
                    name="min_marks"
                  />
                </td>
                <td>
                  <input type="file" className="form-control" />
                </td>
                <td>
                  <input type="file" className="form-control" />
                </td>
                <td>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary btn-sm"
                  >
                    add
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;
