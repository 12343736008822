import React, { useState } from 'react'
import { useEffect } from 'react';
import useEmployee from '../../Hooks/Employee/useEmployee';
import ModalAssignStudents from '../../modals/Examinations/ModalAssignStudents'

import ModalCreateTimetable from '../../modals/Examinations/ModalCreateTimetable'
import { PRE_CLASS_EXAM, PRE_EXAM_COMMITTEE } from '../../utils/Examination.apiConst';
import { LOCAL_COLLEGE, LOCAL_DEPARTMENT, LOCAL_PROGRAM } from '../../utils/LocalStorageConstants'


//other libraries
import { toast } from 'react-toastify'
import axios from 'axios';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SEMESTER, ACADEMICS_ADD_SUBJECT } from '../../utils/Academics.apiConst';
import ModalPublishTimeTable from '../../modals/Examinations/ModalPublishTimeTable';



function CreateExamTimetable({ setLoading }) {


  //get data from local storage

  //////program data//////

  //function to get data
  const getLocalProgramData = () => localStorage.getItem(LOCAL_PROGRAM) ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM)) : null;

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  useEffect(() => {
    setProgramOpt(getLocalProgramData())
  }, [localStorage.getItem(LOCAL_PROGRAM)])

  //////College Data//////

  //function to get data
  const getLocalCollegeData = () => localStorage.getItem(LOCAL_COLLEGE) ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE)) : null;

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData())
  }, [localStorage.getItem(LOCAL_COLLEGE)])

  //////Department Data//////

  //function to get data
  const getLocalDeptData = () => localStorage.getItem(LOCAL_DEPARTMENT) ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT)) : null;

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData())
  }, [localStorage.getItem(LOCAL_DEPARTMENT)])



  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: '',
    college_id: '',
    class_id: '',
    semester_id: '',
    month: '',
    year: '',
    department_id: ''
  })

  //main object for data manipulation
  const [mainUser, setMainUser] = useState({
    class_examination_id: '',
    course_id: '',
    date: '',
    time_from: '',
    time_to: '',
    duration: '',
    max_marks: '',
    min_marks: ''
  })


  //Data for adding time table 
  const [addData, setAddData] = useState()


  /////Other Data States for 

  //main data
  const [data, setData] = useState([])

  //class data
  const [classOpt, setClassOpt] = useState([])

  //semster data
  const [semesterOpt, setSemesterOpt] = useState([])

  //subject/course Data
  const [courseOpt, setCourseOpt] = useState([])

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id)

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState([])



  ///////Other states for management//////////
  const [newValue, setNewValue] = useState(false)

  const [showProgram, setShowProgram] = useState()
  const [showCollege, setShowCollege] = useState()
  const [showMonth, setShowMonth] = useState()
  const [showYear, setShowYear] = useState()




  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prev => ({
      ...prev,
      [name]: value
    }));
  }



  /////////function calls from db to fe///////////
  //get all required data for creating a exam time tabel i.e, class and semester 
  const getBasicData = async () => {
    const config = {
      method: 'get',
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }

    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({ ...config, url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}` })
        .then(res => {
          setClassOpt(res.data.data)
        })
        .catch(err => flag = 1)
      ,
      axios({ ...config, url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}` })
        .then(res => {
          setSemesterOpt(res.data.data)
        })
        .catch(err => flag = 1)
    ])

    if (flag) return toast.error('Something went wrong')
  }

  //Get Exam Details
  const getData = async (exam_id) => {
    setLoading(1)
    const config = {
      method: 'get',
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      }
    }

    await axios(config)
      .then(res => {
        console.log(res);
        setData(res.data.data)
      })
      .catch(err => console.log(err))


    await setShowCollege(user?.college_id)
    await setShowProgram(user?.program_id)
    await setShowMonth(user?.month)
    await setShowYear(user?.year)
    setLoading(0)
  }

  //Get Committee Data
  const getCommiittee = async () => {
    if (!user.program_id || !user.college_id || !user.month || !user.year || !user?.class_id || !user?.semester_id) return toast.error('All fileds are required for search')
    setLoading(1)
    const config = {
      method: 'get',
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    }
    await axios(config)
      .then(res => {
        if (res.data.data.length != 0) {

          const m = []
          empOpt?.map(s => {
            if (res.data.data[0]?.employee?.find(k => k == s?.id)) m.push(s)
          })
          setEmployeeOpt(m)
          setMainUser(prev => ({
            ...prev,
            exam_committee_id: res.data.data[0].id
          }))
          getBasicData()
          getData(res.data.data[0].id);
        }
        else {
          toast.warning('Committe is not exist')
        }
      })
      .catch(err => {
        toast.error('Something Went Wrong')
      })

    setLoading(0)
    // getBasicData();
  }



  //useEffects
  useEffect(() => {
    getBasicData()
  }, [])



  return (

    <div className="CreateExamTimetable">
      <ModalPublishTimeTable
        addData={addData}
        data={user}
        programOpt={programOpt}
        collegeOpt={collegeOpt}
        classOpt={classOpt}
        semesterOpt={semesterOpt}
        showCollege={showCollege}
        showProgram={showProgram}
        showMonth={showMonth}
        showYear={showYear}
        setLoading={setLoading}
        reloadData={()=>getData(setMainUser?.exam_committee_id)}
      />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Create Timetable</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Create Timetable
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {
                              programOpt?.map((i, key) => (
                                <option value={i?.id} key={key}>{i?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {
                              collegeOpt?.map((i, key) => (
                                <option value={i?.id} key={key}>{i?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className='form-control'
                            name='year'
                            id="year"
                            value={user?.year + '-' + user?.month}
                            onChange={e => {
                              setUser(prev => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }))
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control">
                            <option value="">Select Department</option>
                            {
                              DeptOpt?.filter(s => s.college_id == user?.college_id)?.map((i, key) => (
                                <option value={i?.id} key={key}>{i?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control">
                            <option value="">Select Class</option>
                            {
                              classOpt?.filter(s => s.college_id == user?.college_id)?.map((i, key) => (
                                <option value={i?.id} key={key}>{i?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control">
                            <option value="">Select Semester</option>
                            {
                              semesterOpt?.filter(s => s.class_id == user?.class_id)?.map((i, key) => (
                                <option value={i?.id} key={key}>{i?.name}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}

                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" /> Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>


            <div className="row">

              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className='card-title text-uppercase '>List</h4>
                    <hr />

                    <div className="table-responsive">

                      <table
                        id=""
                        className="display table table-bordered  nowrap table-hover "
                        style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}
                      >
                        <thead>
                          <tr>
                            <th> Sl. No.</th>
                            <th>Course</th>
                            <th>Specialization</th>
                            <th>Date</th>

                            <th>Faculties</th>

                            <th>Course Year</th>

                            <th>Semester</th>



                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>



                          {
                            data && data?.map((i, key) => {
                              return <tr key={key}>
                                <td>{key + 1}</td>
                                <td>{programOpt?.find(s => s.id == showProgram)?.name}</td>
                                <td> {collegeOpt?.find(s => s.id == showCollege)?.name}</td>
                                <td > {showMonth + ',' + showYear}</td>
                                <td> {i?.employee?.map((j, key2) => (
                                  <div>{key2 + 1}. {employeeOpt?.find(s => s.id == j)?.first_name}</div>
                                ))}</td>
                                <td> {classOpt?.find(s => s.id == i.class_id)?.name}</td>
                                <td> {semesterOpt?.find(s => s.id == i.semester_id)?.name}</td>
                                <td> {i?.status}</td>


                                <td>
                                  <a
                                    data-toggle="modal"
                                    data-target="#create"
                                    className='badge badge-light text-dark mr-3'
                                    title="Create Timetable"
                                    onClick={() => setAddData(i)}
                                  >
                                    <i class="fa fa-edit " aria-hidden="true"></i>
                                  </a>
                                </td>
                              </tr>

                            })


                          }
                        </tbody>
                      </table>



                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateExamTimetable