import React from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ROUTES } from '../../Router/routerConfig'
import { SESSION_AUTH, SESSION_ROLE } from '../../utils/sessionStorageContants'

const FromAdmin = () => {

    const {id} = useParams()

    const navigate = useNavigate()

    const Login = () => {
        sessionStorage.setItem(SESSION_ROLE,'COE');
        sessionStorage.setItem(SESSION_AUTH,id);
        toast.success("Welcome to Examination Portal Admin")
        navigate(ROUTES.Examination.AddEvaluators)
    }

    useEffect(()=>{
        if(id){
            Login();
        }
    },[id])
  return (
    <div className='p-5'>Loading ......................</div>
  )
}

export default FromAdmin