import React, { useEffect } from "react";
import { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Slider, Switch, TimePicker } from "antd";
import { Http } from "../../../../Services/Services";
import {
  EXAM_MASTER,
  NEW_EXAM_TIMETABLE,
  RE_EXAM_TIMETABLE,
} from "../../../../utils/Examination.apiConst";
import { ACADEMICS_ADD_SUBJECT } from "../../../../utils/Academics.apiConst";
import { toast } from "react-toastify";
import { getFileUrl } from "../../../../Helpers/Helpers";
import { ASSET_EMPLOYEE_IMAGE } from "../../../../utils/AssetsReferenceTypes";

const TimeTable = () => {
  const { examId } = useParams();

  const [loading, setLoading] = useState(false);

  const { examData } = useOutletContext();

  // console.log(examData);

  const [data, setData] = useState([]);
  const [editableRows, setEditableRows] = useState("");
  const [subjectOpt, setSubjectOpt] = useState([]);

  const [instructionFlag, setInstructionFlag] = useState(false);
  const [remunerationFlag, setRemunerationFlag] = useState(false);
  const [masterData, setMasterData] = useState([]);

  const [user, setUser] = useState({
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
    instructions: "",
    remuneration: "",
  });

  const handleFileUplaod = async (e, str, value) => {
    try {
      const d = await getFileUrl(
        ASSET_EMPLOYEE_IMAGE,
        `Examination_${str}`,
        e.target.value.split(".")[1],
        setLoading,
        e.target.files[0]
      );
      setUser((prev) => ({
        ...prev,
        [e.target.name]: d,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

 

  const getData = () => {
    Http.get(`${NEW_EXAM_TIMETABLE}?class_examination_id=${examId}`)
      .then((res) => {
        // console.log(res.data.data);
        setData(res.data.data);
        setEditableRows({}); // Clear the editableRows state when data is fetched
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMasterData = async () => {
    Http.get(EXAM_MASTER)
      .then((res) => {
        setMasterData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearData = () => {
    setUser({
      course_id: "",
      date: "",
      time_from: "",
      time_to: "",
      duration: "",
      max_marks: "",
      min_marks: "",
      instructions: "",
      remuneration: "",
    });
  };

  const getSubjectData = () => {
    Http.get(`${ACADEMICS_ADD_SUBJECT}?semester_id=${examData?.semester_id}`)
      .then((res) => {
        // console.log("subjects"+res.data.data)
        setSubjectOpt(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = () => {
    console.log(user);
    Http.post(NEW_EXAM_TIMETABLE, { ...user, class_examination_id: examId, attachment: user.instructions, attachment1: user.remuneration })
      .then((res) => {
        toast.success("Time Table Added Successfully");
        getData();
        clearData();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (examId) getData();
    getMasterData();
  }, [examId]);

  useEffect(() => {
    if (examData?.semester_id) getSubjectData();
  }, [examData?.semester_id]);




  const handleTimeChange = (field, newValue) => {
    setUser((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  useEffect(() => {
    // Calculate duration when time_from and time_to change

    if (user.time_from && user.time_to) {
      const startTime = new Date(`2000-01-01T${user.time_from}`);
      const endTime = new Date(`2000-01-01T${user.time_to}`);
      const timeDiffInMillis = endTime - startTime;
      const durationInMinutes = timeDiffInMillis / (1000 * 60);
      setUser((prev) => ({
        ...prev,
        duration: durationInMinutes,
      }));
    }
  }, [user.time_from, user.time_to]);  

 

  const handleEdit = (rowIndex) => {
    setEditableRows((prev) => ({
      ...prev,
      [rowIndex]: true,
    }));
    setUser({ ...data[rowIndex] }); // Set the user state to the data of the row being edited
  };

  const handleSave = (rowIndex) => {
    // Update the data for the row and toggle off editing mode
    const updatedData = [...data];
    updatedData[rowIndex] = { ...user };
    setData(updatedData);
    setEditableRows((prev) => ({
      ...prev,
      [rowIndex]: false,
    }));

    // Clear the user state
    setUser({
      course_id: "",
      date: "",
      time_from: "",
      time_to: "",
      duration: "",
      max_marks: "",
      min_marks: "",
      instructions: "",
      remuneration: "",
    });
  };

  return (
    <div className="card" style={{ maxWidth: "100%", overflow: "scroll" }}>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12">
            <h6 className="card-header">Time Table</h6>
          </div>
        </div>

        <div className="row mt-2">
          <div className="table-responsive">
            <table className="table table-bordered">
              <tr>
                <th>Sl. No</th>
                <th style={{ minWidth: "150px" }}>Subjects</th>
                <th>Date</th>
                <th style={{ minWidth: "150px" }}>Time From</th>
                <th style={{ minWidth: "150px" }}>Time To</th>
                <th>
                  Duration <br /> (in min)
                </th>

                <th style={{ minWidth: "100px" }}> Marks (Max..)</th>
                <th style={{ minWidth: "100px" }}>Marks (Min..)</th>
                <th>Instructions</th>
                <th>Remuneration</th>

                <th>Action</th>
              </tr>
              {data &&
                data.map((i, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    {/* <td>
                      {subjectOpt?.find((s) => s.id == i?.course_id)?.name}
                    </td> */}

                  <td>
                      {editableRows[key] ? (
                        <select
                          name="course_id"
                          className="form-control"
                          value={user?.course_id}
                          onChange={handleChange}
                        >
                          <option value="">Select Subject</option>
                          {subjectOpt &&
                            subjectOpt.map((subj, subjKey) => (
                              <option key={subjKey} value={subj?.id}>
                                {subj?.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        subjectOpt?.find((s) => s.id == i?.course_id)?.name
                      )}
                    </td>
                    <td>{i?.date?.split("T")[0]}</td>
                    <td>{i?.time_from}</td>
                    <td>{i?.time_to}</td>
                    <td>{i?.duration}</td>
                    <td>{i?.max_marks}</td>
                    <td>{i?.min_marks}</td>
                    <th>
                      { i?.attachment
                        ?
                        <a href={i?.attachment} target="_blank">Click Here</a>
                        :
                        "Not Attached"
                      }
                  </th>
                    <th>
                      { i?.attachment1
                        ?
                        <a href={i?.attachment1} target="_blank">Click Here</a>
                        :
                        "Not Attached"
                      }
                      </th>

                    {/* <th><button className="btn btn-primary"  onClick={() => handleEdit(key)}> Edit </button></th> */}

                    <td>
                      {editableRows[key] ? (
                        <button
                          className="btn btn-success"
                          onClick={() => handleSave(key)}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleEdit(key)}
                        >
                          Edit
                        </button>
                      )}
                    </td>
                                    
                  </tr>
                ))}
              <tr>
                <td>{data?.length + 1}</td>
                <td>
                  <select
                    name="course_id"
                    className="form-control"
                    value={user.course_id}
                    onChange={handleChange}
                  >
                    <option value="">Select Subject</option>
                    {subjectOpt &&
                      subjectOpt.map((i, key) => (
                        <option key={key} value={i?.id}>
                          {i?.name}
                        </option>
                      ))}
                  </select>
                </td>
                <td>
                  <input
                    type="date"
                    className="form-control"
                    value={user.date}
                    onChange={handleChange}
                    name="date"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control"
                    id=""
                    value={user.time_from}
                    onChange={handleChange}  
                  //  onChange={(e) => handleTimeChange('time_from', e.target.value)}                                 
                    name="time_from"
                  />
                </td>
                <td>
                  <input
                    type="time"
                    className="form-control"
                    id=""
                    value={user.time_to}
                    onChange={handleChange}
                    name="time_to"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.duration}
                    onChange={handleChange}
                    name="duration"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.max_marks}
                    onChange={handleChange}
                    placeholder="Enter Max marks"
                    name="max_marks"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={user.min_marks}
                    onChange={handleChange}
                    placeholder="Enter Min marks"
                    name="min_marks"
                  />
                </td>
                <td>
                  <div
                    className="d-flex align-items-center mb-2"
                    style={{ gap: "8px", whiteSpace: "nowrap" }}
                  >
                    Select Master{" "}
                    <Switch
                      checked={instructionFlag}
                      onChange={(e) => setInstructionFlag(e)}
                    />{" "}
                    Upload
                  </div>
                  {instructionFlag ? (
                    <input
                      onChange={handleFileUplaod}
                      type="file"
                      name="instructions"
                      className="form-control"
                    />
                  ) : (
                    <select
                      onChange={handleChange}
                      name="instructions"
                      className="form-control"
                      id=""
                    >
                      {masterData.length != 0 ? 
                      <>
                      <option value="">Select Master</option>
                      {
                        masterData?.map((d, i) => (
                          <option key={i} value={d?.url}>
                            {d?.title}
                          </option>
                        ))
                      }
                      </>
                      : (
                        <option value="">No Master Data</option>
                      )}
                    </select>
                  )}
                </td>
                <td>
                  <div
                    className="d-flex align-items-center mb-2"
                    style={{ gap: "8px", whiteSpace: "nowrap" }}
                  >
                    Select Master{" "}
                    <Switch
                      checked={remunerationFlag}
                      onChange={(e) => setRemunerationFlag(e)}
                    />{" "}
                    Upload
                  </div>
                  {remunerationFlag ? (
                    <input
                      onChange={handleFileUplaod}
                      type="file"
                      name="remuneration"
                      className="form-control"
                    />
                  ) : (
                    <select
                      onChange={handleChange}
                      name="remuneration"
                      className="form-control"
                      id=""
                    >
                      {masterData.length != 0 ? 
                      <>
                      <option value="">Select Master</option>
                      {
                        masterData?.map((d, i) => (
                          <option key={i} value={d?.url}>
                            {d?.title}
                          </option>
                        ))
                      }
                      </>
                      : (
                        <option value="">No Master Data</option>
                      )}
                    </select>
                  )}
                </td>
                <td>
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary btn-sm"
                  >
                    add
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTable;
