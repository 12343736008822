import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PREV_QUESTION_PAPER_GET, PREV_QUESTION_PAPER_PUT } from '../../utils/InfoUploadingApiConst';
import { ACADEMICS_ADD_CLASS, ACADEMICS_ADD_SEMESTER, ACADEMICS_ADD_SUBJECT } from '../../utils/Academics.apiConst';


function ViewPrevPaper({ setLoading }) {
    const [data, setData] = useState([]);

    const [facultyopt, setFacultyOpt] = useState(
        JSON.parse(localStorage.getItem("COLLEGE"))
    );

    const [classopt, setClassOpt] = useState([]);
    const [semesterOpt, setSemesterOpt] = useState([]);
    const [subjectOpt, setSubjectOpt] = useState([]);

    useEffect(() => {
        const config = {
          method: "get",
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
            "Content-Type": "application/json",
          },
        };
    
        axios({
          ...config,
          url: `${ACADEMICS_ADD_CLASS}`,
        })
          .then((res) => {
            setClassOpt(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
    
        axios({
          ...config,
          url: `${ACADEMICS_ADD_SEMESTER}`,
        })
          .then((res) => {
            setSemesterOpt(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });

        axios({
          ...config,
          url: `${ACADEMICS_ADD_SUBJECT}`,
        })
          .then((res) => {
            setSubjectOpt(res.data.data);
          })
          .catch((err) => {
            console.log(err);
        });
    
      }, []);
    

    const navigate = useNavigate();

    const getData = async () => {
        const config = {
            method: "get",
            url: PREV_QUESTION_PAPER_GET,
            headers: {
                "Content-Type": "application/json",
            },
        };

        await axios(config)
            .then((res) => {
                res.data.data.sort((a, b) => b.id - a.id);
                res.data.data.forEach((element) => {
                    element.attachments = JSON.parse(element.attachments);
                });
                console.log(res.data.data)
                setData(res.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getData();
    }, []);

    const handleDelete = (id) => {
        const config = {
            method: 'put',
            url: `${PREV_QUESTION_PAPER_PUT}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data: {
                status: 'INACTIVE'
            }
        }

        axios(config)
            .then(res => {
                setLoading(0)
                toast.success("Success")
                const updatedData = data.map(item => (item.id === id ? { ...item, status: 'INACTIVE' } : item));
                setData(updatedData);
            })
            .catch(err => {
                setLoading(0)
                toast.error("Something Went Wrong")
            })
    }

    const handleApprove = (id) => {
        const config = {
            method: 'put',
            url: `${PREV_QUESTION_PAPER_PUT}/${id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data: {
                status: 'ACTIVE'
            }
        }

        axios(config)
            .then(res => {
                setLoading(0)
                toast.success("Success")
                const updatedData = data.map(item => (item.id === id ? { ...item, status: 'ACTIVE' } : item));
                setData(updatedData);
            })
            .catch(err => {
                setLoading(0)
                toast.error("Something Went Wrong")
            })
    }
    

    return (
        <div>       

            <div class="card">

                <div class="card-header">
                    {/* <h3 class="text-dark pt-3"> Previous Question Papers </h3> */}
                    <h3 class="text-dark pt-3"> PREVIOUS QUESTION PAPERS </h3>
                </div>

                <div className="card-body">
                    <table className="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th>Sl.No.</th>
                            <th>Faculty</th>
                            <th>Class</th>
                            <th>Course Name</th>
                            <th>Code</th>
                            <th>Title</th>
                            <th>Attachments</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>

                            {
                                data && data?.map((item, key) => {
                                    return (

                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>{facultyopt?.find((s) => s?.id == data[0]?.college_id)?.name || "N/A"}</td>
                                            <td>{classopt?.find(s=>s?.id == data[0]?.class_id)?.name}</td>
                                            <td>{subjectOpt?.find(s=>s?.id == item?.subject_id)?.name}</td> 
                                            <td>{subjectOpt?.find(s=>s?.id == item?.subject_id)?.code}</td>
                                            <td>{item?.title}</td>
                                            <td><a href={item?.attachments} target="_blank">View Uploaded File</a></td>
                                            <td>{item?.status}</td>
                                            <td>{item?.status == "INACTIVE" ? <button className="btn btn-danger mr-2" onClick={() => handleApprove(item?.id)}>INACTIVE</button> : <button className="btn btn-success mr-2" onClick={() => handleDelete(item?.id)}>Active</button>}</td>
                                        </tr>

                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>        

        </div>
           
    )
}

export default ViewPrevPaper;
