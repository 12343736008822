import React, { useEffect, useState } from 'react'
import { Http } from '../../Services/Services'
import { toast } from 'react-toastify'
import { NEW_EXAM_RECHECKING } from '../../utils/Examination.apiConst'

const ModalNewExamReChecking = ({
    reloadData,
    setLoading,
    reData
}) => {

    const [reScore, setReScore] = useState(reData?.new_marks||'')

    useEffect(() => {
        setReScore(reData?.new_marks||'')
    }, [reData])

    const handleSubmit = async () => {
        setLoading(true)
        if(reData?.re_id){
            const obj = {
                new_marks:reScore
            }
            await Http.put(NEW_EXAM_RECHECKING+'/'+reData?.re_id,obj)
            .then((res)=>{
                reloadData()
                toast.success('Rechecking Done');
            })
            .catch((err)=>{
                console.log(err);
                toast.error('Something went wrong');
            })
        }
        else{
            const obj = {
                old_marks:reData?.m_marks,
                new_marks:reScore,
                time_table_id:reData?.time_table_id,
                exam_marks_id:reData?.marks_id,
                student_id:reData?.student_id
            }
            await Http.post(NEW_EXAM_RECHECKING,obj)
            .then((res)=>{
                reloadData()
                toast.success('Rechecking Done');
            })
            .catch((err)=>{
                console.log(err);
                toast.error('Something went wrong');
            })
        }
    }


  return (
    <div>
            <div
                className="modal fade"
                id="recheck"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog mw-75 w-100 ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                                Rechecking
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="row p-4">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Student Id</label>
                                    <input value={reData?.student_id} type="text" disabled className="form-control" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">Marks Obtained</label>
                                    <input value={reData?.re_id?reData?.old_marks:reData?.m_marks} type="number" disabled className="form-control" />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="">New Marks Obtained</label>
                                    <input value={reScore} onChange={(e)=>{setReScore(e.target.value)}} type="number" className="form-control" placeholder='Enter New Marks Obtained' />
                                </div>
                            </div>
                            <div className="col-12 mt-3 d-flex justify-content-between">
                                <button 
                                data-dismiss="modal"
                                aria-label="Close" 
                                className='btn btn-danger'
                                >
                                    Cancel
                                </button>
                                <button 
                                className='btn btn-success'
                                onClick={handleSubmit}
                                data-dismiss="modal"
                                aria-label="Close" 
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ModalNewExamReChecking