import React, { useEffect, useState } from "react";
import { Http } from "../../Services/Services";
import { PRE_EXAM_INVALIGATOR } from "../../utils/Examination.apiConst";
import { LOCAL_EMPLOYEE } from "../../utils/LocalStorageConstants";
import Select from "react-select";
import { toast } from "react-toastify";

const ModalInvigilator = ({timeTableId}) => {

  const [inputFields, setInputFields] = useState({ 
    is_employee: true,
    name: "", 
    email: "", 
    employee_id:" "
  },
  );

  const [empData, setEmpdata] = useState([])

  

  const [data, setData] = useState([])

  const handleSubmit = async(e) => {
    if(!timeTableId) window.location.reload()
    if(!inputFields.name || !inputFields.email) return toast.error("Please fill all the fields")


    const obj = {
      time_table_id: timeTableId,
      is_employee: inputFields.is_employee,
      employee_id: inputFields.employee_id,
      name: inputFields.name,
      email: inputFields.email
    }
    await Http.post(PRE_EXAM_INVALIGATOR, obj)
    .then(res=>{
      toast.success("Evaluator Added Successfully")
      setInputFields({
        is_employee: true,
        name: "", 
        email: "", 
        employee_id:" "
      })
      getData()
    })
    .catch(err=>{
      console.log(err);
    })
  }

  const getData = async() => {
    await Http.get(`${PRE_EXAM_INVALIGATOR}?time_table_id=${timeTableId}`)
    .then(res=>{
      setData(res.data.data)
    })
    .catch(err=>{
      console.log(err);
    })
  }

  useEffect(()=>{
    if(timeTableId) getData()
  },[timeTableId])

  useEffect(()=>{
    const employeeList = JSON.parse(localStorage.getItem(LOCAL_EMPLOYEE))
    
    setEmpdata(employeeList.map((i)=>({label:i.first_name+" "+i.last_name,email:i.email,value:i.id})))
  },[])
    
  return (
    <div
      className="modal fade"
      id="modal1"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-75 w-100 modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Add Details
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div className="modal-body">
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-bordered">
                            <tr>
                                <th>
                                    Sl No
                                </th>
                                <th>
                                    Is Employee
                                </th>
                                <th>
                                    Employee Name
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Action
                                </th>
                            </tr>

                            {
                              data?.length != 0
                              ?
                              data?.map((i,key)=>(
                                <tr>
                                  <td>{key+1}</td>
                                  <td>{i?.is_employee?"Yes":"No"}</td>
                                  <td>{i?.name}</td>
                                  <td>{i?.email}</td>
                                  <td>{i?.status}</td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                </tr>
                              ))
                              :
                              <tr>
                                <td colSpan={7}>
                                  No Data Found
                                </td>
                              </tr>
                            }

                            <tr>
                              <td>

                              </td>
                              <td>
                                <select value={inputFields.is_employee} onChange={e=>{setInputFields(prev=>({...prev,is_employee:e.target.value}))}} name="" className="form-control" id="">
                                  <option value={true}>Yes</option>
                                  <option value={false}>No</option>
                                </select>
                              </td>
                              <td>
                                {
                                  inputFields?.is_employee=="true"
                                  ?
                                  <Select
                                options={empData}
                                onChange={e=>{
                                  console.log(e);
                                  setInputFields(prev=>({...prev,employee_id:e.value,name:e.label, email:e.email}))
                                }}
                                value={empData.find(obj => obj.value === inputFields.employee_id)}
                                />
                                :
                                <input value={inputFields.name} onChange={e=>{setInputFields(prev=>({...prev, name:e.target.value}))}} type="text" className="form-control" placeholder="Enter Name" />
                                }
                                
                              </td>
                              <td>
                                <input value={inputFields.email} onChange={e=>{setInputFields(prev=>({...prev, email:e.target.value}))}} type="email" className="form-control" placeholder="Enter Email" />
                              </td>
                              <td>
                                <span className="badge badge-soft-primary">
                                  Clck here to add {`->`}
                                </span>
                              </td>
                              <td>
                                <button onClick={handleSubmit} className="btn btn-sm btn-primary">
                                  Add
                                </button>
                              </td>
                            </tr>

                        </table>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInvigilator;
